import {useParams} from 'react-router-dom';
import type {ReactNode} from "react";
import EmployeeForm from "@/components/Forms/EmployeeForm.tsx";
import {useEmployee} from "@/contexts/EmployeeProvider.tsx";
import {Alert} from "@mui/material";

const EmployeeDetail = (): ReactNode => {
    const {hasPermission} = useEmployee()
    const { employeeId } = useParams<{ employeeId: string }>();

    if (!employeeId) {
        return <div>No Employee ID provided</div>;
    }

    return <>
        {!hasPermission("edit:employee") && <Alert severity={'error'}>You do not have access to this page.</Alert> }
        {hasPermission("edit:employee") && <EmployeeForm employeeId={employeeId}/>}
    </>
};

export default EmployeeDetail;
