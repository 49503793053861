import {useMemo, useState} from 'react';
import {
    MaterialReactTable,
    type MRT_ColumnDef,
    type MRT_ColumnFiltersState,
    type MRT_PaginationState,
    type MRT_Row,
    type MRT_SortingState,
    useMaterialReactTable,
} from 'material-react-table';
import {Box, IconButton} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import {enqueueSnackbar} from "notistack";
import {getErrorMessage} from "@/utils/api.ts";
import {useDeletePersonAddress} from "@/mutations/useDeletePersonAddress.tsx";
import {type MrtAddress, useFetchMrtAddressesForPerson} from "@/queries/useFetchMrtAddressesForPerson.ts";
import EditIcon from "@mui/icons-material/Edit";
import AddEditPersonAddressDialog from "@/components/Forms/SubForms/AddEditPersonAddressDialog.tsx";
import {useEmployee} from "@/contexts/EmployeeProvider.tsx";

type Props = {
    personUUID: string;
};

const MrtPersonAddressesTable = ({personUUID} : Props) => {
    const {hasPermission} = useEmployee()
    const [editAddressId, setEditAddressId] = useState<string | undefined>();
    const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
        [],
    );
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState<MRT_SortingState>([]);
    const [pagination, setPagination] = useState<MRT_PaginationState>({
        pageIndex: 0,
        pageSize: 10,
    });

    const deletePersonAddress = useDeletePersonAddress();
    const {data, isError, isRefetching, isLoading, error} = useFetchMrtAddressesForPerson({
        personUUID: personUUID,
        columnFilters,
        globalFilter,
        pagination,
        sorting,
    });

    const handleDeleteRow = (row: MRT_Row<MrtAddress>) => {
        if (row.original.idUUID) {
            if (window.confirm('Are you sure you want to delete this address?')) {
                deletePersonAddress.mutate(
                    {
                        addressUUID: row.original.idUUID,
                    },
                    {
                        onSuccess: () => {
                            enqueueSnackbar("Address removed.", { variant: "success" });
                        },
                        onError: (error) => {
                            enqueueSnackbar("Error: Address could not be removed.", { variant: "error" });
                            console.info('error', getErrorMessage(error));
                        },
                    },
                )
            }
        }
    };

    const handleEditRow = (idUUID: string) => {
        setEditAddressId(idUUID);
    }

    const columns = useMemo<MRT_ColumnDef<MrtAddress>[]>(
        () => {
            const columnDefs: MRT_ColumnDef<MrtAddress>[] = [
                {
                    accessorKey: 'edit',
                    header: '',
                    enableSorting: false,
                    Cell: ({row}) => (
                        <IconButton
                            onClick={(e) => {
                                e.stopPropagation();

                                if (row.original.idUUID) {
                                    handleEditRow(row.original.idUUID);
                                }
                            }}
                            size="small"
                        >
                            <EditIcon />
                        </IconButton>
                    ),
                    size: 50,
                },
                {
                    accessorKey: 'addressType',
                    header: 'Type',
                    size: 50,
                },
                {
                    accessorKey: 'address1',
                    header: 'Address',
                },
                {
                    accessorKey: 'address2',
                    header: 'Apt/Suite',
                    size: 50,
                },
                {
                    accessorKey: 'city',
                    header: 'City',
                    size: 50,
                },
                {
                    accessorKey: 'state',
                    header: 'State',
                    size: 50,
                },
                {
                    accessorKey: 'zip',
                    header: 'Zip',
                    size: 50,
                },
            ]

            if (hasPermission("delete:contact-info")) {
                columnDefs.push({
                    accessorKey: 'delete',
                    header: 'Delete',
                    size: 50,
                    Cell: ({ row }) => (
                        <IconButton onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                            event.stopPropagation();
                            handleDeleteRow(row)
                        }}>
                            <DeleteIcon />
                        </IconButton>
                    ),
                });
            }

            return columnDefs;
        }, [handleDeleteRow, handleEditRow, hasPermission]);

    const table = useMaterialReactTable({
        columns,
        data: (isLoading || isRefetching || isError || data === undefined) ? [] : data,
        initialState: {
            showColumnFilters: false,
            density: 'compact',
        },
        enableTopToolbar: false,
        manualFiltering: true,
        manualPagination: true,
        manualSorting: true,
        muiToolbarAlertBannerProps: isError
            ? {
                color: 'error',
                children: 'Error loading data',
            }
            : undefined,
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        onPaginationChange: setPagination,
        onSortingChange: setSorting,
        rowCount: 0,
        state: {
            columnFilters,
            globalFilter,
            isLoading,
            pagination,
            showAlertBanner: isError,
            showProgressBars: isRefetching,
            showLoadingOverlay: (isLoading || isRefetching),
            sorting,
        },
    });



    if (isError) {
        return (
            <div>{error.message}</div>
        );
    }

    if (data === undefined) {
        return <div>No data</div>;
    }

    return <Box>
        <MaterialReactTable table={table} />
        <AddEditPersonAddressDialog
            personUUID={personUUID}
            showAddButton={false}
            editAddressId={editAddressId}
            clearEditAddressId={() => {
                setEditAddressId(undefined)
            }}
        />
    </Box>;
};

export default MrtPersonAddressesTable;
