import useAuthenticatedFetch from "@/hooks/useAuthenticatedFetch.ts";
import {apiUrl} from "@/utils/api.ts";
import {useMutation, type UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {handleJsonApiError} from "jsonapi-zod-query";

export const useDeleteCase = (): UseMutationResult<void, Error, { caseUUID: string }> => {
    const fetch = useAuthenticatedFetch();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async ({ caseUUID }) => {
            const response = await fetch(apiUrl(`/case/delete`), {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/vnd.api+json",
                },
                body: JSON.stringify({
                    caseUUID: caseUUID,
                }),
            });
            await handleJsonApiError(response);
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: ["case-table-data"],
            });
            await queryClient.invalidateQueries({
                queryKey: ["dashboard-cases-table-data"],
            });
        },
    });
};
