import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid2 as Grid,
    MenuItem,
    Stack
} from "@mui/material";
import {type BaseSyntheticEvent, type ReactNode, useCallback, useState} from "react";
import {enqueueSnackbar} from "notistack";
import {getErrorMessage} from "@/utils/api.ts";
import {type NewEmployee, useAddEmployee} from "@/mutations/useAddEmployee.tsx";
import {FormProvider, type SubmitHandler, useForm} from "react-hook-form";
import {RhfTextField} from "mui-rhf-integration";
import type {z} from "zod";
import {addEmployeeSchema} from "@/components/Forms/schema/saveEmployeeSchema.ts";
import {zodResolver} from "@hookform/resolvers/zod";
import {attorneyTypeOptions, dashboardVersionOptions} from "@/components/Person/EmployeeInformation.tsx";
import {Link} from "react-router-dom";

export type AddEmployeeFormValues = z.infer<typeof addEmployeeSchema>;

const AddEmployeeButton = (): ReactNode => {
    const [open, setOpen] = useState(false);
    const [newEmployeeId, setNewEmployeeId] = useState('');
    const addEmployeeMutation = useAddEmployee();

    const form = useForm<AddEmployeeFormValues>({
        resolver: zodResolver(addEmployeeSchema),
    });

    const dashboardVersion = form.watch('dashboardVersion');

    const handleSubmit = useCallback(
        (values: AddEmployeeFormValues) => {
            addEmployeeMutation.mutate(
                {
                    ...values,
                },
                {
                    onSuccess: (employee: NewEmployee) => {
                        enqueueSnackbar("Employee added.", { variant: "success" });

                        if (employee.idUUID) {
                            setNewEmployeeId(employee.idUUID);
                        }
                    },
                    onError: (error) => {
                        enqueueSnackbar("Error: Add Employee failed.", { variant: "error" });
                        console.info('error', getErrorMessage(error));
                    },
                },
            );
        },
        [addEmployeeMutation],
    );

    const onSubmit: SubmitHandler<AddEmployeeFormValues> = async (
        data: AddEmployeeFormValues,
        event?: BaseSyntheticEvent
    ) => {
        event?.stopPropagation();
        handleSubmit(data);
    }

    const closeDialog = () => {
        setOpen(false);
        setNewEmployeeId('');
        form.reset();
    }

    return (
        <>
            <Button
                variant={'contained'}
                onClick={() => setOpen(true)}
            >
                Add Employee
            </Button>
            <Dialog open={open} maxWidth="sm" fullWidth>
                <FormProvider {...form}>
                    <form>
                        <DialogTitle>New Employee</DialogTitle>
                        <DialogContent dividers>
                            {newEmployeeId && <Stack
                                sx={{
                                    flexDirection: 'column',
                                }}
                            >
                                <Box>Employee created successfully.</Box>
                                <Box>Click <Link to={`/employee-detail/${newEmployeeId}`}>here</Link> to open the Employee in a new tab.</Box>
                            </Stack>}
                            {!newEmployeeId && <Grid container spacing={2}>
                                <Grid size={6} sx={{p: 1}}>
                                    <RhfTextField
                                        control={form.control}
                                        name={'nameFirst'}
                                        label={'First Name'}
                                        sx={{
                                            width: '100%',
                                        }}
                                    />
                                </Grid>
                                <Grid size={6} sx={{p: 1}}>
                                    <RhfTextField
                                        control={form.control}
                                        name={'nameLast'}
                                        label={'Last Name'}
                                        sx={{
                                            width: '100%',
                                        }}
                                    />
                                </Grid>
                                <Grid size={6} sx={{p: 1}}>
                                    <RhfTextField
                                        select
                                        control={form.control}
                                        name={'dashboardVersion'}
                                        label={'Dashboard Version'}
                                        sx={{
                                            width: '100%',
                                        }}
                                    >
                                        {dashboardVersionOptions.map((type) => <MenuItem
                                            key={`dashboard-version-${type.value}`}
                                            value={type.value}
                                        >
                                            {type.text}
                                        </MenuItem>)}
                                    </RhfTextField>
                                </Grid>
                                {dashboardVersion === 'Attorney' && <Grid size={6} sx={{p: 1}}>
                                    <RhfTextField
                                        select
                                        control={form.control}
                                        name={'attorneyType'}
                                        label={'Attorney Type'}
                                        sx={{
                                            width: '100%',
                                        }}
                                    >
                                        {attorneyTypeOptions.map((type) => <MenuItem
                                            key={`attorney-type-${type}`}
                                            value={type}
                                        >
                                            {type}
                                        </MenuItem>)}
                                    </RhfTextField>
                                </Grid>}
                                <Grid size={12} sx={{p: 1}}>
                                    <RhfTextField
                                        control={form.control}
                                        name={'eMailAddress'}
                                        label={'Email Address'}
                                        sx={{
                                            width: '100%',
                                        }}
                                    />
                                </Grid>
                            </Grid>}
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={closeDialog}>Close</Button>
                            {!newEmployeeId && <Button
                                variant={'contained'}
                                onClick={form.handleSubmit(onSubmit)}
                            >
                                Add Employee
                            </Button>}
                        </DialogActions>
                    </form>
                </FormProvider>
            </Dialog>
        </>
    );
};

export default AddEmployeeButton;
