import {Button, Grid2 as Grid, Paper, Stack, Typography} from "@mui/material";
import type {UseFormReturn} from "react-hook-form";
import type {PersonRequestSchemaType} from "@/components/Forms/PersonForm.tsx";
import {RhfTextField} from "mui-rhf-integration";

type Props = {
    form: UseFormReturn<PersonRequestSchemaType>;
};

const EmergencyContacts = ({form} : Props) => {
    return <Grid container>
        <Grid size={6}>
            <Paper
                sx={{
                    mb: 2,
                    mx: 1,
                }}
            >
                <Grid
                    container
                    sx={{
                        borderRadius: '8px',
                        p: 1,
                    }}
                >
                    <Grid size={12} sx={{p: 1, mb: 1}}>
                        <Grid size={12} sx={{mb: 1}}>
                            <Stack
                                sx={{
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    mb: 2
                                }}
                            >
                                <Typography variant={'h5'}>Emergency Contact #1</Typography>
                                {form.formState.isDirty && <Button
                                    variant='contained'
                                    type={'submit'}
                                    sx={{
                                        p: 1,
                                        borderRadius: '8px',
                                        fontWeight: 800,
                                        mr: 2,
                                    }}
                                >
                                    Save
                                </Button>}
                            </Stack>
                        </Grid>
                        <Grid size={12} sx={{mb: 1}}>
                            <RhfTextField
                                control={form.control}
                                name={'emergencyContactName1'}
                                label={'Name'}
                                sx={{
                                    width: '100%',
                                    mb: 1,
                                }}
                            />
                        </Grid>
                        <Grid size={12}>
                            <RhfTextField
                                control={form.control}
                                name={'emergencyContactPhone1'}
                                label={'Phone'}
                                sx={{
                                    width: '100%',
                                    mb: 1,
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid size={12} sx={{p: 1, mb: 1}}>
                        <Grid size={12} sx={{mb: 1}}>
                            <Typography variant={'h5'}>Emergency Contact #2</Typography>
                        </Grid>
                        <Grid size={12} sx={{mb: 1}}>
                            <RhfTextField
                                control={form.control}
                                name={'emergencyContactName2'}
                                label={'Name'}
                                sx={{
                                    width: '100%',
                                    mb: 1,
                                }}
                            />
                        </Grid>
                        <Grid size={12}>
                            <RhfTextField
                                control={form.control}
                                name={'emergencyContactPhone2'}
                                label={'Phone'}
                                sx={{
                                    width: '100%',
                                    mb: 1,
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    </Grid>
};

export default EmergencyContacts;
