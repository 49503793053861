import {useEffect, useMemo, useState} from "react";
import {
    MaterialReactTable,
    type MRT_ColumnDef,
    type MRT_ColumnFiltersState,
    type MRT_PaginationState,
    type MRT_Row,
    type MRT_SortingState,
    useMaterialReactTable,
} from "material-react-table";
import {Box, IconButton} from "@mui/material";
import {type MrtDashboardCase, useFetchMrtDashboardCases} from "@/queries/useFetchMrtDashboardCases.ts";
import {getSolReportMrtStyle} from "@/functions/getSolReportMrtStyle.ts";
import DeleteIcon from "@mui/icons-material/Delete";
import {enqueueSnackbar} from "notistack";
import {getErrorMessage} from "@/utils/api.ts";
import {useEmployee} from "@/contexts/EmployeeProvider.tsx";
import {useDeleteCase} from "@/mutations/useDeleteCase.tsx";
import {attorneyColumnDefs} from "@/components/Table/MrtCaseColumnLists/attorneyColumnDefs.tsx";
import {disbursementColumnDefs} from "@/components/Table/MrtCaseColumnLists/disbursementColumnDefs.tsx";
import {intakeColumnDefs} from "@/components/Table/MrtCaseColumnLists/intakeColumnDefs.tsx";
import AttorneyPreLitFilters from "@/components/Table/MrtCaseFilters/AttorneyPreLitFilters.tsx";
import AttorneyLitigationFilters from "@/components/Table/MrtCaseFilters/AttorneyLitigationFilters.tsx";
import CaseManagerFiltersOnTeam from "@/components/Table/MrtCaseFilters/CaseManagerFiltersOnTeam.tsx";
import DisbursementSpecialistFilters from "@/components/Table/MrtCaseFilters/DisbursementSpecialistFilters.tsx";
import IntakeSpecialistFilters from "@/components/Table/MrtCaseFilters/IntakeSpecialistFilters.tsx";
import {caseManagerOnTeamColumnDefs} from "@/components/Table/MrtCaseColumnLists/caseManagerOnTeamColumnDefs.tsx";
import {caseManagerNoTeamColumnDefs} from "@/components/Table/MrtCaseColumnLists/caseManagerNoTeamColumnDefs.tsx";
import CaseManagerFiltersNoTeam from "@/components/Table/MrtCaseFilters/CaseManagerFiltersNoTeam.tsx";

const MrtCaseTableDashboard = () => {
    const {hasPermission} = useEmployee()
    const {employee} = useEmployee();
    const deleteCase = useDeleteCase();
    const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);
    const [globalFilter, setGlobalFilter] = useState("");
    const [sorting, setSorting] = useState<MRT_SortingState>([]);
    const [pagination, setPagination] = useState<MRT_PaginationState>({
        pageIndex: 0,
        pageSize: 10,
    });

    const [selectedTab, setSelectedTab] = useState('');

    const {
        data,
        isError,
        isRefetching,
        isLoading,
        refetch,
    } = useFetchMrtDashboardCases({
        selectedTab,
        columnFilters,
        globalFilter,
        pagination,
        sorting,
    });

    const handleRowClick = (row: MRT_Row<MrtDashboardCase>) => {
        window.location.href = `/case-detail/${row.original.id}`;
    };

    const handleDeleteRow = (row: MRT_Row<MrtDashboardCase>) => {
        if (window.confirm('Are you sure you want to delete this Case?')) {
            deleteCase.mutate(
                {
                    caseUUID: row.original.idUUID,
                },
                {
                    onSuccess: () => {
                        enqueueSnackbar("Case removed.", { variant: "success" });
                    },
                    onError: (error) => {
                        enqueueSnackbar("Error: Case could not be removed.", { variant: "error" });
                        console.info('error', getErrorMessage(error));
                    },
                },
            )
        }
    };

    const columns = useMemo<MRT_ColumnDef<MrtDashboardCase>[]>(
        () => {
            let columnDefs: MRT_ColumnDef<MrtDashboardCase>[] = attorneyColumnDefs;

            if (employee?.dashboardVersion === 'CaseManager') {
                if (employee.team) {
                    columnDefs = caseManagerOnTeamColumnDefs;
                } else {
                    columnDefs = caseManagerNoTeamColumnDefs;
                }
            }

            if (employee?.dashboardVersion === 'DisbursementSpecialist') {
                columnDefs = disbursementColumnDefs;
            }

            if (employee?.dashboardVersion === 'IntakeSpecialist') {
                columnDefs = intakeColumnDefs;
            }

            if (hasPermission("delete:case") && !columnDefs.some(column => column.accessorKey === 'delete')) {
                columnDefs.push({
                    accessorKey: 'delete',
                    header: 'Delete',
                    size: 50,
                    Cell: ({ row }) => (
                        <IconButton onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                            event.stopPropagation();
                            handleDeleteRow(row)
                        }}>
                            <DeleteIcon />
                        </IconButton>
                    ),
                });
            }

            return columnDefs;
        }, [handleDeleteRow, hasPermission, employee?.dashboardVersion]);

    useEffect(() => {
        void refetch();
    }, [selectedTab, refetch])

    const table = useMaterialReactTable({
        columns,
        data: (isLoading || isRefetching || isError || data === undefined) ? [] : data.data,
        enableColumnActions: false,
        defaultColumn: {
            size: 30,
            muiTableBodyCellProps: {
                sx: {
                    wordWrap: 'break-word',
                    whiteSpace: 'normal',
                },
            },
        },
        initialState: {
            showColumnFilters: false,
            showGlobalFilter: true,
            density: 'compact',
        },

        // top toolbar
        enableTopToolbar: true,
        enableDensityToggle: false,
        enableFullScreenToggle: false,
        enableHiding: false,
        enableColumnFilters: false,

        // server side searching and pagination
        manualFiltering: true,
        manualPagination: true,
        manualSorting: true,
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        onPaginationChange: setPagination,
        onSortingChange: setSorting,

        // state
        rowCount: data?.meta.totalRowCount ?? 0,
        state: {
            columnFilters,
            globalFilter,
            isLoading,
            pagination,
            showAlertBanner: isError,
            showProgressBars: isRefetching,
            showLoadingOverlay: (isLoading || isRefetching),
            sorting,
        },

        // design
        muiToolbarAlertBannerProps: isError
            ? {
                color: 'error',
                children: 'Error loading data',
            }
            : undefined,
        muiTableBodyRowProps: ({ row }: { row: MRT_Row<MrtDashboardCase> }) => {
            return {
                onClick: () => handleRowClick(row),
                sx: {
                    cursor: "pointer",
                    '& .MuiTableCell-root': {
                        ...getSolReportMrtStyle({
                            caseStatus: row.original.caseStatus ?? '',
                            dateOfBirth: row.original.idPeople?.dateofbirth ?? null,
                        })
                    },
                    '& .MuiTableSortLabel-icon': {
                        display: 'none',
                    },
                },
            };
        },
    });

    return <Box>
        {employee?.dashboardVersion === 'Attorney' && employee?.attorneyType === 'Pre-Lit'
            && <AttorneyPreLitFilters selectedTab={selectedTab} setSelectedTab={setSelectedTab}/>}

        {employee?.dashboardVersion === 'Attorney' && employee?.attorneyType === 'Litigation'
            && <AttorneyLitigationFilters selectedTab={selectedTab} setSelectedTab={setSelectedTab}/>}

        {employee?.dashboardVersion === 'CaseManager' && employee.team
            && <CaseManagerFiltersOnTeam selectedTab={selectedTab} setSelectedTab={setSelectedTab}/>}

        {employee?.dashboardVersion === 'CaseManager' && !employee.team
            && <CaseManagerFiltersNoTeam selectedTab={selectedTab} setSelectedTab={setSelectedTab}/>}

        {employee?.dashboardVersion === 'DisbursementSpecialist'
            && <DisbursementSpecialistFilters selectedTab={selectedTab} setSelectedTab={setSelectedTab}/>}

        {employee?.dashboardVersion === 'IntakeSpecialist'
            && <IntakeSpecialistFilters selectedTab={selectedTab} setSelectedTab={setSelectedTab}/>}

        <MaterialReactTable table={table} />
    </Box>
};

export default MrtCaseTableDashboard;
