import {
    Alert,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid2 as Grid,
    MenuItem,
} from "@mui/material";
import {type BaseSyntheticEvent, type ReactNode, useEffect, useState} from "react";
import {FormProvider, type SubmitHandler, useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {z} from "zod";
import {enqueueSnackbar} from "notistack";
import {getErrorMessage} from "@/utils/api.ts";
import {RhfTextField} from "mui-rhf-integration";
import {addressSchema} from "@/components/Forms/schema/addAddressSchema.ts";
import {useAddPersonAddress} from "@/mutations/useAddPersonAddress.tsx";
import {stateAbbreviations} from "@/constants/stateAbbreviations.ts";
import {useFetchAddress} from "@/queries/useFetchAddress.ts";
import ZipCodeAutocomplete from "@/components/Forms/ZipCodeAutocomplete.tsx";

export type AddAddressFormValues = z.infer<typeof addressSchema>;

const addressTypes = [
    'Primary',
    'Home',
    'Work',
    'Office',
    'Previous Home',
    'Previous Office',
]

type Props = {
    personUUID: string;
    showAddButton ?: boolean;
    editAddressId ?: string;
    clearEditAddressId ?: () => void;
};

const AddEditPersonAddressDialog = (
    {
        personUUID,
        showAddButton = true,
        editAddressId,
        clearEditAddressId,
    }: Props): ReactNode => {
    const addEditAddress = useAddPersonAddress(personUUID);
    const [open, setOpen] = useState(false);

    const form = useForm<AddAddressFormValues>({
        resolver: zodResolver(addressSchema),
        defaultValues: {},
    });

    const {
        data: {data} = {},
        isError,
        isRefetching,
        isLoading,
    } = useFetchAddress({
        addressUUID: editAddressId ?? null,
        isQueryEnabled: editAddressId !== undefined,
    });

    const handleCloseModal = () => {
        form.reset({
            idUUID: '',
            addressType: '',
            address1: '',
            address2: '',
            city: '',
            state: '',
            zip: '',
        });

        setOpen(false);

        if (clearEditAddressId) {
            clearEditAddressId();
        }
    };

    const handleSubmit = (values: AddAddressFormValues) => {
        addEditAddress.mutateAsync(
            {
                ...values
            },
        ).then(() => {
            enqueueSnackbar("Address added.", { variant: "success" });

            if (clearEditAddressId) {
                clearEditAddressId();
            }

            form.reset({});
            setOpen(false)
        }).catch( (error) => {
            console.info('error', getErrorMessage(error));
            enqueueSnackbar("Error: Add Address failed.", { variant: "error" });
        })
    };

    const onSubmit: SubmitHandler<AddAddressFormValues> = async (
        data: AddAddressFormValues,
        event?: BaseSyntheticEvent
    ) => {
        event?.stopPropagation();
        handleSubmit(data);
    }

    useEffect(() => {
        if (editAddressId && data) {
            form.reset(data)
            setOpen(true);
            return;
        }

        setOpen(false);
    }, [data, editAddressId]);

    return <>
        {showAddButton && <Button
            variant={'contained'}
            sx={{
                ml: 2,
                minWidth: '120px',
            }}
            onClick={() => setOpen(true)}
        >
            {editAddressId ? 'Edit' : 'New'} Address
        </Button>}
        <Dialog open={open} maxWidth="sm" fullWidth>
            <FormProvider {...form}>
                <form>
                    <DialogTitle>New Address</DialogTitle>
                    <DialogContent dividers>
                        {(isLoading || isRefetching) && <CircularProgress/>}
                        {isError && <Alert severity={'error'}>Error loading form data.</Alert>}
                        <Grid container spacing={2}>
                            <Grid size={{xs: 12, md: 9}}>
                                <RhfTextField
                                    control={form.control}
                                    name={'address1'}
                                    label={'Address'}
                                    sx={{
                                        width: '100%',
                                    }}
                                />
                            </Grid>
                            <Grid size={{xs: 12, md: 3}}>
                                <RhfTextField
                                    select
                                    control={form.control}
                                    name={'addressType'}
                                    label={'Type'}
                                    sx={{
                                        width: '100%',
                                    }}
                                >
                                    {addressTypes.map((type) => <MenuItem
                                        key={`addressType-${type}`}
                                        value={type}
                                    >
                                        {type}
                                    </MenuItem>)}
                                </RhfTextField>
                            </Grid>
                            <Grid size={{xs: 12, md: 2}}>
                                <RhfTextField
                                    control={form.control}
                                    name={'address2'}
                                    label={'Apt/Suite'}
                                    sx={{
                                        width: '100%',
                                    }}
                                />
                            </Grid>
                            <Grid size={{xs: 12, md: 4}}>
                                <RhfTextField
                                    control={form.control}
                                    name={'city'}
                                    label={'City'}
                                    sx={{
                                        width: '100%',
                                    }}
                                />
                            </Grid>
                            <Grid size={{xs: 12, md: 3}}>
                                <RhfTextField
                                    select
                                    control={form.control}
                                    name={'state'}
                                    label={'State'}
                                    sx={{
                                        width: '100%',
                                    }}
                                >
                                    {stateAbbreviations.map((type) => <MenuItem
                                        key={`state-${type}`}
                                        value={type}
                                    >
                                        {type}
                                    </MenuItem>)}
                                </RhfTextField>
                            </Grid>
                            <Grid size={{xs: 12, md: 3}}>
                                <ZipCodeAutocomplete/>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseModal}>Close</Button>
                        <Button
                            variant={'contained'}
                            onClick={form.handleSubmit(onSubmit)}
                        >
                            {editAddressId ? 'Edit' : 'Add'} Address
                        </Button>
                    </DialogActions>
                </form>
            </FormProvider>
        </Dialog>
    </>;
};

export default AddEditPersonAddressDialog;
