import useAuthenticatedFetch from "@/hooks/useAuthenticatedFetch.ts";
import {apiUrl} from "@/utils/api.ts";
import {useMutation, type UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {handleJsonApiError} from "jsonapi-zod-query";
import {AddPersonFormValues} from "@/components/Forms/SubForms/AddEditPersonPhoneNumberDialog.tsx";

export const useAddEditPersonPhoneNumber = (
    personUUID: string
): UseMutationResult<void, Error, AddPersonFormValues> => {
    const fetch = useAuthenticatedFetch();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (attributes) => {
            const response = await fetch(apiUrl(`/person/phone-number/create`), {
                method: "PUT",
                headers: {
                    "Content-Type": "application/vnd.api+json",
                },
                body: JSON.stringify({
                    personUUID: personUUID,
                    ...attributes,
                }),
            });

            await handleJsonApiError(response);
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: ["person-phone-number-table-data", personUUID],
            });
        },
    });
};
