import type {UseFormReturn} from 'react-hook-form';
import {MenuItem} from "@mui/material";
import {RhfTextField} from "mui-rhf-integration";
import type {PersonRequestSchemaType} from "@/components/Forms/PersonForm.tsx";
import {stateAbbreviations} from "@/constants/stateAbbreviations.ts";

export const namePrefixes = [
    { value: 'Mr.'},
    { value: 'Mrs.'},
    { value: 'Ms.'},
    { value: 'Dr.'},
    { value: 'Prof.'},
    { value: 'Rev.'},
    { value: 'Fr.' }
]


type Props = {
    form: UseFormReturn<PersonRequestSchemaType>;
}

const PersonDlIssuedBySelect = ({form} : Props) => {
    return <RhfTextField
        select
        control={form.control}
        name={'dlissuedBy'}
        label={'DL Issued By'}
        sx={{
            width: '100%',
            backgroundColor: '#fff',
        }}
    >
        {stateAbbreviations?.map((stateAbr) => <MenuItem
            key={`role-${stateAbr}`}
            value={`${stateAbr}`}
        >
            {stateAbr}
        </MenuItem>)}
    </RhfTextField>
};

export default PersonDlIssuedBySelect;
