import type {UseFormReturn} from 'react-hook-form';
import {Alert, Button, Grid2 as Grid, Paper, Stack, Typography} from "@mui/material";
import {RhfTextField} from "mui-rhf-integration";
import type {PersonRequestSchemaType} from "@/components/Forms/PersonForm.tsx";
import {RhfDatePicker} from "mui-rhf-integration/date-picker";
import PersonReferredByIndividualSelect from "@/components/Forms/PersonReferredByIndividualSelect.tsx";
import PersonReferredByCompanyAutocomplete from "@/components/Forms/PersonReferredByCompanySelect.tsx";
import PersonRoleSelect from "@/components/Forms/PersonRoleSelect.tsx";
import PersonNamePrefixSelect from "@/components/Forms/PersonNamePrefixSelect.tsx";
import PersonSexSelect from "@/components/Forms/PersonSexSelect.tsx";
import PersonNameSuffixSelect from "@/components/Forms/PersonNamePrefixSuffix.tsx";
import PersonDlIssuedBySelect from "@/components/Forms/PersonDlIssuedBySelect.tsx";
import MaskedSSN from "@/components/Forms/MaskedSSN.tsx";
import MaskedDLN from "@/components/Forms/MaskedDLN.tsx";
import {isMinor} from "@/functions/IsMinor.tsx";

export const roleDefinitions = [
    {name: 'Court Reporter', isLegal: true},
    {name: 'Defendant', isLegal: false},
    {name: 'Judge Assistant', isLegal: true},
    {name: 'Judge', isLegal: true},
    {name: 'Liability Adjuster', isLegal: true},
    {name: 'MedPay Adjuster', isLegal: true},
    {name: 'Mediator', isLegal: true},
    {name: 'Minor\'s Legal Guardian', isLegal: false},
    {name: 'Opposing Counsel', isLegal: true},
    {name: 'Owner\'s Vehicle', isLegal: false},
    {name: 'Paralegal', isLegal: true},
    {name: 'Plaintiff', isLegal: false},
    {name: 'Property Damage Adjuster', isLegal: true},
    {name: 'Special Investigation Unit (SIU)', isLegal: true},
    {name: 'Staff Attorney', isLegal: true},
    {name: 'UM/UIM Adjuster', isLegal: true},
]

type Props = {
    form: UseFormReturn<PersonRequestSchemaType>;
    foundPersonIdUUID: string;
    personId: string;
}

const Information = ({form, foundPersonIdUUID, personId} : Props) => {
    const role = form.watch('role');
    const dateOfBirth = form.watch('dateofbirth');
    const selectedRoleDefinition = roleDefinitions
        .find(roleDefinition => (roleDefinition.isLegal && roleDefinition.name === role));
    const isLegalRole = selectedRoleDefinition ? selectedRoleDefinition.isLegal : false;
    const under18 = dateOfBirth ? isMinor(dateOfBirth) : false;


    return <Grid container>
        <Grid size={12}>
            <Paper
                sx={{
                    mb: 2,
                    mx: 1,
                }}
            >
                <Grid
                    container
                    sx={{
                        borderRadius: '8px',
                        p: 1,
                    }}
                >
                    <Grid size={12} sx={{p: 1, mb: 1}}>
                        <Stack
                            sx={{
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Stack
                                sx={{
                                    flexDirection: 'row',
                                    justifyContent: 'flex-start',
                                }}
                            >
                                <Typography variant={'h5'} sx={{mr: 2}}>Information</Typography>
                                {under18 && <Alert severity={'error'}>MINOR</Alert>}
                            </Stack>
                            {form.formState.isDirty && <Button
                                variant='contained'
                                type={'submit'}
                                sx={{
                                    p: 1,
                                    borderRadius: '8px',
                                    fontWeight: 800,
                                    mb: 2,
                                    mr: 2,
                                }}
                            >
                                Save
                            </Button>}
                        </Stack>
                    </Grid>
                    <Grid size={12} sx={{p: 1}}>
                        <PersonRoleSelect form={form} />
                    </Grid>
                    <Grid size={2} sx={{p: 1}}>
                        <PersonNamePrefixSelect form={form} />
                    </Grid>
                    <Grid size={8} sx={{p: 1}}>
                        <RhfTextField
                            control={form.control}
                            name={'nameFirst'}
                            label={'First Name'}
                            sx={{
                                width: '100%',
                            }}
                        />
                    </Grid>
                    <Grid size={2} sx={{p: 1}}>
                        <RhfTextField
                            control={form.control}
                            name={'nameI'}
                            label={'MI'}
                            sx={{
                                width: '100%',
                            }}
                        />
                    </Grid>
                    <Grid size={6} sx={{p: 1}}>
                        <RhfTextField
                            control={form.control}
                            name={'nameLast'}
                            label={'Last Name'}
                            sx={{
                                width: '100%',
                            }}
                        />
                    </Grid>
                    <Grid size={4} sx={{p: 1}}>
                        <RhfTextField
                            control={form.control}
                            name={'nameAKA'}
                            label={'AKA/Preferred'}
                            sx={{
                                width: '100%',
                            }}
                        />
                    </Grid>
                    <Grid size={2} sx={{p: 1}}>
                        <PersonNameSuffixSelect form={form}/>
                    </Grid>
                    <Grid size={5} sx={{p: 1, display: isLegalRole ? 'none' : 'block'}}>
                        <MaskedSSN fieldPath={'ssnumber'} foundPersonIdUUID={foundPersonIdUUID} personId={personId}/>
                    </Grid>
                    <Grid size={4} sx={{p: 1, display: isLegalRole ? 'none' : 'block'}}>
                        <RhfTextField
                            control={form.control}
                            name={'race'}
                            label={'Race'}
                            sx={{
                                width: '100%',
                            }}
                        />
                    </Grid>
                    <Grid size={3} sx={{p: 1, display: isLegalRole ? 'none' : 'block'}}>
                        <PersonSexSelect form={form}/>
                    </Grid>
                    <Grid size={4} sx={{p: 1, display: isLegalRole ? 'none' : 'block'}}>
                        <RhfTextField
                            control={form.control}
                            name={'taxIDNumber'}
                            label={'Tax ID'}
                            sx={{
                                width: '100%',
                            }}
                        />
                    </Grid>
                    <Grid size={4} sx={{p: 1, display: isLegalRole ? 'none' : 'block'}}>
                        <MaskedDLN fieldPath={'dlNumber'} foundPersonIdUUID={foundPersonIdUUID} personId={personId}/>
                    </Grid>
                    <Grid size={4} sx={{p: 1, display: isLegalRole ? 'none' : 'block'}}>
                        <PersonDlIssuedBySelect form={form}/>
                    </Grid>
                    <Grid size={6} sx={{p: 1, display: isLegalRole ? 'none' : 'block'}}>
                        <RhfDatePicker
                            control={form.control}
                            name={'dateofbirth'}
                            label={'Date Of Birth'}
                            views={['year', 'month', 'day']}
                            sx={{
                                width: '100%',
                            }}
                        />
                    </Grid>
                    <Grid size={6} sx={{p: 1, display: isLegalRole ? 'none' : 'block'}}>
                        <RhfDatePicker
                            control={form.control}
                            name={'dateofdeath'}
                            label={'Date Of Death'}
                            views={['year', 'month', 'day']}
                            sx={{
                                width: '100%',
                            }}
                        />
                    </Grid>
                    <Grid size={6} sx={{p: 1, display: isLegalRole ? 'none' : 'block'}}>
                        <PersonReferredByIndividualSelect form={form}/>
                    </Grid>
                    <Grid size={6} sx={{p: 1, display: isLegalRole ? 'none' : 'block'}}>
                        <PersonReferredByCompanyAutocomplete
                            form={form}
                            referralRadio={'Company'}
                        />
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    </Grid>
};

export default Information;
