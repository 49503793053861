import {useQuery, type UseQueryResult} from "@tanstack/react-query";
import {apiUrl} from "@/utils/api.ts";
import {createDataSelector, createResourceSelector, JsonApiError,} from "jsonapi-zod-query";
import {caseFormSchemaInput} from "@/components/Forms/schema/caseFormSchemaInput.ts";

const resourceSelector = createResourceSelector({
    type: "case",
    attributesSchema: caseFormSchemaInput,
});

export const caseSelector = createDataSelector(resourceSelector);
export type CaseData = ReturnType<typeof caseSelector>;

export type CaseDocument = ReturnType<typeof resourceSelector>;

type Props = {
    caseNumber?: string;
    userPIN?: string;
    enabled: boolean;
}
export const useFetchClientLogin = (
    {
        caseNumber,
        userPIN,
        enabled,
    } : Props): UseQueryResult<CaseDocument, JsonApiError> => {

    return useQuery({
        retry: 1,
        enabled: enabled,
        queryKey: ["client-case", { caseNumber }],
        queryFn: async ({ signal }) => {
            const url = apiUrl(`/public/login/${caseNumber}`);

            const response = await fetch(url, {
                method: 'POST',
                body: JSON.stringify({
                    data: {
                        id: caseNumber,
                        type: "case",
                        attributes: {
                            caseNumber: caseNumber,
                            userPIN: userPIN,
                        },
                    }
                }),
                signal,
                headers: {
                    'Content-Type': 'application/vnd.api+json',
                    accept: "application/vnd.api+json",
                },
            });

            if (!response.ok && response.status === 429) {
                throw new JsonApiError("Too many requests", 429, [])
            }

            return await response.json();
        },
        select: resourceSelector,
    });
};
