import {useQuery, type UseQueryResult} from "@tanstack/react-query";
import useAuthenticatedFetch from "@/hooks/useAuthenticatedFetch.ts";
import {apiUrl} from "@/utils/api.ts";
import {createResourceSelector,} from "jsonapi-zod-query";
import {personSchema} from "@/components/Forms/schema/personSchema.ts";

const resourceSelector = createResourceSelector({
    type: "person",
    attributesSchema: personSchema,
});

export type Person = ReturnType<typeof resourceSelector>;

type Props = {
    isQueryEnabled: boolean
    personUUID: string
}
export const useFetchPersonByUUID = (
{
    isQueryEnabled,
    personUUID,
} : Props): UseQueryResult<Person> => {
    const fetch = useAuthenticatedFetch();

    return useQuery({
        enabled: isQueryEnabled,
        queryKey: ["person-uuid", { personUUID }],
        queryFn: async ({ signal }) => {
            const url = apiUrl(`/person/by-uuid/${personUUID}`);

            const response = await fetch(url, {
                signal,
                headers: {
                    accept: "application/vnd.api+json",
                },
            });

            return await response.json();
        },
        select: resourceSelector,
    });
};
