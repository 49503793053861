import {keepPreviousData, useQuery, type UseQueryResult} from "@tanstack/react-query";
import useAuthenticatedFetch from "@/hooks/useAuthenticatedFetch.ts";
import {apiUrl} from "@/utils/api.ts";
import {createResourceSelector} from "jsonapi-zod-query";
import {emailAddressSchema} from "@/components/Forms/schema/emailAddressSchema.ts";

const emailAddressTypeSelector = createResourceSelector({
    type: "emailAddress",
    attributesSchema: emailAddressSchema,
});

export type EmailAddress = ReturnType<typeof emailAddressTypeSelector>;

type Props = {
    emailAddressUUID : string | null;
    isQueryEnabled ?: boolean;
};
export const useFetchEmailAddress = (
    {
        isQueryEnabled = true,
        emailAddressUUID,
    } : Props
 ) : UseQueryResult<EmailAddress> => {
    const fetch = useAuthenticatedFetch();

    return useQuery({
        queryKey: [
            'email-address',
            emailAddressUUID,
        ],
        enabled: isQueryEnabled,
        queryFn: async () => {
            const fetchURL = apiUrl(`/email-address/${emailAddressUUID}`);

            const response = await fetch(fetchURL.href, {
                method: 'GET',
                headers: {
                    "Content-Type": "application/vnd.api+json",
                },
            });

            return await response.json();
        },
        select: emailAddressTypeSelector,
        placeholderData: keepPreviousData,
    });
};
