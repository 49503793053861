import useAuthenticatedFetch from "@/hooks/useAuthenticatedFetch.ts";
import {keepPreviousData, useQuery, type UseQueryResult} from "@tanstack/react-query";
import type {MRT_ColumnFiltersState, MRT_PaginationState, MRT_SortingState,} from 'material-react-table';
import {createDataSelector, createResourceCollectionSelector} from "jsonapi-zod-query";
import {apiUrl} from "@/utils/api.ts";
import {mrtCaseSchema} from "@/components/Forms/schema/mrtCaseSchema.ts";
import {dtMetaSchema} from "@/components/Forms/schema/dtMetaSchema.ts";
import {personSchema} from "@/components/Forms/schema/personSchema.ts";
import {clientSchema} from "@/components/Forms/schema/caseFormSchemaInput.ts";

export const caseResourceSelector = createResourceCollectionSelector({
    type: "case",
    attributesSchema: mrtCaseSchema,
    documentMetaSchema: dtMetaSchema,
    relationships: {
        idClient: {
            relationshipType: "one_nullable",
            include: {
                type: "client",
                attributesSchema: clientSchema,
            },
        },
        idPeople: {
            relationshipType: "one_nullable",
            include: {
                type: "person",
                attributesSchema: personSchema,
            },
            required: false,
        },
    }
});

export const casesSelector = createDataSelector(caseResourceSelector);
export type DashboardCases = ReturnType<typeof casesSelector>;

export type DashboardCasesDocument = ReturnType<typeof caseResourceSelector>;
export type MrtDashboardCase = DashboardCasesDocument['data'][number];

type Props = {
    isQueryEnabled ?: boolean;
    selectedTab ?: string;
    columnFilters : MRT_ColumnFiltersState;
    globalFilter : string;
    pagination : MRT_PaginationState;
    sorting : MRT_SortingState;
};

export const useFetchMrtDashboardCases = (
    {
        isQueryEnabled = true,
        selectedTab = '',
        columnFilters,
        globalFilter,
        pagination,
        sorting,
    } : Props
) : UseQueryResult<DashboardCasesDocument> => {
    const fetch = useAuthenticatedFetch();

    return useQuery({
        queryKey: [
            'dashboard-cases-table-data',
            columnFilters,
            globalFilter,
            pagination.pageIndex,
            pagination.pageSize,
            sorting,
        ],
        enabled: isQueryEnabled,
        queryFn: async () => {
            const requestBody = {
                start: pagination.pageIndex * pagination.pageSize,
                size: pagination.pageSize,
                filters: columnFilters ?? [],
                globalFilter: globalFilter ?? '',
                sorting: sorting ?? [],
                selectedTab: selectedTab ?? '',
            };

            const response = await fetch(apiUrl('/dashboard/cases'), {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody)
            });

            return await response.json();
        },
        select: caseResourceSelector,
        placeholderData: keepPreviousData,
    });
};
