import {z} from "zod";

export const historySchema = z.object({
    idUUID: z.string(),
    idClient: z.string().optional().nullable(),
    idrecIDHistory: z.number().optional().nullable(),
    idGlobalUUID: z.string().optional().nullable(),
    idEmployeeUUID: z.string().optional().nullable(),
    module: z.string().optional().nullable(),
    modulesingular: z.string().optional().nullable(),
    name: z.string().optional().nullable(),
    baseURL: z.string().optional().nullable(),
    metaCreatedBy: z.string().optional().nullable(),
    metaCreatedByPretty: z.string().optional().nullable(),
    metaCreatedWhen: z.coerce.date().nullable(),
    metaModifiedBy: z.string().optional().nullable(),
    metaModifiedByPretty: z.string().optional().nullable(),
    metaModifiedWhen: z.coerce.date().nullable(),
    faicon: z.string().optional().nullable(),
    faiconcolor: z.string().optional().nullable(),
    isPinned: z.string().optional().nullable(),
    iPData: z.string().optional().nullable(),
});
