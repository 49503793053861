import useAuthenticatedFetch from "@/hooks/useAuthenticatedFetch.ts";
import {apiUrl} from "@/utils/api.ts";
import {useMutation, type UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {handleJsonApiError} from "jsonapi-zod-query";

export const useDeleteMedia = (): UseMutationResult<void, Error, { mediaUUID: string }> => {
    const fetch = useAuthenticatedFetch();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async ({ mediaUUID }) => {
            const response = await fetch(apiUrl(`/media/delete`), {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/vnd.api+json",
                },
                body: JSON.stringify({
                    mediaUUID: mediaUUID,
                }),
            });
            await handleJsonApiError(response);
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: ["case-media"],
            });
            await queryClient.invalidateQueries({
                queryKey: ["person-media"],
            });
            await queryClient.invalidateQueries({
                queryKey: ["company-media"],
            });
        },
    });
};
