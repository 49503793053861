import {Grid2 as Grid} from "@mui/material";
import MediaDocumentsCollectionForCompany from "@/components/Documents/MediaDocumentsCollectionForCompany.tsx";

type Props = {
    companyUUID: string;
}

const CompanyMedia = ({companyUUID} : Props) => {
    return <Grid container>
        <Grid size={12}>
            <MediaDocumentsCollectionForCompany companyUUID={companyUUID}/>
        </Grid>
    </Grid>
};

export default CompanyMedia;
