import {z} from "zod";

export const mrtCompanySchema = z.object({
    idUUID: z.string(),
    name: z.string().optional().nullable(),
    city: z.string().optional().nullable(),
    zip: z.string().optional().nullable(),
    specialty: z.string().optional().nullable(),
    allPhoneNumbers: z.string().optional().nullable(),

    primaryPhoneNumberDisplay: z.string().optional().nullable(),
});
