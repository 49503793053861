import {Typography} from "@mui/material";
import type {ReactNode} from "react";
import {Outlet} from "react-router-dom";
import MrtCaseTableSolReport from "@/components/Table/MrtCaseTableSolReport.tsx";
import {useEmployee} from "@/contexts/EmployeeProvider.tsx";

const ReportSol = (): ReactNode => {
    const {hasPermission} = useEmployee()

    if (!hasPermission("view:report-sol")) {
        return <div>You do not have access to this page.</div>;
    }

    return (
        <>
            <Typography variant="h4" mb={2}>SOL</Typography>
            <MrtCaseTableSolReport/>
            <Outlet />
        </>
    );
};

export default ReportSol;
