import {keepPreviousData, useQuery, type UseQueryResult} from "@tanstack/react-query";
import useAuthenticatedFetch from "@/hooks/useAuthenticatedFetch.ts";
import {apiUrl} from "@/utils/api.ts";
import {createDataSelector, createResourceCollectionSelector, handleJsonApiError} from "jsonapi-zod-query";
import {mediaSchema} from "@/components/Forms/schema/mediaSchema.ts";

const resourceSelector = createResourceCollectionSelector({
    type: "media",
    attributesSchema: mediaSchema,
});

const mediaCollectionSelector = createDataSelector(resourceSelector);

export type MediaCollection = ReturnType<typeof mediaCollectionSelector>;
export type Media = MediaCollection[number];

type Props = {
    personUUID : string;
};

export const useFetchMediasForPerson = (
    {
        personUUID,
    } : Props
) : UseQueryResult<MediaCollection> => {
    const fetch = useAuthenticatedFetch();

    return useQuery({
        queryKey: [
            `person-media`,
            personUUID,
        ],
        queryFn: async () => {
            const fetchURL = apiUrl('/media/for-person');
            fetchURL.searchParams.set('personUUID', personUUID);

            const response = await fetch(fetchURL.href, {
                method: 'POST',
                headers: {
                    accept: "application/vnd.api+json",
                },
                body: JSON.stringify({
                    personUUID: personUUID,
                }),
            });

            await handleJsonApiError(response);
            return await response.json();
        },
        select: mediaCollectionSelector,
        placeholderData: keepPreviousData,
    });
};
