import type {MRT_ColumnDef} from "material-react-table";
import type {MrtDashboardCase} from "@/queries/useFetchMrtDashboardCases.ts";
import {Stack, Typography} from "@mui/material";
import LinkIcon from "@mui/icons-material/Link";
import {ChronoUnit, LocalDate} from "@js-joda/core";
import {dateFormatterDashed} from "@/utils/mapDateOrStringToLocalDate.ts";

export const caseManagerOnTeamColumnDefs: MRT_ColumnDef<MrtDashboardCase>[] = [
    {
        accessorKey: "id",
        header: "Case #",
        sortingFn: "alphanumeric",
        size: 100,
    },
    {
        accessorKey: "clientName",
        header: "Client Name",
        Cell: ({ renderedCellValue, row }) => {
            return <Stack sx={{flexDirection: 'row', justifyContent: 'flex-start'}}>
                <Typography sx={{fontSize: 'inherit'}}>{renderedCellValue}</Typography>
                {row.original.hasCompanions && <LinkIcon/>}
            </Stack>
        },
    },
    {
        accessorKey: "caseType",
        header: "Type",
    },
    {
        accessorKey: "caseStatus",
        header: "Liability Status",
    },
    {
        accessorKey: "caseStatusUM",
        header: "UM Status",
    },
    {
        accessorKey: "caseName",
        header: "Case Name",
    },
    {
        accessorKey: "dateOfIncident",
        header: "DOI",
        Cell: ({ renderedCellValue }) => {
            if (renderedCellValue instanceof LocalDate) {
                return renderedCellValue.format(dateFormatterDashed)
            }

            return ''
        },
    },
    {
        accessorKey: "dateOfIncidentAge",
        header: "SOL Days",
        Cell: ({ row }) => {
            if (row.original.dateOfIncident) {
                const today = LocalDate.now();
                return ChronoUnit.DAYS.between(row.original.dateOfIncident, today);
            }

            return ''
        },
    },
    {
        accessorKey: "lastTouch",
        header: "DSC",
        Cell: ({ row }) => {
            if (row.original.lastTouch) {
                const today = new Date();
                return Math.floor((today.getTime() - row.original.lastTouch.getTime()) / (1000 * 60 * 60 * 24));
            }

            return ''
        },
    },
    {
        accessorKey: "assignedTeam",
        header: "Team",
    },
]

