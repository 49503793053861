import type {UseMutationResult} from '@tanstack/react-query';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import type {Document, DocumentData} from '@/types/document.ts';
import {apiUrl} from '@/utils/api.ts';
import useAuthenticatedFetch from "@/hooks/useAuthenticatedFetch.ts";
import {handleJsonApiError} from "jsonapi-zod-query";

export type S3SignedUrl = {
    url : string;
};

export const useCreateApiDocumentMutation = () : UseMutationResult<
    Document,
    unknown,
    DocumentData
> => {
    const fetch = useAuthenticatedFetch();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (document: DocumentData) => {
            const response = await fetch(apiUrl('/document'), {
                method: "POST",
                body: JSON.stringify(document),
                headers: {
                    "Content-Type": "application/vnd.api+json",
                },
            });

            await handleJsonApiError(response);
            return (await response.json() as Document);
        },
        onSuccess: () => {
            void queryClient.invalidateQueries({
                queryKey: ["case-media"],
            });
            void queryClient.invalidateQueries({
                queryKey: ["person-media"],
            });
            void queryClient.invalidateQueries({
                queryKey: ["company-media"],
            });
        },
    });
};

export const useGeneratePreSignedPutUrl = () : UseMutationResult<
    S3SignedUrl,
    unknown,
    DocumentData
> => {
    const fetch = useAuthenticatedFetch();

    return useMutation({
        mutationFn: async (document: DocumentData) => {
            const response = await fetch(apiUrl('/s3/generate-pre-signed-put-url'), {
                method: "POST",
                body: JSON.stringify(document),
                headers: {
                    "Content-Type": "application/vnd.api+json",
                },
            });
            await handleJsonApiError(response);
            return (await response.json() as S3SignedUrl);
        },
    });
};

export const useGetDocumentUrlMutation = () : UseMutationResult<
    S3SignedUrl,
    unknown,
    string
> => {
    const fetch = useAuthenticatedFetch();

    return useMutation({
        mutationFn: async (mediaUUID: string) => {
            const response = await fetch(apiUrl('/s3/get-pre-signed-url'), {
                method: "POST",
                body: JSON.stringify({mediaUUID: mediaUUID}),
                headers: {
                    "Content-Type": "application/vnd.api+json",
                },
            });
            await handleJsonApiError(response);
            return (await response.json() as S3SignedUrl);
        },
    });
};

export const useDeleteDocumentMutation = () : UseMutationResult<void, unknown, Document> => {
    const fetch = useAuthenticatedFetch();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (document: Document) => {
            const response = await fetch(apiUrl(`/document/${document.id}`), {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/vnd.api+json",
                },
            });
            await handleJsonApiError(response);
        },
        onSuccess: async (_data, values) => {
            void queryClient.invalidateQueries({
                queryKey: ["documents"],
            });
            void queryClient.invalidateQueries({
                queryKey: ["case-document", values.case.id],
            });
            void queryClient.invalidateQueries({
                queryKey: ["person-media"],
            });
            void queryClient.invalidateQueries({
                queryKey: ["company-media"],
            });
        },
    });
};
