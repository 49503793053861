import {Grid2 as Grid, Paper} from '@mui/material';
import MediaDocumentsListForCase from "@/components/Documents/MediaDocumentsListForCase.tsx";
import NegotiationForm from "@/components/Forms/NegotiationForm.tsx";
import NoteList from "@/components/Documents/NoteList.tsx";

type Props = {
    caseIdUUID: string;
    foundCaseId: string;
};

const NegotiationTab = ({caseIdUUID, foundCaseId} : Props) => {
    return <Grid container sx={{pb: {xs: '200px', md: '100px'}}}>
        <Grid size={12}>
            <Paper
                sx={{
                    mb: 2,
                    mx: 1,
                }}
            >
                <Grid
                    container
                    sx={{
                        borderRadius: '8px',
                        p: 1,
                    }}
                >
                    <Grid size={12} sx={{p: 1, mb: 1}}>
                        <Paper>
                            <NegotiationForm caseUUID={caseIdUUID} foundCaseId={foundCaseId}/>
                        </Paper>
                    </Grid>
                    <Grid size={6} sx={{p: 1, mb: 1}}>
                        <NoteList caseIdUUID={caseIdUUID} limitToCategory={'Negotiation'}/>
                    </Grid>
                    <Grid size={6} sx={{p: 1, mb: 1}}>
                        <MediaDocumentsListForCase caseIdUUID={caseIdUUID} limitToCategory={'Negotiation'}/>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    </Grid>;
};

export default NegotiationTab;
