import {useParams} from 'react-router-dom';
import type {ReactNode} from "react";
import CompanyForm from "@/components/Forms/CompanyForm.tsx";

const CompanyDetail = (): ReactNode => {
    const { placeId } = useParams<{ placeId: string }>();

    if (!placeId) {
        return <div>No Place ID provided</div>;
    }

    return (
        <div>
            <CompanyForm placeId={placeId}/>
        </div>
    );
};

export default CompanyDetail;
