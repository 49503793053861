import {InputAdornment, SxProps} from "@mui/material";
import {type ReactNode} from "react";
import {useFormContext} from "react-hook-form";
import {RhfTextField} from "mui-rhf-integration";
import {createMaskedInput} from "@/components/RhfCurrencyInput/masked-input.tsx";

const MaskedGiftAmountInput = createMaskedInput({
    mask: Number,
    scale: 2,
    thousandsSeparator: ',',
    padFractionalZeros: true,
    normalizeZeros: true,
    radix: '.',
    mapToRadix: ['.'],
    min: 0,
    autofix: true,

});

type Props = {
    name: string;
    label: string;
    sx?: SxProps;
};

const RhfCurrencyInput = ({ name, label, sx }: Props): ReactNode => {
    const { control, watch } = useFormContext();
    const value = watch(name);

    return <RhfTextField
        control={control}
        name={name}
        label={label}
        slotProps={{
            input: {
                inputComponent: MaskedGiftAmountInput,
                startAdornment: value ? <InputAdornment sx={{mr: 0}} position={'start'}>$</InputAdornment> : null,
            },
        }}
        fullWidth
        sx={sx}
    />

};

export default RhfCurrencyInput;
