import type {UseFormReturn} from 'react-hook-form';
import {CircularProgress, MenuItem, Paper, Typography} from "@mui/material";
import {RhfTextField} from "mui-rhf-integration";
import type {EmployeeFormValues} from "@/components/Forms/EmployeeForm.tsx";
import {useFetchTeams} from "@/queries/useFetchTeam.ts";
import {useEffect} from "react";

type Props = {
    form: UseFormReturn<EmployeeFormValues>;
    name: keyof EmployeeFormValues
    label: string
}

const EmployeeFormTeamsSelect = ({form, name, label} : Props) => {
    const {
        data,
        isError,
        isRefetching,
        isLoading,
        error,
    } = useFetchTeams();

    const fieldValue = form.watch(name);

    useEffect(() => {
        if (fieldValue && data) {
            const team = data.find((team) => team.id === fieldValue);
            if (team) {
                form.setValue('team', team.teamName);
            }
        }
    }, [fieldValue]);

    if (isError) {
        return <Paper sx={{
            p: 2,
            borderRadius: '8px',
        }}>
            <Typography variant={'h5'}>Error loading teams</Typography>
            <Typography variant={'body1'}>{error.message}</Typography>
        </Paper>
    }

    if (isLoading || isRefetching) {
        return <Paper sx={{
            p: 2,
            borderRadius: '8px',
        }}>
            <CircularProgress size={'12px'}/>
        </Paper>
    }

    return <RhfTextField
        select
        control={form.control}
        name={name}
        label={label}
        sx={{
            width: '100%',
            backgroundColor: '#fff',
        }}
    >
        {data?.map((team) => <MenuItem
            key={`${name}-${team.id}`}
            value={`${team.id}`}
        >
            {team.teamName}
        </MenuItem>)}
    </RhfTextField>
};

export default EmployeeFormTeamsSelect;
