import {useMemo, useState} from 'react';
import {
    MaterialReactTable,
    type MRT_ColumnDef,
    type MRT_ColumnFiltersState,
    type MRT_PaginationState,
    MRT_Row,
    type MRT_SortingState,
    useMaterialReactTable,
} from 'material-react-table';
import {Box} from "@mui/material";
import {MrtCase, useFetchMrtCasesForCompany} from "@/queries/useFetchMrtCasesForCompany.ts";

type Props = {
    companyUUID: string;
};

const MrtCompanyCasesTable = ({companyUUID} : Props) => {
    const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
        [],
    );
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState<MRT_SortingState>([]);
    const [pagination, setPagination] = useState<MRT_PaginationState>({
        pageIndex: 0,
        pageSize: 10,
    });

    const {data, isError, isRefetching, isLoading, error} = useFetchMrtCasesForCompany({
        companyUUID: companyUUID,
        columnFilters,
        globalFilter,
        pagination,
        sorting,
    });

    const columns = useMemo<MRT_ColumnDef<MrtCase>[]>(
        () => [
            {
                accessorKey: 'id',
                header: 'Case Number',
                size: 50,
            },
            {
                accessorKey: 'clientName',
                header: 'Client Name',
                size: 50,
            },
            {
                accessorKey: 'caseStatus',
                header: 'Liability Status',
                size: 50,
            },
            {
                accessorKey: 'caseName',
                header: 'Case Name',
                size: 50,
            },
        ], []);

    const handleRowClick = (row: MRT_Row<MrtCase>) => {
        window.location.href = `/case-detail/${row.original.id}`;
    };

    const table = useMaterialReactTable({
        columns,
        data: (isLoading || isRefetching || isError || data === undefined) ? [] : data,
        initialState: {
            showColumnFilters: false,
            density: 'compact',
        },
        enableTopToolbar: false,
        manualFiltering: true,
        manualPagination: true,
        manualSorting: true,
        muiToolbarAlertBannerProps: isError
            ? {
                color: 'error',
                children: 'Error loading data',
            }
            : undefined,
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        onPaginationChange: setPagination,
        onSortingChange: setSorting,
        rowCount: 0,
        state: {
            columnFilters,
            globalFilter,
            isLoading,
            pagination,
            showAlertBanner: isError,
            showProgressBars: isRefetching,
            showLoadingOverlay: (isLoading || isRefetching),
            sorting,
        },
        muiTableBodyRowProps: ({ row }: { row: MRT_Row<MrtCase> }) => ({
            onClick: () => handleRowClick(row),
            sx: { cursor: "pointer" },
        }),
    });



    if (isError) {
        return (
            <div>{error.message}</div>
        );
    }

    if (data === undefined) {
        return <div>No data</div>;
    }

    return <Box>
        <MaterialReactTable table={table} />
    </Box>;
};

export default MrtCompanyCasesTable;
