import {createTheme, type PaletteMode} from "@mui/material";
import {ThemeProvider as MuiThemeProvider} from "@mui/material/styles";
import {createContext, type ReactNode, useCallback, useContext, useMemo, useState} from "react";

export type ThemeMode = PaletteMode | "system";

type ThemeContextValue = {
    switchMode: (mode: ThemeMode) => void;
    mode: ThemeMode;
};

const ThemeContext = createContext<ThemeContextValue | null>(null);

const getStoredMode = (): ThemeMode => {
    const storedMode = window.localStorage.getItem("aps_theme_mode");

    return storedMode === "light" || storedMode === "dark" ? storedMode : "system";
};

type Props = {
    children: ReactNode;
};

const ThemeProvider = ({ children }: Props): ReactNode => {
    const [themeMode, setThemeMode] = useState(getStoredMode);

    const paletteMode = useMemo((): PaletteMode => {
        if (themeMode === "system") {
            return window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light";
        }

        return themeMode;
    }, [themeMode]);

    const theme = useMemo(
        () =>
            createTheme({
                components: {
                    MuiTableCell: {
                        styleOverrides: {
                            root: {
                                fontSize: '0.875em',
                            },
                        },
                    },
                    MuiFormLabel: {
                        styleOverrides: {
                            root: {
                                marginTop: -8,
                                paddingTop: '0',
                            },
                        },
                    },
                    MuiInputBase: {
                        styleOverrides: {
                            input: {
                                paddingTop: '8px !important',
                                paddingBottom: '8px !important',
                            },
                        },
                    },
                },
                palette: {
                    // mode: paletteMode,
                    primary: {
                        main: "#5ac3bf",
                    },
                    // secondary: {
                    //     main: "#3a6f7f",
                    // },
                },
            }),
        [paletteMode],
    );

    const switchMode = useCallback((mode: ThemeMode) => {
        setThemeMode(mode);
        window.localStorage.setItem("aps_theme_mode", mode);
    }, []);

    const value = useMemo(
        () => ({
            mode: themeMode,
            switchMode,
        }),
        [themeMode, switchMode],
    );

    return (
        <ThemeContext.Provider value={value}>
            <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
        </ThemeContext.Provider>
    );
};

export const useThemeContext = () => {
    const context = useContext(ThemeContext);

    if (!context) {
        throw new Error("Context used outside ThemeProvider");
    }

    return context;
};

export default ThemeProvider;
