import {Stack, Typography} from "@mui/material";
import type {ReactNode} from "react";
import {Outlet} from "react-router-dom";
import MrtEmployeeTable from "@/components/Table/MrtEmployeeTable.tsx";
import type {z} from "zod";
import type {addEmployeeSchema} from "@/components/Forms/schema/saveEmployeeSchema.ts";
import AddEmployeeButton from "@/components/AddEmployeeButton.tsx";
import {useEmployee} from "@/contexts/EmployeeProvider.tsx";

export type AddEmployeeFormValues = z.infer<typeof addEmployeeSchema>;

const AdminUserManagement = (): ReactNode => {
    const {hasPermission, hasAnyPermission} = useEmployee()

    if (!hasAnyPermission(["create:employee", "delete:employee", "edit:employee"])) {
        return <div>You do not have access to this page.</div>;
    }

    return (
        <>
            <Stack
                sx={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 2,
                }}
            >
                <Typography variant="h4" mb={2}>
                    Users
                </Typography>
                {hasPermission("create:employee") && <AddEmployeeButton/>}
            </Stack>

            <MrtEmployeeTable/>
            <Outlet />
        </>
    );
};

export default AdminUserManagement;
