import {z} from "zod";
import {dayjsLocalDateSchema} from "@/utils/zod.ts";
import {zj} from "zod-joda";

export const personSchema = z.object({
    idUUID: z.string(),
    id: z.number(),
    role: z.string().optional().nullable(),
    nameFirst: z.string().optional().nullable(),
    nameLast: z.string().optional().nullable(),

    namePrefix: z.string().optional().nullable(),
    nameI: z.string().optional().nullable(),
    nameAKA: z.string().optional().nullable(),
    nameSuffix: z.string().optional().nullable(),
    crmStatus: z.string().optional().nullable(),
    race: z.string().optional().nullable(),
    sex: z.string().optional().nullable(),
    ssnumber: z.string().optional().nullable(),
    taxIDNumber: z.string().optional().nullable(),
    dlNumber: z.string().optional().nullable(),
    dlissuedBy: z.string().optional().nullable(),
    dateofbirth: zj.localDate().nullable(),
    dateofdeath: zj.localDate().nullable(),
    idReferredBy: z.string().optional().nullable(),
    idSourceView: z.string().optional().nullable(),
    idSource: z.string().optional().nullable(),

    primaryPhoneNumber: z.string().optional().nullable(),

    emergencyContactPhone1: z.string().optional().nullable(),
    emergencyContactPhone2: z.string().optional().nullable(),
    emergencyContactName1: z.string().optional().nullable(),
    emergencyContactName2: z.string().optional().nullable(),
    legalGuardianName: z.string().optional().nullable(),
    legalGuardianPhone: z.string().optional().nullable(),
    legalGuardianEmail: z.string().optional().nullable(),
});

export const savePersonSchema = personSchema.extend({
    dateofbirth: dayjsLocalDateSchema.nullable(),
    dateofdeath: dayjsLocalDateSchema.nullable(),
    legalGuardianName: z.string().optional().nullable(),
    legalGuardianPhone: z.string().optional().nullable(),
    legalGuardianEmail: z.string().optional().nullable(),
});

export const addPersonSchema = z.object({
    role: z.string(),
    nameFirst: z.string(),
    nameLast: z.string(),
})

export const testPersonSchema = z.object({
    idUUID: z.string().optional().nullable(),
    id: z.number().optional().nullable(),
    dateofbirth: zj.localDate().nullable(),
})
