import {keepPreviousData, useQuery, type UseQueryResult} from "@tanstack/react-query";
import useAuthenticatedFetch from "@/hooks/useAuthenticatedFetch.ts";
import {apiUrl} from "@/utils/api.ts";
import {createDataSelector, createResourceCollectionSelector} from "jsonapi-zod-query";
import {employeeSchema} from "@/components/Forms/schema/employeeSchema.ts";

const resourceSelector = createResourceCollectionSelector({
    type: "employee",
    attributesSchema: employeeSchema,
});

export const employeesSelector = createDataSelector(resourceSelector);
export type EmployeesType = ReturnType<typeof employeesSelector>;

export const useFetchEmployeesForLastTouch = () : UseQueryResult<EmployeesType> => {
    const fetch = useAuthenticatedFetch();

    return useQuery({
        queryKey: [
            `employees-last-touch`,
        ],
        queryFn: async () => {
            const fetchURL = apiUrl('/employee/last-touch');
            const response = await fetch(fetchURL.href);
            return await response.json();
        },
        select: employeesSelector,
        placeholderData: keepPreviousData,
    });
};
