import {useEffect, useMemo, useState} from "react";
import {
    MaterialReactTable,
    type MRT_ColumnDef,
    type MRT_ColumnFiltersState,
    type MRT_PaginationState,
    type MRT_Row,
    type MRT_SortingState,
    useMaterialReactTable,
} from "material-react-table";
import {useFetchMrtCases} from "@/queries/useFetchMrtCases.ts";
import type {MrtDashboardCase} from "@/queries/useFetchMrtDashboardCases.ts";
import {ChronoUnit, LocalDate} from "@js-joda/core";
import {dateFormatterDashed} from "@/utils/mapDateOrStringToLocalDate.ts";
import {IconButton, Paper, Stack, Typography} from "@mui/material";
import {FormProvider, useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {casesFilterSchema} from "@/components/Forms/schema/caseFormSchema.ts";
import CaseStatusesForCasesTable from "@/components/Forms/MrtCaseTableFilters/CaseStatusesForCasesTable.tsx";
import type {z} from "zod";
import CaseTypesForCasesTable from "@/components/Forms/MrtCaseTableFilters/CaseTypesForCasesTable.tsx";
import DaysToSolForCasesTable from "@/components/Forms/MrtCaseTableFilters/DaysToSolForCasesTable.tsx";
import {getSolReportMrtStyle} from "@/functions/getSolReportMrtStyle.ts";
import {useDeleteCase} from "@/mutations/useDeleteCase.tsx";
import {enqueueSnackbar} from "notistack";
import {getErrorMessage} from "@/utils/api.ts";
import DeleteIcon from "@mui/icons-material/Delete";
import {useEmployee} from "@/contexts/EmployeeProvider.tsx";

export type CasesTableFilter = z.infer<typeof casesFilterSchema>;

export type CaseApiResponse = {
    data: Case[];
    meta: {
        totalRowCount: number;
    };
};

export type Case = {
    person: {
        namePrefix ?: string,
        nameFirst ?: string,
        nameI ?: string,
        nameLast ?: string,
        nameAKA ?: string,
        nameSuffix ?: string,
        crmStatus ?: string,
        race ?: string,
        sex ?: string,
        ssnumber ?: string,
        taxIDNumber ?: string,
        dlNumber ?: string,
        dlissuedBy ?: string,
        dateofbirth ?: string,
        dateofdeath ?: string,
        idReferredBy ?: string,
        idSourceView ?: string,
    }
    id: string;
    idUUID : string | null;
    clientName : string | null;
    caseType : string | null;
    caseStatus : string | null;
    caseStatusUM : string | null;
    caseName : string | null;
    dateOfIncident : Date | null;
    dateOfIncidentAge : number | null;
    lastTouch : string | null;
    assignedTeam : string | null;

    accidentCaseNumber: string;
    accidentReportDone: string;
    accidentSummary: string;
    ambulanceName: string;
};

const MrtCaseTable = () => {
    const {hasPermission} = useEmployee()
    const deleteCase = useDeleteCase();
    const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);
    const [globalFilter, setGlobalFilter] = useState("");
    const [sorting, setSorting] = useState<MRT_SortingState>([]);
    const [pagination, setPagination] = useState<MRT_PaginationState>({
        pageIndex: 0,
        pageSize: 10,
    });

    const {data, isError, isRefetching, isLoading, error} = useFetchMrtCases({
        columnFilters,
        globalFilter,
        pagination,
        sorting,
    });

    const form = useForm<CasesTableFilter>({
        resolver: zodResolver(casesFilterSchema),
    });

    const caseType = form.watch('caseType')
    const caseStatus = form.watch('caseStatus')
    const daysToSol = form.watch('daysToSol')

    const handleRowClick = (row: MRT_Row<MrtDashboardCase>) => {
        window.location.href = `/case-detail/${row.original.id}`;
    };

    const handleDeleteRow = (row: MRT_Row<MrtDashboardCase>) => {
        if (window.confirm('Are you sure you want to delete this Case?')) {
            deleteCase.mutate(
                {
                    caseUUID: row.original.idUUID,
                },
                {
                    onSuccess: () => {
                        enqueueSnackbar("Case removed.", { variant: "success" });
                    },
                    onError: (error) => {
                        enqueueSnackbar("Error: Case could not be removed.", { variant: "error" });
                        console.info('error', getErrorMessage(error));
                    },
                },
            )
        }
    };

    const columns = useMemo<MRT_ColumnDef<MrtDashboardCase>[]>(
        () => {
            const columnDefs: MRT_ColumnDef<MrtDashboardCase>[] = [
                {
                    accessorKey: "id",
                    header: "Case #",
                },
                {
                    accessorKey: "clientName",
                    header: "Client Name",
                },
                {
                    accessorKey: "caseType",
                    header: "Type",
                },
                {
                    accessorKey: "caseStatus",
                    header: "Liability Status",
                },
                {
                    accessorKey: "caseStatusUM",
                    header: "UM Status",
                },
                {
                    accessorKey: "caseName",
                    header: "Case Name",
                },
                {
                    accessorKey: "dateOfIncident",
                    header: "DOI",
                    Cell: ({ renderedCellValue }) => {
                        if (renderedCellValue instanceof LocalDate) {
                            return renderedCellValue.format(dateFormatterDashed)
                        }

                        return ''
                    },
                },
                {
                    accessorKey: "dateOfIncidentAge",
                    header: "SOL Days",
                    Cell: ({ row }) => {
                        if (row.original.dateOfIncident) {
                            const today = LocalDate.now();
                            return ChronoUnit.DAYS.between(row.original.dateOfIncident, today);
                        }

                        return ''
                    },
                },
                {
                    accessorKey: "lastTouch",
                    header: "DSC",
                    Cell: ({ row }) => {
                        if (row.original.lastTouch) {
                            const today = new Date();
                            return Math.floor((today.getTime() - row.original.lastTouch.getTime()) / (1000 * 60 * 60 * 24));
                        }

                        return ''
                    },
                },
                {
                    accessorKey: "assignedTeam",
                    header: "Team",
                },
            ]

            if (hasPermission("delete:case")) {
                columnDefs.push({
                    accessorKey: 'delete',
                    header: 'Delete',
                    size: 50,
                    Cell: ({ row }) => (
                        <IconButton onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                            event.stopPropagation();
                            handleDeleteRow(row)
                        }}>
                            <DeleteIcon />
                        </IconButton>
                    ),
                });
            }

            return columnDefs;
        }, [hasPermission, handleDeleteRow]);

    const table = useMaterialReactTable({
        columns,
        data: (isLoading || isRefetching || isError || data === undefined) ? [] : data.data,
        enableColumnActions: false,
        defaultColumn: {
            size: 30,
            muiTableBodyCellProps: {
                sx: {
                    wordWrap: 'break-word',
                    whiteSpace: 'normal',
                },
            },
        },
        initialState: {
            showColumnFilters: false,
            showGlobalFilter: true,
            density: 'compact',
        },

        // top toolbar
        enableTopToolbar: true,
        enableDensityToggle: false,
        enableFullScreenToggle: false,
        enableHiding: false,
        enableColumnFilters: false,

        manualFiltering: true,
        manualPagination: true,
        manualSorting: true,
        muiToolbarAlertBannerProps: isError
            ? {
                color: "error",
                children: "Error loading data",
            }
            : undefined,
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        onPaginationChange: setPagination,
        onSortingChange: setSorting,
        rowCount: data?.meta.totalRowCount ?? 0,
        state: {
            columnFilters,
            globalFilter,
            isLoading,
            pagination,
            showAlertBanner: isError,
            showProgressBars: isRefetching,
            showLoadingOverlay: (isLoading || isRefetching),
            sorting,
        },
        muiTableBodyRowProps: ({ row }: { row: MRT_Row<MrtDashboardCase> }) => {
            return {
                onClick: () => handleRowClick(row),
                sx: {
                    cursor: "pointer",
                    '& .MuiTableCell-root': {
                        ...getSolReportMrtStyle({
                            caseStatus: row.original.caseStatus ?? '',
                            dateOfBirth: row.original.idPeople?.dateofbirth ?? null,
                        })
                    },
                },
            };
        },
    });

    useEffect(() => {
        table.getColumn('caseType')?.setFilterValue(caseType);
        table.getColumn('caseStatus')?.setFilterValue(caseStatus);
        table.getColumn('dateOfIncidentAge')?.setFilterValue(daysToSol);
    }, [caseType, caseStatus, daysToSol, table.getColumn]);

    if (isError) {
        return (
            <div>{error.message}</div>
        );
    }

    return <>
        <FormProvider {...form}>
            <Paper elevation={0}>
                <Typography variant={'h5'}>Filter</Typography>
                <Stack
                    sx={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        gap: 1,
                        py: 2,
                    }}
                >
                    <CaseTypesForCasesTable form={form}/>
                    <CaseStatusesForCasesTable form={form}/>
                    <DaysToSolForCasesTable form={form}/>
                </Stack>
            </Paper>
            <MaterialReactTable table={table} />
        </FormProvider>
    </>;
};

export default MrtCaseTable;
