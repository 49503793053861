import {FormProvider, type SubmitHandler, useForm} from 'react-hook-form';
import {z} from 'zod';
import {zodResolver} from '@hookform/resolvers/zod';
import {CircularProgress, Grid2 as Grid} from "@mui/material";
import {type BaseSyntheticEvent, useCallback, useEffect, useState} from "react";
import {useFetchPartyRole} from "@/queries/useFetchPartyRole.ts";
import {useSavePartyRole} from "@/mutations/useSavePartyRole.tsx";
import {partyRoleSchema} from "@/components/Forms/schema/partyRoleSchema.ts";
import PartyRoleInformation from "@/components/PartyRoleInformation.tsx";
import {enqueueSnackbar} from "notistack";
import {getErrorMessage} from "@/utils/api.ts";

export type PartyRoleFormValues = z.infer<typeof partyRoleSchema>;

const massageLocalDateToDayjsDates = (data: Record<string, any>) => {

    return {
        ...data.data,
        order: data.data.order ? data.data.order.toString() : null,
    }
}

type Props = {
    partyRoleUUID: string;
}

const PartyRoleForm = ({partyRoleUUID} : Props) => {
    const savePartyRole = useSavePartyRole(partyRoleUUID);
    const [foundPartyRoleIdUUID, setFoundPartyRoleIdUUID] = useState<string | null>(null);

    const {
        data,
        isError,
        isRefetching,
        isLoading,
        isPending,
        error,
    } = useFetchPartyRole(partyRoleUUID);

    const form = useForm<PartyRoleFormValues>({
        resolver: zodResolver(partyRoleSchema),
    });

    const handleSubmit = useCallback(
        (values: PartyRoleFormValues) => {
            if (foundPartyRoleIdUUID) {
                savePartyRole.mutate(
                    {
                        ...values,
                        order: values.order ? parseInt(values.order) : null,
                    },
                    {
                        onSuccess: () => {
                            enqueueSnackbar("PartyRole saved.", { variant: "success" });
                            form.reset(form.getValues());
                        },
                        onError: (error) => {
                            enqueueSnackbar("Error: PartyRole failed to save.", { variant: "error" });
                            console.info('error', getErrorMessage(error));
                        },
                    },
                );
            }
        },
        [savePartyRole, foundPartyRoleIdUUID],
    );

    const onSubmit: SubmitHandler<PartyRoleFormValues> = async (
        data: PartyRoleFormValues,
        event?: BaseSyntheticEvent
    ) => {
        event?.stopPropagation();
        handleSubmit(data);
    }

    useEffect(() => {
        if (!isError && !isRefetching && !isLoading && data) {
            form.reset(massageLocalDateToDayjsDates(data));

            if (data.data.idUUID) {
                setFoundPartyRoleIdUUID(data.data.idUUID);
            }
        }
    }, [data, isError, isRefetching, isLoading]);

    if (isPending) {
        return null;
    }

    if (isError) {
        return (
            <div>{error.message}</div>
        );
    }

    if (isLoading || isRefetching) {
        return (
            <CircularProgress/>
        );
    }

    if (error) {
        return (
            <div>{error}</div>
        );
    }

    return <>
        <FormProvider {...form}>
            <form onSubmit={
                form.handleSubmit(onSubmit)
            }>
                {foundPartyRoleIdUUID && <Grid container>
                    <Grid size={12}>
                        <PartyRoleInformation form={form} />
                    </Grid>
                </Grid>}
            </form>
        </FormProvider>
    </>
};

export default PartyRoleForm;
