import {z} from "zod";

export const addPhoneNumberSchema = z.object({
    idUUID: z.string().nullable().optional(),
    phoneType: z.string().optional().nullable(),
    phoneNumber: z.string().optional().nullable(),
    extension: z.string().optional().nullable(),
    primary: z.string().optional().nullable(),
    oK2Call: z.string().optional().nullable(),
    altContactType: z.string().optional().nullable(),
    altContactName: z.string().optional().nullable(),
});
