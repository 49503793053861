import {keepPreviousData, useQuery, type UseQueryResult} from "@tanstack/react-query";
import useAuthenticatedFetch from "@/hooks/useAuthenticatedFetch.ts";
import {apiUrl} from "@/utils/api.ts";
import {createResourceCollectionSelector} from "jsonapi-zod-query";
import {zipCodeSchema} from "@/components/Forms/schema/zipCodeSchema.ts";

const zipCodeTypeSelector = createResourceCollectionSelector({
    type: "zipCode",
    attributesSchema: zipCodeSchema,
});

export type ZipCode = ReturnType<typeof zipCodeTypeSelector>;

type Props = {
    zipCode : string | null;
    isQueryEnabled ?: boolean;
};
export const useFetchZipCodes = (
    {
        isQueryEnabled = true,
        zipCode,
    } : Props
 ) : UseQueryResult<ZipCode> => {
    const fetch = useAuthenticatedFetch();

    return useQuery({
        queryKey: [
            'zipCode',
            zipCode,
        ],
        enabled: isQueryEnabled,
        queryFn: async () => {
            const fetchURL = apiUrl(`/zipCode/${zipCode}`);

            const response = await fetch(fetchURL.href, {
                method: 'GET',
                headers: {
                    "Content-Type": "application/vnd.api+json",
                },
            });

            return await response.json();
        },
        select: zipCodeTypeSelector,
        placeholderData: keepPreviousData,
    });
};
