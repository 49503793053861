import {useEffect, useState} from 'react';
import {useAuth0} from '@auth0/auth0-react';

const useInactivityLogout = (timeoutDuration = 900000) => { // Default to 15 minutes (900000 ms)
    const { logout } = useAuth0();
    const [lastActiveTime, setLastActiveTime] = useState(Date.now());

    const resetTimer = () => {
        setLastActiveTime(Date.now());
    };

    useEffect(() => {
        const handleUserActivity = () => {
            resetTimer();
        };

        const events = ['mousemove', 'keydown', 'mousedown', 'scroll'];

        events.forEach(event => {
            window.addEventListener(event, handleUserActivity);
        });

        const checkInactivity = () => {
            const currentTime = Date.now();

            if (currentTime - lastActiveTime > timeoutDuration) {
                void logout({
                    logoutParams: {
                        returnTo: window.origin,
                    }
                });
            }
        };

        const inactivityInterval = setInterval(checkInactivity, 1000);

        return () => {
            events.forEach(event => {
                window.removeEventListener(event, handleUserActivity);
            });

            clearInterval(inactivityInterval);
        };
    }, [lastActiveTime, logout, timeoutDuration]);
};

export default useInactivityLogout;
