import type {UseMutationResult} from '@tanstack/react-query';
import {useMutation} from '@tanstack/react-query';
import type {DocumentData} from '@/types/document.ts';
import {apiUrl} from '@/utils/api.ts';
import useAuthenticatedFetch from "@/hooks/useAuthenticatedFetch.ts";
import {handleJsonApiError} from "jsonapi-zod-query";
import {S3SignedUrl} from "@/mutations/document.ts";

export const useGenerateMultiplePreSignedPutUrl = () : UseMutationResult<
    S3SignedUrl[],
    unknown,
    DocumentData[]
> => {
    const fetch = useAuthenticatedFetch();

    return useMutation({
        mutationFn: async (documents: DocumentData[]) => {
            const response = await fetch(apiUrl('/s3/generate-multiple-pre-signed-put-url'), {
                method: "POST",
                body: JSON.stringify(documents),
                headers: {
                    "Content-Type": "application/vnd.api+json",
                },
            });
            await handleJsonApiError(response);
            return (await response.json());
        },
    });
};

