import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import CaseSubformDetails from "@/components/Forms/SubForms/CaseSubformDetails.tsx";
import LiabilityTab from "@/components/LiabilityTab.tsx";
import UmUimTab from "@/components/UmUimTab.tsx";
import ProvidersTab from "@/components/ProvidersTab.tsx";
import LoanTab from "@/components/LoanTab.tsx";
import NegotiationTab from "@/components/NegotiationTab.tsx";
import DisbursementTab from "@/components/DisbursementTab.tsx";
import MediaDocumentsCollectionForCase from "@/components/Documents/MediaDocumentsCollectionForCase.tsx";
import {CaseData} from "@/queries/useFetchCase.ts";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

const CustomTabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ pt: 2 }}>{children}</Box>}
        </div>
    );
}

const a11yProps = (index: number) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
        sx: {
            fontSize: {xs: '16px'},
            px: '8px',
            borderTopRightRadius: '8px',
            borderTopLeftRadius: '8px',
            width: 'auto',
            '&.Mui-selected': {
                // backgroundColor: '#e6e6e6',
                borderTop: '1px solid #e6e6e6',
                borderLeft: '1px solid #e6e6e6',
                borderRight: '1px solid #e6e6e6',
            },
            '&:hover': {
                backgroundColor: '#e6e6e6',
            },
            mb: 1,
        }
    };
}

type Props = {
    foundCaseId: string;
    caseIdUUID: string;
    caseData: CaseData;
}

const CaseTabs = ({foundCaseId, caseIdUUID, caseData} : Props) =>  {
    const [value, setValue] = React.useState(0);

    const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                    TabIndicatorProps={{ sx: { display: 'none', },     style: {
                            backgroundColor: "#D97D54"
                        } }}
                    value={value}
                    onChange={handleChange}
                    aria-label="Case Tabs"
                    sx={{
                        '& .MuiTabs-flexContainer': {
                            flexWrap: 'wrap',
                        },
                    }}
                    centered
                >
                    <Tab label="Case Details" {...a11yProps(0)} />
                    <Tab label="Liability" {...a11yProps(1)} />
                    <Tab label="UM/UIM" {...a11yProps(2)} />
                    <Tab label="Providers" {...a11yProps(3)} />
                    <Tab label="Loan" {...a11yProps(4)} />
                    <Tab label="Negotiation" {...a11yProps(5)} />
                    <Tab label="Disbursements" {...a11yProps(6)} />
                    <Tab label="General Media" {...a11yProps(7)} wrapped/>
                </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
                {caseIdUUID && <CaseSubformDetails caseIdUUID={caseIdUUID} foundCaseId={foundCaseId}/>}
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
                {foundCaseId && <LiabilityTab caseIdUUID={caseIdUUID}/>}
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
                {foundCaseId && caseData && <UmUimTab caseIdUUID={caseIdUUID} foundCaseId={foundCaseId} caseData={caseData}/>}
            </CustomTabPanel>
            <CustomTabPanel value={value} index={3}>
                {foundCaseId && <ProvidersTab caseIdUUID={caseIdUUID}/>}
            </CustomTabPanel>
            <CustomTabPanel value={value} index={4}>
                {foundCaseId && <LoanTab caseIdUUID={caseIdUUID}/>}
            </CustomTabPanel>
            <CustomTabPanel value={value} index={5}>
                {foundCaseId && <NegotiationTab caseIdUUID={caseIdUUID} foundCaseId={foundCaseId}/>}
            </CustomTabPanel>
            <CustomTabPanel value={value} index={6}>
                {foundCaseId && <DisbursementTab caseIdUUID={caseIdUUID}/>}
            </CustomTabPanel>
            <CustomTabPanel value={value} index={7}>
                {foundCaseId && <MediaDocumentsCollectionForCase caseIdUUID={caseIdUUID}/>}
            </CustomTabPanel>
        </Box>
    );
}

export default CaseTabs;
