import {useMemo, useState} from 'react';
import {
    MaterialReactTable,
    type MRT_ColumnDef,
    type MRT_ColumnFiltersState,
    type MRT_PaginationState,
    type MRT_Row,
    type MRT_SortingState,
    useMaterialReactTable,
} from 'material-react-table';
import {type MrtParty, useFetchMrtPartiesForCase} from "@/queries/useFetchMrtPartiesForCase.ts";
import {Box, IconButton, Stack} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import AddEditProviderDialog, {
    mediaJsonToFileName,
    mediaJsonToValue
} from "@/components/Forms/AddEditProviderDialog.tsx";
import {currencyFormatter} from "@/utils/currencyFormatter.ts";
import {useDeleteParty} from "@/mutations/useDeleteParty.tsx";
import {enqueueSnackbar} from "notistack";
import {getErrorMessage} from "@/utils/api.ts";
import DeleteIcon from "@mui/icons-material/Delete";
import {useEmployee} from "@/contexts/EmployeeProvider.tsx";
import MediaRedirectLink from "@/components/Documents/MediaRedirectLink.tsx";

type Props = {
    caseIdUUID: string;
};

const MrtProvidersTable = ({caseIdUUID} : Props) => {
    const {hasPermission} = useEmployee();
    const deleteParty = useDeleteParty();
    const [editPartyId, setEditPartyId] = useState<string | undefined>();
    const roleName = ['Provider'];
    const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
        [],
    );
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState<MRT_SortingState>([]);
    const [pagination, setPagination] = useState<MRT_PaginationState>({
        pageIndex: 0,
        pageSize: 100,
    });

    const {
        data: { data = [], meta } = {},
        isError,
        isRefetching,
        isLoading,
    } = useFetchMrtPartiesForCase({
        caseIdUUID,
        roleName,
        columnFilters,
        globalFilter,
        pagination,
        sorting,
    });

    const sumProviderBill = useMemo(
        () => data.reduce((acc, curr) => acc + (curr.providerBill ? parseFloat(curr.providerBill) : 0), 0),
        [data]
    );

    const sumProviderBalance = useMemo(
        () => data.reduce((acc, curr) => acc + (curr.providerBalance ? parseFloat(curr.providerBalance) : 0), 0),
        [data]
    );

    const handleEditRow = (idUUID: string) => {
        setEditPartyId(idUUID);
    }

    const handleDeleteRow = (row: MRT_Row<MrtParty>) => {
        if (window.confirm('Are you sure you want to delete this Provider?')) {
            deleteParty.mutate(
                {
                    partyUUID: row.original.idUUID,
                },
                {
                    onSuccess: () => {
                        enqueueSnackbar("Provider removed.", { variant: "success" });
                    },
                    onError: (error) => {
                        enqueueSnackbar("Error: Provider could not be removed.", { variant: "error" });
                        console.info('error', getErrorMessage(error));
                    },
                },
            )
        }
    };

    const columns = useMemo<MRT_ColumnDef<MrtParty>[]>(
        () => {
            const columnDefs: MRT_ColumnDef<MrtParty>[] = [
                {
                    accessorKey: 'edit',
                    header: '',
                    enableSorting: false,
                    Cell: ({row}) => (
                        <IconButton
                            onClick={(e) => {
                                e.stopPropagation();
                                handleEditRow(row.original.idUUID);
                            }}
                            size="small"
                        >
                            <EditIcon />
                        </IconButton>
                    ),
                    size: 50,
                },
                {
                    accessorKey: 'companyName',
                    header: 'Company',
                },
                {
                    accessorKey: 'phone',
                    header: 'Phone',
                },
                {
                    accessorKey: 'providerStartDate',
                    header: 'Treatment Start',
                    muiTableHeadCellProps: {
                        className: 'wordwrap'
                    }
                },
                {
                    accessorKey: 'providerEndDate',
                    header: 'Treatment End',
                },
                {
                    accessorKey: 'selectedMedia',
                    header: 'Records',
                    Cell: ({row}) => {
                        const selectedMediaUUID = mediaJsonToValue(row.original.selectedMedia ?? '');
                        const fileName = mediaJsonToFileName(row.original.selectedMedia ?? '');

                        if (selectedMediaUUID) {
                            return <MediaRedirectLink mediaUUID={selectedMediaUUID} fileName={fileName ?? 'Unknown File Name'}/>
                        }

                        return null
                    },
                },
                {
                    accessorKey: 'providerBill',
                    header: 'Bill',
                    aggregationFn: 'sum',
                    Cell: ({row}) => {
                        if (row.original.providerBill) {
                            return currencyFormatter.format(Number.parseFloat(row.original.providerBill))
                        }
                    },
                    Footer: () => (
                        <Stack>
                            Bill Amount:
                            <Box>{currencyFormatter.format(sumProviderBill)}</Box>
                        </Stack>
                    ),
                },
                {
                    accessorKey: 'selectedRecord',
                    header: 'Bill Upload',
                    Cell: ({row}) => {
                        const selectedRecordUUID = mediaJsonToValue(row.original.selectedRecord ?? '');
                        const fileName = mediaJsonToFileName(row.original.selectedRecord ?? '');

                        if (selectedRecordUUID) {
                            return <MediaRedirectLink mediaUUID={selectedRecordUUID} fileName={fileName ?? 'Unknown File Name'}/>
                        }

                        return null
                    },
                },
                {
                    accessorKey: 'providerHealthInsPmt',
                    header: 'Health Ins Pmt',
                },
                {
                    accessorKey: 'providerAdjustment',
                    header: 'H.I. Adj',
                },
                {
                    accessorKey: 'providerSelfPay',
                    header: 'Self Pay',
                },
                {
                    accessorKey: 'providerMedPay',
                    header: 'MedPay',
                },
                {
                    accessorKey: 'providerClientToPay',
                    header: 'Client to Pay',
                },
                {
                    accessorKey: 'providerReduction',
                    header: 'Reduction Amount',
                },
                {
                    accessorKey: 'providerBalance',
                    header: 'Balance',
                    aggregationFn: 'sum',
                    Cell: ({row}) => {
                        if (row.original.providerBalance) {
                            return currencyFormatter.format(Number.parseFloat(row.original.providerBalance))
                        }
                    },
                    Footer: () => (
                        <Stack>
                            Subtotal:
                            <Box>{currencyFormatter.format(sumProviderBalance)}</Box>
                        </Stack>
                    ),
                },
            ]

            if (hasPermission("delete:provider")) {
                columnDefs.push({
                    accessorKey: 'delete',
                    header: 'Delete',
                    size: 50,
                    Cell: ({ row }) => (
                        <IconButton onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                            event.stopPropagation();
                            handleDeleteRow(row)
                        }}>

                        <DeleteIcon />
                        </IconButton>
                    ),
                });
            }

            return columnDefs;
        }, [sumProviderBill, sumProviderBalance, hasPermission, handleEditRow, handleEditRow, handleDeleteRow]);

    const table = useMaterialReactTable({
        columns,
        data,
        enableColumnActions: false,
        defaultColumn: {
            size: 20,
            muiTableBodyCellProps: {
                sx: {
                    wordWrap: 'break-word',
                    whiteSpace: 'normal',
                    fontSize: '13px'
                },
            },
            muiTableHeadCellProps: {
                sx: {
                    px: '1px',
                    '& div': {
                        whiteSpace: 'pre-line !important',
                        alignItems: 'flex-start',
                    },
                },
            },
        },
        enablePagination: false,
        initialState: {
            showColumnFilters: false,
            density: 'compact',
        },
        enableTopToolbar: false,
        manualFiltering: true,
        manualPagination: true,
        manualSorting: true,
        muiToolbarAlertBannerProps: isError
            ? {
                color: 'error',
                children: 'Error loading data',
            }
            : undefined,
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        onPaginationChange: setPagination,
        onSortingChange: setSorting,
        rowCount: meta?.totalRowCount ?? 0,
        state: {
            columnFilters,
            globalFilter,
            isLoading,
            pagination,
            showAlertBanner: isError,
            showProgressBars: isRefetching,
            showLoadingOverlay: (isLoading || isRefetching),
            sorting,
        },
        muiTableProps: {
            sx: {
                width: '100%',
                tableLayout: 'auto', // Ensure that the table auto-adjusts its layout
                overflowX: 'auto', // Allow horizontal scrolling in case of wide content
            },
        },
        muiTableBodyRowProps: ({ row }: { row: MRT_Row<MrtParty> }) => {
            let backgroundColor = 'inherit';
            const providerReductionApproved = row.original.providerReductionApproved;

            if (providerReductionApproved === 'Yes') {
                backgroundColor = '#c2d7b7';
            }

            if (providerReductionApproved === 'No') {
                backgroundColor = '#FFCCCB';
            }

            return {
                sx: {
                    '& .MuiTableCell-root': {
                        backgroundColor:  backgroundColor,
                    },
                },
            };
        },

    });

    return <>
        <MaterialReactTable table={table} />
        <AddEditProviderDialog
            caseUUID={caseIdUUID}
            showAddButton={false}
            editPartyId={editPartyId}
            clearEditPartyId={() => {
                setEditPartyId(undefined)
            }}
        />
    </>
};

export default MrtProvidersTable;
