import {useParams} from 'react-router-dom';
import type {ReactNode} from "react";
import CaseStatusForm from "@/components/Forms/CaseStatusForm.tsx";

const CaseStatusDetail = (): ReactNode => {
    const { caseStatusUUID } = useParams<{ caseStatusUUID: string }>();

    if (!caseStatusUUID) {
        return <div>No CaseStatus ID provided</div>;
    }

    return (
        <div>
            <CaseStatusForm caseStatusUUID={caseStatusUUID}/>
        </div>
    );

};

export default CaseStatusDetail;
