import type {UseFormReturn} from 'react-hook-form';
import {Button, Grid2 as Grid, MenuItem, Paper, Stack, Typography} from "@mui/material";
import {RhfTextField} from "mui-rhf-integration";
import {CaseStatusFormValues} from "@/components/Forms/CaseStatusForm.tsx";

type Props = {
    form: UseFormReturn<CaseStatusFormValues>;
}

const CaseStatusInformation = ({form} : Props) => {
    return <Grid container>
        <Grid size={12}>
            <Paper
                sx={{
                    mb: 2,
                    mx: 1,
                }}
            >
                <Grid
                    container
                    sx={{
                        borderRadius: '8px',
                        p: 1,
                    }}
                >
                    <Grid size={12} sx={{p: 1, mb: 1}}>
                        <Stack
                            sx={{
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Typography variant={'h5'}>Information</Typography>
                            {form.formState.isDirty && <Button
                                variant='contained'
                                type={'submit'}
                                sx={{
                                    p: 1,
                                    borderRadius: '8px',
                                    fontWeight: 800,
                                    mb: 2,
                                    mr: 2,
                                }}
                            >
                                Save
                            </Button>}
                        </Stack>
                    </Grid>
                    <Grid size={6} sx={{p: 1}}>
                        <RhfTextField
                            control={form.control}
                            name={'order'}
                            label={'Order'}
                            sx={{
                                width: '100%',
                            }}
                        />
                    </Grid>
                    <Grid size={6} sx={{p: 1}}>
                        <RhfTextField
                            control={form.control}
                            name={'name'}
                            label={'Name'}
                            sx={{
                                width: '100%',
                            }}
                        />
                    </Grid>
                    <Grid size={6} sx={{p: 1}}>
                        <RhfTextField
                            select
                            control={form.control}
                            name={'inProgress'}
                            label={'Active Case?'}
                            sx={{
                                width: '100%',
                            }}
                        >
                            <MenuItem key={'in-progress-blank'} value={''}></MenuItem>
                            <MenuItem key={'in-progress-yes'} value={'Yes'}>Yes</MenuItem>
                            <MenuItem key={'in-progress-no'} value={'No'}>No</MenuItem>
                        </RhfTextField>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    </Grid>
};

export default CaseStatusInformation;
