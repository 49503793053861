import {useQuery, type UseQueryResult} from "@tanstack/react-query";
import useAuthenticatedFetch from "@/hooks/useAuthenticatedFetch.ts";
import {apiUrl} from "@/utils/api.ts";
import {createDataSelector, createResourceSelector,} from "jsonapi-zod-query";
import {caseFormSchemaInput, clientSchema} from "@/components/Forms/schema/caseFormSchemaInput.ts";
import {personSchema} from "@/components/Forms/schema/personSchema.ts";

const resourceSelector = createResourceSelector({
    type: "case",
    attributesSchema: caseFormSchemaInput,
    relationships: {
        idClient: {
            relationshipType: "one_nullable",
            include: {
                type: "client",
                attributesSchema: clientSchema,
            },
        },
        idPeople: {
            relationshipType: "one_nullable",
            include: {
                type: "person",
                attributesSchema: personSchema,
            },
            required: false,
        },
    },
});

export const caseSelector = createDataSelector(resourceSelector);
export type CaseData = ReturnType<typeof caseSelector>;

export type CaseDocument = ReturnType<typeof resourceSelector>;

export const useFetchCase = (caseId: string): UseQueryResult<CaseDocument> => {
    const fetch = useAuthenticatedFetch();

    return useQuery({
        queryKey: ["case", { caseId }],
        queryFn: async ({ signal }) => {
            const url = apiUrl(`/case/by-id/${caseId}`);

            const response = await fetch(url, {
                signal,
                headers: {
                    accept: "application/vnd.api+json",
                },
            });

            return await response.json();
        },
        select: resourceSelector,
    });
};
