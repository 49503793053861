import {Grid2 as Grid, Paper, Typography} from '@mui/material';
import MrtLiabilityTable from "@/components/Table/MrtLiabilityTable.tsx";
import MediaDocumentsListForCase from "@/components/Documents/MediaDocumentsListForCase.tsx";
import AddEditLiabilityDialog from "@/components/Forms/AddEditLiabilityDialog.tsx";
import NoteList from "@/components/Documents/NoteList.tsx";

type Props = {
    caseIdUUID: string;
};

const LiabilityTab = ({caseIdUUID} : Props) => {
    return <Grid container sx={{pb: {xs: '200px', md: '100px'}}}>
        <Grid size={12}>
            <Paper
                sx={{
                    mb: 2,
                    mx: 1,
                }}
            >
                <Grid
                    container
                    sx={{
                        borderRadius: '8px',
                        p: 1,
                    }}
                >
                    <Grid
                        size={12}
                        sx={{
                            p: 1,
                            mb: 1,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}>
                        <Typography variant={'h5'}>Liability Claim Information</Typography>
                        <AddEditLiabilityDialog caseUUID={caseIdUUID}/>
                    </Grid>
                    <Grid size={12} sx={{p: 1, mb: 1}}>
                        <MrtLiabilityTable caseIdUUID={caseIdUUID} roleName={['Liability - Bodily Injury']}/>
                    </Grid>
                    <Grid size={6} sx={{p: 1, mb: 1}}>
                        <NoteList caseIdUUID={caseIdUUID} limitToCategory={'Liability'}/>
                    </Grid>
                    <Grid size={6} sx={{p: 1, mb: 1}}>
                        <MediaDocumentsListForCase caseIdUUID={caseIdUUID} limitToCategory={'Liability'}/>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    </Grid>;
};

export default LiabilityTab;
