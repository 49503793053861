import {keepPreviousData, useQuery, type UseQueryResult} from "@tanstack/react-query";
import useAuthenticatedFetch from "@/hooks/useAuthenticatedFetch.ts";
import {apiUrl} from "@/utils/api.ts";
import {createDataSelector, createResourceCollectionSelector} from "jsonapi-zod-query";
import {teamSchema} from "@/components/Forms/schema/teamSchema.ts";

const resourceSelector = createResourceCollectionSelector({
    type: "team",
    attributesSchema: teamSchema,
});

const teamsSelector = createDataSelector(resourceSelector);

export type Teams = ReturnType<typeof teamsSelector>;
export type Team = Teams[number];

export const useFetchTeams = () : UseQueryResult<Teams> => {
    const fetch = useAuthenticatedFetch();

    return useQuery({
        queryKey: [
            'team',
        ],
        queryFn: async () => {
            const fetchURL = apiUrl('/team/all');
            const response = await fetch(fetchURL.href);
            return await response.json()
        },
        select: teamsSelector,
        placeholderData: keepPreviousData,
    });
};
