import useAuthenticatedFetch from "@/hooks/useAuthenticatedFetch.ts";
import {apiUrl} from "@/utils/api.ts";
import {useMutation, type UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {handleJsonApiError} from "jsonapi-zod-query";
import {z} from "zod";
import {saveEmployeeSchemaForApi} from "@/components/Forms/schema/saveEmployeeSchema.ts";

type EmployeeFormValuesForApi = z.infer<typeof saveEmployeeSchemaForApi>;

export const useSaveEmployee = (): UseMutationResult<void, Error, {employeeUUID: string} & EmployeeFormValuesForApi> => {
    const fetch = useAuthenticatedFetch();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async ({employeeUUID, ...attributes}) => {
            const response = await fetch(apiUrl(`/employee/save/${employeeUUID}`), {
                method: "POST",
                headers: {
                    "Content-Type": "application/vnd.api+json",
                },
                body: JSON.stringify({
                    data: {
                        id: employeeUUID,
                        type: "employee",
                        attributes: attributes,
                    },
                }),
            });
            await handleJsonApiError(response);
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: ["employees"],
            });
        },
    });
};
