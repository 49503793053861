import {z} from "zod";
import {dayjsLocalDateSchema} from "@/utils/zod.ts";

export const saveNegotiationSchema = z.object({
    id: z.string(),
    idUUID: z.string(),
    checkReceived: z.boolean().optional().nullable(),
    releaseReceived: z.boolean().optional().nullable(),
    medpayCheckReceived: z.boolean().optional().nullable(),
    umCheckReceived: z.boolean().optional().nullable(),
    umReleaseReceived: z.boolean().optional().nullable(),

    checkReceivedTimestamp: z.string().optional().nullable(),
    releaseReceivedTimestamp: z.string().optional().nullable(),
    medpayCheckReceivedTimestamp: z.string().optional().nullable(),
    umCheckReceivedTimestamp: z.string().optional().nullable(),
    umReleaseReceivedTimestamp: z.string().optional().nullable(),

    caseExpenses: z.string().min(0).optional().nullable(),

    demand1: z.string().min(0).optional().nullable(),
    demand2: z.string().min(0).optional().nullable(),
    demand3: z.string().min(0).optional().nullable(),
    demand4: z.string().min(0).optional().nullable(),
    demand5: z.string().min(0).optional().nullable(),
    dateDemand1: dayjsLocalDateSchema.optional().nullable(),
    dateDemand2: dayjsLocalDateSchema.optional().nullable(),
    dateDemand3: dayjsLocalDateSchema.optional().nullable(),
    dateDemand4: dayjsLocalDateSchema.optional().nullable(),
    dateDemand5: dayjsLocalDateSchema.optional().nullable(),

    counter1: z.string().min(0).optional().nullable(),
    counter2: z.string().min(0).optional().nullable(),
    counter3: z.string().min(0).optional().nullable(),
    counter4: z.string().min(0).optional().nullable(),
    counter5: z.string().min(0).optional().nullable(),
    dateCounter1: dayjsLocalDateSchema.optional().nullable(),
    dateCounter2: dayjsLocalDateSchema.optional().nullable(),
    dateCounter3: dayjsLocalDateSchema.optional().nullable(),
    dateCounter4: dayjsLocalDateSchema.optional().nullable(),
    dateCounter5: dayjsLocalDateSchema.optional().nullable(),
});
