import {useQuery, type UseQueryResult} from "@tanstack/react-query";
import useAuthenticatedFetch from "@/hooks/useAuthenticatedFetch.ts";
import {apiUrl} from "@/utils/api.ts";
import {createResourceSelector,} from "jsonapi-zod-query";
import {emailAddressSchema} from "@/components/Forms/schema/emailAddressSchema.ts";

const emailSelector = createResourceSelector({
    type: "emailAddress",
    attributesSchema: emailAddressSchema,
});

export type EmailAddress = ReturnType<typeof emailSelector>;

type Props = {
    personId: string | null;
    isQueryEnabled ?: boolean;
};

export const useFetchPrimaryEmail = (
    {
        personId,
        isQueryEnabled = true,
    }: Props): UseQueryResult<EmailAddress> => {
    const fetch = useAuthenticatedFetch();

    return useQuery({
        enabled: isQueryEnabled,
        queryKey: ["email", personId],
        queryFn: async ({ signal }) => {
            const url = apiUrl(`/person/email/primary/${personId}`);

            const response = await fetch(url, {
                signal,
                headers: {
                    accept: "application/vnd.api+json",
                },
            });

            return await response.json();
        },
        select: emailSelector,
    });
};
