import {useParams} from "react-router-dom";
import type {ReactNode} from "react";
import PartyRoleForm from "@/components/Forms/PartyRoleForm.tsx";

const PartyRoleDetail = (): ReactNode => {
    const { partyRoleUUID } = useParams<{ partyRoleUUID: string }>();

    if (!partyRoleUUID) {
        return <div>No PartyRole ID provided</div>;
    }

    return <PartyRoleForm partyRoleUUID={partyRoleUUID} />;
};

export default PartyRoleDetail;
