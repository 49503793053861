import type {UseFormReturn} from 'react-hook-form';
import {MenuItem} from "@mui/material";
import {RhfTextField} from "mui-rhf-integration";
import type {PersonRequestSchemaType} from "@/components/Forms/PersonForm.tsx";

export const namePrefixes = [
    { value: 'Mr.'},
    { value: 'Mrs.'},
    { value: 'Ms.'},
    { value: 'Dr.'},
    { value: 'Prof.'},
    { value: 'Rev.'},
    { value: 'Fr.' }
]


type Props = {
    form: UseFormReturn<PersonRequestSchemaType>;
}

const PersonNamePrefixSelect = ({form} : Props) => {
    return <RhfTextField
        select
        control={form.control}
        name={'namePrefix'}
        label={'Prefix'}
        sx={{
            width: '100%',
            backgroundColor: '#fff',
        }}
    >
        {namePrefixes?.map((role) => <MenuItem
            key={`role-${role.value}`}
            value={`${role.value}`}
        >
            {role.value}
        </MenuItem>)}
    </RhfTextField>
};

export default PersonNamePrefixSelect;
