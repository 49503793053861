import {createContext, type ReactNode, useContext, useEffect, useMemo, useState} from 'react';
import type {Employee} from "@/types/Employee.ts";
import {useFetchCurrentEmployee} from "@/queries/useFetchCurrentEmployee.ts";
import type {DecodedToken} from "@/utils/checkPermission.ts";
import {useAuth0} from "@auth0/auth0-react";
import {jwtDecode} from "jwt-decode";

type EmployeeContextType = {
    employee: Employee | null;
    isLoading: boolean;
    error: Error | null;
    permissions: string[];
    hasPermission: (permission: string) => boolean;
    hasAnyPermission: (permissionList: string[]) => boolean;
};

const EmployeeContext = createContext<EmployeeContextType | undefined>(undefined);

type Props = {
    children: ReactNode;
};

const EmployeeProvider = ({ children }: Props) => {
    const { getAccessTokenSilently, isAuthenticated } = useAuth0();
    const { data, isLoading, error } = useFetchCurrentEmployee();
    const [permissions, setPermissions] = useState<string[] | null>(null);

    const hasPermission = (permission: string) => {
        return permissions?.includes(permission) ?? false;
    }

    const hasAnyPermission = (permissionList: string[]) => {
        return permissionList.some((permission) => permissions?.includes(permission));
    }

    const value = useMemo(() => {
        return {
            employee: data?.data ?? null,
            isLoading,
            error,
            permissions: permissions ?? [],
            hasPermission,
            hasAnyPermission,
        }
    }, [isLoading, data, error, permissions, hasPermission, hasAnyPermission]);

    const doGetPermissions = async () => {
        if (!isAuthenticated) {
            return [];
        }

        try {
            // Get the access token silently
            const token = await getAccessTokenSilently();

            // Decode the token
            const decodedToken: DecodedToken = jwtDecode(token);

            // Assuming permissions are in the 'permissions' claim
            setPermissions(decodedToken.permissions || []);
        } catch (error) {
            return [];
        }
    }

    useEffect(() => {
        if (permissions === null) {
            void doGetPermissions()
        }
    }, [doGetPermissions, permissions]);

    return <EmployeeContext.Provider value={value}>
        {children}
    </EmployeeContext.Provider>
};

export const useEmployee = () => {
    const context = useContext(EmployeeContext);

    if (context === undefined) {
        throw new Error('useEmployee must be used within an EmployeeProvider');
    }

    return context;
};

export default EmployeeProvider;
