import {useMemo, useState} from 'react';
import {
    MaterialReactTable,
    type MRT_ColumnDef,
    type MRT_ColumnFiltersState,
    type MRT_PaginationState,
    type MRT_Row,
    type MRT_SortingState,
    useMaterialReactTable,
} from 'material-react-table';
import {type MrtEmployee, useFetchMrtEmployees} from "@/queries/useFetchMrtEmployees.ts";
import {IconButton} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import {enqueueSnackbar} from "notistack";
import {getErrorMessage} from "@/utils/api.ts";
import {useDeleteEmployee} from "@/mutations/useDeleteEmployee.tsx";
import {useEmployee} from "@/contexts/EmployeeProvider.tsx";

const MrtEmployeeTable = () => {
    const {hasPermission} = useEmployee()
    const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
        [],
    );
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState<MRT_SortingState>([]);
    const [pagination, setPagination] = useState<MRT_PaginationState>({
        pageIndex: 0,
        pageSize: 10,
    });

    const {
        data,
        isError,
        isRefetching,
        isLoading,
    } = useFetchMrtEmployees({
        columnFilters,
        globalFilter,
        pagination,
        sorting,
    });

    const handleRowClick = (row: MRT_Row<MrtEmployee>) => {
        if (hasPermission("edit:employee")) {
            window.location.href = `/employee-detail/${row.original.id}`;
        }
    };

    const deleteCompanionCase = useDeleteEmployee();

    const handleDeleteRow = (row: MRT_Row<MrtEmployee>) => {
        if (window.confirm('Are you sure you want to delete this employee?')) {
            deleteCompanionCase.mutate(
                {
                    employeeId: row.original.idUUID,
                },
                {
                    onSuccess: () => {
                        enqueueSnackbar("Employee case removed.", { variant: "success" });
                    },
                    onError: (error) => {
                        enqueueSnackbar("Error: Employee case could not be removed.", { variant: "error" });
                        console.info('error', getErrorMessage(error));
                    },
                },

            )
        }
    };

    const columns = useMemo<MRT_ColumnDef<MrtEmployee>[]>(
        () => {
            const columnDefs: MRT_ColumnDef<MrtEmployee>[] = [
                {
                    accessorKey: 'fullName',
                    header: 'Name',
                },
                {
                    accessorKey: 'eMailAddress',
                    header: 'Email',
                },
                {
                    accessorKey: 'dashboardVersion',
                    header: 'Dashboard Version',
                    enableGlobalFilter: false,
                },
                {
                    accessorKey: 'employeePhone',
                    header: 'Phone #',
                    enableGlobalFilter: false,
                },
                {
                    accessorKey: 'status',
                    header: 'Status',
                    enableGlobalFilter: false,
                },
            ];

            if (hasPermission("delete:employee")) {
                columnDefs.push({
                    accessorKey: 'delete',
                    header: 'Delete',
                    size: 50,
                    Cell: ({ row }) => (
                        <IconButton onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                            event.stopPropagation();
                            handleDeleteRow(row)
                        }}>
                            <DeleteIcon />
                        </IconButton>
                    ),
                });
            }

            return columnDefs;
        }, [hasPermission, handleDeleteRow]);

    const table = useMaterialReactTable({
        columns,
        data: (isLoading || isRefetching || isError || data === undefined) ? [] : data.data,

        initialState: {
            showColumnFilters: false,
            showGlobalFilter: true,
            density: 'compact',
        },

        // top toolbar
        enableTopToolbar: true,
        enableDensityToggle: false,
        enableFullScreenToggle: false,
        enableHiding: false,
        enableColumnFilters: false,

        // server side searching and pagination
        manualFiltering: true,
        manualPagination: true,
        manualSorting: true,
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        onPaginationChange: setPagination,
        onSortingChange: setSorting,

        // state
        rowCount: data?.meta.totalRowCount ?? 0,
        state: {
            columnFilters,
            globalFilter,
            isLoading,
            pagination,
            showAlertBanner: isError,
            showProgressBars: isRefetching,
            showLoadingOverlay: (isLoading || isRefetching),
            sorting,
        },

        // design
        muiToolbarAlertBannerProps: isError
            ? {
                color: 'error',
                children: 'Error loading data',
            }
            : undefined,
        muiTableBodyRowProps: ({ row }: { row: MRT_Row<MrtEmployee> }) => ({
            onClick: () => handleRowClick(row),
            sx: { cursor: "pointer" },
        }),
    });

    return <MaterialReactTable table={table} />;
};

export default MrtEmployeeTable;
