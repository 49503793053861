import {z} from "zod";
import {zj} from "zod-joda";

export const employeeSchema = z.object({
    idUUID: z.string(),
    idClient: z.string().nullable(),
    idrecID: z.number().nullable(),
    nameFirst: z.string().nullable(),
    nameLast: z.string().nullable(),
    username: z.string().nullable(),
    password: z.string().nullable(),
    eMailAddress: z.string().nullable(),
    phoneNumber: z.string().nullable(),
    mobileNumber: z.string().nullable(),
    faxNumber: z.string().nullable(),
    privilegeSet: z.string().nullable(),
    fullName: z.string().nullable(),
    status: z.string().nullable(),
    dashboardVersion: z.string().nullable(),
    externalAccess: z.string().nullable(),
    signature: z.string().nullable(),
    picture: z.string().nullable(),
    managerNotes: z.string().nullable(),
    metaCreatedBy: z.string().nullable(),
    metaCreatedByPretty: z.string().nullable(),
    metaCreatedWhen: z.coerce.date().nullable(),
    metaModifiedBy: z.string().nullable(),
    metaModifiedByPretty: z.string().nullable(),
    metaModifiedWhen: z.coerce.date().nullable(),
    signAttorney: z.string().nullable(),
    calbackgroundColor: z.string().nullable(),
    calborderColor: z.string().nullable(),
    caltextColor: z.string().nullable(),
    portletOrderPeople: z.string().nullable(),
    emailServer: z.string().nullable(),
    emailPort: z.string().nullable(),
    emailSSL: z.string().nullable(),
    emailUserName: z.string().nullable(),
    emailPassword: z.string().nullable(),
    emailDownload: z.string().nullable(),
    extNumber: z.string().nullable(),
    loggingUserName: z.string().nullable(),
    int3CX: z.string().nullable(),
    intEmail: z.string().nullable(),
    intLogging: z.string().nullable(),
    clockInOut: z.string().nullable(),
    clockAutoOut: z.string().nullable(),
    clockTS: zj.localDate().nullable(),
    clockText: z.string().nullable(),
    clockRecID: z.string().nullable(),
    passwordForceChange: z.string().nullable(),
    ownCases: z.string().nullable(),
    clientExtIP: z.string().nullable(),
    isDev: z.string().nullable(),
    idrecIDAdmin: z.number().nullable(),
    decodeTest: z.string().nullable(),
    sigTest: z.string().nullable(),
    employeePhone: z.string().nullable(),
    team: z.string().nullable(),
    teamUUID: z.string().nullable(),
    attorneyType: z.string().nullable(),
    isParalegal: z.string().nullable(),
});
