import {Grid2 as Grid, Paper, Typography} from '@mui/material';
import MediaDocumentsListForCase from "@/components/Documents/MediaDocumentsListForCase.tsx";
import MrtLoansTable from "@/components/Table/MrtLoansTable.tsx";
import AddEditLoanDialog from "@/components/Forms/AddEditLoanDialog.tsx";
import NoteList from "@/components/Documents/NoteList.tsx";

type Props = {
    caseIdUUID: string;
};

const LoanTab = ({caseIdUUID} : Props) => {
    return <Grid container sx={{pb: {xs: '200px', md: '100px'}}}>
        <Grid size={12}>
            <Paper
                sx={{
                    mb: 2,
                    mx: 1,
                }}
            >
                <Grid
                    container
                    sx={{
                        borderRadius: '8px',
                        p: 1,
                    }}
                >
                    <Grid
                        size={12}
                        sx={{
                            p: 1,
                            mb: 1,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}>
                        <Typography variant={'h5'}>Loans</Typography>
                        <AddEditLoanDialog caseUUID={caseIdUUID}/>
                    </Grid>
                    <Grid size={12} sx={{p: 1, mb: 1}}>
                        <MrtLoansTable caseIdUUID={caseIdUUID}/>
                    </Grid>
                    <Grid size={6} sx={{p: 1, mb: 1}}>
                        <NoteList caseIdUUID={caseIdUUID} limitToCategory={'Loan'}/>
                    </Grid>
                    <Grid size={6} sx={{p: 1, mb: 1}}>
                        <MediaDocumentsListForCase caseIdUUID={caseIdUUID} limitToCategory={'Loan'}/>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    </Grid>;
};

export default LoanTab;
