import {useParams} from 'react-router-dom';
import type {ReactNode} from "react";
import PersonForm from "@/components/Forms/PersonForm.tsx";

const PersonDetail = (): ReactNode => {
    const { personId } = useParams<{ personId: string }>();

    if (!personId) {
        return <div>No Person ID provided</div>;
    }

    return (
        <div>
            <PersonForm personId={personId}/>
        </div>
    );
};

export default PersonDetail;
