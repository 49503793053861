import useAuthenticatedFetch from "@/hooks/useAuthenticatedFetch.ts";
import {apiUrl} from "@/utils/api.ts";
import {useMutation, type UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {handleJsonApiError} from "jsonapi-zod-query";

export const useDeleteEmployee = (): UseMutationResult<void, Error, { employeeId: string }> => {
    const fetch = useAuthenticatedFetch();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async ({ employeeId }) => {
            const response = await fetch(apiUrl(`/employee/delete/${employeeId}`), {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/vnd.api+json",
                },
            });
            await handleJsonApiError(response);
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: ["employees-table-data"],
            });
        },
    });
};
