import {Button, Stack, Typography} from "@mui/material";
import {type ReactNode, useCallback} from "react";
import {Outlet} from "react-router-dom";
import MrtCompaniesTable from "@/components/Table/MrtCompaniesTable.tsx";
import {type NewCompany, useAddCompany} from "@/mutations/useAddCompany.tsx";
import {enqueueSnackbar} from "notistack";
import {getErrorMessage} from "@/utils/api.ts";

const PlacesPage = (): ReactNode => {
    const addCompanyMutation = useAddCompany();

    const doAddCompany = useCallback(
        () => {
            addCompanyMutation.mutate(
                null,
                {
                    onSuccess: (company: NewCompany) => {
                        enqueueSnackbar("Company added.", { variant: "success" });
                        window.location.href = `/place-detail/${company.idUUID}`;
                    },
                    onError: (error) => {
                        enqueueSnackbar("Error: Add Company failed.", { variant: "error" });
                        console.info('error', getErrorMessage(error));
                    },
                },
            );
        },
        [addCompanyMutation],
    );

    return (
        <>
            <Stack
                sx={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 2,
                }}
            >
                <Typography variant="h4" mb={2}>
                    Companies
                </Typography>
                <Button
                    variant={'contained'}
                    onClick={() => doAddCompany()}
                >
                    Add Company
                </Button>
            </Stack>

            <MrtCompaniesTable/>
            <Outlet />
        </>
    );
};

export default PlacesPage;
