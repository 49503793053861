import {keepPreviousData, useQuery, type UseQueryResult} from "@tanstack/react-query";
import type {MRT_ColumnFiltersState, MRT_PaginationState, MRT_SortingState,} from 'material-react-table';
import {createResourceCollectionSelector} from "jsonapi-zod-query";
import {apiUrl} from "@/utils/api.ts";
import useAuthenticatedFetch from "@/hooks/useAuthenticatedFetch.ts";
import {dtMetaSchema} from "@/components/Forms/schema/dtMetaSchema.ts";
import {mrtCompanySchema} from "@/components/Forms/schema/mrtCompanySchema.ts";

const resourceSelector = createResourceCollectionSelector({
    type: "company",
    attributesSchema: mrtCompanySchema,
    documentMetaSchema: dtMetaSchema,
});

export type CompanyDocument = ReturnType<typeof resourceSelector>;
export type MrtCompany = CompanyDocument['data'][number];

type Props = {
    isQueryEnabled ?: boolean;
    columnFilters : MRT_ColumnFiltersState;
    globalFilter : string;
    pagination : MRT_PaginationState;
    sorting : MRT_SortingState;
};

export const useFetchMrtCompanies = (
    {
        isQueryEnabled = true,
        columnFilters,
        globalFilter,
        pagination,
        sorting,
    } : Props
) : UseQueryResult<CompanyDocument> => {
    const fetch = useAuthenticatedFetch();

    return useQuery({
        queryKey: [
            'companies-table-data',
            columnFilters,
            globalFilter,
            pagination.pageIndex,
            pagination.pageSize,
            sorting,
        ],
        enabled: isQueryEnabled,
        queryFn: async () => {
            const requestBody = {
                start: pagination.pageIndex * pagination.pageSize,
                size: pagination.pageSize,
                filters: columnFilters ?? [],
                globalFilter: globalFilter ?? '',
                sorting: sorting ?? []
            };

            const response = await fetch(apiUrl('/company'), {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody)
            });

            return await response.json();
        },
        select: resourceSelector,
        placeholderData: keepPreviousData,
    });
};
