import type {UseFormReturn} from 'react-hook-form';
import {Grid2 as Grid, Paper, Stack, Typography} from "@mui/material";
import {RhfTextField} from "mui-rhf-integration";
import type {CaseFormValues} from "@/components/Forms/CaseForm.tsx";
import {RhfDatePicker} from "mui-rhf-integration/date-picker";
import MaskedSSN from "@/components/Forms/MaskedSSN.tsx";
import MaskedDLN from "@/components/Forms/MaskedDLN.tsx";

type Props = {
    form: UseFormReturn<CaseFormValues>;
}

const ClientDetails = ({form} : Props) => {
    const personUUID = form.watch('idPeople.idUUID')
    const personId = form.watch('idPeople.id').toString();

    return <Grid container>
        <Grid size={12}>
            <Paper
                sx={{
                    mb: 2,
                    mx: 1,
                }}
            >
                <Grid
                    container
                    sx={{
                        borderRadius: '8px',
                        p: 1,
                    }}
                >
                    <Grid size={12} sx={{p: 1, mb: 1}}>
                        <Stack
                            sx={{
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Typography variant={'h5'}>Client - Details</Typography>
                        </Stack>
                    </Grid>
                    <Grid size={{xs:12, md:2}} sx={{p: 1}}>
                        <RhfTextField
                            control={form.control}
                            name={'idPeople.namePrefix'}
                            label={'Prefix'}
                            sx={{
                                width: '100%',
                                backgroundColor: '#e9ecef',
                            }}
                            disabled
                        />
                    </Grid>
                    <Grid size={{xs:12, md:8}} sx={{p: 1}}>
                        <RhfTextField
                            control={form.control}
                            name={'idPeople.nameFirst'}
                            label={'First Name'}
                            sx={{
                                width: '100%',
                                backgroundColor: '#e9ecef',
                            }}
                            disabled
                        />
                    </Grid>
                    <Grid size={{xs:12, md:2}} sx={{p: 1}}>
                        <RhfTextField
                            control={form.control}
                            name={'idPeople.nameI'}
                            label={'MI'}
                            sx={{
                                width: '100%',
                                backgroundColor: '#e9ecef',
                            }}
                            disabled
                        />
                    </Grid>
                    <Grid size={{xs:12, md:5}} sx={{p: 1}}>
                        <RhfTextField
                            control={form.control}
                            name={'idPeople.nameLast'}
                            label={'Last Name'}
                            sx={{
                                width: '100%',
                                backgroundColor: '#e9ecef',
                            }}
                            disabled
                        />
                    </Grid>
                    <Grid size={{xs:12, md:5}} sx={{p: 1}}>
                        <RhfTextField
                            control={form.control}
                            name={'idPeople.nameAKA'}
                            label={'AKA/Preferred'}
                            sx={{
                                width: '100%',
                                backgroundColor: '#e9ecef',
                            }}
                            disabled
                        />
                    </Grid>
                    <Grid size={{xs:12, md:2}} sx={{p: 1}}>
                        <RhfTextField
                            control={form.control}
                            name={'idPeople.nameSuffix'}
                            label={'Suffix'}
                            sx={{
                                width: '100%',
                                backgroundColor: '#e9ecef',
                            }}
                            disabled
                        />
                    </Grid>
                    <Grid size={{xs:12, md:4}} sx={{p: 1}}>
                        <MaskedSSN fieldPath={'idPeople.ssnumber'} foundPersonIdUUID={personUUID} personId={personId}/>
                    </Grid>
                    <Grid size={{xs:12, md:4}} sx={{p: 1}}>
                        <RhfTextField
                            control={form.control}
                            name={'idPeople.race'}
                            label={'Race'}
                            sx={{
                                width: '100%',
                                backgroundColor: '#e9ecef',
                            }}
                            disabled
                        />
                    </Grid>
                    <Grid size={{xs:12, md:4}} sx={{p: 1}}>
                        <RhfTextField
                            control={form.control}
                            name={'idPeople.sex'}
                            label={'Sex'}
                            sx={{
                                width: '100%',
                                backgroundColor: '#e9ecef',
                            }}
                            disabled
                        />
                    </Grid>
                    <Grid size={{xs:12, md:4}} sx={{p: 1}}>
                        <RhfTextField
                            control={form.control}
                            name={'idPeople.taxIDNumber'}
                            label={'Tax ID'}
                            sx={{
                                width: '100%',
                                backgroundColor: '#e9ecef',
                            }}
                            disabled
                        />
                    </Grid>
                    <Grid size={{xs:12, md:4}} sx={{p: 1}}>
                        <MaskedDLN fieldPath={'idPeople.dlNumber'} foundPersonIdUUID={personUUID} personId={personId}/>
                    </Grid>
                    <Grid size={{xs:12, md:4}} sx={{p: 1}}>
                        <RhfTextField
                            control={form.control}
                            name={'idPeople.dlissuedBy'}
                            label={'DL Issued By'}
                            sx={{
                                width: '100%',
                                backgroundColor: '#e9ecef',
                            }}
                            disabled
                        />
                    </Grid>
                    <Grid size={{xs:12, md:6}} sx={{p: 1}}>
                        <RhfDatePicker
                            control={form.control}
                            name={'idPeople.dateofbirth'}
                            label={'Date Of Birth'}
                            views={['year', 'month', 'day']}
                            sx={{
                                width: '100%',
                                backgroundColor: '#e9ecef',
                            }}
                            disabled
                        />

                    </Grid>
                    <Grid size={{xs:12, md:6}} sx={{p: 1}}>
                        <RhfDatePicker
                            control={form.control}
                            name={'idPeople.dateofdeath'}
                            label={'Date Of Death'}
                            views={['year', 'month', 'day']}
                            sx={{
                                width: '100%',
                                backgroundColor: '#e9ecef',
                            }}
                            disabled
                        />
                    </Grid>
                    <Grid size={{xs:12, md:6}} sx={{p: 1}}>
                        <RhfTextField
                            control={form.control}
                            name={'idPeople.idReferredBy'}
                            label={'Referred By'}
                            sx={{
                                width: '100%',
                                backgroundColor: '#e9ecef',
                            }}
                            disabled
                        />
                    </Grid>
                    <Grid size={{xs:12, md:6}} sx={{p: 1}}>
                        <RhfTextField
                            control={form.control}
                            name={'idPeople.idSourceView'}
                            label={'Source'}
                            sx={{
                                width: '100%',
                                backgroundColor: '#e9ecef',
                            }}
                            disabled
                        />
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    </Grid>
};

export default ClientDetails;
