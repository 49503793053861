import {useEffect, useState} from 'react';
import {useFormContext} from 'react-hook-form';
import {RhfTextField} from 'mui-rhf-integration';
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    InputAdornment,
    TextField
} from '@mui/material';
import {Visibility} from '@mui/icons-material';
import {useFetchPersonUnMasked} from "@/queries/useFetchPersonUnMasked.ts";
import {enqueueSnackbar} from "notistack";
import {getErrorMessage} from "@/utils/api.ts";
import {useSavePersonDLN} from "@/mutations/useSavePersonDLN.tsx";

type Props = {
    personId: string;
    foundPersonIdUUID: string;
    fieldPath: string;
};

const MaskedDLN = ({personId, foundPersonIdUUID, fieldPath}: Props) => {
    const form = useFormContext();
    const [openDialog, setOpenDialog] = useState(false);
    const [dlNumber, setDlNumber] = useState<string | null>(null);
    const [editMode, setEditMode] = useState(false);
    const [confirmed, setConfirmed] = useState(false);
    const savePersonDLN = useSavePersonDLN();

    const {
        data,
        isLoading,
        refetch,
        error,
    } = useFetchPersonUnMasked({
        personUUID: foundPersonIdUUID,
        isQueryEnabled: confirmed
    });

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleViewDln = async () => {
        setConfirmed(true);
        setEditMode(false);
    };

    const handleEditDln = () => {
        setConfirmed(true);
        setEditMode(true);
    };

    const handleSaveDln = () => {
        if (foundPersonIdUUID) {
            savePersonDLN.mutate(
                {
                    personId: personId,
                    personUUID: foundPersonIdUUID,
                    dlNumber: dlNumber ?? '',
                },
                {
                    onSuccess: () => {
                        enqueueSnackbar("Person saved.", { variant: "success" });
                        form.reset(form.getValues());
                        handleCloseDialog();
                    },
                    onError: (error) => {
                        enqueueSnackbar("Error: Person failed to save.", { variant: "error" });
                        console.info('error', getErrorMessage(error));
                    },
                },
            );
        }

    };

    useEffect(() => {
        if (confirmed) {
            if (data?.data.dlNumber) {
                setDlNumber(data.data.dlNumber);
            } else {
                setDlNumber('')
            }
        }
    }, [data, confirmed]);

    useEffect(() => {
        if (!openDialog) {
            setEditMode(false);
            setConfirmed(false);
            setDlNumber(null);
        }
    }, [openDialog]);

    useEffect(() => {
        if (confirmed) {
            void refetch();
        }
    }, [confirmed]);

    return (
        <>
            <RhfTextField
                control={form.control}
                name={fieldPath}
                label={'DL #'}
                sx={{
                    width: '100%',
                    backgroundColor: '#e9ecef',
                }}
                disabled
                slotProps={{
                    input:  {
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={handleOpenDialog} edge="end">
                                    <Visibility />
                                </IconButton>
                            </InputAdornment>
                        ),
                    },
                }}
            />

            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle>DLN Options</DialogTitle>
                <DialogContent>
                    {isLoading && <CircularProgress/>}
                    {editMode && dlNumber !== null && <TextField
                        label="Edit DLN"
                        value={dlNumber || ''}
                        onChange={(e) => setDlNumber(e.target.value)}
                        fullWidth
                    />}
                    {!editMode && dlNumber !== null && <div>DLN: {dlNumber ?? 'Field is empty.'}</div>}

                    {error && <div>Error fetching DLN: {error.message}</div>}
                </DialogContent>
                <DialogActions>
                    {!editMode && dlNumber === null && <Button onClick={handleViewDln} color="primary">
                        View DLN
                    </Button>}
                    {!editMode && dlNumber === null && <Button onClick={handleEditDln} color="primary">
                        Edit DLN
                    </Button>}
                    <Button onClick={handleCloseDialog} color="secondary">
                        Cancel
                    </Button>
                    {editMode && dlNumber &&  <Button onClick={handleSaveDln} color="primary">
                        Save
                    </Button>}
                </DialogActions>
            </Dialog>
        </>
    );
};

export default MaskedDLN;
