import type {UseFormReturn} from 'react-hook-form';
import {MenuItem} from "@mui/material";
import {RhfTextField} from "mui-rhf-integration";
import type {PersonRequestSchemaType} from "@/components/Forms/PersonForm.tsx";

export const sexOptions = [
    { value: 'Male'},
    { value: 'Female'},
]


type Props = {
    form: UseFormReturn<PersonRequestSchemaType>;
}

const PersonSexSelect = ({form} : Props) => {
    return <RhfTextField
        select
        control={form.control}
        name={'sex'}
        label={'Sex'}
        sx={{
            width: '100%',
            backgroundColor: '#fff',
        }}
    >
        {sexOptions?.map((role) => <MenuItem
            key={`role-${role.value}`}
            value={`${role.value}`}
        >
            {role.value}
        </MenuItem>)}
    </RhfTextField>
};

export default PersonSexSelect;
