import {Grid2 as Grid, Paper, Typography} from "@mui/material";
import MrtCompanyEmailsTable from "@/components/Table/MrtCompanyEmailsTable.tsx";
import MrtCompanyPhoneNumbersTable from "@/components/Table/MrtCompanyPhoneNumbersTable.tsx";
import AddEditCompanyPhoneNumberDialog from "@/components/Forms/SubForms/AddEditCompanyPhoneNumberDialog.tsx";
import AddEditCompanyEmailDialog from "@/components/Forms/SubForms/AddCompanyEmailDialog.tsx";

type Props = {
    companyUUID: string;
}
const CompanyContactInfo = ({companyUUID}: Props) => {
    return <Grid container>
        <Grid size={12}>
            <Paper
                sx={{
                    mb: 2,
                    mx: 1,
                }}
            >
                <Grid
                    container
                    sx={{
                        borderRadius: '8px',
                        p: 1,
                    }}
                >
                    <Grid size={12} sx={{p: 1, mb: 1}}>
                        <Typography variant={'h5'}>Contact Info</Typography>
                    </Grid>
                    <Grid
                        size={12}
                        sx={{
                            p: 1,
                            mb: 1,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Typography variant={'h6'}>Phone Numbers</Typography>
                        <AddEditCompanyPhoneNumberDialog companyUUID={companyUUID}/>
                    </Grid>
                    <Grid
                        size={12}
                        sx={{
                            mb: 2,
                        }}
                    >
                        <MrtCompanyPhoneNumbersTable companyUUID={companyUUID}/>
                    </Grid>
                    <Grid
                        size={12}
                        sx={{
                            p: 1,
                            mb: 1,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Typography variant={'h6'}>Email</Typography>
                        <AddEditCompanyEmailDialog companyUUID={companyUUID}/>
                    </Grid>
                    <Grid size={12}>
                        <MrtCompanyEmailsTable companyUUID={companyUUID}/>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    </Grid>
};

export default CompanyContactInfo;
