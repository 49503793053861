import {FormProvider, type SubmitHandler, useForm} from 'react-hook-form';
import {
    Box,
    Button,
    CircularProgress,
    FormControlLabel,
    Grid2 as Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import {enqueueSnackbar} from "notistack";
import {zodResolver} from "@hookform/resolvers/zod";
import {type BaseSyntheticEvent, useCallback, useEffect, useMemo} from "react";
import {getErrorMessage} from "@/utils/api.ts";
import {useFetchCase} from "@/queries/useFetchCase.ts";
import {RhfCheckbox} from "mui-rhf-integration";
import {saveNegotiationSchema} from "@/components/Forms/schema/saveNegotiationSchema.ts";
import {z} from "zod";
import {useSaveCaseNegotiationMutation} from "@/mutations/useSaveCaseNegotiationMutation.tsx";
import useNegotiationSpecials from "@/components/Forms/hook/useNegotiationSpecials.tsx";
import useNegotiationLoansTotal from "@/components/Forms/hook/useNegotiationLoansTotal.tsx";
import {useEmployee} from "@/contexts/EmployeeProvider.tsx";
import NegotiationCell from "@/components/Forms/NegotiationCell.tsx";
import {localDateToDayjs} from "@/utils/datetime.ts";
import {currencyFormatter} from "@/utils/currencyFormatter.ts";
import RhfCurrencyInput from "@/components/RhfCurrencyInput/RhfCurrencyInput.tsx";

const massageLocalDateToDayjsDates = (
    data: Record<string, any>,
) => {
    return {
        ...data.data,
        dateDemand1: data.data.dateDemand1 ? localDateToDayjs(data.data.dateDemand1) : null,
        dateDemand2: data.data.dateDemand2 ? localDateToDayjs(data.data.dateDemand2) : null,
        dateDemand3: data.data.dateDemand3 ? localDateToDayjs(data.data.dateDemand3) : null,
        dateDemand4: data.data.dateDemand4 ? localDateToDayjs(data.data.dateDemand4) : null,
        dateDemand5: data.data.dateDemand5 ? localDateToDayjs(data.data.dateDemand5) : null,
        dateCounter1: data.data.dateCounter1 ? localDateToDayjs(data.data.dateCounter1) : null,
        dateCounter2: data.data.dateCounter2 ? localDateToDayjs(data.data.dateCounter2) : null,
        dateCounter3: data.data.dateCounter3 ? localDateToDayjs(data.data.dateCounter3) : null,
        dateCounter4: data.data.dateCounter4 ? localDateToDayjs(data.data.dateCounter4) : null,
        dateCounter5: data.data.dateCounter5 ? localDateToDayjs(data.data.dateCounter5) : null,
    }
}

type Props = {
    caseUUID: string;
    foundCaseId: string;
}

export type NegotiationFormValues = z.infer<typeof saveNegotiationSchema>;

const NegotiationForm = ({caseUUID, foundCaseId} : Props) => {
    const {employee} = useEmployee()
    const saveCaseMutation = useSaveCaseNegotiationMutation(caseUUID);
    const specialsAmt = useNegotiationSpecials(caseUUID);
    const loansTotal = useNegotiationLoansTotal(caseUUID);

    const {
        data,
        isError,
        isRefetching,
        isLoading,
        isPending,
        error,
    } = useFetchCase(foundCaseId);

    const form = useForm<NegotiationFormValues>({
        resolver: zodResolver(saveNegotiationSchema),
    });

    const caseExpenses = form.watch('caseExpenses');
    const checkReceived = form.watch('checkReceived');
    const checkReceivedTimestamp = form.watch('checkReceivedTimestamp');

    const releaseReceived = form.watch('releaseReceived');
    const releaseReceivedTimestamp = form.watch('releaseReceivedTimestamp');

    const medpayCheckReceived = form.watch('medpayCheckReceived');
    const medpayCheckReceivedTimestamp = form.watch('medpayCheckReceivedTimestamp');

    const umCheckReceived = form.watch('umCheckReceived');
    const umCheckReceivedTimestamp = form.watch('umCheckReceivedTimestamp');

    const umReleaseReceived = form.watch('umReleaseReceived');
    const umReleaseReceivedTimestamp = form.watch('umReleaseReceivedTimestamp');

    const demand1 = form.watch('demand1');
    const demand2 = form.watch('demand2');
    const demand3 = form.watch('demand3');
    const demand4 = form.watch('demand4');
    const demand5 = form.watch('demand5');

    const counter1 = form.watch('counter1');
    const counter2 = form.watch('counter2');
    const counter3 = form.watch('counter3');
    const counter4 = form.watch('counter4');
    const counter5 = form.watch('counter5');
    const dateCounter1 = form.watch('dateCounter1');
    const dateCounter2 = form.watch('dateCounter2');
    const dateCounter3 = form.watch('dateCounter3');
    const dateCounter4 = form.watch('dateCounter4');

    const showNegotiationsRow2 = !!counter1 || !!dateCounter1;
    const showNegotiationsRow3 = !!counter2 || !!dateCounter2;
    const showNegotiationsRow4 = !!counter3 || !!dateCounter3;
    const showNegotiationsRow5 = !!counter4 || !!dateCounter4;

    const latestDemand : number | undefined = useMemo(() => {
        if (demand5) {
            return Number.parseFloat(demand5.replace(/,/g, ""));
        }

        if (demand4) {
            return Number.parseFloat(demand4.replace(/,/g, ""));
        }

        if (demand3) {
            return Number.parseFloat(demand3.replace(/,/g, ""));
        }

        if (demand2) {
            return Number.parseFloat(demand2.replace(/,/g, ""));
        }

        if (demand1) {
            return Number.parseFloat(demand1.replace(/,/g, ""));
        }

        return undefined
    }, [data?.data, demand1, demand2, demand3, demand4, demand5])

    const latestOffer : number | undefined = useMemo(() => {
        if (counter5) {
            return Number.parseFloat(counter5.replace(/,/g, ""));
        }

        if (counter4) {
            return Number.parseFloat(counter4.replace(/,/g, ""));
        }

        if (counter3) {
            return Number.parseFloat(counter3.replace(/,/g, ""));
        }

        if (counter2) {
            return Number.parseFloat(counter2.replace(/,/g, ""));
        }

        if (counter1) {
            return Number.parseFloat(counter1.replace(/,/g, ""));
        }

        return undefined
    }, [data?.data, counter1, counter2, counter3, counter4, counter5])

    const latestAttorneyFee : number | undefined = useMemo(() => {
        if (data?.data.attorneyFee && latestOffer) {
            return (Number.parseFloat(data.data.attorneyFee) / 100) * latestOffer;
        }

        return undefined
    }, [data?.data.attorneyFee, latestOffer])

    const clientProceeds = (latestOffer ?? 0) - (latestAttorneyFee ?? 0) - (specialsAmt ?? 0) - (loansTotal ?? 0)
        - (caseExpenses ? Number.parseFloat(caseExpenses) : 0);

    const handleSubmit = useCallback(
        (values: NegotiationFormValues) => {
            if (!caseUUID) {
                return;
            }
            saveCaseMutation.mutate(
                {
                    ...values
                },
                {
                    onSuccess: () => {
                        enqueueSnackbar("Changes were successfully saved.", { variant: "success" });
                        form.reset(form.getValues());
                    },
                    onError: (error) => {
                        enqueueSnackbar("Error: save failed.", { variant: "error" });
                        console.info('error', getErrorMessage(error));
                    },
                },
            );
        },
        [saveCaseMutation, caseUUID],
    );

    const onSubmit: SubmitHandler<NegotiationFormValues> = async (
        data: NegotiationFormValues,
        event?: BaseSyntheticEvent
    ) => {
        handleSubmit(data);
        event?.stopPropagation();
    }

    useEffect(() => {
        if (!isError && !isRefetching && !isLoading && data) {
            form.reset(massageLocalDateToDayjsDates(data));
        }
    }, [data, isError, isRefetching, isLoading]);

    useEffect(() => {
        if (checkReceived && !checkReceivedTimestamp) {
            form.setValue('checkReceivedTimestamp', `${(new Date()).toLocaleString()} - ${employee?.fullName}`)
        }
    }, [checkReceived]);

    useEffect(() => {
        if (releaseReceived && !releaseReceivedTimestamp) {
            form.setValue('releaseReceivedTimestamp', `${(new Date()).toLocaleString()} - ${employee?.fullName}`)
        }
    }, [releaseReceived]);

    useEffect(() => {
        if (medpayCheckReceived && !medpayCheckReceivedTimestamp) {
            form.setValue('medpayCheckReceivedTimestamp', `${(new Date()).toLocaleString()} - ${employee?.fullName}`)
        }
    }, [medpayCheckReceived]);

    useEffect(() => {
        if (umCheckReceived && !umCheckReceivedTimestamp) {
            form.setValue('umCheckReceivedTimestamp', `${(new Date()).toLocaleString()} - ${employee?.fullName}`)
        }
    }, [umCheckReceived]);

    useEffect(() => {
        if (umReleaseReceived && !umReleaseReceivedTimestamp) {
            form.setValue('umReleaseReceivedTimestamp', `${(new Date()).toLocaleString()} - ${employee?.fullName}`)
        }
    }, [umReleaseReceived]);

    if (isPending) {
        return null;
    }

    if (isError) {
        return (
            <div>{error.message}</div>
        );
    }

    if (isLoading) {
        return (
            <CircularProgress/>
        );
    }

    if (!(foundCaseId && caseUUID)) {
        return (
            <div>Case not found</div>
        );
    }

    return <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
            <Grid container>
                <Grid
                    size={6}
                    sx={{
                        px: 2,
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <FormControlLabel
                        control={<RhfCheckbox control={form.control} name="checkReceived"/>}
                        label="Liability Check Received"
                    />
                    <Box>{checkReceivedTimestamp}</Box>
                    <FormControlLabel
                        control={<RhfCheckbox control={form.control} name="releaseReceived"/>}
                        label="Release Received"
                    />
                    <Box>{releaseReceivedTimestamp}</Box>
                    <FormControlLabel
                        control={<RhfCheckbox control={form.control} name="medpayCheckReceived"/>}
                        label="Medpay Check Received"
                    />
                    <Box>{medpayCheckReceivedTimestamp}</Box>
                </Grid>
                <Grid
                    size={6}
                    sx={{
                        px: 2,
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <FormControlLabel
                        control={<RhfCheckbox control={form.control} name="umCheckReceived"/>}
                        label="UM/UIM Check Received"
                    />
                    <Box>{umCheckReceivedTimestamp}</Box>
                    <FormControlLabel
                        control={<RhfCheckbox control={form.control} name="umReleaseReceived"/>}
                        label="Release Received"
                    />
                    <Box>{umReleaseReceivedTimestamp}</Box>
                </Grid>
                <Grid size={12}>
                    {form.formState.isDirty && <Button
                        variant='contained'
                        type={'submit'}
                        sx={{
                            p: 1,
                            borderRadius: '8px',
                            fontWeight: 800,
                            m: 2,
                        }}
                    >
                        Save
                    </Button>}
                </Grid>
                <Grid size={12}>
                    <Paper
                        sx={{
                            backgroundColor: '#555555',
                        }}
                    >
                        <Grid container spacing={2}>
                            <Grid size={12} sx={{pt: 2, px: 2}}>
                                <TableContainer component={Paper}>
                                    <Table
                                        className="negotiationTable"
                                        sx={{
                                            backgroundColor: 'grey.900',
                                            '& .MuiTableCell-root': {
                                                color: 'white',
                                                borderColor: 'rgba(255, 255, 255, 0.12)',
                                            }
                                        }}
                                    >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{whiteSpace: 'nowrap', textAlign: 'center'}}>Latest Demand</TableCell>
                                                <TableCell sx={{whiteSpace: 'nowrap', textAlign: 'center'}}>Latest Offer</TableCell>
                                                <TableCell sx={{whiteSpace: 'nowrap', textAlign: 'center'}}>Attorney Fee</TableCell>
                                                <TableCell sx={{whiteSpace: 'nowrap', textAlign: 'center'}}>Expenses</TableCell>
                                                <TableCell sx={{whiteSpace: 'nowrap', textAlign: 'center'}}>Specials</TableCell>
                                                <TableCell sx={{whiteSpace: 'nowrap', textAlign: 'center'}}>Loans</TableCell>
                                                <TableCell sx={{whiteSpace: 'nowrap', textAlign: 'center'}}>Client Proceeds</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow sx={{ '&:nth-of-type(odd)': { backgroundColor: 'grey.800' } }}>
                                                <TableCell sx={{verticalAlign: 'top', textAlign: 'center', pt: 4}}>{latestDemand ? currencyFormatter.format(latestDemand) : 'N/A'}</TableCell>
                                                <TableCell sx={{verticalAlign: 'top', textAlign: 'center', pt: 4}}>{latestOffer ? currencyFormatter.format(latestOffer) : 'N/A'}</TableCell>
                                                <TableCell sx={{verticalAlign: 'top', textAlign: 'center', pt: 4}}>
                                                    <Box>{latestAttorneyFee ? currencyFormatter.format(latestAttorneyFee) : 'N/A'}</Box>
                                                    <Box sx={{color: 'green', fontSize: '12px'}}>{data.data.attorneyFee}%</Box>
                                                </TableCell>
                                                <TableCell sx={{backgroundColor: '#fff'}}>
                                                    <RhfCurrencyInput
                                                        name={'caseExpenses'}
                                                        label={'Expenses'}
                                                        sx={{
                                                            maxWidth: '200px',
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell sx={{verticalAlign: 'top', textAlign: 'center', pt: 4}}>{specialsAmt ? currencyFormatter.format(specialsAmt) : 'N/A'}</TableCell>
                                                <TableCell sx={{verticalAlign: 'top', textAlign: 'center', pt: 4}}>{loansTotal ? currencyFormatter.format(loansTotal) : 'N/A'}</TableCell>
                                                <TableCell sx={{verticalAlign: 'top', textAlign: 'center', pt: 4}}>{currencyFormatter.format(clientProceeds)}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                            <Grid size={12} sx={{pb: 2, px: 2}}>
                                <Paper
                                    sx={{
                                        backgroundColor: '#dddddd',
                                        py: 2,
                                    }}
                                >
                                    <Grid container spacing={2}>
                                        <Grid size={6}>
                                            <NegotiationCell
                                                form={form}
                                                field={'demand1'}
                                                dateField={'dateDemand1'}
                                                title={'Demand'}
                                            />
                                        </Grid>
                                        <Grid size={6}>
                                            <NegotiationCell
                                                form={form}
                                                field={'counter1'}
                                                dateField={'dateCounter1'}
                                                title={'First Offer'}
                                            />
                                        </Grid>
                                        {showNegotiationsRow2 && <Grid size={6}>
                                            <NegotiationCell
                                                form={form}
                                                field={'demand2'}
                                                dateField={'dateDemand2'}
                                                title={'Counter Demand'}
                                            />
                                        </Grid>}
                                        {showNegotiationsRow2 && <Grid size={6}>
                                            <NegotiationCell
                                                form={form}
                                                field={'counter2'}
                                                dateField={'dateCounter2'}
                                                title={'Second Offer'}
                                            />
                                        </Grid>}
                                        {showNegotiationsRow3 && <Grid size={6}>
                                            <NegotiationCell
                                                form={form}
                                                field={'demand3'}
                                                dateField={'dateDemand3'}
                                                title={'Counter Demand'}
                                            />
                                        </Grid>}
                                        {showNegotiationsRow3 && <Grid size={6}>
                                            <NegotiationCell
                                                form={form}
                                                field={'counter3'}
                                                dateField={'dateCounter3'}
                                                title={'Third Offer'}
                                            />
                                        </Grid>}
                                        {showNegotiationsRow4 && <Grid size={6}>
                                            <NegotiationCell
                                                form={form}
                                                field={'demand4'}
                                                dateField={'dateDemand4'}
                                                title={'Counter Demand'}
                                            />
                                        </Grid>}
                                        {showNegotiationsRow4 && <Grid size={6}>
                                            <NegotiationCell
                                                form={form}
                                                field={'counter4'}
                                                dateField={'dateCounter4'}
                                                title={'Fourth Offer'}
                                            />
                                        </Grid>}
                                        {showNegotiationsRow5 && <Grid size={6}>
                                            <NegotiationCell
                                                form={form}
                                                field={'demand5'}
                                                dateField={'dateDemand5'}
                                                title={'Counter Demand'}
                                            />
                                        </Grid>}
                                        {showNegotiationsRow5 && <Grid size={6}>
                                            <NegotiationCell
                                                form={form}
                                                field={'counter5'}
                                                dateField={'dateCounter5'}
                                                title={'Fifth Offer'}
                                            />
                                        </Grid>}
                                    </Grid>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </form>
    </FormProvider>
};

export default NegotiationForm;
