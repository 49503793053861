import React from 'react';
import {Alert, Box, Button, Card, Stack, TextField} from "@mui/material";
import Tab from "@mui/material/Tab";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import type {CaseData} from "@/queries/useFetchClientLogin.ts";
import ProgressBar from "@/ClientApplication/ProgressBar.tsx";
import useSendEmail from "@/hooks/useSendEmail.ts";
import {useSnackbar} from "notistack";

const buildDashboardProgress = (caseStatus: string) => {
    let statusDescription = "";
    let stepNewClient = false;
    let stepTreating = false;
    let stepDoneTreating = false;
    let stepDemandReady = false;
    let stepDemandComplete = false;
    let stepNegotiation = false;
    let stepHoldHarmless = false;
    let stepChecksPrinted = false;
    let stepDisbursed = false;
    let stepSettled = false;
    const pendingInformation = false;

    if (caseStatus === "New Client") {
        statusDescription = `Processing/verifying client's information. Claim set up.`;
        stepNewClient = true;
    }

    if (caseStatus === "Treating") {
        statusDescription = "Treatment is ongoing.";
        stepNewClient = true;
        stepTreating = true;
    }
    if (caseStatus === "Done Treating") {
        statusDescription = "Gathering bills & records from providers.";
        stepNewClient = true;
        stepTreating = true;
        stepDoneTreating = true;
    }
    if (caseStatus === "Demand Ready") {
        statusDescription = "Drafting demand. Managing partner review & approval.";
        stepNewClient = true;
        stepTreating = true;
        stepDoneTreating = true;
        stepDemandReady = true;
    }
    if (caseStatus === "Demand Complete") {
        statusDescription = "Demand sent to liability (30 days) or to UIM (60 days) waiting on response.";
        stepNewClient = true;
        stepTreating = true;
        stepDoneTreating = true;
        stepDemandReady = true;
        stepDemandComplete = true;
    }
    if (caseStatus === "Negotiation") {
        statusDescription = `\nAt this point we have received your first offer for settlement from the at-fault party’s insurance company. \n \n

Now is the time that we will contact you to begin negotiations between you and the insurance company with the goal of reaching the best and final top offer.

The Attorney assigned to your case will advise you on the strategy and possibility of reaching the top offer.

Keep in mind that there are limitations of the at-fault party’s insurance policy, as well as the type and extent of your injuries for reaching the top offer. However, rest assured that your Attorney will fight for you to get the most money you deserve!
`;
        stepNewClient = true;
        stepTreating = true;
        stepDoneTreating = true;
        stepDemandReady = true;
        stepDemandComplete = true;
        stepNegotiation = true;
    }

    // this was commented out in the old system
    // if(caseStatus !== "New Client" || caseStatus !== "Treating" || caseStatus !== "Done Treating" || caseStatus !== "Demand Ready" || caseStatus !== "Demand Complete" || caseStatus !== "Negotiation" || caseStatus !== "Hold Harmless" || caseStatus !== "Checks Printed" || caseStatus !== "Disbursed" || caseStatus !== "Settled"){
    //   pendingInformation = true
    // }

    if (caseStatus === "Hold Harmless") {
        statusDescription = "Hold Harmless some text here";
        stepNewClient = true;
        stepTreating = true;
        stepDoneTreating = true;
        stepDemandReady = true;
        stepDemandComplete = true;
        stepNegotiation = true;
        stepHoldHarmless = true;
    }

    if (caseStatus === "Checks Printed") {
        statusDescription = "Checks Printed some text here";
        stepNewClient = true;
        stepTreating = true;
        stepDoneTreating = true;
        stepDemandReady = true;
        stepDemandComplete = true;
        stepNegotiation = true;
        stepHoldHarmless = true;
        stepChecksPrinted = true
    }

    if (caseStatus === "Disbursed") {
        statusDescription = "Disbursed";
        stepNewClient = true;
        stepTreating = true;
        stepDoneTreating = true;
        stepDemandReady = true;
        stepDemandComplete = true;
        stepNegotiation = true;
        stepHoldHarmless = true;
        stepChecksPrinted = true
        stepDisbursed = true
    }

    if (caseStatus === "Settled") {
        statusDescription = "Settled";
        stepNewClient = true;
        stepTreating = true;
        stepDoneTreating = true;
        stepDemandReady = true;
        stepDemandComplete = true;
        stepNegotiation = true;
        stepHoldHarmless = true;
        stepChecksPrinted = true
        stepDisbursed = true
        stepSettled = true
    }

    return {
        statusDescription,
        stepNewClient,
        stepTreating,
        stepDoneTreating,
        stepDemandReady,
        stepDemandComplete,
        stepNegotiation,
        stepHoldHarmless,
        stepChecksPrinted,
        stepDisbursed,
        stepSettled,
        pendingInformation
    };
}

type Props = {
    caseData: CaseData;
}
const Dashboard = ({caseData} : Props ) => {
    const {enqueueSnackbar} = useSnackbar();
    const sendEmail = useSendEmail();
    const [selectedTab, setSelectedTab] = React.useState('1');
    const [clientNote, setClientNote] = React.useState('');
    const [showError, setShowError] = React.useState(false);

    const handleChange = (_event: React.SyntheticEvent, newValue: string) => {
        setSelectedTab(newValue);
    };

    const handleSendEmail = async () => {
        if (!clientNote) {
            setShowError(true);
            return;
        }

        const response = await sendEmail({
            caseUUID: caseData.idUUID,
            clientName: caseData.clientName ?? '',
            caseManagerEmail: caseData.cMEmailAddress ?? undefined,
            attorneyEmail: caseData.attorneyEmailAddress ?? undefined,
            caseNumber: caseData.id,
            caseStatus: caseData.caseStatus ?? '',
            clientNote: clientNote,
            // recordID: '12345', //caseData.recordID,
        })

        if (response?.status === 200) {
            enqueueSnackbar('Email sent successfully', { variant: 'success' });
            setClientNote('');
        } else {
            enqueueSnackbar('Error sending email', { variant: 'error' });
        }
    }

    const attorneyName = caseData.assignedToAttorneyPretty ?? "Contact Us";
    const caseManagerName = caseData.assignedToParalegalPretty ?? "Contact Us";
    const attorneyPhoneNumber = caseData.attorneyPhoneNumber ?? "(770) 502-6116";
    const cMPhoneNumber = caseData.cMPhoneNumber ?? "(770) 502-6116";

    const {
        // statusDescription,
        stepNewClient,
        stepTreating,
        stepDoneTreating,
        stepDemandReady,
        stepDemandComplete,
        stepNegotiation,
        stepHoldHarmless,
        stepChecksPrinted,
        stepDisbursed,
        stepSettled,
        // pendingInformation
    } = buildDashboardProgress(caseData.caseStatus ?? '');

    return <Box>
        <Box>
            <a href="https://www.glenncambre.com" target="_blank" rel="noreferrer">
                <img id="logoLink2" src="../assets/logo.png" alt="" />
            </a>
        </Box>
        <Box sx={{width: '100%'}}>
            <TabContext value={selectedTab}>
                <Stack
                    sx={{
                        borderBottom: 1,
                        borderColor: 'divider',
                        flexDirection: 'row',
                        justifyContent: 'center',
                    }}
                >
                    <TabList onChange={handleChange} aria-label="Case Tab List">
                        <Tab label="Case Progress" value="1"/>
                        <Tab label="Request Contact" value="2"/>
                    </TabList>
                </Stack>
                <TabPanel value="1">
                    <ProgressBar
                        stepNewClient={stepNewClient}
                        stepTreating={stepTreating}
                        stepDoneTreating={stepDoneTreating}
                        stepDemandReady={stepDemandReady}
                        stepDemandComplete={stepDemandComplete}
                        stepNegotiation={stepNegotiation}
                        stepHoldHarmless={stepHoldHarmless}
                        stepChecksPrinted={stepChecksPrinted}
                        stepDisbursed={stepDisbursed}
                        stepSettled={stepSettled}
                    />
                    {caseData.caseStatus === 'New Client' && <p className="statusDescription">
                        Thank you for choosing Cambre &amp; Associates to
                        represent you in your case against the at-fault party and
                        their insurance company. <br/><br/>
                        We are currently processing your information and verifying
                        we have all the correct details to process your claim.
                        <br/><br/>Once this is completed, we will file your
                        claim with the at-fault party's insurance company and
                        contact them to request that they accept liability.
                    </p>}
                    {caseData.caseStatus === 'Treating' && <p className="statusDescription">
                        Your claim has been filed and you should continue to
                        attend all your medical appointments. It is vital to your
                        case that you follow through with your doctors'
                        recommendations for treatment until they conclude you can
                        be released back to good health.
                        <br/><br/>

                        If you have any questions regarding your medical
                        appointments or need to make a change, please "Request
                        Contact" above, so that we can assist you.<br/><br/>

                        It is important that you do not have any unnecessary time
                        gaps in treatment of your injuries.
                    </p>}
                    {caseData.caseStatus === 'Done Treating' && <p className="statusDescription">
                        Now that you have completed your medical treatments, we
                        will gather all the bills and records from your medical
                        providers to include in your demand for payment of your
                        claim. We must include all the bills and records that are
                        applicable to your case, such as the following... <br/><br/>
                        <ul>
                            <li>Ambulance</li>
                            <li>Hospital</li>
                            <li>Chiropractor</li>
                            <li>Surgeon</li>
                            <li>Orthopedics</li>
                            <li>Physical Therapy</li>
                        </ul>
                        <br/><br/>
                        Sometimes the delivery of bills and records are delayed
                        from the medical providers, so patience is necessary.
                        However, you can also request the bills and records
                        yourself, which may speed up the process.<br/><br/>
                        In some cases, you may also have lost wages that can be included, please
                        "Request Contact" above if
                        you require the form to apply.
                    </p>}
                    {caseData.caseStatus === 'Demand Ready' && <p className="statusDescription">
                        Now that we have received all the bills and records from your medical
                        providers that apply to your
                        case, we will draft the legal demand for payment of your claim. <br/><br/>

                        The draft will then be reviewed by the Managing Partner Attorney for final
                        edits and approval.

                    </p>}
                    {caseData.caseStatus === 'Demand Complete' && <p className="statusDescription">
                        Your demand for payment has been completed and sent by certified mail to the
                        at-fault party's
                        insurance adjuster. <br/><br/>

                        The at-fault party's insurance company then has up to thirty (30) days to
                        respond to a claim of
                        liability.<br/><br/>

                        If you approved to file the claim against your own Uninsured Motorist (UM)
                        or Under Insured
                        Motorist (UIM) insurance coverage, your insurance company has up to sixty
                        (60) days to
                        respond.<br/><br/>

                        This is the time when most clients get antsy because there is nothing more
                        to do but wait. Please
                        be patient as we will contact you as soon as we hear something. The
                        insurance companies are
                        required to respond in the timeframe lest they be held in bad faith by the
                        courts.
                    </p>}
                    {caseData.caseStatus === 'Negotiation' && <p className="statusDescription">
                        At this point we have received your first offer for settlement from the
                        at-fault party's insurance
                        company.<br/><br/>

                        Now is the time that we will contact you to begin negotiations between you
                        and the insurance
                        company with the goal of reaching the best and final top offer.<br/><br/>

                        The Attorney assigned to your case will advise you on the strategy and
                        possibility of reaching the
                        top offer.<br/><br/>

                        Keep in mind that there are limitations of the at-fault party's insurance
                        policy, as well as the
                        type and extent of your injuries for reaching the top offer. However, rest
                        assured that your
                        Attorney will fight for you to get the most money you deserve!
                    </p>}
                    {caseData.caseStatus === 'Hold Harmless' && <p className="statusDescription">
                        This is the stage when you have agreed to accept the top offer from the
                        at-fault party's insurance
                        company and a Hold Harmless Agreement is sent to the adjuster.

                        <br/><br/>

                        Before the settlement can be funded by the at-fault party's insurance
                        company, a Hold Harmless
                        Agreement is used as a release of liability and to forever discharge all
                        claims of the aforesaid
                        incident, including all claims for bodily injury, personal injury, medical
                        expenses, pain and
                        suffering, lost wages, punitive damages, attorneys' fees, costs, and any
                        other type of damage of
                        whatsoever kind and nature.
                    </p>}
                    {caseData.caseStatus === 'Checks Printed' && <p className="statusDescription">
                        At this stage the firm's accounting department has received the closeout
                        package from the Attorney
                        and the closeout process has started but is not complete. Checks have been
                        prepared for you, the
                        legal fees, and the medical providers to pay for your medical bills, however
                        the liability release
                        must be received before check disbursement.
                    </p>}
                    {caseData.caseStatus === 'Disbursed' && <p className="statusDescription">
                        Also known as Settlement Distribution Payout, at this stage the firm's
                        accounting department has
                        completed processing the closeout of your case and has sent the file to the
                        Front Desk for
                        disbursement.
                        <br/><br/>
                        The firm's Front Desk will contact you to arrange for signatures of receipt
                        and/or ACH information
                        to deposit settlement amounts into your bank account or schedule time for
                        you to pick up the check
                        in our offices.
                    </p>}
                    {caseData.caseStatus === 'Settled' && <p className="statusDescription">
                        The settlement of your claim against the at-fault party's insurance company
                        is now complete.
                        Payouts have been sent to all parties, a copy of your signed General Release
                        is sent to the
                        adjuster to close out their claim. All paperwork and legal documents are
                        archived, and the case is
                        considered closed.
                    </p>}
                </TabPanel>
                <TabPanel value="2">
                    <Stack
                        sx={{
                            borderRadius: '20px',
                            margin: '3px',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Box
                            sx={{
                                fontSize: '20px',
                                fontWeight: 600,
                                mb: 2,
                            }}
                        >
                            Need to speak with someone?
                        </Box>
                        <Box sx={{textAlign: 'center'}}>
                            Here's the contact information for your case manager and your
                            attorney. Feel free to reach out either by phone or email and they
                            will respond to you as soon as possible.
                        </Box>
                        <Stack
                            sx={{
                                borderRadius: '20px',
                                margin: '3px',
                                flexDirection: {xs: 'column', md: 'row'},
                                alignItems: 'center',
                                width: '100%',
                                justifyContent: 'center',
                            }}
                        >
                            <Card
                                sx={{
                                    borderRadius: '20px',
                                    margin: '3px',
                                    p: 2,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    textAlign: 'center',
                                    gap: 1,
                                    mt: 2,
                                    width: {xs: '100%', md: '40%'},
                                }}
                            >
                                <Box sx={{fontWeight: 800}}>Your Case Manager</Box>
                                <Box>{caseManagerName}</Box>
                                {caseData.cMEmailAddress && <Box>{caseData.cMEmailAddress}</Box>}
                                <Box>{cMPhoneNumber}</Box>
                            </Card>
                            <Card
                                sx={{
                                    borderRadius: '20px',
                                    margin: '3px',
                                    p: 2,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    textAlign: 'center',
                                    gap: 1,
                                    mt: 2,
                                    width: {xs: '100%', md: '40%'},
                                }}
                            >
                                <Box sx={{fontWeight: 800}}>Your Attorney</Box>
                                <Box>{attorneyName}</Box>
                                {caseData.attorneyEmailAddress && <Box>{caseData.attorneyEmailAddress}</Box>}
                                <Box>{attorneyPhoneNumber}</Box>
                            </Card>
                        </Stack>

                        <Box
                            sx={{
                                mt: 2,
                                fontSize: '12px',
                                textAlign: 'center',
                                maxWidth: '300px',
                            }}
                        >
                            Additionally, if you'd like someone to contact you, please leave a short note and press "Request Contact".
                        </Box>
                        <TextField
                            placeholder="Reason for contact request"
                            multiline
                            rows={2}
                            maxRows={4}
                            value={clientNote}
                            onChange={(event) => {
                                setShowError(false);
                                setClientNote(event.target.value)
                            }}
                            sx={{
                                mt: 2,
                                width:  '100%',
                                maxWidth: '300px',
                            }}
                            error={showError}
                        />
                        {showError && <Alert severity={'error'} sx={{mt: 1}}>Note is required to email Cambre.</Alert>}
                        <Button
                            variant="contained"
                            onClick={handleSendEmail}
                            sx={{
                                mt: 2,
                                width:  '100%',
                                maxWidth: '300px',
                            }}
                        >
                            Request Contact
                        </Button>
                    </Stack>
                </TabPanel>
            </TabContext>
        </Box>
    </Box>
};

export default Dashboard;
