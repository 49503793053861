import useAuthenticatedFetch from "@/hooks/useAuthenticatedFetch.ts";
import {apiUrl} from "@/utils/api.ts";
import {useMutation, type UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {handleJsonApiError} from "jsonapi-zod-query";
import {z} from "zod";
import {caseTypeApiSchema} from "@/components/Forms/schema/caseTypeSchema.ts";

type CaseTypeForApi = z.infer<typeof caseTypeApiSchema>;

export const useSaveCaseType = (caseTypeUUID: string): UseMutationResult<void, Error, CaseTypeForApi> => {
    const fetch = useAuthenticatedFetch();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async ({...attributes}) => {
            const response = await fetch(apiUrl(`/case-type/save/${caseTypeUUID}`), {
                method: "POST",
                headers: {
                    "Content-Type": "application/vnd.api+json",
                },
                body: JSON.stringify({
                    data: {
                        id: caseTypeUUID,
                        type: "caseType",
                        attributes: {
                            ...attributes,
                            id: null,
                        },
                    },
                }),
            });
            await handleJsonApiError(response);
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: ["case-types"],
            });
        },
    });
};
