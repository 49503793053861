import {useMemo, useState} from 'react';
import {
    MaterialReactTable,
    type MRT_ColumnDef,
    type MRT_ColumnFiltersState,
    type MRT_PaginationState,
    type MRT_Row,
    type MRT_SortingState,
    useMaterialReactTable,
} from 'material-react-table';
import {Box, IconButton} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import {enqueueSnackbar} from "notistack";
import {getErrorMessage} from "@/utils/api.ts";
import {MrtEmailAddress, useFetchMrtEmailsForCompany} from "@/queries/useFetchMrtEmailsForCompany.ts";
import {useDeleteCompanyEmail} from "@/mutations/useDeleteCompanyEmail.tsx";
import AddCompanyEmailDialog from "@/components/Forms/SubForms/AddCompanyEmailDialog.tsx";
import EditIcon from "@mui/icons-material/Edit";

type Props = {
    companyUUID: string;
};

const MrtCompanyEmailsTable = ({companyUUID} : Props) => {
    const [editEmailAddressId, setEditEmailAddressId] = useState<string | undefined>();
    const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
        [],
    );
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState<MRT_SortingState>([]);
    const [pagination, setPagination] = useState<MRT_PaginationState>({
        pageIndex: 0,
        pageSize: 10,
    });

    const deleteCompanyEmail = useDeleteCompanyEmail();
    const {data, isError, isRefetching, isLoading, error} = useFetchMrtEmailsForCompany({
        companyUUID: companyUUID,
        columnFilters,
        globalFilter,
        pagination,
        sorting,
    });

    const handleDeleteRow = (row: MRT_Row<MrtEmailAddress>) => {
        if (window.confirm('Are you sure you want to delete this email?')) {
            deleteCompanyEmail.mutate(
                {
                    emailUUID: row.original.idUUID,
                },
                {
                    onSuccess: () => {
                        enqueueSnackbar("Email removed.", { variant: "success" });
                    },
                    onError: (error) => {
                        enqueueSnackbar("Error: Email could not be removed.", { variant: "error" });
                        console.info('error', getErrorMessage(error));
                    },
                },
            )
        }
    };

    const handleEditRow = (idUUID: string) => {
        setEditEmailAddressId(idUUID);
    }

    const columns = useMemo<MRT_ColumnDef<MrtEmailAddress>[]>(
        () => [
            {
                accessorKey: 'edit',
                header: '',
                enableSorting: false,
                Cell: ({row}) => (
                    <IconButton
                        onClick={(e) => {
                            e.stopPropagation();
                            handleEditRow(row.original.idUUID);
                        }}
                        size="small"
                    >
                        <EditIcon />
                    </IconButton>
                ),
                size: 50,
            },
            {
                accessorKey: 'emailType',
                header: 'Type',
                size: 50,
            },
            {
                accessorKey: 'emailAddress',
                header: 'Email Address',
            },
            {
                accessorKey: 'primary',
                header: 'Primary?',
                size: 50,
            },
            {
                accessorKey: 'delete',
                header: '',
                enableSorting: false,
                Cell: ({ row }) => (
                    <IconButton
                        onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteRow(row);
                        }}
                        size="small"
                    >
                        <DeleteIcon />
                    </IconButton>
                ),
                size: 50,
            },
        ], []);

    const table = useMaterialReactTable({
        columns,
        data: (isLoading || isRefetching || isError || data === undefined) ? [] : data,
        initialState: {
            showColumnFilters: false,
            density: 'compact',
        },
        enableTopToolbar: false,
        manualFiltering: true,
        manualPagination: true,
        manualSorting: true,
        muiToolbarAlertBannerProps: isError
            ? {
                color: 'error',
                children: 'Error loading data',
            }
            : undefined,
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        onPaginationChange: setPagination,
        onSortingChange: setSorting,
        rowCount: 0,
        state: {
            columnFilters,
            globalFilter,
            isLoading,
            pagination,
            showAlertBanner: isError,
            showProgressBars: isRefetching,
            showLoadingOverlay: (isLoading || isRefetching),
            sorting,
        },
        // muiTableBodyRowProps: ({ row }: { row: MRT_Row<MrtEmailAddress> }) => ({
        //     onClick: () => handleRowClick(row),
        //     sx: { cursor: "pointer" },
        // }),
    });



    if (isError) {
        return (
            <div>{error.message}</div>
        );
    }

    if (data === undefined) {
        return <div>No data</div>;
    }

    return <Box>
        <MaterialReactTable table={table} />
        <AddCompanyEmailDialog
            companyUUID={companyUUID}
            showAddButton={false}
            editEmailAddressId={editEmailAddressId}
            clearEditEmailAddressId={() => {
                setEditEmailAddressId(undefined)
            }}
        />
    </Box>;
};

export default MrtCompanyEmailsTable;
