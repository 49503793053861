import {Button, ButtonGroup} from "@mui/material";

type Props = {
    selectedTab: string;
    setSelectedTab: (selectedTab: string) => void;
}

const AttorneyLitigationFilters = ({selectedTab, setSelectedTab} : Props) => {
    return <ButtonGroup
        aria-label="Basic button group"
        fullWidth
        sx={{mb: 2}}
    >
        <Button
            onClick={() => setSelectedTab('')}
            variant={selectedTab === '' ? 'contained' : 'outlined'}
        >
            All Cases
        </Button>
        <Button
            onClick={() => setSelectedTab('Litigation')}
            variant={selectedTab === 'Litigation' ? 'contained' : 'outlined'}
        >
            Litigation
        </Button>
        <Button
            onClick={() => setSelectedTab('Mediation')}
            variant={selectedTab === 'Mediation' ? 'contained' : 'outlined'}
        >
            Mediation
        </Button>
        <Button
            onClick={() => setSelectedTab('Hold Harmless')}
            variant={selectedTab === 'Hold Harmless' ? 'contained' : 'outlined'}
        >
            Hold Harmless
        </Button>
        <Button
            onClick={() => setSelectedTab('Checks Printed')}
            variant={selectedTab === 'Checks Printed' ? 'contained' : 'outlined'}
        >
            Checks Printed
        </Button>
        <Button
            onClick={() => setSelectedTab('Disbursed')}
            variant={selectedTab === 'Disbursed' ? 'contained' : 'outlined'}
        >
            Disbursed
        </Button>
    </ButtonGroup>
};

export default AttorneyLitigationFilters;
