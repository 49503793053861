import {useQuery, type UseQueryResult} from "@tanstack/react-query";
import useAuthenticatedFetch from "@/hooks/useAuthenticatedFetch.ts";
import {apiUrl} from "@/utils/api.ts";
import {createResourceSelector,} from "jsonapi-zod-query";
import {personSchema} from "@/components/Forms/schema/personSchema.ts";

const personSelector = createResourceSelector({
    type: "person",
    attributesSchema: personSchema,
});

export type Person = ReturnType<typeof personSelector>;

type Props = {
    personId: string | null;
    isQueryEnabled ?: boolean;
};

export const useFetchPerson = (
    {
        personId,
        isQueryEnabled = true,
    }: Props): UseQueryResult<Person> => {
    const fetch = useAuthenticatedFetch();

    return useQuery({
        enabled: isQueryEnabled,
        queryKey: ["person", personId],
        queryFn: async ({ signal }) => {
            const url = apiUrl(`/person/by-id/${personId}`);

            const response = await fetch(url, {
                signal,
                headers: {
                    accept: "application/vnd.api+json",
                },
            });

            return await response.json();
        },
        select: personSelector,
    });
};
