import {keepPreviousData, useQuery, type UseQueryResult} from "@tanstack/react-query";
import {createDataSelector, createResourceCollectionSelector} from "jsonapi-zod-query";
import {apiUrl} from "@/utils/api.ts";
import useAuthenticatedFetch from "@/hooks/useAuthenticatedFetch.ts";
import {noteSchema} from "@/components/Forms/schema/noteSchema.ts";

const resourceSelector = createResourceCollectionSelector({
    type: "note",
    attributesSchema: noteSchema,
});

export const notesSelector = createDataSelector(resourceSelector);

export type NotesType = ReturnType<typeof notesSelector>;

type Props = {
    caseIdUUID : string;
};

export const useFetchNotesForCase = (
    {
        caseIdUUID,
    } : Props
) : UseQueryResult<NotesType> => {
    const fetch = useAuthenticatedFetch();

    return useQuery({
        queryKey: [
            `case-notes`,
            caseIdUUID,
        ],
        queryFn: async () => {
            const requestBody = {
                caseIdUUID: caseIdUUID
            };

            const response = await fetch(apiUrl('/note/for-case'), {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody)
            });

            return await response.json();
        },
        select: notesSelector,
        placeholderData: keepPreviousData,
    });
};
