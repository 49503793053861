import {CircularProgress, Grid2 as Grid, Paper, Stack, Typography} from "@mui/material";
import {useFetchMediasForPerson} from "@/queries/useFetchMediaForPerson.ts";
import MediaDocumentsListRaw from "@/components/Documents/MediaDocumentsListRaw.tsx";
import MediaUploadMultiple from "@/components/MediaUploadMultiple.tsx";

type Props = {
    personUUID: string;
};

const MediaDocumentsCollectionForPerson = ({ personUUID }: Props) => {
    const {
        data: media,
        isError,
        isRefetching,
        isLoading,
    } = useFetchMediasForPerson({
        personUUID: personUUID,
    });

    return (
        <Grid container>
            <Grid size={12}>
                <Paper
                    sx={{
                        mb: 2,
                        mx: 1,
                    }}
                >
                    <Grid
                        container
                        sx={{
                            borderRadius: "8px",
                            p: 1,
                        }}
                    >
                        <Grid size={12} sx={{ p: 1, mb: 1 }}>
                            <Stack
                                sx={{
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Typography variant={"h5"}>Documents</Typography>
                                <MediaUploadMultiple
                                    idGlobalUUID={personUUID}
                                    forceTag={'Person Details'}
                                    category={'Person'}
                                    description={'Person'}
                                    resourceName={`Person/${personUUID}`}
                                />
                            </Stack>
                            {(isLoading || isRefetching) && <CircularProgress size={"12px"} />}
                            {isError && <Typography variant={"h5"}>Error loading documents</Typography>}
                            {media && (!(isLoading || isRefetching || isError)) && <MediaDocumentsListRaw mediaArray={media}/>}
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
};

export default MediaDocumentsCollectionForPerson;
