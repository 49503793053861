import logo from "@/assets/logo.png";
import HouseIcon from "@mui/icons-material/House";
import GroupsIcon from "@mui/icons-material/Groups";
import FactoryIcon from "@mui/icons-material/Factory";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import BarChartIcon from "@mui/icons-material/BarChart";
import {Box, Collapse, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText,} from "@mui/material";
import {Fragment, type ReactNode, useState} from "react";
import {Link} from "react-router-dom";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import {useEmployee} from "@/contexts/EmployeeProvider.tsx";

type NavigationItem = {
    href: string;
    label: string;
    icon ?: ReactNode;
    children?: NavigationItem[];
    allowedPermissions?: string[];
};

const navigationItems: NavigationItem[] = [
    {
        label: "Dashboard",
        href: "/",
        icon: <HouseIcon />,
    },
    {
        label: "People",
        href: "/people",
        icon: <GroupsIcon />,
    },
    {
        label: "Places",
        href: "/places",
        icon: <FactoryIcon />,
    },
    {
        label: "Cases",
        href: "/cases",
        icon: <BusinessCenterIcon />,
    },
    // {
    //     label: "Leads",
    //     href: "/leads",
    //     icon: <FormatListBulletedIcon />,
    // },
    // {
    //     label: "Tasks",
    //     href: "/tasks",
    //     icon: <FormatListBulletedIcon />,
    // },
    {
        label: "Reports",
        href: "/reports",
        icon: <BarChartIcon />,
        allowedPermissions: ["view:report-sol", "view:report-referrals", "view:report-demand-dates", "view:report-last-touch"],
        children: [
            {
                label: "Demand Dates",
                href: "/reports/demand-dates",
                allowedPermissions: ["view:report-demand-dates"],
            },
            {
                label: "Last Touch",
                href: "/reports/last-touch",
                allowedPermissions: ["view:report-last-touch"],
            },
            {
                label: "Referrals",
                href: "/reports/referrals",
                allowedPermissions: ["view:report-referrals"],
            },
            {
                label: "SOL",
                href: "/reports/sol",
                allowedPermissions: ["view:report-sol"],
            },
        ],
    },
    {
        label: "Admin",
        href: "/admin",
        icon: <BarChartIcon />,
        allowedPermissions: ["create:employee", "delete:employee", "edit:employee"],
        children: [
            {
                label: "User Management",
                href: "/admin/user-management",
            },
            // {
            //     label: "Case Status Setup",
            //     href: "/admin/case-status-setup",
            // },
            // {
            //     label: "Party Role Setup",
            //     href: "/admin/party-role-setup",
            // },
            // {
            //     label: "Case Type",
            //     href: "/admin/case-type",
            // },
        ],
    },
];

const DrawerContent = (): ReactNode => {
    const {hasAnyPermission} = useEmployee()
    const [open, setOpen] = useState<Record<string, boolean>>({});

    const handleClick = (label: string) => {
        if (open[label]) {
            setOpen((prev) => ({...prev, [label]: false}));
        } else {
            setOpen((prev) => ({...prev, [label]: true}));
        }
    };

    return (
        <>
            <Box
                sx={{
                    textAlign: 'center',
                }}
            >
                <Box
                    component="img"
                    src={logo}
                    maxHeight={80}
                    alt="Logo"
                    sx={{ my: 2 }}
                />
            </Box>
            <Divider variant="middle" />

            <List>
                {navigationItems.map((item) => {
                    if (item.allowedPermissions && !hasAnyPermission(item.allowedPermissions)) {
                        return null;
                    }

                    if (item.children) {
                        return (
                            <Fragment key={item.href} >
                                <ListItemButton onClick={() => {
                                    handleClick(item.label);
                                }}>
                                    <ListItemText primary={item.label} />
                                    {open[item.label] ? <ExpandLess /> : <ExpandMore />}
                                </ListItemButton>
                                <Collapse in={open[item.label]} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        {item.children.map(childItem => {
                                            if (childItem.allowedPermissions && !hasAnyPermission(childItem.allowedPermissions)) {
                                                return null;
                                            }

                                            return <ListItem key={childItem.href} disablePadding sx={{ml: 2}}>
                                                <ListItemButton component={Link} to={childItem.href}>
                                                    {childItem.icon && <ListItemIcon sx={{color: '#fff'}}>{childItem.icon}</ListItemIcon>}
                                                    <ListItemText primary={childItem.label}/>
                                                </ListItemButton>
                                            </ListItem>
                                        })}
                                    </List>
                                </Collapse>
                            </Fragment>
                        );
                    }

                    return (
                        <ListItem key={item.href} disablePadding>
                            <ListItemButton component={Link} to={item.href}>
                                {item.icon && <ListItemIcon sx={{color: '#fff'}}>{item.icon}</ListItemIcon>}
                                <ListItemText primary={item.label} />
                            </ListItemButton>
                        </ListItem>
                    );
                })}
            </List>
        </>
    );
};

export default DrawerContent;
