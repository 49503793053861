import {ChangeEvent, useEffect, useMemo, useRef, useState} from 'react';
import {
    MaterialReactTable,
    type MRT_ColumnDef,
    type MRT_ColumnFiltersState,
    type MRT_PaginationState,
    MRT_Row,
    type MRT_RowVirtualizer,
    type MRT_SortingState,
    useMaterialReactTable,
} from 'material-react-table';
import {MrtLastTouchReportCase, useFetchReportLastTouch} from "@/queries/useFetchReportLastTouch.ts";
import {dateFormatterDashed} from "@/utils/mapDateOrStringToLocalDate.ts";
import {download, generateCsv, mkConfig} from 'export-to-csv';
import {Box, Button, MenuItem, Stack, TextField, Typography} from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {useFetchEmployeesForLastTouch} from "@/queries/useFetchEmployeesForLastTouch.ts";
import {getSolReportMrtStyle} from "@/functions/getSolReportMrtStyle.ts"; //or use your library of choice here

const csvConfig = mkConfig({
    fieldSeparator: ',',
    decimalSeparator: '.',
    useKeysAsHeaders: true,
});

const statuses = [
    'Last Touch - Attorney',
    'Last Touch - CM',
    'Last Touch - All Statuses',
]

const attorneyStatuses = [
    'Litigation',
    'Litigation Review',
    'Negotiation',
    'Hold Harmless',
]

const cmStatuses = [
    'New Client',
    'Treating',
    'Done Treating',
    'Demand Complete',
    'Demand Ready',
    'Withdrawal Pending',
]

const MrtLastTouchReportVirtualized = () => {
    const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);
    const [globalFilter, setGlobalFilter] = useState("");
    const [sorting, setSorting] = useState<MRT_SortingState>([]);
    const [pagination, setPagination] = useState<MRT_PaginationState>({
        pageIndex: 0,
        pageSize: 10,
    });
    const [selectedReportType, setSelectedReportType] = useState('');
    const [attorney, setAttorney] = useState('');
    const [team, setTeam] = useState('');
    const rowVirtualizerInstanceRef = useRef<MRT_RowVirtualizer>(null);

    const {
        data,
        isError,
        isRefetching,
        isLoading,
    } = useFetchReportLastTouch();

    const {
        data: employeeData,
    } = useFetchEmployeesForLastTouch();

    const employeesSeparated = useMemo(() => {
        let attorneyOptions: string[] = [''];
        let caseManagerOptions: string[] = [];
        let inactiveOptions: string[] = [''];

        if (employeeData) {
            for (const employee of employeeData) {
                if (employee.fullName) {
                    if (
                        (employee.status == "Active" && employee.privilegeSet == "Attorney") ||
                        employee.fullName == "Glenn Cambre" ||
                        employee.fullName == "Ian Perez"
                    ) {
                        attorneyOptions.push(employee.fullName);
                    } else if (
                        employee.status == "Active" &&
                        employee.privilegeSet == "Case Manager"
                    ) {
                        caseManagerOptions.push(employee.fullName);
                    } else {
                        inactiveOptions.push(employee.fullName);
                    }
                }
            }
        }

        return {
            attorneyOptions: attorneyOptions.sort((a, b) => a.localeCompare(b)),
            caseManagerOptions: caseManagerOptions.sort((a, b) => a.localeCompare(b)),
            inactiveOptions: inactiveOptions.sort((a, b) => a.localeCompare(b)),
        };
    }, [employeeData])

    const displayData = useMemo(() => {
        if (selectedReportType === 'Last Touch - Attorney') {
            return data?.filter(datum => datum.caseStatus && attorneyStatuses.includes(datum.caseStatus))
                .filter(datum => datum.assignedToAttorneyPretty === attorney || attorney === '')
                .filter(datum => datum.assignedTeam === team || team === '')
        }

        if (selectedReportType === 'Last Touch - CM') {
            return data?.filter(datum => datum.caseStatus && cmStatuses.includes(datum.caseStatus))
                .filter(datum => datum.assignedToAttorneyPretty === attorney || attorney === '')
                .filter(datum => datum.assignedTeam === team || team === '')
        }

        return data?.filter(datum => datum.assignedToAttorneyPretty === attorney || attorney === '')
            .filter(datum => datum.assignedTeam === team || team === '')
    },[data, selectedReportType, attorney, team]);

    const columns = useMemo<MRT_ColumnDef<MrtLastTouchReportCase>[]>(
        () => [
            {
                accessorKey: "id",
                header: "Case #",
                sortingFn: "alphanumeric",
                size: 110,
            },
            {
                accessorKey: "clientName",
                header: "Client Name",
            },
            {
                accessorKey: "caseType",
                header: "Type",
            },
            {
                accessorKey: "caseStatus",
                header: "Liability Status",
            },
            {
                accessorKey: "caseStatusUM",
                header: "UM Status",
            },
            {
                accessorKey: "caseName",
                header: "Case Name",
            },
            {
                accessorKey: "lastTouch",
                header: "Days Since Touch",
                Cell: ({ row }) => {
                    if (row.original.lastTouch) {
                        const today = new Date();
                        return Math.floor((today.getTime() - row.original.lastTouch.getTime()) / (1000 * 60 * 60 * 24));
                    }

                    return ''
                },
            },
            {
                accessorKey: "assignedToAttorneyPretty",
                header: "Attorney",
            },
            {
                accessorKey: "assignedTeam",
                header: "Team",
            },
        ],
        [],
    );

    const handleExportData = () => {
        if (displayData) {
            const exportData = displayData.map((datum) => {
                return {
                    id: datum.id,
                    clientName: datum.clientName,
                    caseType: datum.caseType,
                    caseStatus: datum.caseStatus,
                    caseStatusUM: datum.caseStatusUM,
                    caseName: datum.caseName,
                    dateOfIncident:  datum.dateOfIncident?.format(dateFormatterDashed) ?? '',
                    dateOfIncidentAge: datum.dateOfIncidentAge,
                    lastTouch: datum.lastTouch?.toLocaleDateString(),
                    assignedTeam: datum.assignedTeam,
                }
            });
            const csv = generateCsv(csvConfig)(exportData);
            download(csvConfig)(csv);
        }
    };

    const handleRowClick = (row: MRT_Row<MrtLastTouchReportCase>) => {
        window.location.href = `/case-detail/${row.original.id}`;
    };

    useEffect(() => {
        //scroll to the top of the table when the sorting changes
        try {
            rowVirtualizerInstanceRef.current?.scrollToIndex?.(0);
        } catch (error) {
            console.error(error);
        }
    }, [sorting]);

    useEffect(() => {
        setAttorney('');
        setTeam('');
    }, [selectedReportType]);

    const table = useMaterialReactTable({
        columns,
        data: (selectedReportType === '' || isLoading || isRefetching || isError || displayData === undefined) ? [] : displayData,

        initialState: {
            showColumnFilters: false,
            showGlobalFilter: false,
            density: 'compact',
        },

        // top toolbar
        enableGlobalFilter: false,
        enableTopToolbar: true,
        enableDensityToggle: false,
        enableFullScreenToggle: false,
        enableHiding: false,
        enableColumnFilters: false,

        // server side searching and pagination
        manualFiltering: true,
        manualPagination: true,

        // enablePagination: false,
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        onPaginationChange: setPagination,
        onSortingChange: setSorting,

        // state
        rowCount: displayData?.length ?? 0,
        state: {
            columnFilters,
            globalFilter,
            isLoading,
            pagination,
            showAlertBanner: isError,
            showProgressBars: isRefetching,
            showLoadingOverlay: (isLoading || isRefetching),
            sorting,
        },

        muiToolbarAlertBannerProps: isError
            ? {
                color: 'error',
                children: 'Error loading data',
            }
            : undefined,

        renderTopToolbarCustomActions: () => (
            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                }}
            >
                <Button
                    onClick={handleExportData}
                    startIcon={<FileDownloadIcon />}
                >
                    Generate Spreadsheet
                </Button>
            </Box>
        ),

        enablePagination: false,
        enableRowVirtualization: true,
        muiTableContainerProps: { sx: { maxHeight: '600px' } },
        rowVirtualizerOptions: { overscan: 5 },
        rowVirtualizerInstanceRef,
        muiTableBodyRowProps: ({ row }: { row: MRT_Row<MrtLastTouchReportCase> }) => ({
            onClick: () => handleRowClick(row),
            sx: {
                cursor: "pointer",
                '& .MuiTableCell-root': {
                    ...getSolReportMrtStyle({
                        caseStatus: row.original.caseStatus ?? '',
                        dateOfBirth: row.original.idPeople?.dateofbirth ?? null,
                    })
                },
            },
        }),
    });

    return <Stack
        sx={{
            flexGrow: 1,
        }}
    >
        <Stack
            sx={{
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <TextField
                select
                value={selectedReportType}
                name={'status'}
                label={'Report Type'}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    setSelectedReportType(event.target.value);
                }}
                sx={{
                    my: 1,
                    maxWidth: '300px',
                    minWidth: '300px',
                }}
            >
                <MenuItem
                    key={`status-blank`}
                    value={``}
                >
                    &nbsp;
                </MenuItem>
                {statuses.map((option) => <MenuItem
                    key={`status-${option}`}
                    value={`${option}`}
                >
                    {option}
                </MenuItem>)}
            </TextField>

            {selectedReportType && <Typography variant="body2" mb={2} sx={{color: '#1c881a', textAlign: 'center'}}>
                {selectedReportType === 'Last Touch - Attorney' && (`Showing Statuses: ${attorneyStatuses.join(', ')}`)}
                {selectedReportType === 'Last Touch - CM' && (`Showing Statuses: ${cmStatuses.join(', ')}`)}
                {selectedReportType === 'Last Touch - All Statuses' && 'Showing Statuses: All Statuses'}
            </Typography>}
        </Stack>
        <Stack
            sx={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
                gap: 2,
            }}
        >
            <TextField
                select
                value={attorney}
                name={'attorney'}
                label={'Attorney'}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    setAttorney(event.target.value);
                }}
                sx={{
                    my: 1,
                    width: '100%',
                }}
            >
                {employeesSeparated.attorneyOptions.map((option) => <MenuItem
                    key={`status-${option}`}
                    value={`${option}`}
                >
                    {option === '' ? 'All' : option}
                </MenuItem>)}
            </TextField>
            <TextField
                select
                value={team}
                name={'team'}
                label={'Team'}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    setTeam(event.target.value);
                }}
                sx={{
                    my: 1,
                    width: '100%',
                }}
            >
                {['', 'CM1', 'CM2', 'CM3'].map((option) => <MenuItem
                    key={`team-${option}`}
                    value={`${option}`}
                    sx={{
                        height: '40px'
                    }}
                >
                    {option === '' ? 'All' : option}
                </MenuItem>)}
            </TextField>
        </Stack>
        <MaterialReactTable table={table} />
    </Stack>
};

export default MrtLastTouchReportVirtualized;
