import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid2 as Grid,} from "@mui/material";
import {type BaseSyntheticEvent, type ReactNode, useState} from "react";
import {FormProvider, type SubmitHandler, useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import type {z} from "zod";
import {enqueueSnackbar} from "notistack";
import {getErrorMessage} from "@/utils/api.ts";
import {RhfTextField} from "mui-rhf-integration";
import {type NewPerson, useAddPerson} from "@/mutations/useAddPerson.tsx";
import {addPersonSchema} from "@/components/Forms/schema/personSchema.ts";
import AddPersonRoleSelect from "@/components/Forms/AddPersonRoleSelect.tsx";

export type AddPersonFormValues = z.infer<typeof addPersonSchema>;

const AddPersonDialog = (): ReactNode => {
    const [open, setOpen] = useState(false);
    const addPersonMutation = useAddPerson();

    const form = useForm<AddPersonFormValues>({
        resolver: zodResolver(addPersonSchema),
    });

    const handleSubmit = (values: AddPersonFormValues) => {
        return addPersonMutation.mutate(
            {
                ...values,
            },
            {
                onSuccess: (person: NewPerson) => {
                    form.reset();
                    enqueueSnackbar("Person added.", { variant: "success" });
                    setOpen(false);
                    window.location.href = `/person-detail/${person.id}`;
                },
                onError: (error) => {
                    enqueueSnackbar("Error: Add Person failed.", { variant: "error" });
                    console.info('error', getErrorMessage(error));
                },
            },
        );
    }

    const onSubmit: SubmitHandler<AddPersonFormValues> = async (
        data: AddPersonFormValues,
        event?: BaseSyntheticEvent
    ) => {
        event?.stopPropagation();
        handleSubmit(data);
    }

    return <>
        <Button
            variant={'contained'}
            sx={{
                ml: 2,
                minWidth: '120px',
            }}
            onClick={() => setOpen(true)}
        >
            New Person
        </Button>
        <Dialog open={open} maxWidth="sm" fullWidth>
            <FormProvider {...form}>
                <form>
                    <DialogTitle>New Person</DialogTitle>
                    <DialogContent dividers>
                        <Grid container spacing={2}>
                            <Grid size={{xs: 12, md: 3}}>
                                <AddPersonRoleSelect form={form} />
                            </Grid>
                            <Grid size={{xs: 12, md: 4}}>
                                <RhfTextField
                                    control={form.control}
                                    name={'nameFirst'}
                                    label={'Name First'}
                                    sx={{
                                        width: '100%',
                                    }}
                                />
                            </Grid>
                            <Grid size={{xs: 12, md: 5}}>
                                <RhfTextField
                                    control={form.control}
                                    name={'nameLast'}
                                    label={'Name Last'}
                                    sx={{
                                        width: '100%',
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpen(false)}>Close</Button>
                        <Button
                            variant={'contained'}
                            onClick={form.handleSubmit(onSubmit)}
                        >
                            Add Person
                        </Button>
                    </DialogActions>
                </form>
            </FormProvider>
        </Dialog>
    </>;
};

export default AddPersonDialog;
