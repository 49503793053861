import {keepPreviousData, useQuery, type UseQueryResult} from "@tanstack/react-query";
import useAuthenticatedFetch from "@/hooks/useAuthenticatedFetch.ts";
import {apiUrl} from "@/utils/api.ts";
import {createResourceCollectionSelector} from "jsonapi-zod-query";
import {historySchema} from "@/components/Forms/schema/historySchema.ts";

const historyCollectionSelector = createResourceCollectionSelector({
    type: "history",
    attributesSchema: historySchema,
});

export type HistoryCollection = ReturnType<typeof historyCollectionSelector>;
export type HistoryItem = HistoryCollection["data"][number];

export const useFetchHistoryForCurrentUser = (): UseQueryResult<HistoryCollection> => {
    const fetch = useAuthenticatedFetch();

    return useQuery({
        queryKey: ["history"],
        queryFn: async ({ signal }) => {
            const url = apiUrl('/history/for-current-user');

            const response = await fetch(url, {
                signal,
                headers: {
                    accept: "application/vnd.api+json",
                },
            });

            return await response.json();
        },
        select: historyCollectionSelector,
        placeholderData: keepPreviousData,
    });
};
