import {z} from "zod";
import {zj} from "zod-joda";
import {dayjsLocalDateSchema} from "@/utils/zod.ts";

export const clientSchema = z.object({
    idUUID: z.string(),
    companyName: z.string().nullable(),
    addr1: z.string().nullable(),
    addr2: z.string().nullable(),
    addrCity: z.string().nullable(),
    addrState: z.string().nullable(),
    addrZip: z.string().nullable(),
    caseKey: z.number().nullable(),
});

export const caseFormSchemaInput = z.object({
    idUUID: z.string(),
    clientName: z.string().optional().nullable(),
    caseName: z.string().optional().nullable(),
    caseType: z.string().optional().nullable(),

    anteLitemState: z.string().optional().nullable(),
    dOICounty: z.string().optional().nullable(),
    dateOfIncident: zj.localDate().nullable(),
    statuteOfLimitations: zj.localDate().nullable(),

    anteLitemYesNo: z.string().optional().nullable(),
    anteLitemGovernmentEntity: z.string().optional().nullable(),
    anteLitem: zj.localDate().nullable(),
    anteLitemEntity: z.string().optional().nullable(),

    idCaseStatus: z.string().optional().nullable(),
    caseStatus: z.string().optional().nullable(),
    liabilityAccepted: z.string().optional().nullable(),
    demandLiabilitySentOn: zj.localDate().nullable(),
    litigationStatus: z.string().optional().nullable(),

    idCaseStatusUM: z.string().optional().nullable(),
    caseStatusUM: z.string().optional().nullable(),
    uMUIMAccepted: z.string().optional().nullable(),
    demandUMSentOn: zj.localDate().nullable(),
    litigationSubStatus: z.string().optional().nullable(),

    assignedTeam: z.string().optional().nullable(),
    assignedToAttorney: z.string().optional().nullable(),
    assignedToAttorneyPretty: z.string().optional().nullable(),
    assignedToDisbursementSpecialist: z.string().optional().nullable(),
    assignedToIntakeSpecialist: z.string().optional().nullable(),

    assignedToParalegal: z.string().optional().nullable(),
    assignedToParalegalPretty: z.string().optional().nullable(),
    attorneyPhoneNumber: z.string().optional().nullable(),
    cMPhoneNumber: z.string().optional().nullable(),
    cMEmailAddress: z.string().optional().nullable(),
    attorneyEmailAddress: z.string().optional().nullable(),

    financeLoan: z.string().optional().nullable(),
    attorneyFee: z.string().optional().nullable(),
    crmStatus: z.string().optional().nullable(),

    clientPIN: z.string().optional().nullable(),

    // health insurance
    insidCompany: z.string().optional().nullable(),
    insMemberName: z.string().optional().nullable(),
    insMemberID: z.string().optional().nullable(),
    insPlanNumber: z.string().optional().nullable(),

    // accident info
    accidentReportDone: z.string().optional().nullable(),
    incidentReportDoneWho: z.string().optional().nullable(),
    accidentCaseNumber: z.string().optional().nullable(),
    pDSettled: z.string().optional().nullable(),
    pDSettledAmount: z.string().optional().nullable(),
    mainInjury1: z.string().optional().nullable(),
    mainInjury2: z.string().optional().nullable(),
    injury: z.string().optional().nullable(),
    clientPainScale: z.string().optional().nullable(),
    primaryStatute: z.string().optional().nullable(),
    accidentSummary: z.string().optional().nullable(),
    incidentLocation: z.string().optional().nullable(),

    checkReceived: z.boolean().optional().nullable(),
    releaseReceived: z.boolean().optional().nullable(),
    medpayCheckReceived: z.boolean().optional().nullable(),
    umCheckReceived: z.boolean().optional().nullable(),
    umReleaseReceived: z.boolean().optional().nullable(),
    checkReceivedTimestamp: z.string().optional().nullable(),
    releaseReceivedTimestamp: z.string().optional().nullable(),
    medpayCheckReceivedTimestamp: z.string().optional().nullable(),
    umCheckReceivedTimestamp: z.string().optional().nullable(),
    umReleaseReceivedTimestamp: z.string().optional().nullable(),
    caseExpenses: z.string().optional().nullable(),

    demand1: z.string().optional().nullable(),
    demand2: z.string().optional().nullable(),
    demand3: z.string().optional().nullable(),
    demand4: z.string().optional().nullable(),
    demand5: z.string().optional().nullable(),
    dateDemand1: zj.localDate().nullable(),
    dateDemand2: zj.localDate().nullable(),
    dateDemand3: zj.localDate().nullable(),
    dateDemand4: zj.localDate().nullable(),
    dateDemand5: zj.localDate().nullable(),
    counter1: z.string().optional().nullable(),
    counter2: z.string().optional().nullable(),
    counter3: z.string().optional().nullable(),
    counter4: z.string().optional().nullable(),
    counter5: z.string().optional().nullable(),
    dateCounter1: zj.localDate().nullable(),
    dateCounter2: zj.localDate().nullable(),
    dateCounter3: zj.localDate().nullable(),
    dateCounter4: zj.localDate().nullable(),
    dateCounter5: zj.localDate().nullable(),

    idReferredBy: z.string().nullable().optional(),
    idReferredByView: z.string().nullable().optional(),
    idReferredByCompany: z.string().nullable().optional(),
    idReferredByCompanyView: z.string().nullable().optional(),

    insClaimNumber: z.string().nullable().optional(),
    uMUIMCompanyName: z.string().nullable().optional(),
    inspolicy: z.string().nullable().optional(),
    coverageType: z.string().nullable().optional(),
    insamountPerPerson: z.string().nullable().optional(),
    insFormLimits: z.string().nullable().optional(),
    insmedicalPmtAmount: z.string().nullable().optional(),
    insClaimNumber2: z.string().nullable().optional(),
    uMUIMCompanyName2: z.string().nullable().optional(),
    inspolicy2: z.string().nullable().optional(),
    coverageType2: z.string().nullable().optional(),
    insamountPerPerson2: z.string().nullable().optional(),
    insFormLimits2: z.string().nullable().optional(),
    insmedicalPmtAmount2: z.string().nullable().optional(),
    insClaimNumber3: z.string().nullable().optional(),
    uMUIMCompanyName3: z.string().nullable().optional(),
    inspolicy3: z.string().nullable().optional(),
    coverageType3: z.string().nullable().optional(),
    insamountPerPerson3: z.string().nullable().optional(),
    insFormLimits3: z.string().nullable().optional(),
    insmedicalPmtAmount3: z.string().nullable().optional(),
    metaCreatedByPretty: z.string().nullable().optional(),
    metaCreatedWhen: z.coerce.date().nullable(),
});

export const caseSchemaForApi = caseFormSchemaInput.extend({
    dateOfIncident: dayjsLocalDateSchema.nullable(),
    statuteOfLimitations: dayjsLocalDateSchema.nullable(),
    anteLitem: dayjsLocalDateSchema.nullable(),
    demandLiabilitySentOn: dayjsLocalDateSchema.nullable(),
    demandUMSentOn: dayjsLocalDateSchema.nullable(),
});

