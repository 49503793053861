import {keepPreviousData, useQuery, type UseQueryResult} from "@tanstack/react-query";
import useAuthenticatedFetch from "@/hooks/useAuthenticatedFetch.ts";
import {apiUrl} from "@/utils/api.ts";
import {createResourceSelector} from "jsonapi-zod-query";
import {partySchema} from "@/components/Forms/schema/partySchema.ts";

const partyTypeSelector = createResourceSelector({
    type: "party",
    attributesSchema: partySchema,
});

export type Party = ReturnType<typeof partyTypeSelector>;

type Props = {
    partyId : string | null;
    isQueryEnabled ?: boolean;
};
export const useFetchParty = (
    {
        isQueryEnabled = true,
        partyId,
    } : Props
 ) : UseQueryResult<Party> => {
    const fetch = useAuthenticatedFetch();

    return useQuery({
        queryKey: [
            'party',
            partyId,
        ],
        enabled: isQueryEnabled,
        queryFn: async () => {
            const fetchURL = apiUrl(`/party/${partyId}`);

            const response = await fetch(fetchURL.href, {
                method: 'GET',
                headers: {
                    "Content-Type": "application/vnd.api+json",
                },
            });

            return await response.json();
        },
        select: partyTypeSelector,
        placeholderData: keepPreviousData,
    });
};
