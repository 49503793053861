import {FormProvider, type SubmitHandler, useForm} from 'react-hook-form';
import {z} from 'zod';
import {zodResolver} from '@hookform/resolvers/zod';
import {CircularProgress, Grid2 as Grid} from "@mui/material";
import {type BaseSyntheticEvent, useCallback, useEffect, useState} from "react";
import {enqueueSnackbar} from "notistack";
import {getErrorMessage} from "@/utils/api.ts";
import {useFetchCompany} from "@/queries/useFetchCompany.ts";
import {saveCompanySchema} from "@/components/Forms/schema/saveCompanySchema.ts";
import {useSaveCompany} from "@/mutations/useSaveCompany.tsx";
import CompanyInformation from "@/components/Company/CompanyInformation.tsx";
import CompanyContactInfo from "@/components/Company/CompanyContactInfo.tsx";
import CompanyCases from "@/components/Person/CompanyCases.tsx";
import CompanyMedia from "@/components/Company/CompanyMedia.tsx";

export type CompanyRequestSchemaType = z.infer<typeof saveCompanySchema>;

const massageLocalDateToDayjsDates = (data: Record<string, any>) => {
    return {
        ...data.data,
    }
}

type Props = {
    placeId: string;
}

const CompanyForm = ({placeId} : Props) => {
    const saveCompany = useSaveCompany();
    const [foundCompanyIdUUID, setFoundCompanyIdUUID] = useState<string | null>(null);

    const {
        data,
        isError,
        isRefetching,
        isLoading,
        isPending,
        error,
    } = useFetchCompany({placeId});

    const form = useForm<CompanyRequestSchemaType>({
        resolver: zodResolver(saveCompanySchema),
    });

    const handleSubmit = useCallback(
        (values: CompanyRequestSchemaType) => {
            if (foundCompanyIdUUID) {
                saveCompany.mutate(
                    {
                        companyUUID: foundCompanyIdUUID,
                        ...values
                    },
                    {
                        onSuccess: () => {
                            enqueueSnackbar("Company saved.", { variant: "success" });
                            form.reset(form.getValues());
                        },
                        onError: (error) => {
                            enqueueSnackbar("Error: Company failed to save.", { variant: "error" });
                            console.info('error', getErrorMessage(error));
                        },
                    },
                );
            }
        },
        [saveCompany, foundCompanyIdUUID],
    );

    const onSubmit: SubmitHandler<CompanyRequestSchemaType> = async (
        data: CompanyRequestSchemaType,
        event?: BaseSyntheticEvent
    ) => {
        event?.stopPropagation();
        handleSubmit(data);
    }

    useEffect(() => {
        if (!isError && !isRefetching && !isLoading && data) {
            form.reset(massageLocalDateToDayjsDates(data));
            setFoundCompanyIdUUID(data.data.idUUID);
        }
    }, [data, isError, isRefetching, isLoading]);

    if (isPending) {
        return null;
    }

    if (isError) {
        return (
            <div>{error.message}</div>
        );
    }

    if (isLoading || isRefetching) {
        return (
            <CircularProgress/>
        );
    }

    if (error) {
        return (
            <div>{error}</div>
        );
    }

    return <>
        <FormProvider {...form}>
            <form onSubmit={
                form.handleSubmit(onSubmit)
            }>
                {foundCompanyIdUUID && <Grid container>
                    <Grid size={6}>
                        <CompanyInformation form={form}/>
                    </Grid>
                    <Grid size={6}>
                        <CompanyMedia companyUUID={foundCompanyIdUUID}/>
                        <CompanyCases companyUUID={foundCompanyIdUUID}/>
                    </Grid>
                    <Grid size={12}>
                        <CompanyContactInfo companyUUID={foundCompanyIdUUID}/>
                    </Grid>
                </Grid>}
            </form>
        </FormProvider>
    </>
};

export default CompanyForm;
