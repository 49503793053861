import type {ReactNode} from "react";
import {Container} from "@mui/material";
import Login from "@/ClientApplication/Login.tsx";

const PublicClientLogin = (): ReactNode => {
    return <Container
        sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'flex-start',
            height: '100vh',
            backgroundColor: '#313131',
        }}
    >
        <Login/>
    </Container>;
};

export default PublicClientLogin;
