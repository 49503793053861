import type {SelectOption} from "@/types/SelectOption.ts";

export const governmentEntityTypes : SelectOption[] = [
    {
        id: 'City',
        label: 'City',
    },
    {
        id: 'County',
        label: 'County',
    },
    {
        id: 'State',
        label: 'State',
    },
]

export const governmentEntityTypesWithBlank : SelectOption[] = [
    {
        id: '',
        label: '',
    },
    ...governmentEntityTypes,
]
