import {ChangeEvent, useEffect, useMemo, useState} from 'react';
import {Autocomplete, Box, Button, CircularProgress, Stack, TextField, Typography} from '@mui/material';
import {useFetchCompanionCasesForAutocomplete} from "@/queries/useFetchCompanionCasesForAutocomplete.ts";
import {useAddCompanionCase} from "@/mutations/useAddCompanionCase.tsx";
import {enqueueSnackbar} from "notistack";
import {getErrorMessage} from "@/utils/api.ts";

type Option = {
    id: string | null,
    name: string | null
}

type Props = {
    caseId: string;
}

const CompanionCaseSearchAutocomplete = ({caseId}: Props) => {
    const [inputValue, setInputValue] = useState('');
    const [searchString, setSearchString] = useState('');
    const [selectedCompanionCase, setSelectedCompanionCase] = useState('');
    const addCompanionCaseMutation = useAddCompanionCase(caseId);

    const {
        data: {data} = {},
        isError,
        isRefetching,
        isLoading,
    } = useFetchCompanionCasesForAutocomplete({
        searchString: searchString,
    });

    const options : Option[] = useMemo(() => {
        if (data) {
            return data.map((companionCase) => ({
                id: companionCase.idUUID,
                name: `${companionCase.id} - ${companionCase.clientName}`,
            }));
        }
        return [];
    }, [data]);

    const doAddCompanionCase = () => {
        if (selectedCompanionCase === '') {
            enqueueSnackbar("Please search for and select a companion case.", { variant: "error" });
            return;
        }

        addCompanionCaseMutation.mutate(
            {
                companionCaseId: selectedCompanionCase,
            },
            {
                onSuccess: () => {
                    enqueueSnackbar("Companion case added successfully.", { variant: "success" });
                },
                onError: (error) => {
                    enqueueSnackbar("Error: adding companion case failed.", { variant: "error" });
                    console.info('error', getErrorMessage(error));
                },
            },
        );
    }

    useEffect(() => {
        if (inputValue.length >= 2) {
            setSearchString(inputValue);
        }
    }, [inputValue]);

    return <Stack
        sx={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            pb: 2,
        }}
    >
        <Box sx={{flexGrow: 1}}>
            {(isLoading || isRefetching) && <CircularProgress/>}
            {isError && <Typography variant={"h5"}>Error loading companies</Typography>}
            <Autocomplete
                renderInput={(params) => <TextField
                    {...params}
                    label="Enter a Case Number or Client Name and click Add"
                    size="small"
                    value={inputValue}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        setInputValue(event.target.value);
                    }}
                />}
                options={options}
                loading={(isLoading || isRefetching)}
                getOptionLabel={(option: Option) => (option.name ?? '')}
                onChange={(_event, value) => {
                    if (value?.id) {
                        setSelectedCompanionCase(value.id);
                    }
                }}
                autoHighlight
                blurOnSelect
            />
        </Box>
        <Box>
            <Button
                variant={'contained'}
                sx={{
                    ml: 2,
                }}
                onClick={doAddCompanionCase}
            >
                Add
            </Button>
        </Box>
    </Stack>
};

export default CompanionCaseSearchAutocomplete;
