import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import {
    Box,
    CircularProgress,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText
} from "@mui/material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import dayjs from "dayjs";
import {Media} from "@/queries/useFetchMediaForCase.ts";
import {useEffect, useMemo, useState} from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import {enqueueSnackbar} from "notistack";
import {getErrorMessage} from "@/utils/api.ts";
import {useDeleteMedia} from "@/mutations/useDeleteMedia.tsx";
import {useEmployee} from "@/contexts/EmployeeProvider.tsx";
import {S3SignedUrl, useGetDocumentUrlMutation} from "@/mutations/document.ts";

type Props = {
    mediaArray : Media[];
    limitToCategory ?: string;
};

const MediaDocumentsListRaw = ({mediaArray, limitToCategory}: Props) => {
    const {hasPermission} = useEmployee()
    const getDocumentUrlMutation = useGetDocumentUrlMutation();
    const deleteMedia = useDeleteMedia();
    const [mediaUUID, setMediaUUID] = useState<string | undefined>();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);

        if (mediaUUID) {
            getDocumentUrlMutation.mutate(
                mediaUUID,
                {
                    onSuccess: (data: S3SignedUrl) => {
                        if (data.url) {
                            window.open(data.url, '_blank');
                        }

                        setLoading(false);
                        setMediaUUID(undefined);
                    },
                    onError: (error) => {
                        console.info('error', getErrorMessage(error));
                        setLoading(false);
                        setMediaUUID(undefined);
                    },
                },
            )
        }

    }, [mediaUUID]);

    const filteredDocuments = useMemo(() => {
        if (limitToCategory) {
            return mediaArray.filter((mediaItem: Media) => {
                return mediaItem.tag?.toLowerCase().includes(limitToCategory.toLowerCase());
            });
        }

        return mediaArray;
    }, [])

    const handleDeleteMedia = (mediaUUID: string) => {
        if (window.confirm('Are you sure you want to delete this Document?')) {
            deleteMedia.mutate(
                {
                    mediaUUID: mediaUUID,
                },
                {
                    onSuccess: () => {
                        enqueueSnackbar("Document removed.", { variant: "success" });
                    },
                    onError: (error) => {
                        enqueueSnackbar("Error: Document could not be removed.", { variant: "error" });
                        console.info('error', getErrorMessage(error));
                    },
                },
            )
        }
    };

    return <List>
        {filteredDocuments.map((media) => {
            const fileExtension = media.filename?.substring(media.filename?.lastIndexOf('.') + 1).toLowerCase();

            return <ListItem
                    key={media.idUUID}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        p: 1,
                        borderBottom: '1px solid #ccc',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                            width: '100%',
                        }}
                    >
                        <ListItemButton
                            onClick={() => {
                                setMediaUUID(media.idUUID);
                            }}
                        >
                            {fileExtension === 'pdf' && (
                                <ListItemIcon sx={{height: '100%'}}>
                                    <PictureAsPdfIcon color="action"/>
                                </ListItemIcon>
                            )}

                            {(fileExtension === 'doc' || fileExtension === 'docx') && (
                                <ListItemIcon sx={{height: '100%'}}>
                                    <InsertDriveFileIcon color="action"/>
                                </ListItemIcon>
                            )}

                            {fileExtension && !['pdf', 'doc', 'docx'].includes(fileExtension) && (
                                <ListItemIcon sx={{height: '100%'}}>
                                    <InsertDriveFileIcon color="action"/>
                                </ListItemIcon>
                            )}

                            <ListItemText
                                primary={media.filename}
                            />
                            {(media.idUUID === mediaUUID && loading) && <CircularProgress/>}
                            {media.idUUID && hasPermission("delete:attachment") &&  <IconButton
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleDeleteMedia(media.idUUID);
                                }}
                                size="small"
                            >
                                <DeleteIcon/>
                            </IconButton>}
                        </ListItemButton>
                    </Box>
                    <ListItemText
                        primary={
                            <Box sx={{fontSize: "12px", fontStyle: 'italic'}}>
                                {`${dayjs(media.metaModifiedWhen).format('MMM D YYYY - hh:mma')} ${media.tag}`}
                            </Box>
                        }
                    />
                </ListItem>
            }
        )}
    </List>
};

export default MediaDocumentsListRaw;
