import useAuthenticatedFetch from "@/hooks/useAuthenticatedFetch.ts";
import {apiUrl} from "@/utils/api.ts";
import {useMutation, type UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {handleJsonApiError} from "jsonapi-zod-query";
import {z} from "zod";
import {caseStatusApiSchema} from "@/components/Forms/schema/caseStatusSchema.ts";

type CaseStatusForApi = z.infer<typeof caseStatusApiSchema>;

export const useSaveCaseStatus = (caseStatusUUID: string): UseMutationResult<void, Error, CaseStatusForApi> => {
    const fetch = useAuthenticatedFetch();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async ({...attributes}) => {
            const response = await fetch(apiUrl(`/case-status/save/${caseStatusUUID}`), {
                method: "POST",
                headers: {
                    "Content-Type": "application/vnd.api+json",
                },
                body: JSON.stringify({
                    data: {
                        id: caseStatusUUID,
                        type: "caseStatus",
                        attributes: {
                            ...attributes,
                            id: null,
                        },
                    },
                }),
            });
            await handleJsonApiError(response);
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: ["caseStatuss"],
            });
        },
    });
};
