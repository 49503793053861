import React, {useMemo, useState} from 'react';
import {CircularProgress, Typography} from '@mui/material';
import {useFetchCompaniesForAutocomplete} from "@/queries/useFetchCompaniesForAutocomplete.ts";
import type {UseFormReturn} from "react-hook-form";
import type {CaseFormValues} from "@/components/Forms/CaseForm.tsx";
import {RhfAutocomplete} from "mui-rhf-integration";

type Option = {
    id: string | null,
    name: string | null
}

type Props = {
    form: UseFormReturn<CaseFormValues>;
}

const CaseReferredByCompanyAutocomplete = ({form}: Props) => {
    const [searchString, setSearchString] = useState('');
    const formValue = form.watch('idReferredByCompany')

    const {
        data: {data} = {},
        isError,
        isRefetching,
        isLoading,
    } = useFetchCompaniesForAutocomplete({
        searchString: searchString,
        fieldIdValue: formValue ?? null,
    });

    const options : Option[] = useMemo(() => {
        if (data) {
            return data.map((company) => ({
                id: company.idUUID,
                name: company.name ?? '',
            }));
        }
        return [];
    }, [data]);

    const handleInputChange = async (_event: React.SyntheticEvent, newInputValue: string) => {
        if (newInputValue.length >= 2) {
            setSearchString(newInputValue);
        }
    };

    return <>
        {(isLoading || isRefetching) && <CircularProgress/>}
        {isError && <Typography variant={"h5"}>Error loading companies</Typography>}
        <RhfAutocomplete
            control={form.control}
            name="idReferredByCompany"
            options={options}
            loading={(isLoading || isRefetching)}
            onInputChange={handleInputChange}

            textFieldProps={{
                label: 'Referred By Company',
            }}

            // display the name attribute as the dropdown value
            getOptionLabel={(option : Option | string) => {
                if (typeof option === 'string') {
                    return option;
                }

                return option.name || '';
            }}
            getOptionKey={(option : Option | string) => {
                if (typeof option === 'string') {
                    return option;
                }

                return option.id || '';
            }}
            optionToValue={(option : Option | string) => {
                if (typeof option === 'string') {
                    return option;
                }

                return option.id || '';
            }}
            valueToOption={value => {
                return options.find(option => option.id === value);
            }}

            sx={{
                backgroundColor: '#fff',
            }}
        />
    </>
};

export default CaseReferredByCompanyAutocomplete;
