import {Divider, ListItem, ListItemText, Menu, MenuItem} from "@mui/material";
import {bindMenu, type PopupState} from "material-ui-popup-state/hooks";
import type {ReactNode} from "react";
import {useAuth0} from "@auth0/auth0-react";
import {useEmployee} from "@/contexts/EmployeeProvider.tsx";

type Props = {
    state: PopupState;
};

const UserMenu = ({ state }: Props): ReactNode => {
    const {logout} = useAuth0();
    const {employee} = useEmployee()

    return (
        <Menu
            {...bindMenu(state)}
            slotProps={{
                paper: { sx: { maxWidth: 400 } },
            }}
        >
            {employee && <ListItem>
                <ListItemText
                    primaryTypographyProps={{
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                    }}
                    secondaryTypographyProps={{
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                    }}
                    primary={employee.fullName}
                    secondary={employee.eMailAddress}
                />
            </ListItem>}
            <Divider key="divider" sx={{ mb: 1 }} />
            {/*<ListItem>*/}
            {/*    <ModeSwitch />*/}
            {/*</ListItem>*/}
            {/*<Divider sx={{ my: 1 }} />*/}
            <MenuItem
                onClick={() => {
                    state.close();
                    void logout({
                        logoutParams: {
                            returnTo: window.origin,
                        }
                    });
                }}
            >
                Log out
            </MenuItem>
        </Menu>
    );
};

export default UserMenu;
