import {FormProvider, type SubmitHandler, useForm} from 'react-hook-form';
import {z} from 'zod';
import {zodResolver} from '@hookform/resolvers/zod';
import {CircularProgress, Grid2 as Grid} from "@mui/material";
import {type BaseSyntheticEvent, useCallback, useEffect, useState} from "react";
import {useFetchCaseType} from "@/queries/useFetchCaseType.ts";
import {useSaveCaseType} from "@/mutations/useSaveCaseType.tsx";
import {caseTypeSchema} from "@/components/Forms/schema/caseTypeSchema.ts";
import CaseTypeInformation from "@/components/CaseTypeInformation.tsx";
import {enqueueSnackbar} from "notistack";
import {getErrorMessage} from "@/utils/api.ts";

export type CaseTypeFormValues = z.infer<typeof caseTypeSchema>;

const massageLocalDateToDayjsDates = (data: Record<string, any>) => {
    return {
        ...data.data,
        order: data.data.order ? data.data.order.toString() : null,
    }
}

type Props = {
    caseTypeUUID: string;
}

const CaseTypeForm = ({caseTypeUUID} : Props) => {
    const saveCaseType = useSaveCaseType(caseTypeUUID);
    const [foundCaseTypeIdUUID, setFoundCaseTypeIdUUID] = useState<string | null>(null);

    const {
        data,
        isError,
        isRefetching,
        isLoading,
        isPending,
        error,
    } = useFetchCaseType(caseTypeUUID);

    const form = useForm<CaseTypeFormValues>({
        resolver: zodResolver(caseTypeSchema),
    });

    const handleSubmit = useCallback(
        (values: CaseTypeFormValues) => {
            if (foundCaseTypeIdUUID) {
                saveCaseType.mutate(
                    {
                        ...values,
                        order: values.order ? parseInt(values.order) : null,
                    },
                    {
                        onSuccess: () => {
                            enqueueSnackbar("CaseType saved.", { variant: "success" });
                            form.reset(form.getValues());
                        },
                        onError: (error) => {
                            enqueueSnackbar("Error: CaseType failed to save.", { variant: "error" });
                            console.info('error', getErrorMessage(error));
                        },
                    },
                );
            }
        },
        [saveCaseType, foundCaseTypeIdUUID],
    );

    const onSubmit: SubmitHandler<CaseTypeFormValues> = async (
        data: CaseTypeFormValues,
        event?: BaseSyntheticEvent
    ) => {
        event?.stopPropagation();
        handleSubmit(data);
    }

    useEffect(() => {
        if (!isError && !isRefetching && !isLoading && data) {
            form.reset(massageLocalDateToDayjsDates(data));

            if (data.data.idUUID) {
                setFoundCaseTypeIdUUID(data.data.idUUID);
            }
        }
    }, [data, isError, isRefetching, isLoading]);

    if (isPending) {
        return null;
    }

    if (isError) {
        return (
            <div>{error.message}</div>
        );
    }

    if (isLoading || isRefetching) {
        return (
            <CircularProgress/>
        );
    }

    if (error) {
        return (
            <div>{error}</div>
        );
    }

    return <>
        <FormProvider {...form}>
            <form onSubmit={
                form.handleSubmit(onSubmit)
            }>
                {foundCaseTypeIdUUID && <Grid container>
                    <Grid size={12}>
                        <CaseTypeInformation form={form} />
                    </Grid>
                </Grid>}
            </form>
        </FormProvider>
    </>
};

export default CaseTypeForm;
