import type {UseFormReturn} from 'react-hook-form';
import {MenuItem} from "@mui/material";
import {RhfTextField} from "mui-rhf-integration";
import {CasesTableFilter} from "@/components/Table/MrtCaseTable.tsx";

type Props = {
    form: UseFormReturn<CasesTableFilter>;
}

const daysToSol = [
    {value: '', text: 'All'},
    {value: '60', text: '< 60'},
    {value: '90', text: '< 90'},
    {value: '120', text: '< 120'},
    {value: '180', text: '< 180'},
]

const DaysToSolForCasesTable = ({form} : Props) => {
    return <RhfTextField
        select
        control={form.control}
        name={'daysToSol'}
        label={'Days to Sol'}
        sx={{
            width: '100%',
            backgroundColor: '#fff',
        }}
    >
        {daysToSol?.map((days) => <MenuItem
            key={`case-type-${days.value}`}
            value={`${days.value}`}
        >
            {days.text}
        </MenuItem>)}
    </RhfTextField>
};

export default DaysToSolForCasesTable;
