import {Grid2 as Grid} from "@mui/material";
import MediaDocumentsCollectionForPerson from "@/components/Documents/MediaDocumentsCollectionForPerson.tsx";

type Props = {
    personUUID: string;
}

const PersonMedia = ({personUUID} : Props) => {
    return <Grid container>
        <Grid size={12}>
            <MediaDocumentsCollectionForPerson personUUID={personUUID}/>
        </Grid>
    </Grid>
};

export default PersonMedia;
