import {FormProvider, type SubmitHandler, useForm} from 'react-hook-form';
import {z} from 'zod';
import {zodResolver} from '@hookform/resolvers/zod';
import {Box, Grid2 as Grid} from "@mui/material";
import {type BaseSyntheticEvent, useCallback, useEffect, useState} from "react";
import Information from "@/components/Person/Information.tsx";
import PersonContactInfo from "@/components/Person/PersonContactInfo.tsx";
import LegalGuardianInformation from "@/components/Person/LegalGuardianInformation.tsx";
import EmergencyContacts from "@/components/Person/EmergencyContacts.tsx";
import PersonCases from "@/components/Person/PersonCases.tsx";
import PersonMedia from "@/components/Person/PersonMedia.tsx";
import {useFetchPerson} from "@/queries/useFetchPerson.ts";
import {localDateToDayjs} from "@/utils/datetime.ts";
import {personSchema, savePersonSchema} from "@/components/Forms/schema/personSchema.ts";
import {useSavePerson} from "@/mutations/useSavePerson.tsx";
import {enqueueSnackbar} from "notistack";
import {getErrorMessage} from "@/utils/api.ts";

export type PersonRequestSchemaType = z.infer<typeof personSchema>;

const massageLocalDateToDayjsDates = (data: Record<string, any>) => {
    return {
        ...data.data,
        dateofbirth: data.data.dateofbirth ? localDateToDayjs(data.data.dateofbirth) : null,
        dateofdeath: data.data.dateofdeath ? localDateToDayjs(data.data.dateofdeath) : null,
    }
}

type Props = {
    personId: string;
}

const PersonForm = ({personId} : Props) => {
    const savePerson = useSavePerson();

    const [foundPersonIdUUID, setFoundPersonIdUUID] = useState<string | null>(null);

    const {
        data,
        isError,
        isRefetching,
        isLoading,
        error,
    } = useFetchPerson({
        personId: personId
    });

    const form = useForm<PersonRequestSchemaType>({
        resolver: zodResolver(savePersonSchema),
    });

    const handleSubmit = useCallback(
        (values: PersonRequestSchemaType) => {
            if (foundPersonIdUUID) {
                savePerson.mutate(
                    {
                        personId: personId,
                        personUUID: foundPersonIdUUID,
                        ...values
                    },
                    {
                        onSuccess: () => {
                            enqueueSnackbar("Person saved.", { variant: "success" });
                            form.reset(form.getValues());
                        },
                        onError: (error) => {
                            enqueueSnackbar("Error: Person failed to save.", { variant: "error" });
                            console.info('error', getErrorMessage(error));
                        },
                    },
                );
            }
        },
        [savePerson, foundPersonIdUUID],
    );

    const onSubmit: SubmitHandler<PersonRequestSchemaType> = async (
        data: PersonRequestSchemaType,
        event?: BaseSyntheticEvent
    ) => {
        event?.stopPropagation();
        handleSubmit(data);
    }

    useEffect(() => {
        if (!isError && !isRefetching && !isLoading && data) {
            form.reset(massageLocalDateToDayjsDates(data));
            setFoundPersonIdUUID(data.data.idUUID);
        }
    }, [data, isError, isRefetching, isLoading]);

    if (isError) {
        return (
            <Box>
                <div>{error.message}</div>
            </Box>
        );
    }

    return <>
        <FormProvider {...form}>
            <form onSubmit={
                form.handleSubmit(onSubmit)
            }>
                {foundPersonIdUUID && <Grid container>
                    <Grid size={6}>
                        <Information form={form} foundPersonIdUUID={foundPersonIdUUID} personId={personId}/>
                        <LegalGuardianInformation form={form}/>
                    </Grid>
                    <Grid size={6}>
                        <PersonCases personUUID={foundPersonIdUUID}/>
                        <PersonMedia personUUID={foundPersonIdUUID}/>
                    </Grid>
                    <Grid size={12}>
                        <PersonContactInfo personUUID={foundPersonIdUUID}/>
                        <EmergencyContacts form={form}/>
                    </Grid>
                </Grid>}
            </form>
        </FormProvider>
    </>
};

export default PersonForm;
