import {useMemo, useState} from 'react';
import {
    MaterialReactTable,
    type MRT_ColumnDef,
    type MRT_ColumnFiltersState,
    type MRT_PaginationState,
    type MRT_Row,
    type MRT_SortingState,
    useMaterialReactTable,
} from 'material-react-table';
import {type MrtCompany, useFetchMrtCompanies} from "@/queries/useFetchMrtCompanies.ts";
import {useDeleteCompany} from "@/mutations/useDeleteCompany.tsx";
import {enqueueSnackbar} from "notistack";
import {getErrorMessage} from "@/utils/api.ts";
import {IconButton} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import {useEmployee} from "@/contexts/EmployeeProvider.tsx";

const MrtCompaniesTable = () => {
    const {hasPermission} = useEmployee()
    const deleteCompany = useDeleteCompany();
    const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
        [],
    );
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState<MRT_SortingState>([]);
    const [pagination, setPagination] = useState<MRT_PaginationState>({
        pageIndex: 0,
        pageSize: 10,
    });

    const {
        data,
        isError,
        isRefetching,
        isLoading,
    } = useFetchMrtCompanies({
        columnFilters,
        globalFilter,
        pagination,
        sorting,
    });

    const handleRowClick = (row: MRT_Row<MrtCompany>) => {
        window.location.href = `/place-detail/${row.original.id}`;
    };

    const handleDeleteRow = (row: MRT_Row<MrtCompany>) => {
        if (window.confirm('Are you sure you want to delete this Company?')) {
            deleteCompany.mutate(
                {
                    companyUUID: row.original.idUUID,
                },
                {
                    onSuccess: () => {
                        enqueueSnackbar("Company removed.", { variant: "success" });
                    },
                    onError: (error) => {
                        enqueueSnackbar("Error: Company could not be removed.", { variant: "error" });
                        console.info('error', getErrorMessage(error));
                    },
                },
            )
        }
    };

    const columns = useMemo<MRT_ColumnDef<MrtCompany>[]>(
        () => {
            const columnDefs: MRT_ColumnDef<MrtCompany>[] = [
                {
                    accessorKey: 'name',
                    header: 'Name ',
                },
                {
                    accessorKey: 'city',
                    header: 'City',
                },
                {
                    accessorKey: 'zip',
                    header: 'Zip Code',
                },
                {
                    accessorKey: 'specialty',
                    header: 'Specialty',
                },
                {
                    accessorKey: 'primaryPhoneNumberDisplay',
                    header: 'Phone Number',
                },
            ]

            if (hasPermission("delete:company")) {
                columnDefs.push({
                    accessorKey: 'delete',
                    header: 'Delete',
                    size: 50,
                    Cell: ({ row }) => (
                        <IconButton onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                            event.stopPropagation();
                            handleDeleteRow(row)
                        }}>
                            <DeleteIcon />
                        </IconButton>
                    ),
                });
            }

            return columnDefs;
        }, [hasPermission, handleDeleteRow]);

    const table = useMaterialReactTable({
        columns,
        data: (isLoading || isRefetching || isError || data === undefined) ? [] : data.data,

        initialState: {
            showColumnFilters: false,
            showGlobalFilter: true,
            density: 'compact',
        },

        // top toolbar
        enableTopToolbar: true,
        enableDensityToggle: false,
        enableFullScreenToggle: false,
        enableHiding: false,
        enableColumnFilters: false,

        // server side searching and pagination
        manualFiltering: true,
        manualPagination: true,
        manualSorting: true,
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        onPaginationChange: setPagination,
        onSortingChange: setSorting,

        // state
        rowCount: data?.meta.totalRowCount ?? 0,
        state: {
            columnFilters,
            globalFilter,
            isLoading,
            pagination,
            showAlertBanner: isError,
            showProgressBars: isRefetching,
            showLoadingOverlay: (isLoading || isRefetching),
            sorting,
        },

        // design
        muiToolbarAlertBannerProps: isError
            ? {
                color: 'error',
                children: 'Error loading data',
            }
            : undefined,
        muiTableBodyRowProps: ({ row }: { row: MRT_Row<MrtCompany> }) => ({
            onClick: () => handleRowClick(row),
            sx: { cursor: "pointer" },
        }),
    });

    return <MaterialReactTable table={table} />;
};

export default MrtCompaniesTable;
