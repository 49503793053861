import {
    Alert,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid2 as Grid,
} from "@mui/material";
import {type BaseSyntheticEvent, type ReactNode, useCallback, useEffect, useState} from "react";
import {FormProvider, type SubmitHandler, useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {addLiabilitySchema} from "@/components/Forms/schema/addLiabilitySchema.ts";
import {z} from "zod";
import {enqueueSnackbar} from "notistack";
import {getErrorMessage} from "@/utils/api.ts";
import {useAddLiabilityToCase} from "@/mutations/useAddLiabilityToCase.tsx";
import LiabilityInsuranceCompanyAutocomplete from "@/components/Forms/LiabilityInsuranceCompanyAutocomplete.tsx";
import {RhfTextField} from "mui-rhf-integration";
import LiabilityAdjusterAutocomplete from "@/components/Forms/LiabilityAdjusterAutocomplete.tsx";
import {useFetchParty} from "@/queries/useFetchParty.ts";

export type AddLiabilityFormValues = z.infer<typeof addLiabilitySchema>;

type Props = {
    caseUUID: string;
    showAddButton ?: boolean;
    editPartyId ?: string;
    clearEditPartyId ?: () => void;
};

const AddEditLiabilityDialog = (
    {
        caseUUID,
        editPartyId,
        clearEditPartyId,
        showAddButton = true,
    }
: Props): ReactNode => {
    const addLiabilityToCaseMutation = useAddLiabilityToCase(caseUUID);
    const [open, setOpen] = useState(false);

    const {
        data: {data} = {},
        isError,
        isRefetching,
        isLoading,
    } = useFetchParty({
        partyId: editPartyId ?? null,
        isQueryEnabled: editPartyId !== undefined,
    });

    const form = useForm<AddLiabilityFormValues>({
        resolver: zodResolver(addLiabilitySchema),
    });

    const handleSubmit = useCallback(
        (values: AddLiabilityFormValues) => {
            addLiabilityToCaseMutation.mutate(
                {
                    ...values
                },
                {
                    onSuccess: () => {
                        enqueueSnackbar("Liability added.", { variant: "success" });
                        if (clearEditPartyId) {
                            clearEditPartyId();
                        }

                        form.reset({});
                        setOpen(false);
                    },
                    onError: (error) => {
                        enqueueSnackbar("Error: Add liability failed.", { variant: "error" });
                        console.info('error', getErrorMessage(error));
                    },
                },
            );
        },
        [addLiabilityToCaseMutation, caseUUID],
    );

    const onSubmit: SubmitHandler<AddLiabilityFormValues> = async (
        data: AddLiabilityFormValues,
        event?: BaseSyntheticEvent
    ) => {
        event?.stopPropagation();
        handleSubmit(data);
    }

    useEffect(() => {
        if (editPartyId && data) {
            form.reset(data)
            setOpen(true);
        }
    }, [data, editPartyId]);

    return <>
        {showAddButton && <Button
            variant={'contained'}
            sx={{
                ml: 2,
                minWidth: '120px',
            }}
            onClick={() => {
                form.reset();
                setOpen(true);
            }}
        >
            {editPartyId ? 'Edit' : 'New'} Liability
        </Button>}
        <Dialog open={open} maxWidth="sm" fullWidth>
            <FormProvider {...form}>
                <form>
                    <DialogTitle>New Liability - Bodily Injury</DialogTitle>
                    <DialogContent dividers>
                        {(isLoading || isRefetching) && <CircularProgress/>}
                        {isError && <Alert severity={'error'}>Error loading form data.</Alert>}
                        <Grid container spacing={2}>
                            <Grid size={12}>
                                <RhfTextField
                                    control={form.control}
                                    name={'claimNumber'}
                                    label={'Claim Number'}
                                    sx={{
                                        width: '100%',
                                    }}
                                />
                            </Grid>
                            <Grid size={12}>
                                <LiabilityInsuranceCompanyAutocomplete form={form}/>
                            </Grid>
                            <Grid size={12}>
                                <LiabilityAdjusterAutocomplete form={form}/>
                            </Grid>
                            <Grid size={12}>
                                <RhfTextField
                                    control={form.control}
                                    name={'adjusterPhone'}
                                    label={'Adjuster Phone Number'}
                                    sx={{
                                        width: '100%',
                                    }}
                                    disabled
                                />
                            </Grid>
                            <Grid size={12}>
                                <RhfTextField
                                    control={form.control}
                                    name={'adjusterEmail'}
                                    label={'Adjuster Email'}
                                    sx={{
                                        width: '100%',
                                    }}
                                    disabled
                                />
                            </Grid>
                            <Grid size={12}>
                                <RhfTextField
                                    control={form.control}
                                    name={'adjusterAddress'}
                                    label={'Adjuster Address'}
                                    sx={{
                                        width: '100%',
                                    }}
                                    disabled
                                />
                            </Grid>
                            <Grid size={6}>
                                <RhfTextField
                                    control={form.control}
                                    name={'policyPersonCoverageMM'}
                                    label={'Liability Coverage'}
                                    sx={{
                                        width: '100%',
                                    }}
                                />
                            </Grid>
                            <Grid size={6}>
                                <RhfTextField
                                    control={form.control}
                                    name={'policyVehiculeCoverageMM'}
                                    label={'Property Damage Coverage'}
                                    sx={{
                                        width: '100%',
                                    }}
                                />
                            </Grid>
                            <Grid size={6}>
                                <RhfTextField
                                    control={form.control}
                                    name={'policyInsuredName'}
                                    label={'Insured Name'}
                                    sx={{
                                        width: '100%',
                                    }}
                                />
                            </Grid>
                            <Grid size={6}>
                                <RhfTextField
                                    control={form.control}
                                    name={'policyDriverName'}
                                    label={'Driver Name'}
                                    sx={{
                                        width: '100%',
                                    }}
                                />
                            </Grid>
                            <Grid size={6}>
                                <RhfTextField
                                    control={form.control}
                                    name={'formLimits'}
                                    label={'Limits'}
                                    sx={{
                                        width: '100%',
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                if (clearEditPartyId) {
                                    clearEditPartyId();
                                }

                                form.reset({});
                                setOpen(false);
                            }}
                        >
                            Close
                        </Button>
                        <Button
                            variant={'contained'}
                            onClick={form.handleSubmit(onSubmit)}
                        >
                            {editPartyId ? 'Edit' : 'New'} Liability
                        </Button>
                    </DialogActions>
                </form>
            </FormProvider>
        </Dialog>
    </>;
};

export default AddEditLiabilityDialog;
