import type {SelectOption} from "@/types/SelectOption.ts";

export const optionsLiability : SelectOption[]= [
    {
        id: '',
        label: ''
    },
    {
        id: 'Pending',
        label: 'Pending'
    },
    {
        id: 'No',
        label: 'No'
    },
    {
        id: 'No - Pursuing UM',
        label: 'No - Pursuing UM'
    },
    {
        id: 'Yes',
        label: 'Yes'
    }
]
