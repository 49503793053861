import {FormProvider, type SubmitHandler, useForm} from 'react-hook-form';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import {
    Alert,
    Box,
    Button,
    CircularProgress,
    Divider,
    Grid2 as Grid,
    IconButton,
    InputAdornment,
    MenuItem,
    Paper,
    Stack,
    Typography
} from "@mui/material";
import {RhfRadioGroup, RhfTextField} from "mui-rhf-integration";
import type {CaseFormValues} from "@/components/Forms/CaseForm.tsx";
import MrtCompanionsTable from "@/components/Table/MrtCompanionsTable.tsx";
import AccidentInfo from "@/components/Forms/SubForms/AccidentInfo.tsx";
import ClientDetails from "@/components/Forms/SubForms/ClientDetails.tsx";
import HealthInsurance from "@/components/Forms/SubForms/HealthInsurance.tsx";
import MediaDocumentsCollectionForCase from "@/components/Documents/MediaDocumentsCollectionForCase.tsx";
import CaseTypeSelect from "@/components/Forms/CaseTypeSelect.tsx";
import {stateAbbreviations} from "@/constants/stateAbbreviations.ts";
import {RhfDatePicker} from "mui-rhf-integration/date-picker";
import {optionsYesNoWithBlank} from "@/constants/optionsYesNo.ts";
import {governmentEntityTypesWithBlank} from "@/constants/governmentEntityTypes.ts";
import CaseStatusesSelect from "@/components/Forms/CaseStatusesSelect.tsx";
import {optionsLiability} from "@/constants/optionsLiability.ts";
import {optionsLitigationStatus} from "@/constants/optionsLitigationStatus.ts";
import {optionsUMUIM} from "@/constants/optionsUMUIM.ts";
import {optionsLitigationSubStatus} from "@/constants/optionsLitigationSubStatus.ts";
import CaseFormTeamsSelect from "@/components/Forms/CaseFormTeamsSelect.tsx";
import EmployeeByType from "@/components/Forms/EmployeeByType.tsx";
import {attorneyFeeOptions} from "@/constants/attorneyFeeOptions.ts";
import {leadStatusOptions} from "@/constants/leadStatusOptions.ts";
import {enqueueSnackbar} from "notistack";
import CaseReferredByCompanyAutocomplete from "@/components/Forms/CaseReferredByCompanySelect.tsx";
import CaseReferredByIndividualAutocomplete from "@/components/Forms/CaseReferredByIndividualSelect.tsx";
import {zodResolver} from "@hookform/resolvers/zod";
import {type BaseSyntheticEvent, useCallback, useEffect, useState} from "react";
import {getErrorMessage} from "@/utils/api.ts";
import {useSaveCaseMutation} from "@/mutations/useSaveCaseMutation.tsx";
import {localDateToDayjs} from "@/utils/datetime.ts";
import {useFetchCase} from "@/queries/useFetchCase.ts";
import {useNavigate} from "react-router-dom";
import {getCasePaperStyle} from "@/functions/getCasePaperStyle.ts";
import {caseFormSchema} from "@/components/Forms/schema/caseFormSchema.ts";
import EmailPinToClient from "@/components/Forms/SubForms/EmailPinToClient.tsx";
import NoteList from "@/components/Documents/NoteList.tsx";

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
const massageLocalDateToDayjsDates = (data: Record<string, any>) => {
    let referralRadio = '';

    if (data.data.idReferredBy) {
        referralRadio = 'Individual';
    } else if (data.data.idReferredByCompany) {
        referralRadio = 'Company';
    }

    return {
        ...data.data,

        referralRadio: referralRadio,

        // dates
        statuteOfLimitations: data.data.statuteOfLimitations ? localDateToDayjs(data.data.statuteOfLimitations) : null,
        dateOfIncident: data.data.dateOfIncident ? localDateToDayjs(data.data.dateOfIncident) : null,
        anteLitem: data.data.anteLitem ? localDateToDayjs(data.data.anteLitem) : null,
        demandLiabilitySentOn: data.data.demandLiabilitySentOn ? localDateToDayjs(data.data.demandLiabilitySentOn) : null,
        demandUMSentOn: data.data.demandUMSentOn ? localDateToDayjs(data.data.demandUMSentOn) : null,

        idPeople: data.data.idPeople ? {
            ...data.data.idPeople,
            dateofbirth: data.data.idPeople.dateofbirth ? localDateToDayjs(data.data.idPeople.dateofbirth) : null,
            dateofdeath: data.data.idPeople.dateofdeath ? localDateToDayjs(data.data.idPeople.dateofdeath) : null,
        } : null,
    }
}

type Props = {
    caseIdUUID: string;
    foundCaseId: string;
}

const CaseSubformDetails = ({caseIdUUID, foundCaseId} : Props) => {
    const saveCaseMutation = useSaveCaseMutation();
    const navigate = useNavigate(); // Add this if using React Router
    const [isFinishedLoading, setIsFinishedLoading] = useState(false);

    const {
        data,
        isError,
        isRefetching,
        isLoading,
        isPending,
        error,
    } = useFetchCase(foundCaseId);

    const form = useForm<CaseFormValues>({
        resolver: zodResolver(caseFormSchema),
    });

    const idPeople = form.watch('idPeople');
    const referralRadio = form.watch('referralRadio');
    const idCaseStatus = form.watch('idCaseStatus');
    const idCaseStatusDisplayValue = form.watch('idCaseStatusDisplayValue');
    const financeLoan = form.watch('financeLoan');
    const anteLitemYesNo = form.watch('anteLitemYesNo');

    const handleSubmit = useCallback(
        (values: CaseFormValues) => {
            if (!caseIdUUID) {
                return;
            }

            const saveData = values;

            if (saveData.referralRadio === 'Individual') {
                saveData.idReferredByCompany = '';
            }

            if (saveData.referralRadio === 'Company') {
                saveData.idReferredBy = '';
            }

            saveCaseMutation.mutate(
                {
                    caseUUID: caseIdUUID,
                    ...saveData
                },
                {
                    onSuccess: () => {
                        enqueueSnackbar("Changes were successfully saved.", { variant: "success" });
                        form.reset(form.getValues());
                    },
                    onError: (error) => {
                        enqueueSnackbar("Error: save failed.", { variant: "error" });
                        console.info('error', getErrorMessage(error));
                    },
                },
            );
        },
        [saveCaseMutation, caseIdUUID, form.getValues, form.reset],
    );

    const onSubmit: SubmitHandler<CaseFormValues> = async (
        data: CaseFormValues,
        event?: BaseSyntheticEvent
    ) => {
        handleSubmit(data);
        event?.stopPropagation();
    }

    const handleCardClick = () => {
        if (idPeople?.id) {
            navigate(`/person-detail/${idPeople.id}`);
        }
    };

    useEffect(() => {
        if (!(isError || isRefetching || isLoading) && data) {
            form.reset(massageLocalDateToDayjsDates(data));
            setIsFinishedLoading(true);
        }
    }, [data, isError, isRefetching, isLoading, form.reset]);

    if (isPending) {
        return null;
    }

    if (isError) {
        return (
            <div>{error.message}</div>
        );
    }

    if (isLoading || !isFinishedLoading) {
        return (
            <CircularProgress/>
        );
    }

    if (!(foundCaseId && caseIdUUID) && isFinishedLoading) {
        return (
            <Box>Case not found</Box>
        );
    }

    return <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
            <Grid container sx={{pb: {xs: '200px', md: '100px'}}}>
                <Grid size={12}>
                    <Paper
                        sx={{
                            mb: 2,
                            mx: 1,
                            ...getCasePaperStyle({
                                financeLoan: financeLoan ?? '',
                                liabilityStatus:  idCaseStatusDisplayValue ?? '',
                            }),
                        }}
                    >
                        <Grid
                            container
                            sx={{
                                borderRadius: '8px',
                                p: 1,
                            }}
                        >
                            <Grid size={12} sx={{p: 1, mb: 1}}>
                                <Stack
                                    sx={{
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <Typography variant={'h5'}>Case Details</Typography>
                                    <RhfTextField control={form.control} name={'id'} sx={{display: 'none'}}/>
                                    {form.formState.isDirty && <Button
                                        variant='contained'
                                        type={'submit'}
                                        sx={{
                                            p: 1,
                                            borderRadius: '8px',
                                            fontWeight: 800,
                                            mb: 2,
                                            mr: 2,
                                        }}
                                    >
                                        Save
                                    </Button>}
                                </Stack>
                            </Grid>
                            <Grid size={{xs: 12, md: 3}} sx={{p: 1}}>
                                <RhfTextField
                                    control={form.control}
                                    name={'id'}
                                    label={'Case #'}
                                    sx={{
                                        width: '100%',
                                        backgroundColor: '#fff',
                                    }}
                                    disabled
                                />
                            </Grid>
                            <Grid size={{xs: 12, md: 3}} sx={{p: 1}}>
                                <RhfTextField
                                    control={form.control}
                                    name={'clientName'}
                                    label={'Client Name'}
                                    sx={{
                                        width: '100%',
                                        backgroundColor: '#fff',
                                    }}
                                    disabled
                                    slotProps={{
                                        input:  {
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={handleCardClick}
                                                        edge="end"
                                                        sx={{
                                                            height: '100%',
                                                            padding: 0,
                                                            '&:hover': {
                                                                '& .MuiSvgIcon-root': {
                                                                    color: '#1976d2',
                                                                },
                                                            },
                                                        }}
                                                        disableRipple
                                                    >
                                                        <ContactPageIcon/>
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid size={{xs: 12, md: 3}} sx={{p: 1}}>
                                <RhfTextField
                                    control={form.control}
                                    name={'caseName'}
                                    label={'Case Name'}
                                    sx={{
                                        width: '100%',
                                        backgroundColor: '#fff',
                                    }}
                                />
                            </Grid>
                            <Grid size={{xs: 12, md: 3}} sx={{p: 1}}>
                                <CaseTypeSelect form={form}/>
                            </Grid>
                            <Grid size={{xs: 12, md: 3}} sx={{p: 1}}>
                                <RhfTextField
                                    select
                                    control={form.control}
                                    name={'anteLitemState'}
                                    label={'Incident State'}
                                    sx={{
                                        width: '100%',
                                        backgroundColor: '#fff',
                                    }}
                                >
                                    {stateAbbreviations?.map(caseType => <MenuItem
                                        key={`incident-state-${caseType}`}
                                        value={`${caseType}`}
                                    >
                                        {caseType}
                                    </MenuItem>)}
                                </RhfTextField>

                            </Grid>
                            <Grid size={{xs: 12, md: 3}} sx={{p: 1}}>
                                <RhfTextField
                                    control={form.control}
                                    name={'dOICounty'}
                                    label={'County'}
                                    sx={{
                                        width: '100%',
                                        backgroundColor: '#fff',
                                    }}
                                />
                            </Grid>
                            <Grid size={{xs: 12, md: 3}} sx={{p: 1}}>
                                <RhfDatePicker
                                    control={form.control}
                                    name={'dateOfIncident'}
                                    label={'Date of Incident'}
                                    views={['year', 'month', 'day']}
                                    sx={{
                                        width: '100%',
                                        backgroundColor: '#fff',
                                    }}
                                />
                            </Grid>
                            <Grid size={{xs: 12, md: 3}} sx={{p: 1}}>
                                <RhfDatePicker
                                    control={form.control}
                                    name={'statuteOfLimitations'}
                                    label={'SOL'}
                                    views={['year', 'month', 'day']}
                                    sx={{
                                        width: '100%',
                                        backgroundColor: '#fff',
                                    }}
                                    disabled
                                />
                            </Grid>
                            <Grid size={{xs: 12}} sx={{p: 1}}>
                                <Divider sx={{mb: 1}}/>
                            </Grid>
                            <Grid
                                size={{xs: 12, md: 3}}
                                sx={{
                                    p: 1,
                                    backgroundColor: anteLitemYesNo &&  anteLitemYesNo === 'Yes' ? '#fdff5f' : '',
                                }}
                            >
                                <RhfTextField
                                    select
                                    control={form.control}
                                    name={'anteLitemYesNo'}
                                    label={'Ante Litem Case'}
                                    sx={{
                                        width: '100%',
                                        backgroundColor: '#fff',
                                    }}
                                >
                                    {optionsYesNoWithBlank?.map(item => <MenuItem
                                        key={`ante-litem-yes-no-option-${item.id}`}
                                        value={`${item.id}`}
                                        sx={{minHeight: '32px !important'}}
                                    >
                                        {item.label}
                                    </MenuItem>)}
                                </RhfTextField>
                            </Grid>
                            <Grid
                                size={{xs: 12, md: 3}}
                                sx={{
                                    p: 1,
                                    backgroundColor: anteLitemYesNo &&  anteLitemYesNo === 'Yes' ? '#fdff5f' : '',
                                }}
                            >
                                <RhfTextField
                                    select
                                    control={form.control}
                                    name={'anteLitemGovernmentEntity'}
                                    label={'Government Entity'}
                                    sx={{
                                        width: '100%',
                                        backgroundColor: '#fff',
                                    }}
                                >
                                    {governmentEntityTypesWithBlank?.map(caseType => <MenuItem
                                        key={`ante-litem-government-entity-option-${caseType.id}`}
                                        value={`${caseType.id}`}
                                        sx={{minHeight: '32px !important'}}
                                    >
                                        {caseType.label}
                                    </MenuItem>)}
                                </RhfTextField>
                            </Grid>
                            <Grid
                                size={{xs: 12, md: 3}}
                                sx={{
                                    p: 1,
                                    backgroundColor: anteLitemYesNo &&  anteLitemYesNo === 'Yes' ? '#fdff5f' : '',
                                }}
                            >
                                <RhfDatePicker
                                    control={form.control}
                                    name={'anteLitem'}
                                    label={'Ante Litem Sent On'}
                                    views={['year', 'month', 'day']}
                                    sx={{
                                        width: '100%',
                                        backgroundColor: '#fff',
                                    }}
                                />
                            </Grid>
                            <Grid
                                size={{xs: 12, md: 3}}
                                sx={{
                                    p: 1,
                                    backgroundColor: anteLitemYesNo &&  anteLitemYesNo === 'Yes' ? '#fdff5f' : '',
                                }}
                            >
                                <RhfTextField
                                    control={form.control}
                                    name={'anteLitemEntity'}
                                    label={'AL Entity'}
                                    sx={{
                                        width: '100%',
                                        backgroundColor: '#fff',
                                    }}
                                />
                            </Grid>
                            <Grid size={{xs: 12}} sx={{p: 1}}>
                                <Divider sx={{mb: 1}}/>
                            </Grid>
                            <Grid size={{xs: 12, md: 3}} sx={{p: 1}}>
                                <CaseStatusesSelect
                                    form={form}
                                    name={'idCaseStatus'}
                                    label={'Liability Case Status'}
                                />
                            </Grid>
                            <Grid size={{xs: 12, md: 3}} sx={{p: 1}}>
                                <RhfTextField
                                    select
                                    control={form.control}
                                    name={'liabilityAccepted'}
                                    label={'Liability Accepted'}
                                    sx={{
                                        width: '100%',
                                        backgroundColor: '#fff',
                                    }}
                                >
                                    {optionsLiability?.map(caseType => <MenuItem
                                        key={`liability-accepted-option${caseType.id}`}
                                        value={`${caseType.id}`}
                                    >
                                        {caseType.label}
                                    </MenuItem>)}
                                </RhfTextField>
                            </Grid>
                            <Grid size={{xs: 12, md: 3}} sx={{p: 1}}>
                                <RhfDatePicker
                                    control={form.control}
                                    name={'demandLiabilitySentOn'}
                                    label={'Lbty Demand Sent On'}
                                    views={['year', 'month', 'day']}
                                    sx={{
                                        width: '100%',
                                        backgroundColor: '#fff',
                                    }}
                                />
                            </Grid>
                            <Grid size={{xs: 12, md: 3}} sx={{p: 1}}>
                                {idCaseStatusDisplayValue === 'Litigation' && <RhfTextField
                                    select
                                    control={form.control}
                                    name={'litigationStatus'}
                                    label={'Litigation Status'}
                                    sx={{
                                        width: '100%',
                                        backgroundColor: '#fff',
                                    }}
                                >
                                    {optionsLitigationStatus?.map(label => <MenuItem
                                        key={`litigation-status-option-${label}`}
                                        value={`${label}`}
                                        sx={{minHeight: '32px !important'}}
                                    >
                                        {label}
                                    </MenuItem>)}
                                </RhfTextField>}
                            </Grid>
                            <Grid size={{xs: 12, md: 3}} sx={{p: 1}}>
                                {idCaseStatus !== '' && <CaseStatusesSelect
                                    form={form}
                                    name={'idCaseStatusUM'}
                                    label={'UM/UIM Case Status'}
                                />}
                            </Grid>
                            <Grid size={{xs: 12, md: 3}} sx={{p: 1}}>
                                <RhfTextField
                                    select
                                    control={form.control}
                                    name={'uMUIMAccepted'}
                                    label={'UM/UIM Accepted'}
                                    sx={{
                                        width: '100%',
                                        backgroundColor: '#fff',
                                    }}
                                >
                                    {optionsUMUIM?.map(caseType => <MenuItem
                                        key={`umuim-accepted-option-${caseType.id}`}
                                        value={`${caseType.id}`}
                                    >
                                        {caseType.label}
                                    </MenuItem>)}
                                </RhfTextField>
                            </Grid>
                            <Grid size={{xs: 12, md: 3}} sx={{p: 1}}>
                                <RhfDatePicker
                                    control={form.control}
                                    name={'demandUMSentOn'}
                                    label={'UM Demand Sent On'}
                                    views={['year', 'month', 'day']}
                                    sx={{
                                        width: '100%',
                                        backgroundColor: '#fff',
                                    }}
                                />
                            </Grid>
                            <Grid size={{xs: 12, md: 3}} sx={{p: 1}}>
                                <RhfTextField
                                    select
                                    control={form.control}
                                    name={'litigationSubStatus'}
                                    label={'Substatus'}
                                    sx={{
                                        width: '100%',
                                        backgroundColor: '#fff',
                                    }}
                                >
                                    {optionsLitigationSubStatus?.map(status => <MenuItem
                                        key={`litigation-sub-status-option-${status}`}
                                        value={`${status}`}
                                    >
                                        {status}
                                    </MenuItem>)}
                                </RhfTextField>
                            </Grid>
                            <Grid size={{xs: 12}} sx={{p: 1}}>
                                <Divider sx={{mb: 1}}/>
                            </Grid>
                            <Grid size={{xs: 12, md: 3}} sx={{p: 1}}>
                                <CaseFormTeamsSelect label={'Team'} name={'assignedTeam'} form={form}/>
                            </Grid>
                            <Grid size={{xs: 12, md: 3}} sx={{p: 1}}>
                                <EmployeeByType
                                    label={'Attorney'}
                                    name={'assignedToAttorney'}
                                    form={form}
                                    type={'Attorney'}
                                />
                            </Grid>
                            <Grid size={{xs: 12, md: 3}} sx={{p: 1}}>
                                <EmployeeByType
                                    label={'Disbursement Specialist'}
                                    name={'assignedToDisbursementSpecialist'}
                                    form={form}
                                    type={'DisbursementSpecialist'}
                                />
                            </Grid>
                            <Grid size={{xs: 12, md: 3}} sx={{p: 1}}>
                                <EmployeeByType
                                    label={'Intake Specialist'}
                                    name={'assignedToIntakeSpecialist'}
                                    form={form}
                                    type={'IntakeSpecialist'}
                                />
                            </Grid>
                            <Grid size={{xs: 12, md: 3}} sx={{p: 1}}>
                                <EmployeeByType
                                    label={'Paralegal'}
                                    name={'assignedToParalegal'}
                                    form={form}
                                    type={'CaseManager'}
                                />
                            </Grid>
                            <Grid size={{xs: 12, md: 3}} sx={{p: 1}}>
                            </Grid>
                            <Grid size={{xs: 12, md: 3}} sx={{p: 1}}>
                            </Grid>
                            <Grid size={{xs: 12, md: 3}} sx={{p: 1}}>
                            </Grid>
                            <Grid size={{xs: 12}} sx={{p: 1}}>
                                <Divider sx={{mb: 1}}/>
                            </Grid>
                            <Grid size={{xs: 12, md: 3}} sx={{p: 1}}>
                                <RhfTextField
                                    select
                                    control={form.control}
                                    name={'financeLoan'}
                                    label={'Finance Loan?'}
                                    sx={{
                                        width: '100%',
                                        backgroundColor: '#fff',
                                    }}
                                >
                                    {optionsYesNoWithBlank?.map(item => <MenuItem
                                        key={`ante-litem-yes-no-option-${item.id}`}
                                        value={`${item.id}`}
                                        sx={{minHeight: '32px !important'}}
                                    >
                                        {item.label}
                                    </MenuItem>)}
                                </RhfTextField>
                            </Grid>
                            <Grid size={{xs: 12, md: 3}} sx={{p: 1}}>
                                <RhfTextField
                                    select
                                    control={form.control}
                                    name={'attorneyFee'}
                                    label={'Attorney Fee %'}
                                    sx={{
                                        width: '100%',
                                        backgroundColor: '#fff',
                                    }}
                                >
                                    {attorneyFeeOptions?.map(percent => <MenuItem
                                        key={`attorney-fee-option-${percent}`}
                                        value={`${percent}`}
                                        sx={{minHeight: '32px !important'}}
                                    >
                                        {percent}
                                    </MenuItem>)}
                                </RhfTextField>
                            </Grid>
                            <Grid size={{xs: 12, md: 3}} sx={{p: 1}}>
                                <RhfTextField
                                    select
                                    control={form.control}
                                    name={'crmStatus'}
                                    label={'Lead Status'}
                                    sx={{
                                        width: '100%',
                                        backgroundColor: '#fff',
                                    }}
                                >
                                    {leadStatusOptions?.map(option => <MenuItem
                                        key={`crm-status-option-${option.id}`}
                                        value={`${option.id}`}
                                        sx={{minHeight: '32px !important'}}
                                    >
                                        {option.label}
                                    </MenuItem>)}
                                </RhfTextField>
                            </Grid>
                            <Grid size={{xs: 12, md: 3}} sx={{p: 1}}>
                            </Grid>
                            <Grid size={{xs: 12, md: 3}} sx={{p: 1}}>
                                <RhfRadioGroup
                                    control={form.control}
                                    name={'referralRadio'}
                                    label={'Type of referral:'}
                                    options={[
                                        {value: 'Company', label: 'Company'},
                                        {value: 'Individual', label: 'Individual'},
                                    ]}
                                    sx={{
                                        '& .MuiRadio-root': {
                                            color: '#777'
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid size={{xs: 12, md: 6}} sx={{p: 1, display: referralRadio === 'Individual' ? 'block' : 'none',}}>
                                <CaseReferredByIndividualAutocomplete
                                    form={form}
                                />
                            </Grid>
                            <Grid size={{xs: 12, md: 6}} sx={{p: 1, display: referralRadio === 'Company' ? 'block' : 'none',}}>
                                <CaseReferredByCompanyAutocomplete
                                    form={form}
                                />
                            </Grid>
                            <Grid size={{xs: 12, md: 3}} sx={{p: 1}}>
                            </Grid>
                            <Grid size={{xs: 12, md: 3}} sx={{p: 1}}>
                            </Grid>
                            <Grid size={{xs: 6, md: 3}} sx={{p: 1}}>
                                <RhfTextField
                                    control={form.control}
                                    name={'clientPIN'}
                                    label={'Client PIN'}
                                    sx={{
                                        width: '100%',
                                        backgroundColor: '#fff',
                                    }}
                                    slotProps={{
                                        htmlInput: {
                                            readOnly: true,
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid
                                size={{xs: 6, md: 3}}
                                sx={{
                                    p: 1,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                {caseIdUUID && data?.data.idPeople?.idUUID && <EmailPinToClient caseIdUUID={caseIdUUID} personUUID={data.data.idPeople.idUUID} />}
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid size={12}>
                    <Paper sx={{mb: 2, mx: 1}}>
                        <Grid
                            container
                            sx={{
                                borderRadius: '8px',
                                p: 1,
                            }}
                        >
                            <Grid size={12} sx={{p: 1, mb: 1}}>
                                <Stack
                                    sx={{
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <Typography variant={'h5'}>Companions</Typography>
                                    {form.formState.isDirty && <Button
                                        variant='contained'
                                        type={'submit'}
                                        sx={{
                                            p: 1,
                                            borderRadius: '8px',
                                            fontWeight: 800,
                                            mb: 2,
                                            mr: 2,
                                        }}
                                    >
                                        Save
                                    </Button>}
                                </Stack>
                            </Grid>
                            <Grid size={12} sx={{p: 1, mb: 1}}>
                                {caseIdUUID && <MrtCompanionsTable caseUUID={caseIdUUID}/>}
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid size={{xs: 12, md: 6}}>
                    <AccidentInfo form={form}/>
                </Grid>
                <Grid size={{xs: 12, md: 6}}>
                    {idPeople && <ClientDetails form={form}/>}
                    {!idPeople && <Alert severity={'warning'}>Client Not Assigned</Alert>}
                </Grid>
                <Grid size={{xs: 12, md: 6}}>
                    <HealthInsurance form={form}/>
                </Grid>
                <Grid size={{xs: 12, md: 6}}>
                </Grid>
                <Grid size={{xs: 12, md: 6}}>
                    <NoteList caseIdUUID={caseIdUUID}/>
                </Grid>
                <Grid size={{xs: 12, md: 6}}>
                    {data?.data && <MediaDocumentsCollectionForCase caseIdUUID={caseIdUUID}/>}
                </Grid>
                <Grid size={12}>
                    {form.formState.isDirty && <Button
                        variant='contained'
                        type={'submit'}
                        sx={{
                            p: 1,
                            borderRadius: '8px',
                            fontWeight: 800,
                            mb: 2,
                            mr: 2,
                        }}
                    >
                        Save
                    </Button>}
                </Grid>
            </Grid>
        </form>
    </FormProvider>
};

export default CaseSubformDetails;
