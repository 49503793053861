import type {UseFormReturn} from 'react-hook-form';
import {CircularProgress, MenuItem, Paper, Typography} from "@mui/material";
import {RhfTextField} from "mui-rhf-integration";
import {AddProviderFormValues} from "@/components/Forms/AddEditProviderDialog.tsx";
import {useFetchMediasForCase} from "@/queries/useFetchMediaForCase.ts";

type Props = {
    form: UseFormReturn<AddProviderFormValues>;
    caseIdUUID: string;
}

const ProviderSelectedRecord = ({form, caseIdUUID} : Props) => {
    const {
        data: media,
        isError,
        isRefetching,
        isLoading,
    } = useFetchMediasForCase({
        caseIdUUID,
    });

    if (isError) {
        return <Paper sx={{
            p: 2,
            borderRadius: '8px',
        }}>
            <Typography variant={'h5'}>Error loading case types</Typography>
        </Paper>
    }

    if (isLoading || isRefetching) {
        return <Paper sx={{
            p: 2,
            borderRadius: '8px',
        }}>
            <CircularProgress size={'12px'}/>
        </Paper>
    }

    return <RhfTextField
        select
        control={form.control}
        name={'selectedRecord'}
        label={'Select Record:'}
        sx={{
            width: '100%',
        }}
    >
        <MenuItem key={'provider-selected-record-blank'} value={''}></MenuItem>
        {media?.filter((mediaRecord) => mediaRecord.tag?.includes('Providers'))
            .map((mediaRecord) => <MenuItem
            key={`provider-selected-record-${mediaRecord.idUUID}`}
            value={`${mediaRecord.idUUID}`}
        >
            {mediaRecord.filename}
        </MenuItem>)}
    </RhfTextField>

};

export default ProviderSelectedRecord;
