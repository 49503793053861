import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid2 as Grid, MenuItem,} from "@mui/material";
import {type BaseSyntheticEvent, type ReactNode, useCallback, useState} from "react";
import {FormProvider, type SubmitHandler, useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {addNoteSchema} from "@/components/Forms/schema/addNoteSchema.ts";
import {RhfTextField} from "mui-rhf-integration";
import {z} from "zod";
import {enqueueSnackbar} from "notistack";
import {getErrorMessage} from "@/utils/api.ts";
import {useAddNoteToCase} from "@/mutations/useAddNoteToCase.tsx";

const tags = [
    'Accounting',
    'Client Contact',
    'Closing',
    'Disbursement',
    'File Activity / Review',
    'Intake',
    'Liability',
    'Loan',
    'MedPay',
    'Negotiation',
    'Providers',
    'Subrogation',
    'Termination Process',
    'UM/UIM',
];

export type AddNoteFormValues = z.infer<typeof addNoteSchema>;

type Props = {
    caseUUID: string;
};

const AddNoteDialog = ({caseUUID}: Props): ReactNode => {
    const addNoteToCaseMutation = useAddNoteToCase(caseUUID);
    const [open, setOpen] = useState(false);

    const form = useForm<AddNoteFormValues>({
        resolver: zodResolver(addNoteSchema),
        defaultValues: {},
    });

    const handleSubmit = useCallback(
        (values: AddNoteFormValues) => {
            addNoteToCaseMutation.mutate(
                {
                    ...values
                },
                {
                    onSuccess: () => {
                        enqueueSnackbar("Note added.", { variant: "success" });
                        form.reset(form.getValues());
                        setOpen(false)
                    },
                    onError: (error) => {
                        enqueueSnackbar("Error: Add note failed.", { variant: "error" });
                        console.info('error', getErrorMessage(error));
                    },
                },
            );
        },
        [addNoteToCaseMutation, caseUUID],
    );

    const onSubmit: SubmitHandler<AddNoteFormValues> = async (
        data: AddNoteFormValues,
        event?: BaseSyntheticEvent
    ) => {
        event?.stopPropagation();
        handleSubmit(data);
    }

    return <>
        <Button
            variant={'contained'}
            sx={{
                ml: 2,
                minWidth: '120px',
            }}
            onClick={() => {
                form.reset();
                setOpen(true);
            }}
        >
            New Note
        </Button>
        <Dialog open={open} maxWidth="sm" fullWidth>
            <FormProvider {...form}>
                <form>
                    <DialogTitle>New Note</DialogTitle>
                    <DialogContent dividers>
                        <Grid container>
                            <Grid size={12}>
                                <RhfTextField
                                    select
                                    control={form.control}
                                    name={'tag'}
                                    label={'Tag'}
                                    sx={{
                                        width: '100%',
                                        mb: 2,
                                    }}
                                >
                                    {tags.map((tag) => (
                                        <MenuItem key={tag} value={tag}>
                                            {tag}
                                        </MenuItem>
                                    ))}
                                </RhfTextField>
                            </Grid>
                            <Grid size={12}>
                                <RhfTextField
                                    control={form.control}
                                    name={'note'}
                                    label={'Note'}
                                    sx={{
                                        width: '100%',
                                        mb: 2,
                                    }}
                                    multiline
                                    rows={6}
                                >
                                    {tags.map((tag) => (
                                        <MenuItem key={tag} value={tag}>
                                            {tag}
                                        </MenuItem>
                                    ))}
                                </RhfTextField>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpen(false)}>Close</Button>
                        <Button
                            variant={'contained'}
                            onClick={form.handleSubmit(onSubmit)}
                        >
                            Add Note
                        </Button>
                    </DialogActions>
                </form>
            </FormProvider>
        </Dialog>
    </>;
};

export default AddNoteDialog;
