import useAuthenticatedFetch from "@/hooks/useAuthenticatedFetch.ts";
import {apiUrl} from "@/utils/api.ts";
import {useMutation, type UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {createDataSelector, createResourceSelector, handleJsonApiError} from "jsonapi-zod-query";
import {AddEmployeeFormValues} from "@/pages/AdminUserManagement/AdminUserManagement.tsx";
import {employeeSchema} from "@/components/Forms/schema/employeeSchema.ts";

const resourceSelector = createResourceSelector({
    type: "employee",
    attributesSchema: employeeSchema,
});

export const employeeSelector = createDataSelector(resourceSelector);
export type NewEmployee = ReturnType<typeof employeeSelector>;

export const useAddEmployee =
    (): UseMutationResult<NewEmployee, Error, AddEmployeeFormValues> => {
        const fetch = useAuthenticatedFetch();
        const queryClient = useQueryClient();

        return useMutation({
            mutationFn: async (attributes) => {
                const response = await fetch(apiUrl(`/employee/create`), {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/vnd.api+json",
                    },
                    body: JSON.stringify({
                        data: {
                            type: "employee",
                            attributes: attributes,
                        },
                    }),
                });

                await handleJsonApiError(response);
                const body = await response.json() as unknown;
                return employeeSelector(body)
            },
            onSuccess: async () => {
                await queryClient.invalidateQueries({
                    queryKey: ["employees"],
                });
                await queryClient.invalidateQueries({
                    queryKey: ["employees-table-data"],
                });
            },
        });
    };
