import {ChronoUnit, LocalDate} from "@js-joda/core";

type SolReportMrtStyleProps = {
    caseStatus: string
    dateOfBirth: LocalDate | null
}

export const getSolReportMrtStyle = ({caseStatus, dateOfBirth} : SolReportMrtStyleProps) => {
    const css : Record<string, string | Record<string, string>> = {
        color: 'inherit',
        backgroundColor: 'inherit',
        '&:hover': {
            color: 'inherit',
            backgroundColor: 'inherit',
        }
    };

    if (dateOfBirth) {
        const today = LocalDate.now();
        const nineOrYounger = today.minus(9, ChronoUnit.YEARS);
        const fourteenYearsAgo = today.minus(14, ChronoUnit.YEARS);
        const seventeenYearsAgo = today.minus(17, ChronoUnit.YEARS);

        if (dateOfBirth.isAfter(nineOrYounger)) {
            if (caseStatus === "Settled") {
                css.backgroundColor = 'rgb(102, 0, 0) !important';
                css.color = 'red !important';
                css['&:hover'] = {
                    backgroundColor: 'rgb(78, 0, 0) !important',
                    color: 'red !important',
                }
                // return "settledRowMinor";
            } else if (caseStatus === "Negotiation") {
                css.backgroundColor = 'rgba(255, 115, 0, 0.582) !important';
                css.color = 'red !important';
                css['&:hover'] = {
                    backgroundColor: 'rgba(255, 193, 136, 0.932) !important',
                    color: 'red !important',
                }

                // return "negotiationRowMinor";
            } else if (caseStatus === "Disbursed") {
                css.backgroundColor = '#c2e6ca !important';
                css.color = 'red !important';
                // return "disbursedRowMinor";
            } else if (caseStatus === "Hold Harmless") {
                css.backgroundColor = 'rgba(0, 140, 255, 0.486) !important';
                css.color = 'red !important';
                css['&:hover'] = {
                    backgroundColor: 'rgba(91, 189, 255, 0.541) !important',
                    color: 'red !important',
                }
                // return "holdHarmlessRowMinor";
            } else if (caseStatus === "Client Terminated") {
                css.backgroundColor = 'rgba(0, 0, 0, 0.849) !important';
                css.color = 'red !important';
                css['&:hover'] = {
                    backgroundColor: 'rgba(0, 0, 0, 0.932) !important',
                    color: 'red !important',
                }
                // return "terminatedRowMinor";
            } else if (caseStatus === "Withdrawn") {
                css.backgroundColor = 'rgba(0, 0, 0, 0.658) !important';
                css.color = 'red !important';
                css['&:hover'] = {
                    backgroundColor: 'rgba(0, 0, 0, 0.623) !important',
                    color: 'red !important',
                }

                // return "withdrawnRowMinor";
            } else if (caseStatus === "") {
                css.color = 'red !important';
                // return "miscRowMinor";
            } else {
                css.color = 'red !important';
                // return "miscRowMinor";
            }
        } else if (dateOfBirth.isAfter(fourteenYearsAgo)) {
            if (caseStatus === "Settled") {
                css.backgroundColor = 'rgb(102, 0, 0) !important';
                css.color = 'rgb(116, 116, 255) !important';
                css['&:hover'] = {
                    backgroundColor: 'rgb(78, 0, 0) !important',
                    color: 'rgb(116, 116, 255) !important',
                }
                // return "settledRowMinor_10_14";
            } else if (caseStatus === "Negotiation") {
                css.backgroundColor = 'rgba(255, 115, 0, 0.582) !important';
                css.color = 'rgb(116, 116, 255) !important';
                css['&:hover'] = {
                    backgroundColor: 'rgba(255, 193, 136, 0.932) !important',
                    color: 'rgb(116, 116, 255) !important',
                }
                // return "negotiationRowMinor_10_14";
            } else if (caseStatus === "Disbursed") {
                css.backgroundColor = '#c2e6ca !important';
                css.color = 'red !important';
                css['&:hover'] = {
                    backgroundColor: '#b1dfbb !important',
                    color: 'red !important',
                }
                // return "disbursedRowMinor_10_14";
            } else if (caseStatus === "Hold Harmless") {
                css.backgroundColor = 'rgba(0, 140, 255, 0.486) !important';
                css.color = 'rgb(116, 116, 255) !important';
                css['&:hover'] = {
                    backgroundColor: 'rgba(91, 189, 255, 0.541) !important',
                    color: 'rgb(116, 116, 255) !important',
                }
                // return "holdHarmlessRowMinor_10_14";
            } else if (caseStatus === "Client Terminated") {
                css.backgroundColor = 'rgba(0, 0, 0, 0.849) !important';
                css.color = 'rgb(116, 116, 255) !important';
                css['&:hover'] = {
                    backgroundColor: 'rgba(0, 0, 0, 0.932) !important',
                    color: 'rgb(116, 116, 255) !important',
                }
                // return "terminatedRowMinor_10_14";
            } else if (caseStatus === "Withdrawn") {
                css.backgroundColor = 'rgba(0, 0, 0, 0.658) !important';
                css.color = 'rgb(116, 116, 255) !important';
                css['&:hover'] = {
                    backgroundColor: 'rgba(0, 0, 0, 0.623) !important',
                    color: 'rgb(116, 116, 255) !important',
                }
                // return "withdrawnRowMinor_10_14";
            } else if (caseStatus === "") {
                css.color = 'rgb(116, 116, 255) !important';
                // return "miscRowMinor_10_14";
            } else {
                css.color = 'rgb(116, 116, 255) !important';
                // return "miscRowMinor_10_14";
            }
        } else if (dateOfBirth.isAfter(seventeenYearsAgo)) {
            if (caseStatus === "Settled") {
                css.backgroundColor = 'rgb(102, 0, 0) !important';
                css.color = 'green !important';
                css['&:hover'] = {
                    backgroundColor: 'rgb(78, 0, 0) !important',
                    color: 'green !important',
                }
                // return "settledRowMinor_15_17";
            } else if (caseStatus === "Negotiation") {
                css.backgroundColor = 'rgba(255, 115, 0, 0.582) !important';
                css.color = 'green !important';
                css['&:hover'] = {
                    backgroundColor: 'rgba(255, 193, 136, 0.932) !important',
                    color: 'green !important',
                }
                // return "negotiationRowMinor_15_17";
            } else if (caseStatus === "Disbursed") {
                css.backgroundColor = '#c2e6ca !important';
                css.color = 'red !important';
                css['&:hover'] = {
                    backgroundColor: '#b1dfbb !important',
                    color: 'red !important',
                }
                // return "disbursedRowMinor_15_17";
            } else if (caseStatus === "Hold Harmless") {
                css.backgroundColor = 'rgba(0, 140, 255, 0.486) !important';
                css.color = 'green !important';
                css['&:hover'] = {
                    backgroundColor: 'rgba(91, 189, 255, 0.541) !important',
                    color: 'green !important',
                }
                // return "holdHarmlessRowMinor_15_17";
            } else if (caseStatus === "Client Terminated") {
                css.backgroundColor = 'rgba(0, 0, 0, 0.849) !important';
                css.color = 'green !important';
                css['&:hover'] = {
                    backgroundColor: 'rgba(0, 0, 0, 0.932) !important',
                    color: 'green !important',
                }
                // return "terminatedRowMinor_15_17";
            } else if (caseStatus === "Withdrawn") {
                css.backgroundColor = 'rgba(0, 0, 0, 0.658) !important';
                css.color = 'rgb(0, 197, 0) !important';
                css['&:hover'] = {
                    backgroundColor: 'rgba(0, 0, 0, 0.623) !important',
                    color: 'rgb(0, 196, 0) !important',
                }
                // return "withdrawnRowMinor_15_17";
            } else if (caseStatus === "") {
                css.color = 'green !important';
                // return "miscRowMinor_15_17";
            } else {
                css.color = 'green !important';
                // return "miscRowMinor_15_17";
            }
        }
    }

    if (caseStatus === "Settled") {
        css.backgroundColor = 'rgb(102, 0, 0) !important';
        css.color = 'rgb(255, 255, 255) !important';
        css['&:hover'] = {
            backgroundColor: 'rgb(78, 0, 0) !important',
            color: 'rgb(255, 255, 255) !important',
        }
        // return "settledRow"
    }

    if (caseStatus === "Negotiation") {
        css.backgroundColor = 'rgba(255, 115, 0, 0.582) !important';
        css.color = 'rgb(0, 0, 0) !important';
        css['&:hover'] = {
            backgroundColor: 'rgba(255, 193, 136, 0.932) !important',
            color: 'rgb(0, 0, 0) !important',
        }
        // return "negotiationRow"
    }

    // if (caseStatus === "Disbursed") return "table-success";

    if (caseStatus === "Hold Harmless") {
        css.backgroundColor = 'rgba(0, 140, 255, 0.486) !important';
        css.color = 'rgb(0, 0, 0) !important';
        css['&:hover'] = {
            backgroundColor: 'rgba(91, 189, 255, 0.541) !important',
            color: 'rgb(0, 0, 0) !important',
        }
        // return "holdHarmlessRow"
    }

    if (caseStatus === "Client Terminated") {
        css.backgroundColor = 'rgba(0, 0, 0, 0.849) !important';
        css.color = 'white !important';
        css['&:hover'] = {
            backgroundColor: 'rgba(0, 0, 0, 0.932) !important',
            color: 'white !important',
        }
        // return "terminatedRow"
    }

    if (caseStatus === "Withdrawn") {
        css.backgroundColor = 'rgba(0, 0, 0, 0.658) !important';
        css.color = 'white !important';
        css['&:hover'] = {
            backgroundColor: 'rgba(0, 0, 0, 0.623) !important',
            color: 'white !important',
        }
        // return "withdrawnRow"
    }

    return css;
}
