import {FormProvider, type SubmitHandler, useForm} from 'react-hook-form';
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem} from "@mui/material";
import {RhfTextField} from "mui-rhf-integration";
import {zodResolver} from "@hookform/resolvers/zod";
import {type BaseSyntheticEvent, useCallback, useState} from "react";
import useEmailPinToClient from "@/hooks/useEmailPinToClient.ts";
import {z} from "zod";
import {useSnackbar} from "notistack";
import {useFetchMrtEmailsForPerson} from "@/queries/useFetchMrtEmailsForPerson.ts";

const emailPinSchema = z.object({
    email: z.string(),
});
export type EmailFormValues = z.infer<typeof emailPinSchema>;

type Props = {
    caseIdUUID: string;
    personUUID: string;
}

const EmailPinToClient = ({caseIdUUID, personUUID} : Props) => {
    const {enqueueSnackbar} = useSnackbar();
    const emailPinToClient = useEmailPinToClient();
    const [open, setOpen] = useState(false);

    const {
        data,
        isError,
        isLoading,
    } = useFetchMrtEmailsForPerson({
        enabled: open,
        personUUID: personUUID,
        columnFilters: [],
        globalFilter: '',
        pagination: {
            pageIndex: 0,
            pageSize: 100
        },
        sorting: [],
    });

    const form = useForm<EmailFormValues>({
        resolver: zodResolver(emailPinSchema),
    });

    const handleSubmit = useCallback(
        async (values: EmailFormValues) => {
            if (window.confirm(`Warning: You are about to send an automated email to the client (${values.email}) containing their PIN. Is the client aware they will be receiving this confidential email, are you sure you want to continue?`)) {
                const response = await emailPinToClient({
                    caseUUID: caseIdUUID,
                    email: values.email,
                });

                if (response?.status === 200) {
                    enqueueSnackbar('Email sent successfully', { variant: 'success' });
                    setOpen(false);
                    form.reset(form.getValues());
                    form.setValue('email', '');
                    form.clearErrors();
                } else {
                    enqueueSnackbar('Error sending email', { variant: 'error' });
                }
            }
        },
        [caseIdUUID, emailPinToClient, enqueueSnackbar],
    );

    const onSubmit: SubmitHandler<EmailFormValues> = async (
        data: EmailFormValues,
        event?: BaseSyntheticEvent
    ) => {
        handleSubmit(data);
        event?.stopPropagation();
    }

    return <>
        <Button
            variant={'contained'}
            onClick={() => {
                setOpen(true);
            }}
        >
            Email PIN to client
        </Button>
        {open && isError && <Box>Error loading data</Box>}
        {open && !isLoading && data && <Dialog open={open} onClose={() => setOpen(false)} maxWidth="xs" fullWidth>
            <DialogTitle>Send PIN to Client</DialogTitle>
            <DialogContent dividers>
                <FormProvider {...form}>
                    <form onSubmit={form.handleSubmit(onSubmit)}>
                        <Box sx={{mb: 4}}>
                            Please select the client email you want the PIN to
                            be sent to:
                        </Box>
                        <RhfTextField
                            select
                            control={form.control}
                            name={'email'}
                            label={'Client Email'}
                            sx={{
                                width: '100%',
                                backgroundColor: '#fff',
                            }}
                        >
                            {data?.map(emailAddress => <MenuItem
                                key={`incident-state-${emailAddress.idUUID}`}
                                value={`${emailAddress.emailAddress}`}
                            >
                                {`${emailAddress.emailAddress} - ${emailAddress.emailType}`}
                            </MenuItem>)}
                        </RhfTextField>
                    </form>
                </FormProvider>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {
                    setOpen(false);
                    form.reset(form.getValues());
                }}>Close</Button>
                <Button
                    variant={'contained'}
                    onClick={form.handleSubmit(onSubmit)}
                >
                    Send PIN
                </Button>
            </DialogActions>
        </Dialog>}
    </>
};

export default EmailPinToClient;
