import {keepPreviousData, useQuery, type UseQueryResult} from "@tanstack/react-query";
import useAuthenticatedFetch from "@/hooks/useAuthenticatedFetch.ts";
import {apiUrl} from "@/utils/api.ts";
import {createDataSelector, createResourceCollectionSelector} from "jsonapi-zod-query";
import type {MRT_ColumnFiltersState, MRT_PaginationState, MRT_SortingState} from "material-react-table";
import {emailAddressSchema} from "@/components/Forms/schema/emailAddressSchema.ts";

const resourceSelector = createResourceCollectionSelector({
    type: "emailAddress",
    attributesSchema: emailAddressSchema,
});

const companionSelector = createDataSelector(resourceSelector);

export type EmailAddresses = ReturnType<typeof companionSelector>;
export type MrtEmailAddress = EmailAddresses[number];

type Props = {
    personUUID : string;
    columnFilters : MRT_ColumnFiltersState;
    globalFilter : string;
    pagination : MRT_PaginationState;
    sorting : MRT_SortingState;
    enabled ?: boolean;
};

export const useFetchMrtEmailsForPerson = (
    {
        personUUID,
        columnFilters,
        globalFilter,
        pagination,
        sorting,
        enabled = true,
    } : Props
) : UseQueryResult<EmailAddresses> => {
    const fetch = useAuthenticatedFetch();

    return useQuery({
        enabled: enabled,
        queryKey: [
            'person-email-table-data',
            personUUID,
            columnFilters,
            globalFilter,
            pagination.pageIndex,
            pagination.pageSize,
            sorting,
        ],
        queryFn: async () => {
            const fetchURL = apiUrl('/person/email/for-person');

            const response = await fetch(fetchURL.href, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    personUUID: personUUID,
                    start: pagination.pageIndex * pagination.pageSize,
                    size: pagination.pageSize,
                    filters: columnFilters ?? [],
                    globalFilter: globalFilter ?? '',
                    sorting: sorting ?? [],
                }),
            });

            return await response.json();
        },
        select: companionSelector,
        placeholderData: keepPreviousData,
    });
};
