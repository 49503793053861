import useAuthenticatedFetch from "@/hooks/useAuthenticatedFetch.ts";
import {keepPreviousData, useQuery, type UseQueryResult} from "@tanstack/react-query";
import {apiUrl} from "@/utils/api.ts";
import {createDataSelector, createResourceCollectionSelector} from "jsonapi-zod-query";
import {Dayjs} from "dayjs";
import {caseFormSchemaInput, clientSchema} from "@/components/Forms/schema/caseFormSchemaInput.ts";
import {dayjsToLocalDate} from "@/utils/datetime.ts";
import {personSchema} from "@/components/Forms/schema/personSchema.ts";

const resourceSelector = createResourceCollectionSelector({
    type: "case",
    attributesSchema: caseFormSchemaInput,
    relationships: {
        idClient: {
            relationshipType: "one_nullable",
            include: {
                type: "client",
                attributesSchema: clientSchema,
            },
        },
        idPeople: {
            relationshipType: "one_nullable",
            include: {
                type: "person",
                attributesSchema: personSchema,
            },
            required: false,
        },
    },
});

export const casesSelector = createDataSelector(resourceSelector);
export type ReferralsReportCases = ReturnType<typeof casesSelector>;

export type ReferralsReportCasesDocument = ReturnType<typeof resourceSelector>;
export type ReferralsReportCase = ReferralsReportCasesDocument['data'][number];

type Props = {
    isQueryEnabled ?: boolean;
    startDate: Dayjs | null;
    endDate: Dayjs | null;
};

export const useFetchReportReferrals = (
    {
        isQueryEnabled,
        startDate,
        endDate,
    }: Props) : UseQueryResult<ReferralsReportCases> => {
    const fetch = useAuthenticatedFetch();

    return useQuery({
        queryKey: [
            'report-last-touch',
        ],
        enabled: isQueryEnabled,
        queryFn: async () => {
            const response = await fetch(apiUrl('/report/referrals'), {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    startDate: startDate ? dayjsToLocalDate(startDate) : null,
                    endDate: endDate ? dayjsToLocalDate(endDate) : null,
                })
            });

            return await response.json()
        },
        select: casesSelector,
        placeholderData: keepPreviousData,
    });
};
