import {z} from "zod";

export const addLiabilitySchema = z.object({
    idUUID: z.string().nullable().optional(),
    idCaseID: z.string().nullable().optional(),
    idRoleID: z.string().nullable().optional(),
    adjusterPhone: z.string().nullable().optional(),
    adjusterEmail: z.string().nullable().optional(),
    adjusterAddress: z.string().nullable().optional(),
    claimNumber: z.string().nullable(),
    idCompanyID: z.string().nullable(),
    idAdjuster: z.string().nullable().optional(),
    policyPersonCoverageMM: z.string().nullable().optional(),
    policyVehiculeCoverageMM: z.string().nullable().optional(),
    policyInsuredName: z.string().nullable().optional(),
    policyDriverName: z.string().nullable().optional(),
    formLimits: z.string().nullable().optional(),
});
