import {useQuery, type UseQueryResult} from "@tanstack/react-query";
import useAuthenticatedFetch from "@/hooks/useAuthenticatedFetch.ts";
import {apiUrl} from "@/utils/api.ts";
import {createResourceSelector,} from "jsonapi-zod-query";
import {caseFormSchemaInput} from "@/components/Forms/schema/caseFormSchemaInput.ts";

const resourceSelector = createResourceSelector({
    type: "case",
    attributesSchema: caseFormSchemaInput,
});

export type Case = ReturnType<typeof resourceSelector>;

type Props = {
    isQueryEnabled: boolean
    caseUUID: string
}
export const useFetchCaseByUUID = (
{
    isQueryEnabled,
    caseUUID,
} : Props): UseQueryResult<Case> => {
    const fetch = useAuthenticatedFetch();

    return useQuery({
        enabled: isQueryEnabled,
        queryKey: ["case-uuid", { caseUUID }],
        queryFn: async ({ signal }) => {
            const url = apiUrl(`/case/by-uuid/${caseUUID}`);

            const response = await fetch(url, {
                signal,
                headers: {
                    accept: "application/vnd.api+json",
                },
            });

            return await response.json();
        },
        select: resourceSelector,
    });
};
