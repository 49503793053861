import {keepPreviousData, useQuery, type UseQueryResult} from "@tanstack/react-query";
import useAuthenticatedFetch from "@/hooks/useAuthenticatedFetch.ts";
import {apiUrl} from "@/utils/api.ts";
import {createResourceSelector} from "jsonapi-zod-query";
import {personSchema} from "@/components/Forms/schema/personSchema.ts";

const resourceSelector = createResourceSelector({
    type: "person",
    attributesSchema: personSchema,
});

export type Person = ReturnType<typeof resourceSelector>;

type Props = {
    personUUID : string
    isQueryEnabled: boolean
}
export const useFetchPersonUnMasked = (
    {
        personUUID,
        isQueryEnabled
    } : Props) : UseQueryResult<Person> => {
    const fetch = useAuthenticatedFetch();

    return useQuery({
        enabled: isQueryEnabled,
        queryKey: [],
        queryFn: async () => {
            const fetchURL = apiUrl(`/person/unmasked/${personUUID}`);

            const response = await fetch(fetchURL.href, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            return await response.json();
        },
        select: resourceSelector,
        placeholderData: keepPreviousData,
    });
};
