import useAuthenticatedFetch from "@/hooks/useAuthenticatedFetch.ts";
import {keepPreviousData, useQuery, type UseQueryResult} from "@tanstack/react-query";
import type {MRT_ColumnFiltersState, MRT_PaginationState, MRT_SortingState,} from 'material-react-table';
import {apiUrl} from "@/utils/api.ts";
import {caseResourceSelector, DashboardCasesDocument} from "@/queries/useFetchMrtDashboardCases.ts";

type Props = {
    isQueryEnabled ?: boolean;
    selectedTab ?: string;
    columnFilters : MRT_ColumnFiltersState;
    globalFilter : string;
    pagination : MRT_PaginationState;
    sorting : MRT_SortingState;
};

export const useFetchMrtCases = (
    {
        isQueryEnabled = true,
        selectedTab = '',
        columnFilters,
        globalFilter,
        pagination,
        sorting,
    } : Props
) : UseQueryResult<DashboardCasesDocument> => {
    const fetch = useAuthenticatedFetch();

    return useQuery({
        queryKey: [
            'case-table-data',
            columnFilters,
            globalFilter,
            pagination.pageIndex,
            pagination.pageSize,
            sorting,
        ],
        enabled: isQueryEnabled,
        queryFn: async () => {
            const requestBody = {
                start: pagination.pageIndex * pagination.pageSize,
                size: pagination.pageSize,
                filters: columnFilters ?? [],
                globalFilter: globalFilter ?? '',
                sorting: sorting ?? [],
                selectedTab: selectedTab ?? '',
            };

            const response = await fetch(apiUrl('/case'), {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody)
            });

            return await response.json()
        },
        select: caseResourceSelector,
        placeholderData: keepPreviousData,
    });
};
