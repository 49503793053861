import {AppBar, Link, Stack, Typography} from '@mui/material';
import {CaseData} from "@/queries/useFetchCase.ts";
import {isMinor} from "@/functions/IsMinor.tsx";
import {useFetchPrimaryEmail} from "@/queries/useFetchPrimaryEmail.ts";
import {useFetchPrimaryPhoneNumber} from "@/queries/useFetchPrimaryPhoneNumber.ts";

type Props = {
    caseId: string;
    caseData: CaseData;
}

const CaseFooter = ({caseId, caseData} : Props) => {
    const dateOfBirth = caseData.idPeople?.dateofbirth ?? null;

    const {
        data,
    } = useFetchPrimaryEmail({
        personId: caseData.idPeople?.idUUID ?? null,
        isQueryEnabled: !!caseData.idPeople?.idUUID
    });

    const {
        data: phoneData,
    } = useFetchPrimaryPhoneNumber({
        personId: caseData.idPeople?.idUUID ?? null,
        isQueryEnabled: !!caseData.idPeople?.idUUID
    });

    const firstPrimary = data?.data.emailAddress

    return (
        <AppBar
            position="fixed"
            sx={{
                top: 'auto',
                bottom: 0,
                left: {xs: 0, md: 240},
                backgroundColor: '#343a41',
                p: 1,
                width: {
                    sx: '100%',
                    md: `calc(100% - 240px)`,
                },
            }}
        >
            <Stack
                sx={{
                    flexDirection: {xs: 'column', md: 'row'},
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <Typography variant="body1" component="div" sx={{color: '#909091'}}>
                    Case #: {caseId}
                </Typography>
                <Typography variant="body1" component="div" sx={{color: '#909091'}}>
                    Phone #: {phoneData?.data.phoneNumber}
                </Typography>
                <Typography variant="body1" component="div" sx={{color: 'red', display: {xs: 'none', md: 'block'}}}>
                    Emergency Contact: {caseData.idPeople?.emergencyContactName1}
                    {caseData.idPeople?.emergencyContactPhone1 ? ` : ${caseData.idPeople?.emergencyContactPhone1}` : ''}
                </Typography>
            </Stack>
            <Stack
                sx={{
                    flexDirection: {xs: 'column', md: 'row'},
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <Typography variant="body1" component="div" sx={{color: '#909091'}}>
                    {`Client Name: ${caseData.clientName} (${caseData.metaCreatedByPretty} // ${caseData.metaCreatedWhen?.toLocaleString()})`}
                </Typography>

                {firstPrimary && <Typography variant="body1" component="div" sx={{color: '#909091'}}>
                    <Link href={`mailto:${firstPrimary}`}>Personal : {firstPrimary}</Link>
                </Typography>}

                <Typography variant="body1" component="div" sx={{color: 'red', display: {xs: 'block', md: 'none'}}}>
                    Emergency Contact: {caseData.idPeople?.emergencyContactName1}
                    {caseData.idPeople?.emergencyContactPhone1 ? `: ${caseData.idPeople?.emergencyContactPhone1}` : ''}
                </Typography>
                <Typography variant="body1" component="div" sx={{color: 'red'}}>
                    Emergency Contact: {caseData.idPeople?.emergencyContactName2}
                    {caseData.idPeople?.emergencyContactPhone2 ? `: ${caseData.idPeople?.emergencyContactPhone2}` : ''}
                </Typography>
            </Stack>
            {isMinor(dateOfBirth) && caseData.idPeople && <Stack
                sx={{
                    flexDirection: {xs: 'column', md: 'row'},
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <Typography variant="body1" component="div" sx={{color: '#a5cedb'}}>
                    {`Legal Guardian Contact: ${caseData.idPeople.legalGuardianName}: ${caseData.idPeople.legalGuardianPhone}`}
                </Typography>
            </Stack>}
        </AppBar>
    );
};

export default CaseFooter;
