import {keepPreviousData, useQuery, type UseQueryResult} from "@tanstack/react-query";
import useAuthenticatedFetch from "@/hooks/useAuthenticatedFetch.ts";
import {apiUrl} from "@/utils/api.ts";
import {createResourceCollectionSelector} from "jsonapi-zod-query";
import {companiesForAutocompleteSchema} from "@/components/Forms/schema/companiesForAutocompleteSchema.ts";

const companyTypeSelector = createResourceCollectionSelector({
    type: "company",
    attributesSchema: companiesForAutocompleteSchema,
});

export type Company = ReturnType<typeof companyTypeSelector>;

type Props = {
    searchString: string;
    fieldIdValue : string | null;
    isQueryEnabled ?: boolean;
    subQueryKey ?: string;
};
export const useFetchCompaniesForAutocomplete = (
    {
        searchString,
        fieldIdValue,
        isQueryEnabled = true,
        subQueryKey = 'company-autocomplete',
    } : Props
 ) : UseQueryResult<Company> => {
    const fetch = useAuthenticatedFetch();

    return useQuery({
        queryKey: [
            searchString,
            fieldIdValue,
            subQueryKey
        ],
        enabled: isQueryEnabled,
        queryFn: async () => {
            const fetchURL = apiUrl('/company/search');

            const response = await fetch(fetchURL.href, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/vnd.api+json",
                },
                body: JSON.stringify({
                    searchString: searchString,
                    fieldIdValue: fieldIdValue,
                }),
            });

            return await response.json();
        },
        select: companyTypeSelector,
        placeholderData: keepPreviousData,
    });
};
