import useAuthenticatedFetch from "@/hooks/useAuthenticatedFetch.ts";
import {keepPreviousData, useQuery, type UseQueryResult} from "@tanstack/react-query";
import {apiUrl} from "@/utils/api.ts";
import {createDataSelector, createResourceCollectionSelector} from "jsonapi-zod-query";
import {mrtCaseSchema} from "@/components/Forms/schema/mrtCaseSchema.ts";
import {testPersonSchema} from "@/components/Forms/schema/personSchema.ts";
import {dtMetaSchema} from "@/components/Forms/schema/dtMetaSchema.ts";
import type {MRT_ColumnFiltersState, MRT_PaginationState, MRT_SortingState} from "material-react-table";

const resourceSelector = createResourceCollectionSelector({
    type: "case",
    attributesSchema: mrtCaseSchema,
    documentMetaSchema: dtMetaSchema,
    relationships: {
        idPeople: {
            relationshipType: "one_nullable",
            include: {
                type: "person",
                attributesSchema: testPersonSchema,
            },
            required: true,
        },
    },
});

export const casesSelector = createDataSelector(resourceSelector);
export type SolReportCases = ReturnType<typeof casesSelector>;

export type SolReportCasesDocument = ReturnType<typeof resourceSelector>;
export type SolReportCase = SolReportCasesDocument['data'][number];

type Props = {
    isQueryEnabled : boolean;
    timeframe : number | null;
    columnFilters : MRT_ColumnFiltersState;
    globalFilter : string;
    pagination : MRT_PaginationState;
    sorting : MRT_SortingState;
};

export const useFetchMrtSolReport = (
    {
        isQueryEnabled = true,
        timeframe,
        columnFilters,
        globalFilter,
        pagination,
        sorting,
    } : Props
) : UseQueryResult<SolReportCasesDocument> => {
    const fetch = useAuthenticatedFetch();

    return useQuery({
        queryKey: [
            'case-table-data-sol',
            columnFilters,
            globalFilter,
            pagination.pageIndex,
            pagination.pageSize,
            sorting,
        ],
        enabled: isQueryEnabled,
        queryFn: async () => {
            const requestBody = {
                start: pagination.pageIndex * pagination.pageSize,
                size: pagination.pageSize,
                filters: columnFilters ?? [],
                globalFilter: globalFilter ?? '',
                sorting: sorting ?? [],
                timeframe: timeframe,
            };

            const response = await fetch(apiUrl('/report/sol'), {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody)
            });

            return await response.json()
        },
        select: resourceSelector,
        placeholderData: keepPreviousData,
    });
};
