import {useParams} from "react-router-dom";
import type {ReactNode} from "react";
import CaseForm from "@/components/Forms/CaseForm.tsx";

const CaseDetail = (): ReactNode => {
    const { caseId } = useParams<{ caseId: string }>();

    if (!caseId) {
        return <div>No case ID provided</div>;
    }

    return <CaseForm caseId={caseId} />;
};

export default CaseDetail;
