import {FormProvider, type SubmitHandler, useForm} from 'react-hook-form';
import {z} from 'zod';
import {zodResolver} from '@hookform/resolvers/zod';
import {CircularProgress, Grid2 as Grid} from "@mui/material";
import {type BaseSyntheticEvent, useCallback, useEffect, useState} from "react";
import {useFetchCaseStatus} from "@/queries/useFetchCaseStatus.ts";
import {useSaveCaseStatus} from "@/mutations/useSaveCaseStatus.tsx";
import {caseStatusSchema} from "@/components/Forms/schema/caseStatusSchema.ts";
import CaseStatusInformation from "@/components/CaseStatusInformation.tsx";
import {enqueueSnackbar} from "notistack";
import {getErrorMessage} from "@/utils/api.ts";

export type CaseStatusFormValues = z.infer<typeof caseStatusSchema>;

const massageLocalDateToDayjsDates = (data: Record<string, any>) => {
    return {
        ...data.data,
        order: data.data.order ? data.data.order.toString() : null,
    }
}

type Props = {
    caseStatusUUID: string;
}

const CaseStatusForm = ({caseStatusUUID} : Props) => {
    const saveCaseStatus = useSaveCaseStatus(caseStatusUUID);
    const [foundCaseStatusIdUUID, setFoundCaseStatusIdUUID] = useState<string | null>(null);

    const {
        data,
        isError,
        isRefetching,
        isLoading,
        isPending,
        error,
    } = useFetchCaseStatus(caseStatusUUID);

    const form = useForm<CaseStatusFormValues>({
        resolver: zodResolver(caseStatusSchema),
    });

    const handleSubmit = useCallback(
        (values: CaseStatusFormValues) => {
            if (foundCaseStatusIdUUID) {
                saveCaseStatus.mutate(
                    {
                        ...values,
                        order: values.order ? parseInt(values.order) : null,
                    },
                    {
                        onSuccess: () => {
                            enqueueSnackbar("CaseStatus saved.", { variant: "success" });
                            form.reset(form.getValues());
                        },
                        onError: (error) => {
                            enqueueSnackbar("Error: CaseStatus failed to save.", { variant: "error" });
                            console.info('error', getErrorMessage(error));
                        },
                    },
                );
            }
        },
        [saveCaseStatus, foundCaseStatusIdUUID],
    );

    const onSubmit: SubmitHandler<CaseStatusFormValues> = async (
        data: CaseStatusFormValues,
        event?: BaseSyntheticEvent
    ) => {
        event?.stopPropagation();
        handleSubmit(data);
    }

    useEffect(() => {
        if (!isError && !isRefetching && !isLoading && data) {
            form.reset(massageLocalDateToDayjsDates(data));

            if (data.data.idUUID) {
                setFoundCaseStatusIdUUID(data.data.idUUID);
            }
        }
    }, [data, isError, isRefetching, isLoading]);

    if (isPending) {
        return null;
    }

    if (isError) {
        return (
            <div>{error.message}</div>
        );
    }

    if (isLoading || isRefetching) {
        return (
            <CircularProgress/>
        );
    }

    if (error) {
        return (
            <div>{error}</div>
        );
    }

    return <>
        <FormProvider {...form}>
            <form onSubmit={
                form.handleSubmit(onSubmit)
            }>
                {foundCaseStatusIdUUID && <Grid container>
                    <Grid size={12}>
                        <CaseStatusInformation form={form} />
                    </Grid>
                </Grid>}
            </form>
        </FormProvider>
    </>
};

export default CaseStatusForm;
