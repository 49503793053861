import {keepPreviousData, useQuery, type UseQueryResult} from "@tanstack/react-query";
import useAuthenticatedFetch from "@/hooks/useAuthenticatedFetch.ts";
import {apiUrl} from "@/utils/api.ts";
import {createDataSelector, createResourceCollectionSelector} from "jsonapi-zod-query";
import {companionSchema} from "@/components/Forms/schema/companionSchema.ts";
import {MRT_ColumnFiltersState, MRT_PaginationState, MRT_SortingState} from "material-react-table";
import {z} from "zod";

export const companionCaseSchemaInput = z.object({
    id: z.number(),
    idUUID: z.string(),
    clientName: z.string().nullable(),
    caseName: z.string().nullable(),
});

export type CompanionCase = z.infer<typeof companionCaseSchemaInput>

const resourceSelector = createResourceCollectionSelector({
    type: "companion",
    attributesSchema: companionSchema,
    relationships: {
        idCase1: {
            relationshipType: "one",
            include: {
                type: "case",
                attributesSchema: companionCaseSchemaInput,
            },
        },
        idCase2: {
            relationshipType: "one",
            include: {
                type: "case",
                attributesSchema: companionCaseSchemaInput,
            },
        },
    }
});

const companionSelector = createDataSelector(resourceSelector);

export type Companions = ReturnType<typeof companionSelector>;
export type MrtCompanion = Companions[number];

type Props = {
    caseId : string;
    columnFilters : MRT_ColumnFiltersState;
    globalFilter : string;
    pagination : MRT_PaginationState;
    sorting : MRT_SortingState;
};

export const useFetchMrtCompanionForCase = (
    {
        caseId,
        columnFilters,
        globalFilter,
        pagination,
        sorting,
    } : Props
) : UseQueryResult<Companions> => {
    const fetch = useAuthenticatedFetch();

    return useQuery({
        queryKey: [
            'case-companion-table-data',
            caseId,
            columnFilters,
            globalFilter,
            pagination.pageIndex,
            pagination.pageSize,
            sorting,
        ],
        queryFn: async () => {
            const fetchURL = apiUrl('/companion/for-case');

            const response = await fetch(fetchURL.href, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    caseId: caseId,
                    start: pagination.pageIndex * pagination.pageSize,
                    size: pagination.pageSize,
                    filters: columnFilters ?? [],
                    globalFilter: globalFilter ?? '',
                    sorting: sorting ?? [],
                }),
            });

            return await response.json();
        },
        select: companionSelector,
        placeholderData: keepPreviousData,
    });
};
