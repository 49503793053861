import {z} from "zod";

export const noteSchema = z.object({
    id: z.string().nullable(),
    idUUID: z.string().nullable(),
    idrecIDNote : z.number().nullable(),
    idGlobalUUID: z.string().nullable(),
    note: z.string().nullable(),
    metaCreatedBy: z.string().nullable(),
    metaCreatedByPretty: z.string().nullable(),
    metaCreatedWhen : z.coerce.date().nullable(),
    idClient: z.string().nullable(),
    metaModifiedBy: z.string().nullable(),
    metaModifiedByPretty: z.string().nullable(),
    metaModifiedWhen : z.coerce.date().nullable(),
    detailurl: z.string().nullable(),
    idCreatedBy: z.string().nullable(),
    tag: z.string().nullable(),
    noteBy: z.string().nullable(),
    noteCss: z.string().nullable(),
    noteType: z.string().nullable(),
});
