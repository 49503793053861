import useAuthenticatedFetch from "@/hooks/useAuthenticatedFetch.ts";
import {apiUrl} from "@/utils/api.ts";
import {useMutation, type UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {handleJsonApiError} from "jsonapi-zod-query";

export const useDeletePersonEmail = (): UseMutationResult<void, Error, { emailUUID: string }> => {
    const fetch = useAuthenticatedFetch();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async ({ emailUUID }) => {
            const response = await fetch(apiUrl(`/person/email/delete`), {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/vnd.api+json",
                },
                body: JSON.stringify({
                    emailUUID: emailUUID,
                }),
            });
            await handleJsonApiError(response);
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: ["person-email-table-data"],
            });
        },
    });
};
