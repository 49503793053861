import useAuthenticatedFetch from "@/hooks/useAuthenticatedFetch.ts";
import {apiUrl} from "@/utils/api.ts";
import {useMutation, type UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {handleJsonApiError} from "jsonapi-zod-query";
import {CompanyRequestSchemaType} from "@/components/Forms/CompanyForm.tsx";

export const useSaveCompany =
    (): UseMutationResult<void, Error, {companyUUID: string} & CompanyRequestSchemaType> => {
    const fetch = useAuthenticatedFetch();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async ({companyUUID, ...attributes}) => {
            const response = await fetch(apiUrl(`/company/save/${companyUUID}`), {
                method: "POST",
                headers: {
                    "Content-Type": "application/vnd.api+json",
                },
                body: JSON.stringify({
                    data: {
                        id: companyUUID,
                        type: "company",
                        attributes: attributes,
                    },
                }),
            });
            await handleJsonApiError(response);
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: ["companies"],
            });
        },
    });
};
