import {useCallback} from 'react';
import {apiUrl} from "@/utils/api.ts";

type Props = {
    caseUUID : string;
    clientName ?: string;
    caseManagerEmail ?: string;
    attorneyEmail ?: string;
    caseNumber ?: string;
    caseStatus ?: string;
    clientNote ?: string;
}

const useSendEmail = () : (data : Props) => Promise<Response> => {
    return useCallback(async (data : Props) : Promise<Response> => {
        const url = apiUrl('/public/email');

        return await fetch(url, {
            method: 'POST',
            headers: {
                "Content-Type": "application/vnd.api+json",
            },
            body: JSON.stringify({
                ...data
            }),
        });
    }, []);
};

export default useSendEmail;
