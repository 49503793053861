import {useParams} from "react-router-dom";
import type {ReactNode} from "react";
import CaseTypeForm from "@/components/Forms/CaseTypeForm.tsx";

const CaseTypeDetail = (): ReactNode => {
    const { caseTypeUUID } = useParams<{ caseTypeUUID: string }>();

    if (!caseTypeUUID) {
        return <div>No case type ID provided</div>;
    }

    return <CaseTypeForm caseTypeUUID={caseTypeUUID} />;
};

export default CaseTypeDetail;
