import {useQuery, type UseQueryResult} from "@tanstack/react-query";
import useAuthenticatedFetch from "@/hooks/useAuthenticatedFetch.ts";
import {apiUrl} from "@/utils/api.ts";
import {createResourceSelector,} from "jsonapi-zod-query";
import {phoneNumberSchema} from "@/components/Forms/schema/phoneNumberSchema.ts";

const phoneSelector = createResourceSelector({
    type: "phoneNumber",
    attributesSchema: phoneNumberSchema,
});

export type PhoneNumber = ReturnType<typeof phoneSelector>;

type Props = {
    personId: string | null;
    isQueryEnabled ?: boolean;
};

export const useFetchPrimaryPhoneNumber = (
    {
        personId,
        isQueryEnabled = true,
    }: Props): UseQueryResult<PhoneNumber> => {
    const fetch = useAuthenticatedFetch();

    return useQuery({
        enabled: isQueryEnabled,
        queryKey: ["phone-number", personId],
        queryFn: async ({ signal }) => {
            const url = apiUrl(`/person/phone-number/primary/${personId}`);

            const response = await fetch(url, {
                signal,
                headers: {
                    accept: "application/vnd.api+json",
                },
            });

            return await response.json();
        },
        select: phoneSelector,
    });
};
