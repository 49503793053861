import type {UseFormReturn} from 'react-hook-form';
import {MenuItem} from "@mui/material";
import {RhfTextField} from "mui-rhf-integration";
import {AddPersonFormValues} from "@/components/Forms/SubForms/AddPersonDialog.tsx";
import {roleDefinitions} from "@/components/Person/Information.tsx";

type Props = {
    form: UseFormReturn<AddPersonFormValues>;
}

const AddPersonRoleSelect = ({form} : Props) => {
    return <RhfTextField
        select
        control={form.control}
        name='role'
        label='Role'
        sx={{
            width: '100%',
            backgroundColor: '#fff',
        }}
    >
        {roleDefinitions?.map((role) => <MenuItem
            key={`role-${role.name}`}
            value={`${role.name}`}
        >
            {role.name}
        </MenuItem>)}
    </RhfTextField>
};

export default AddPersonRoleSelect;
