import {FormControl, Grid2 as Grid, InputLabel, Select} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import {
    MaterialReactTable,
    type MRT_ColumnDef,
    type MRT_ColumnFiltersState,
    type MRT_PaginationState,
    MRT_Row,
    type MRT_SortingState,
    useMaterialReactTable,
} from "material-react-table";
import {useEffect, useMemo, useState} from "react";
import {SolReportCase, useFetchMrtSolReport} from "@/queries/useFetchMrtSolReport.ts";
import {dateFormatterDashed} from "@/utils/mapDateOrStringToLocalDate.ts";
import {getSolReportMrtStyle} from "@/functions/getSolReportMrtStyle.ts";
import {ChronoUnit, LocalDate} from "@js-joda/core";

export type Case = {
    id: string;
    idUUID: string | null;
    clientName: string | null;
    caseType: string | null;
    caseStatus: string | null;
    caseStatusUM: string | null;
    caseName: string | null;
    dateOfIncident: Date | null;
    dateOfIncidentAge: number | null;
    lastTouch: string | null;
    assignedTeam: string | null;

    accidentCaseNumber: string;
    accidentReportDone: string;
    accidentSummary: string;
    ambulanceName: string;
};

const MrtCaseTableSolReport = () => {
    const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState<MRT_SortingState>([]);
    const [pagination, setPagination] = useState<MRT_PaginationState>({
        pageIndex: 0,
        pageSize: 100,
    });
    const [teamName, setTeamName] = useState('');
    const [timeframe, setTimeframe] = useState<string | null>(null);
    const isQueryEnabled = timeframe !== null;

    const {
        data,
        isError,
        isRefetching,
        isLoading,
        refetch,
    } = useFetchMrtSolReport({
        isQueryEnabled: isQueryEnabled,
        timeframe : timeframe ? Number.parseInt(timeframe) : null,
        columnFilters,
        globalFilter,
        pagination,
        sorting,
    });

    const handleRowClick = (row: MRT_Row<SolReportCase>) => {
        window.location.href = `/case-detail/${row.original.id}`;
    };

    const columns = useMemo<MRT_ColumnDef<SolReportCase>[]>(
        () => [
            {
                accessorKey: "id",
                header: "Case #",
                size: 50,
            },
            {
                accessorKey: "clientName",
                header: "Client Name",
            },
            {
                accessorKey: "caseType",
                header: "Type",
            },
            {
                accessorKey: "caseStatus",
                header: "Liability Status",
            },
            {
                accessorKey: "caseStatusUM",
                header: "UM Status",
            },
            {
                accessorKey: "caseName",
                header: "Case Name",
            },
            {
                accessorKey: "dateOfIncident",
                header: "DOI",
                Cell: ({ row }) => {
                    if (row.original.dateOfIncident) {
                        return row.original.dateOfIncident.format(dateFormatterDashed)
                    }

                    return 'blah'
                },
            },
            {
                accessorKey: "dateOfIncidentAge",
                header: "Days Til Sol",
                Cell: ({ row }) => {
                    if (row.original.statuteOfLimitations) {
                        const tomorrow = LocalDate.now();
                        return ChronoUnit.DAYS.between(tomorrow, row.original.statuteOfLimitations);
                    }

                    return ''
                },
            },
            {
                accessorKey: "assignedToAttorneyPretty",
                header: "Attorney",
            },
            {
                accessorKey: "assignedTeam",
                header: "Team",
            },
        ],
        [],
    );

    const table = useMaterialReactTable({
        columns,
        data: (isLoading || isRefetching || isError || data?.data === undefined) ? [] : data?.data,
        initialState: {
            showColumnFilters: false,
            showGlobalFilter: true,
            density: 'compact',
        },

        // top toolbar
        enableTopToolbar: true,
        enableDensityToggle: false,
        enableFullScreenToggle: false,
        enableHiding: false,
        enableColumnFilters: false,

        // server side searching and pagination
        manualFiltering: true,
        manualPagination: true,
        manualSorting: true,
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        onPaginationChange: setPagination,
        onSortingChange: setSorting,

        muiToolbarAlertBannerProps: isError
            ? {
                color: "error",
                children: "Error loading data",
            }
            : undefined,
        rowCount: data?.meta.totalRowCount ?? 0,
        state: {
            columnFilters,
            globalFilter,
            isLoading,
            pagination,
            showAlertBanner: isError,
            showProgressBars: isRefetching,
            showLoadingOverlay: (isLoading || isRefetching),
            sorting,
        },
        enablePagination: !!(data?.meta.totalRowCount && data?.meta.totalRowCount > 100),
        enableBottomToolbar: !!(data?.meta.totalRowCount && data?.meta.totalRowCount > 100),
        muiTableBodyRowProps: ({ row }: { row: MRT_Row<SolReportCase> }) => ({
            onClick: () => handleRowClick(row),
            sx: {
                cursor: "pointer",
                '& .MuiTableCell-root': {
                    ...getSolReportMrtStyle({
                        caseStatus: row.original.caseStatus ?? '',
                        dateOfBirth: row.original.idPeople?.dateofbirth ?? null,
                    })
                },
            },
        }),
    });

    useEffect(() => {
        if (isQueryEnabled) {
            if (teamName) {
                setColumnFilters([{
                    id: 'assignedTeam',
                    value: teamName
                }])
                void refetch();

                return;
            }

            setColumnFilters([])

            if (timeframe) {
                void refetch();
            }
        }
    }, [timeframe, teamName]);

    return <Grid
        container
        sx={{
            width: "100%",
        }}
    >
        <Grid size={{xs:12, md:6}} sx={{py: 1, pr: 1}}>
            <FormControl fullWidth>
                <InputLabel id="team-select-label">Timeframe</InputLabel>
                <Select
                    labelId="timeframe-select-label"
                    id="timeframe-select"
                    value={timeframe ?? ''}
                    label="Timeframe"
                    onChange={(event) => {
                        if (event.target.value) {
                            setTimeframe(event.target.value);
                            return;
                        }

                        setTimeframe(null);
                    }}
                    variant='outlined'
                >
                    <MenuItem value="30">30</MenuItem>
                    <MenuItem value="60">60</MenuItem>
                    <MenuItem value="90">90</MenuItem>
                    <MenuItem value="120">120</MenuItem>
                    <MenuItem value="180">180</MenuItem>
                    <MenuItem value="365">365</MenuItem>
                </Select>
            </FormControl>
        </Grid>
        <Grid size={{xs:12, md:6}} sx={{py: 1, pl: 1}}>
            <FormControl fullWidth>
                <InputLabel id="team-select-label">Team</InputLabel>
                <Select
                    labelId="team-select-label"
                    id="team-select"
                    value={teamName}
                    label="Team"
                    onChange={(event) => {
                        setTeamName(event.target.value as string);
                    }}
                    variant='outlined'
                >
                    <MenuItem value="">All Records</MenuItem>
                    <MenuItem value="CM1">CM1</MenuItem>
                    <MenuItem value="CM2">CM2</MenuItem>
                    <MenuItem value="CM3">CM3</MenuItem>
                </Select>
            </FormControl>
        </Grid>
        <Grid size={{xs:12, md:12}} sx={{py: 1, pl: 1}}>
            <MaterialReactTable table={table} />
        </Grid>
    </Grid>;
};

export default MrtCaseTableSolReport;
