import {useMemo, useState} from 'react';
import {
    MaterialReactTable,
    type MRT_ColumnDef,
    type MRT_ColumnFiltersState,
    type MRT_PaginationState,
    type MRT_SortingState,
    useMaterialReactTable,
} from 'material-react-table';
import {MrtParty, useFetchMrtPartiesForCase} from "@/queries/useFetchMrtPartiesForCase.ts";

type Props = {
    caseIdUUID: string;
    roleName ?: string;
};

const MrtDisbursementsTable = ({caseIdUUID} : Props) => {
    const roleName = ['Disbursement', 'Other'];
    const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
        [],
    );
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState<MRT_SortingState>([]);
    const [pagination, setPagination] = useState<MRT_PaginationState>({
        pageIndex: 0,
        pageSize: 10,
    });

    const {
        data,
        isError,
        isRefetching,
        isLoading,
    } = useFetchMrtPartiesForCase({
        caseIdUUID,
        roleName,
        columnFilters,
        globalFilter,
        pagination,
        sorting,
    });

    const columns = useMemo<MRT_ColumnDef<MrtParty>[]>(
        () => [
            {
                accessorKey: 'idCompanyID',
                header: 'Company',
            },
            {
                accessorKey: 'loanAmount',
                header: 'Loan Amount',
            },
            {
                accessorKey: 'loanDate',
                header: 'Loan Date',
            },
            {
                accessorKey: 'loanPayoffAmount',
                header: 'Payoff Amount',
            },
            {
                accessorKey: 'loanPayoffDate',
                header: 'Payoff Date',
            },
        ], []);

    const table = useMaterialReactTable({
        columns,
        data: (isLoading || isRefetching || isError || data === undefined) ? [] : data.data,
        initialState: {
            showColumnFilters: false,
        },
        enableTopToolbar: false,
        manualFiltering: true,
        manualPagination: true,
        manualSorting: true,
        muiToolbarAlertBannerProps: isError
            ? {
                color: 'error',
                children: 'Error loading data',
            }
            : undefined,
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        onPaginationChange: setPagination,
        onSortingChange: setSorting,
        // renderTopToolbarCustomActions: () => (
        //     <Tooltip arrow title="Refresh Data">
        //         <IconButton onClick={() => refetch()}>
        //             <RefreshIcon />
        //         </IconButton>
        //     </Tooltip>
        // ),
        rowCount: data?.meta?.totalRowCount ?? 0,
        state: {
            columnFilters,
            globalFilter,
            isLoading,
            pagination,
            showAlertBanner: isError,
            showProgressBars: isRefetching,
            showLoadingOverlay: (isLoading || isRefetching),
            sorting,
        },
    });

    return <MaterialReactTable table={table} />;
};

export default MrtDisbursementsTable;
