import {UseFormReturn} from 'react-hook-form';
import {CircularProgress, MenuItem, Paper, Typography} from "@mui/material";
import {RhfTextField} from "mui-rhf-integration";
import {useFetchCaseStatuses} from "@/queries/useFetchCaseStatuses.ts";
import {CasesTableFilter} from "@/components/Table/MrtCaseTable.tsx";

type Props = {
    form: UseFormReturn<CasesTableFilter>;
}

const CaseStatusesForCasesTable = ({form} : Props) => {
    const {
        data,
        isError,
        isRefetching,
        isLoading,
    } = useFetchCaseStatuses('not-used');

    if (isError) {
        return <Paper sx={{
            p: 2,
            borderRadius: '8px',
        }}>
            <Typography variant={'h5'}>Error loading case statuses for cases table</Typography>
        </Paper>
    }

    if (isLoading || isRefetching) {
        return <Paper sx={{
            p: 2,
            borderRadius: '8px',
        }}>
            <CircularProgress size={'12px'}/>
        </Paper>
    }

    return <RhfTextField
        select
        control={form.control}
        name={'caseStatus'}
        label={'Case Status'}
        sx={{
            width: '100%',
            backgroundColor: '#fff',
        }}
    >
        <MenuItem
            component={'li'}
            key={`case-status-blank`}
            value={''}
        >
            All
        </MenuItem>
        {data?.data
            .map((caseStatus) => <MenuItem
                component={'li'}
                key={`caseStatus-${caseStatus.idUUID}`}
                value={caseStatus.name ?? ''}
            >
                {caseStatus.name ?? ''}
            </MenuItem>)}
    </RhfTextField>
};

export default CaseStatusesForCasesTable;
