import FullPageSpinner from "@/components/FullPageSpinner/index.ts";
import {useAuth0} from "@auth0/auth0-react";
import type {ReactNode} from "react";
import {useEffect} from "react";
import AuthError from "./AuthError.tsx";
import useInactivityLogout from "@/hooks/useInactivityLogout.ts";

type Props = {
    children: ReactNode;
};

const AuthGuard = ({ children }: Props): ReactNode => {
    const { isAuthenticated, isLoading, loginWithRedirect, error } = useAuth0();

    useInactivityLogout(4 * 60 * 60 * 1000);

    useEffect(() => {
        if (isLoading || isAuthenticated || error) {
            return;
        }

        void loginWithRedirect();
    }, [isLoading, isAuthenticated, error, loginWithRedirect]);

    if (error) {
        return <AuthError error={error} />;
    }

    if (isAuthenticated) {
        return children;
    }

    return <FullPageSpinner />;
};

export default AuthGuard;
