import {CircularProgress, Grid2 as Grid, Paper, Stack, Typography} from "@mui/material";
import {useFetchMediasForCompany} from "@/queries/useFetchMediaForCompany.ts";
import MediaDocumentsListRaw from "@/components/Documents/MediaDocumentsListRaw.tsx";
import MediaUploadMultiple from "@/components/MediaUploadMultiple.tsx";

type Props = {
    companyUUID: string;
};

const MediaDocumentsCollectionForCompany = ({ companyUUID }: Props) => {
    const {
        data: media,
        isError,
        isRefetching,
        isLoading,
    } = useFetchMediasForCompany({
        companyUUID: companyUUID,
    });

    return (
        <Grid container>
            <Grid size={12}>
                <Paper
                    sx={{
                        mb: 2,
                        mx: 1,
                    }}
                >
                    <Grid
                        container
                        sx={{
                            borderRadius: "8px",
                            p: 1,
                        }}
                    >
                        <Grid size={12} sx={{ p: 1, mb: 1 }}>
                            <Stack
                                sx={{
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Typography variant={"h5"}>Documents</Typography>
                                <MediaUploadMultiple
                                    idGlobalUUID={companyUUID}
                                    forceTag={'Companies'}
                                    category={'Companies'}
                                    description={'Companies'}
                                    resourceName={`Companies/${companyUUID}`}
                                />
                            </Stack>
                            {(isLoading || isRefetching) && <CircularProgress size={"12px"} />}
                            {isError && <Typography variant={"h5"}>Error loading documents</Typography>}
                            {media && (!(isLoading || isRefetching || isError)) && <MediaDocumentsListRaw mediaArray={media}/>}
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
};

export default MediaDocumentsCollectionForCompany;
