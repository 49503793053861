import type React from 'react';
import {useEffect, useMemo, useState} from 'react';
import {CircularProgress, Typography} from '@mui/material';
import type {UseFormReturn} from "react-hook-form";
import {RhfAutocomplete} from "mui-rhf-integration";
import {useFetchPeopleForAutocomplete} from "@/queries/useFetchPeopleForAutocomplete.ts";
import type {AddUiUmFormValues} from "@/components/Forms/AddEditUmUimDialog.tsx";

type Option = {
    id: string | null,
    name: string | null
}

type Props = {
    form: UseFormReturn<AddUiUmFormValues>;
}

const UiUmAdjusterAutocomplete = ({form}: Props) => {
    const [searchString, setSearchString] = useState('');
    const formValue = form.watch('idAdjuster')
    const isQueryEnabled = searchString.length >= 2 || (formValue !== null && formValue !== undefined)

    const {
        data: {data} = {},
        isError,
        isRefetching,
        isLoading,
    } = useFetchPeopleForAutocomplete({
        searchString: searchString,
        fieldIdValue: formValue ?? null,
        isQueryEnabled: isQueryEnabled,
        subQueryKey: 'uium-adjuster-autocomplete'
    });

    const options : Option[] = useMemo(() => {
        if (data) {
            return data.map((person) => ({
                id: person.idUUID,
                name: person.fullName ?? '',
            }));
        }
        return [];
    }, [data]);

    const selectedOption = useMemo(() => {
        return data?.find(option => option.id === formValue);
    }, [data, formValue]);

    const handleInputChange = async (_event: React.SyntheticEvent, newInputValue: string) => {
        if (newInputValue.length >= 2) {
            setSearchString(newInputValue);
        }
    };

    useEffect(() => {
        if (formValue && data) {
            if (selectedOption) {
                if (selectedOption.allAddresses?.length && selectedOption.allAddresses[0].address1) {
                    form.setValue('adjusterAddress', selectedOption.allAddresses[0].address1);
                } else {
                    form.setValue('adjusterAddress', '');
                }

                if (selectedOption.allEmailAddresses?.length && selectedOption.allEmailAddresses[0].emailAddress) {
                    form.setValue('adjusterEmail', selectedOption.allEmailAddresses[0].emailAddress);
                } else {
                    form.setValue('adjusterEmail', '');
                }

                if (selectedOption.allPhoneNumbers?.length && selectedOption.allPhoneNumbers[0].phoneNumber) {
                    form.setValue('adjusterPhone', selectedOption.allPhoneNumbers[0].phoneNumber);
                } else {
                    form.setValue('adjusterPhone', '');
                }
            }
        }
    }, [formValue, selectedOption, data, form.setValue])

    return <>
        {(isLoading || isRefetching) && <CircularProgress/>}
        {isError && <Typography variant={"h5"}>Error loading adjusters</Typography>}
        <RhfAutocomplete
            control={form.control}
            name="idAdjuster"
            options={options}
            loading={(isLoading || isRefetching)}
            onInputChange={handleInputChange}

            textFieldProps={{
                label: 'Adjuster',
            }}

            getOptionLabel={(option : Option | string) => {
                if (typeof option === 'string') {
                    return option;
                }

                return option.name || '';
            }}
            getOptionKey={(option : Option | string) => {
                if (typeof option === 'string') {
                    return option;
                }

                return option.id || '';
            }}
            optionToValue={(option : Option | string) => {
                if (typeof option === 'string') {
                    return option;
                }

                return option.id || '';
            }}
            valueToOption={value => {
                return options.find(option => option.id === value);
            }}
            sx={{
                "& .MuiAutocomplete-inputRoot": {
                    padding: "4px!important"
                },
                "& .MuiAutocomplete-input": {
                    paddingTop: "4px!important",
                    paddingBottom: "4px!important",
                },
            }}
        />
    </>
};

export default UiUmAdjusterAutocomplete;
