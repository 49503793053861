import {Grid2 as Grid, Paper, Typography} from '@mui/material';
import MediaDocumentsListForCase from "@/components/Documents/MediaDocumentsListForCase.tsx";
import AddEditUmUimDialog from "@/components/Forms/AddEditUmUimDialog.tsx";
import MrtUmUimTable from "@/components/Table/MrtUmUimTable.tsx";
import UmUimDeprecatedForm from "@/components/Forms/UmUimDeprecatedForm.tsx";
import {CaseData} from "@/queries/useFetchCase.ts";
import NoteList from "@/components/Documents/NoteList.tsx";

type Props = {
    caseIdUUID: string;
    foundCaseId: string;
    caseData: CaseData;
};

const UmUimTab = ({caseIdUUID, foundCaseId, caseData} : Props) => {
    return <Grid container sx={{pb: {xs: '200px', md: '100px'}}}>
        <Grid size={12}>
            <Paper
                sx={{
                    mb: 2,
                    mx: 1,
                }}
            >
                <Grid
                    container
                    sx={{
                        borderRadius: '8px',
                        p: 1,
                    }}
                >
                    <Grid
                        size={12}
                        sx={{
                            p: 1,
                            mb: 1,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}>
                        <Typography variant={'h5'}>UM/UIM Information</Typography>
                        <AddEditUmUimDialog caseUUID={caseIdUUID}/>
                    </Grid>

                    <Grid size={12} sx={{p: 1, mb: 1}}>
                        <MrtUmUimTable caseIdUUID={caseIdUUID} roleName={['UM - Bodily Injury']}/>
                    </Grid>

                    {caseData.insClaimNumber && <Grid
                        size={12}
                        sx={{
                            mb: 3,
                        }}>
                        <UmUimDeprecatedForm caseUUID={caseIdUUID} foundCaseId={foundCaseId}/>
                    </Grid>}

                    <Grid size={6} sx={{p: 1, mb: 1}}>
                        <NoteList caseIdUUID={caseIdUUID} limitToCategory={'UM/UIM'}/>
                    </Grid>
                    <Grid size={6} sx={{p: 1, mb: 1}}>
                        <MediaDocumentsListForCase
                            caseIdUUID={caseIdUUID}
                            limitToCategory={'UM/UIM'}
                        />
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    </Grid>;
};

export default UmUimTab;
