import {LocalDate, LocalTime} from "@js-joda/core";
import type {Dayjs} from "dayjs";
import dayjs from "dayjs";

export const localTimeToDayJs = (time: LocalTime): Dayjs => dayjs(`1970-01-01T${time.toString()}`);
export const dayJsToLocalTime = (time: Dayjs): LocalTime =>
    LocalTime.of(time.hour(), time.minute(), time.second());

export const localDateToDayjs = (date: LocalDate): Dayjs => dayjs(date.toString());

export const dayjsToLocalDate = (date: Dayjs): LocalDate =>
    LocalDate.of(date.year(), date.month() + 1, date.date());

export const dateToDayjs = (date: Date): Dayjs => dayjs(date.toString());

export const dayjsToDate = (date: Dayjs): Date => date.toDate();
