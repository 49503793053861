import {ChangeEvent, useEffect, useMemo, useRef, useState} from 'react';
import {
    MaterialReactTable,
    type MRT_ColumnDef,
    type MRT_Row,
    type MRT_RowVirtualizer,
    useMaterialReactTable,
} from 'material-react-table';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import {DatePicker} from "@mui/x-date-pickers";
import dayjs, {Dayjs} from "dayjs";
import {ReferralsReportCase, useFetchReportReferrals} from "@/queries/useFetchReportReferrals.ts";
import {DemandDatesReportCases} from "@/queries/useFetchReportDemandDates.ts";
import MrtCaseTableReferralsReport from "@/components/Table/MrtCaseTableReferralsReport.tsx"; //or use your library of choice here

const reportTypes = [
    'Company',
    // 'Person', // disabled for now - it's not enabled on the current site
];

export type ObjectBuilder = {
    name: string;
    referralCases: ReferralsReportCase[];
    activeCount: number;
    rejectedCount: number;
    withdrawnCount: number;
    terminatedCount: number;
    total: number;
};

const MrtReferralsReportVirtualized = () => {
    const [open, setOpen] = useState(false);
    const [dateStart, setDateStart] = useState<Dayjs | null>(dayjs().subtract(1, 'month'));
    const [dateEnd, setDateEnd] = useState<Dayjs | null>(dayjs());
    const [doSearch, setDoSearch] = useState(false);
    const [reportType, setReportType] = useState('Company');
    const [selectedRow, setSelectedRow] = useState<ObjectBuilder>();

    const rowVirtualizerInstanceRef = useRef<MRT_RowVirtualizer>(null);

    const {
        data,
        isError,
        isRefetching,
        isLoading,
        refetch,
    } = useFetchReportReferrals({
        isQueryEnabled: doSearch,
        startDate: dateStart,
        endDate: dateEnd,
    });

    // get a list of unique idReferredByView in data array
    const referralPersonNameArr = useMemo(() => {
        return Array.from(new Set(data?.filter(caseRecord => caseRecord.idReferredByView !== null)
            .filter(caseRecord => caseRecord.idReferredByView !== '')
            .map(caseRecord => caseRecord.idReferredByView)));
    }, [data]);

    const referralCompanyNameArr = useMemo(() => {
        return Array.from(new Set(data?.filter(caseRecord => caseRecord.idReferredByCompanyView !== null)
            // .filter(caseRecord => caseRecord.idReferredByCompanyView !== '')
            .map(caseRecord => caseRecord.idReferredByCompanyView)));
    }, [data]);

    const personReferralArr = useMemo(() => {
        const peopleCollected: ObjectBuilder[] = [];

        if (data) {
            for (let i = 0; i < referralPersonNameArr.length; i++) {
                let objectBuilder: ObjectBuilder = {
                    name: referralPersonNameArr[i] ?? '',
                    referralCases: [],
                    activeCount: 0,
                    rejectedCount: 0,
                    withdrawnCount: 0,
                    terminatedCount: 0,
                    total: 0,
                };

                for (let j = 0; j < data
                    .filter(caseRecord => caseRecord.idReferredByView !== null)
                    .length; j++
                ) {
                    if (data[j]["idReferredByView"] === objectBuilder.name) {
                        if (data[j].caseStatus === "Rejected") {
                            objectBuilder.rejectedCount++;
                        } else if (
                            data[j].caseStatus === "Client Terminated"
                        ) {
                            objectBuilder.terminatedCount++;
                        } else if (
                            data[j].caseStatus === "Withdrawn" ||
                            data[j].caseStatus === "Withdrawal Pending"
                        ) {
                            objectBuilder.withdrawnCount++;
                        } else if (
                            data[j].caseStatus === "New Client" ||
                            data[j].caseStatus === "Treating" ||
                            data[j].caseStatus === "Done Treating" ||
                            data[j].caseStatus === "Demand Ready" ||
                            data[j].caseStatus === "Demand Complete" ||
                            data[j].caseStatus === "Denial" ||
                            data[j].caseStatus === "Negotiation" ||
                            data[j].caseStatus === "Policy Limits" ||
                            data[j].caseStatus === "Hold Harmless" ||
                            data[j].caseStatus === "Disbursed" ||
                            data[j].caseStatus === "Settled" ||
                            data[j].caseStatus === "Escrow" ||
                            data[j].caseStatus === "Mediation" ||
                            data[j].caseStatus === "Litigation" ||
                            data[j].caseStatus === "Litigation Review" ||
                            data[j].caseStatus === "Lead" ||
                            data[j].caseStatus === "Completed"
                        ) {
                            objectBuilder.activeCount++;
                        }

                        objectBuilder.referralCases.push(data[j]);
                        objectBuilder.total++;
                    }
                }

                peopleCollected.sort((a, b) => {
                    if (a.total > b.total) {
                        return -1;
                    }
                    if (a.total < b.total) {
                        return 1;
                    }
                    return 0;
                });

                peopleCollected.push(objectBuilder);
            }
        }

        return peopleCollected;
    }, [data, reportType])

    const companyReferralArr = useMemo(() => {
        const companiesCollected: ObjectBuilder[] = [];

        if (data) {
            for (let i = 0; i < referralCompanyNameArr.length; i++) {
                let objectBuilder: ObjectBuilder = {
                    name: referralCompanyNameArr[i] ?? '',
                    referralCases: [],
                    activeCount: 0,
                    rejectedCount: 0,
                    withdrawnCount: 0,
                    terminatedCount: 0,
                    total: 0,
                };

                for (let j = 0; j < data
                    .filter(caseRecord => caseRecord.idReferredByCompanyView !== null)
                    .length; j++
                ) {
                    if (data[j]["idReferredByCompanyView"] === objectBuilder.name) {
                        if (data[j].caseStatus === "Rejected") {
                            objectBuilder.rejectedCount++;
                        } else if (
                            data[j].caseStatus === "Client Terminated"
                        ) {
                            objectBuilder.terminatedCount++;
                        } else if (
                            data[j].caseStatus === "Withdrawn" ||
                            data[j].caseStatus === "Withdrawal Pending"
                        ) {
                            objectBuilder.withdrawnCount++;
                        } else if (
                            data[j].caseStatus === "New Client" ||
                            data[j].caseStatus === "Treating" ||
                            data[j].caseStatus === "Done Treating" ||
                            data[j].caseStatus === "Demand Ready" ||
                            data[j].caseStatus === "Demand Complete" ||
                            data[j].caseStatus === "Denial" ||
                            data[j].caseStatus === "Negotiation" ||
                            data[j].caseStatus === "Policy Limits" ||
                            data[j].caseStatus === "Hold Harmless" ||
                            data[j].caseStatus === "Disbursed" ||
                            data[j].caseStatus === "Settled" ||
                            data[j].caseStatus === "Escrow" ||
                            data[j].caseStatus === "Mediation" ||
                            data[j].caseStatus === "Pursuing UM" ||
                            data[j].caseStatus === "Outsourced" ||
                            data[j].caseStatus === "Litigation" ||
                            data[j].caseStatus === "Litigation Review" ||
                            data[j].caseStatus === "Lead" ||
                            data[j].caseStatus === "" ||
                            data[j].caseStatus === "Completed"
                        ) {
                            objectBuilder.activeCount++;
                        }

                        if (data[j].assignedToAttorneyPretty == "") {
                            data[j].assignedToAttorneyPretty = "Unassigned";
                        }
                        objectBuilder.referralCases.push(data[j]);
                        objectBuilder.total++;
                    }
                }

                companiesCollected.push(objectBuilder);
            }
        }

        companiesCollected.sort((a, b) => {
            if (a.total > b.total) {
                return -1;
            }
            if (a.total < b.total) {
                return 1;
            }
            return 0;
        });

        return companiesCollected;
    }, [data, reportType])

    const displayRecords = useMemo(() => {
        if (reportType === 'Company') {
            return companyReferralArr;
        } else {
            return personReferralArr;
        }
    }, [reportType, companyReferralArr, personReferralArr])


    const columns = useMemo<MRT_ColumnDef<ObjectBuilder>[]>(
        () => [
            {
                accessorKey: "name",
                header: reportType === 'Company' ? "Referral Name" : 'Name',
                size: 250,
            },
            {
                accessorKey: "activeCount",
                header: "Active",
                size: 150,
            },
            {
                accessorKey: "rejectedCount",
                header: "Rejected",
                size: 150,
            },
            {
                accessorKey: "withdrawnCount",
                header: "Withdrawn",
                size: 150,
            },
            {
                accessorKey: "terminatedCount",
                header: "Terminated",
                size: 150,
            },
            {
                accessorKey: "total",
                header: "Total",
                size: 150,
            },
        ],
        [reportType],
    );

    const table = useMaterialReactTable({
        columns,
        data: (isLoading || isRefetching || isError || displayRecords === undefined) ? [] : displayRecords,

        initialState: {
            showColumnFilters: false,
            showGlobalFilter: false,
            density: 'compact',
        },

        // top toolbar
        enableGlobalFilter: false,
        enableTopToolbar: false,
        enableDensityToggle: false,
        enableFullScreenToggle: false,
        enableHiding: false,
        enableColumnFilters: false,

        // state
        rowCount: data?.length ?? 0,
        enablePagination: false,
        enableBottomToolbar: false,
        state: {
            isLoading,
            showAlertBanner: isError,
            showProgressBars: isRefetching,
            showLoadingOverlay: (isLoading || isRefetching),
        },

        muiToolbarAlertBannerProps: isError
            ? {
                color: 'error',
                children: 'Error loading data',
            }
            : undefined,

        enableRowVirtualization: true,
        muiTableContainerProps: { sx: { maxHeight: '600px' } },
        rowVirtualizerOptions: { overscan: 5 },
        rowVirtualizerInstanceRef: rowVirtualizerInstanceRef,

        muiTableBodyRowProps: ({row}: { row: MRT_Row<ObjectBuilder> }) => ({
            onClick: () => {
                setSelectedRow(row.original)
                setOpen(true)
            },
            sx: { cursor: 'pointer' }
        })
    });

    useEffect(() => {
        if (doSearch) {
            void refetch();
            setDoSearch(false);
        }
    }, [doSearch]);

    const modalData : DemandDatesReportCases = useMemo(() => {
        if (selectedRow?.referralCases) {
            return selectedRow.referralCases;
        }

        return [];
    }, [selectedRow]);

    return <Stack
        sx={{
            flexGrow: 1,
        }}
    >
        <Stack
            sx={{
                flexDirection: 'column',
                alignItems: 'center',
                mb: 1,
            }}
        >
            {isLoading && <div>Loading...</div>}

            <TextField
                select
                value={reportType}
                name={'reportType'}
                label={'Report Type'}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    setReportType(event.target.value);
                }}
                sx={{
                    my: 1,
                    mb: 2,
                    maxWidth: '300px',
                    minWidth: '200px',
                }}
            >
                {reportTypes.map((option) => <MenuItem
                    key={`report-type-${option}`}
                    value={`${option}`}
                >
                    {option}
                </MenuItem>)}
            </TextField>

            <Stack
                sx={{
                    flexDirection: 'row',
                    justifyContent: 'center',
                    mb: 1,
                }}
            >
                <DatePicker
                    label="Start Date"
                    value={dateStart}
                    onChange={(newValue) => setDateStart(newValue)}
                    sx={{
                        mr: 1,
                    }}
                />
                <DatePicker
                    label="End Date"
                    value={dateEnd}
                    onChange={(newValue) => setDateEnd(newValue)}
                    sx={{
                        ml: 1,
                    }}
                />
            </Stack>

            {dateStart && dateEnd && <Button
                variant={'contained'}
                onClick={() => {
                    setDoSearch(true);
                }}
                sx={{
                    mb: 2,
                }}
            >
                Filter
            </Button>}
        </Stack>
        <Box>
            <Typography variant={'body1'} sx={{mb: 1}}>
                {displayRecords.reduce((sum, objectBuilder) => sum + objectBuilder.total, 0)} Referrals
            </Typography>
            <MaterialReactTable table={table} />
        </Box>
        <Dialog open={open} maxWidth="lg">
            {selectedRow && <DialogTitle>Cases</DialogTitle>}
            <DialogContent>
                {data && selectedRow && <MrtCaseTableReferralsReport data={modalData} />}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpen(false)}>Close</Button>
            </DialogActions>
        </Dialog>
    </Stack>
};

export default MrtReferralsReportVirtualized;
