import {useQuery, type UseQueryResult} from "@tanstack/react-query";
import useAuthenticatedFetch from "@/hooks/useAuthenticatedFetch.ts";
import {apiUrl} from "@/utils/api.ts";
import {createResourceSelector} from "jsonapi-zod-query";
import {employeeSchema} from "@/components/Forms/schema/employeeSchema.ts";

const employeeSelector = createResourceSelector({
    type: "employee",
    attributesSchema: employeeSchema,
});

export type CaseForm = ReturnType<typeof employeeSelector>;

export const useFetchEmployee = (employeeId: string): UseQueryResult<CaseForm> => {
    const fetch = useAuthenticatedFetch();

    return useQuery({
        queryKey: ["employee"],
        queryFn: async ({ signal }) => {
            const url = apiUrl(`/employee/employee/${employeeId}`);

            const response = await fetch(url, {
                signal,
                headers: {
                    accept: "application/vnd.api+json",
                },
            });

            return await response.json();;
        },
        select: employeeSelector,
    });
};
