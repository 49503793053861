import {useMemo, useState} from 'react';
import {
    MaterialReactTable,
    type MRT_ColumnDef,
    type MRT_ColumnFiltersState,
    type MRT_PaginationState,
    type MRT_Row,
    type MRT_SortingState,
    useMaterialReactTable,
} from 'material-react-table';
import {MrtPerson, useFetchMrtPeople} from "@/queries/useFetchMrtPeople.ts";
import {IconButton, Stack, Typography} from "@mui/material";
import {useDeletePerson} from "@/mutations/useDeletePerson.tsx";
import {enqueueSnackbar} from "notistack";
import {getErrorMessage} from "@/utils/api.ts";
import DeleteIcon from "@mui/icons-material/Delete";
import {useEmployee} from "@/contexts/EmployeeProvider.tsx";

const MrtPeopleTable = () => {
    const {hasPermission} = useEmployee()
    const deletePerson = useDeletePerson();
    const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
        [],
    );
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState<MRT_SortingState>([]);
    const [pagination, setPagination] = useState<MRT_PaginationState>({
        pageIndex: 0,
        pageSize: 10,
    });

    const {
        data,
        isError,
        isRefetching,
        isLoading,
    } = useFetchMrtPeople({
        columnFilters,
        globalFilter,
        pagination,
        sorting,
    });

    const handleRowClick = (row: MRT_Row<MrtPerson>) => {
        window.location.href = `/person-detail/${row.original.id}`;
    };

    const handleDeleteRow = (row: MRT_Row<MrtPerson>) => {
        if (window.confirm('Are you sure you want to delete this Person?')) {
            deletePerson.mutate(
                {
                    personUUID: row.original.idUUID,
                },
                {
                    onSuccess: () => {
                        enqueueSnackbar("Person removed.", { variant: "success" });
                    },
                    onError: (error) => {
                        enqueueSnackbar("Error: Person could not be removed.", { variant: "error" });
                        console.info('error', getErrorMessage(error));
                    },
                },
            )
        }
    };

    const columns = useMemo<MRT_ColumnDef<MrtPerson>[]>(
        () => {
            const columnDefs: MRT_ColumnDef<MrtPerson>[] = [
                {
                    accessorKey: 'nameFirst',
                    header: 'First Name',
                },
                {
                    accessorKey: 'nameLast',
                    header: 'Last Name',
                },
                {
                    accessorKey: 'primaryPhoneNumber',
                    header: 'Phone Number',
                },
                {
                    accessorKey: 'role',
                    header: 'Role',
                },
                {
                    accessorKey: "dateofbirth",
                    header: "Date Of Birth",
                    Cell: ({ renderedCellValue }) => {
                        return <Stack sx={{flexDirection: 'row', justifyContent: 'flex-start'}}>
                            <Typography sx={{fontSize: 'inherit'}}>{renderedCellValue?.toString()}</Typography>
                        </Stack>
                    },
                },
            ]

            if (hasPermission("delete:person")) {
                columnDefs.push({
                    accessorKey: 'delete',
                    header: 'Delete',
                    size: 50,
                    Cell: ({ row }) => (
                        <IconButton onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                            event.stopPropagation();
                            handleDeleteRow(row)
                        }}>
                            <DeleteIcon />
                        </IconButton>
                    ),
                });
            }

            return columnDefs;
        }, []);

    const table = useMaterialReactTable({
        columns,
        data: (isLoading || isRefetching || isError || data === undefined) ? [] : data.data,

        initialState: {
            showColumnFilters: false,
            showGlobalFilter: true,
            density: 'compact',
        },

        // top toolbar
        enableTopToolbar: true,
        enableDensityToggle: false,
        enableFullScreenToggle: false,
        enableHiding: false,
        enableColumnFilters: false,

        // server side searching and pagination
        manualFiltering: true,
        manualPagination: true,
        manualSorting: true,
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        onPaginationChange: setPagination,
        onSortingChange: setSorting,

        // state
        rowCount: data?.meta.totalRowCount ?? 0,
        state: {
            columnFilters,
            globalFilter,
            isLoading,
            pagination,
            showAlertBanner: isError,
            showProgressBars: isRefetching,
            showLoadingOverlay: (isLoading || isRefetching),
            sorting,
        },

        // design
        muiToolbarAlertBannerProps: isError
            ? {
                color: 'error',
                children: 'Error loading data',
            }
            : undefined,
        muiTableBodyRowProps: ({ row }: { row: MRT_Row<MrtPerson> }) => ({
            onClick: () => handleRowClick(row),
            sx: { cursor: "pointer" },
        }),
    });

    return <MaterialReactTable table={table} />;
};

export default MrtPeopleTable;
