import {ChangeEvent, useEffect, useMemo, useRef, useState} from 'react';
import {
    MaterialReactTable,
    type MRT_ColumnDef,
    type MRT_ColumnFiltersState,
    type MRT_PaginationState,
    type MRT_Row,
    type MRT_RowVirtualizer,
    type MRT_SortingState,
    useMaterialReactTable,
} from 'material-react-table';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem,
    Stack,
    TextField
} from "@mui/material";
import {DatePicker} from "@mui/x-date-pickers";
import dayjs, {Dayjs} from "dayjs";
import {
    DemandDatesReportCase,
    DemandDatesReportCases,
    useFetchReportDemandDates
} from "@/queries/useFetchReportDemandDates.ts";
import {localDateToDayjs} from "@/utils/datetime.ts";
import MrtCaseTableDemandDates from "@/components/Table/MrtCaseTableDemandDates.tsx";

export type ObjectBuilder = {
    name: string;
    objectCountLiability: number;
    objectCountUM: number;
    objectTotal: number;
    casesArr: DemandDatesReportCase[]
};

const statuses = [
    'Filter by Attorney',
    'Filter by CM/Paralegal',
]

const MrLastTouchReportVirtualized = () => {
    const [open, setOpen] = useState(false);
    const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);
    const [globalFilter, setGlobalFilter] = useState("");
    const [sorting, setSorting] = useState<MRT_SortingState>([]);
    const [pagination, setPagination] = useState<MRT_PaginationState>({
        pageIndex: 0,
        pageSize: 100,
    });
    const [filterBy, setFilterBy] = useState('');
    const [dateStart, setDateStart] = useState<Dayjs | null>(dayjs().subtract(1, 'month'));
    const [dateEnd, setDateEnd] = useState<Dayjs | null>(dayjs());

    const [doSearch, setDoSearch] = useState(false);

    const [selectedRow, setSelectedRow] = useState<ObjectBuilder>();

    const rowVirtualizerInstanceRef = useRef<MRT_RowVirtualizer>(null);

    const {
        data,
        isError,
        isRefetching,
        isLoading,
        refetch,
    } = useFetchReportDemandDates({
        isQueryEnabled: doSearch,
        startDate: dateStart,
        endDate: dateEnd,
    });

    const attorneyDisplayData : ObjectBuilder[] = useMemo(() => {
        const demandLiabilityArray : DemandDatesReportCase[] = [];
        const demandLiabilityAttorneyArr: string[] = [];

        if (data) {
            for (const datum of data) {
                if (datum.demandLiabilitySentOn !== null) {
                    let dateConvLiability = localDateToDayjs(datum.demandLiabilitySentOn);

                    if (
                        dateConvLiability.isAfter(dateStart) &&
                        dateConvLiability.isBefore(dateEnd)
                    ) {
                        demandLiabilityArray.push(datum as DemandDatesReportCase);
                    }
                }
            }

            for (const demandLiability of demandLiabilityArray) {
                if (demandLiability.assignedToAttorneyPretty) {
                    const foundAttorney = demandLiabilityAttorneyArr.find(attorney => attorney === demandLiability.assignedToAttorneyPretty);

                    if (!foundAttorney) {
                        demandLiabilityAttorneyArr.push(
                            demandLiability.assignedToAttorneyPretty
                        );
                    }
                }
            }

            let objArr : ObjectBuilder[] = [];

            for (const demandLiabilityAttorney of demandLiabilityAttorneyArr) {
                let objectBuilder : ObjectBuilder = {
                    name: demandLiabilityAttorney,
                    objectCountLiability: 0,
                    objectCountUM: 0,
                    objectTotal: 0,
                    casesArr: [],
                };

                for (const demandLiability of demandLiabilityArray) {
                    let checkSwitch = 0;

                    if (demandLiability.assignedToAttorneyPretty == demandLiabilityAttorney) {
                        if (demandLiability.demandUMSentOn) {
                            objectBuilder.objectCountUM++;
                            checkSwitch++;
                        }

                        if (demandLiability.demandLiabilitySentOn) {
                            objectBuilder.objectCountLiability++;
                            checkSwitch++;
                        }

                        if (checkSwitch > 0) {
                            objectBuilder.casesArr.push(demandLiability);
                        }
                    }
                }
                objectBuilder.objectTotal = objectBuilder.casesArr.length;
                objArr.push(objectBuilder);
            }

            return objArr;
        }

        return []
    }, [data, filterBy]);

    const cmDisplayData : ObjectBuilder[] = useMemo(() => {
        const demandLiabilityArray : DemandDatesReportCase[] = [];
        const demandLiabilityAttorneyArr: string[] = [];

        if (data) {
            for (const datum of data) {
                if (datum.demandLiabilitySentOn !== null) {
                    let dateConvLiability = localDateToDayjs(datum.demandLiabilitySentOn);

                    if (
                        dateConvLiability.isAfter(dateStart) &&
                        dateConvLiability.isBefore(dateEnd)
                    ) {
                        demandLiabilityArray.push(datum as DemandDatesReportCase);
                    }
                }
            }

            for (const demandLiability of demandLiabilityArray) {
                if (demandLiability.assignedTeam) {
                    const foundAttorney = demandLiabilityAttorneyArr.find(attorney => attorney === demandLiability.assignedTeam);

                    if (!foundAttorney) {
                        demandLiabilityAttorneyArr.push(
                            demandLiability.assignedTeam
                        );
                    }
                }
            }

            let objArr : ObjectBuilder[] = [];

            for (const demandLiabilityAttorney of demandLiabilityAttorneyArr) {
                let objectBuilder : ObjectBuilder = {
                    name: demandLiabilityAttorney,
                    objectCountLiability: 0,
                    objectCountUM: 0,
                    objectTotal: 0,
                    casesArr: [],
                };

                for (const demandLiability of demandLiabilityArray) {
                    let checkSwitch = 0;

                    if (demandLiability.assignedTeam == demandLiabilityAttorney) {
                        if (demandLiability.demandUMSentOn) {
                            objectBuilder.objectCountUM++;
                            checkSwitch++;
                        }

                        if (demandLiability.demandLiabilitySentOn) {
                            objectBuilder.objectCountLiability++;
                            checkSwitch++;
                        }

                        if (checkSwitch > 0) {
                            objectBuilder.casesArr.push(demandLiability);
                        }
                    }
                }
                objectBuilder.objectTotal = objectBuilder.casesArr.length;
                objArr.push(objectBuilder);
            }

            return objArr;
        }

        return []
    }, [data, filterBy]);

    const displayData = useMemo(() => {
        if (filterBy === statuses[0]) {
            return attorneyDisplayData;
        } else if (filterBy === statuses[1]) {
            return cmDisplayData;
        }
    }, [cmDisplayData, attorneyDisplayData]);

    const modalData : DemandDatesReportCases = useMemo(() => {
        if (filterBy === statuses[0] && selectedRow) {
            return data?.filter(datum => datum.assignedToAttorneyPretty === selectedRow.name) ?? [];
        } else if (filterBy === statuses[1] && selectedRow) {
            return data?.filter(datum => datum.assignedTeam === selectedRow.name) ?? [];
        }

        return [];
    }, [cmDisplayData, attorneyDisplayData, selectedRow]);

    const columns = useMemo<MRT_ColumnDef<ObjectBuilder>[]>(
        () => [
            {
                accessorKey: "name",
                header: "Name",
                size: 110,
            },
            {
                accessorKey: "objectCountLiability",
                header: "Liability Count",
            },
            {
                accessorKey: "objectCountUM",
                header: "UM Count",
            },
            {
                accessorKey: "objectTotal",
                header: "Total",
            },
        ],
        [],
    );

    useEffect(() => {
        try {
            rowVirtualizerInstanceRef.current?.scrollToIndex?.(0);
        } catch (error) {
            console.error(error);
        }
    }, [sorting]);

    const table = useMaterialReactTable({
        columns,
        data: (isLoading || isRefetching || isError || displayData === undefined) ? [] : displayData,

        initialState: {
            showColumnFilters: false,
            showGlobalFilter: false,
            density: 'compact',
        },

        // top toolbar
        enableGlobalFilter: false,
        enableTopToolbar: false,
        enableDensityToggle: false,
        enableFullScreenToggle: false,
        enableHiding: false,
        enableColumnFilters: false,

        // server side searching and pagination
        manualFiltering: true,
        manualPagination: true,
        manualSorting: true,

        // enablePagination: false,
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        onPaginationChange: setPagination,
        onSortingChange: setSorting,

        // state
        rowCount: attorneyDisplayData?.length ?? 0,
        enablePagination: false,
        enableBottomToolbar: false,
        state: {
            columnFilters,
            globalFilter,
            isLoading,
            pagination,
            showAlertBanner: isError,
            showProgressBars: isRefetching,
            showLoadingOverlay: (isLoading || isRefetching),
            sorting,
        },

        muiToolbarAlertBannerProps: isError
            ? {
                color: 'error',
                children: 'Error loading data',
            }
            : undefined,

        enableRowVirtualization: true,
        muiTableContainerProps: { sx: { maxHeight: '600px' } },
        rowVirtualizerOptions: { overscan: 5 },
        rowVirtualizerInstanceRef: rowVirtualizerInstanceRef,

        muiTableBodyRowProps: ({row}: { row: MRT_Row<ObjectBuilder> }) => ({
            onClick: () => {
                setSelectedRow(row.original)
                setOpen(true)
            },
            sx: { cursor: 'pointer' }
        })
    });

    useEffect(() => {
        if (doSearch) {
            void refetch();
            setDoSearch(false);
        }
    }, [doSearch]);

    return <Stack
        sx={{
            flexGrow: 1,
            width: '100%',
        }}
    >
        <Stack
            sx={{
                flexDirection: 'column',
                alignItems: 'center',
                mb: 1,
            }}
        >
            <TextField
                select
                value={filterBy}
                name={'demandDates'}
                label={'Demand Dates'}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    setFilterBy(event.target.value);
                }}
                sx={{
                    my: 1,
                    width: '240px',
                }}
            >
                {statuses.map((option) => <MenuItem
                    key={`status-${option}`}
                    value={`${option}`}
                >
                    {option}
                </MenuItem>)}
            </TextField>

            {filterBy && <Stack
                sx={{
                    flexDirection: 'row',
                    justifyContent: 'center',
                    my: 2,
                }}
            >
                <DatePicker
                    label="Start Date"
                    value={dateStart}
                    onChange={(newValue) => setDateStart(newValue)}
                    sx={{
                        mr: 1,
                    }}
                />
                <DatePicker
                    label="End Date"
                    value={dateEnd}
                    onChange={(newValue) => setDateEnd(newValue)}
                    sx={{
                        ml: 1,
                    }}
                />
            </Stack>}
            {filterBy && dateStart && dateEnd && <Button
                variant={'contained'}
                onClick={() => {
                    setDoSearch(true);
                }}
                sx={{
                    mb: 2,
                }}
            >
                Filter
            </Button>}
        </Stack>
        <Box>
            <MaterialReactTable table={table} />
        </Box>
        <Dialog open={open} maxWidth="lg">
            {selectedRow && <DialogTitle>{selectedRow.name} Cases</DialogTitle>}
            <DialogContent>
                {data && selectedRow && <MrtCaseTableDemandDates data={modalData} />}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpen(false)}>Close</Button>
            </DialogActions>
        </Dialog>
    </Stack>
};

export default MrLastTouchReportVirtualized;
