import {Stack, Typography} from "@mui/material";
import {ReactNode} from "react";
import {Outlet} from "react-router-dom";
import MrtPeopleTable from "@/components/Table/MrtPeopleTable.tsx";
import AddPersonDialog from "@/components/Forms/SubForms/AddPersonDialog.tsx";

const PeoplePage = (): ReactNode => {
    return (
        <>
            <Stack
                sx={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 2,
                }}
            >
                <Typography variant="h4" mb={2}>
                    People
                </Typography>
                <AddPersonDialog/>
            </Stack>

            <MrtPeopleTable/>
            <Outlet />
        </>
    );
};

export default PeoplePage;
