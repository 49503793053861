import {z} from "zod";

export const partySchema = z.object({
    idUUID: z.string(),
    idCaseID: z.string(),
    contactName: z.string().optional().nullable(),
    companyName: z.string().optional().nullable(),
    claimNumber: z.string().optional().nullable(),
    idCompanyID: z.string().optional().nullable(),
    idAdjuster: z.string().optional().nullable(),
    policyPersonCoverageMM: z.string().optional().nullable(),
    policyVehiculeCoverageMM: z.string().optional().nullable(),
    policyInsuredName: z.string().optional().nullable(),
    policyDriverName: z.string().optional().nullable(),
    formLimits: z.string().optional().nullable(),

    address: z.string().optional().nullable(),
    providerStartDate: z.string().optional().nullable(),
    providerEndDate: z.string().optional().nullable(),
    providerReductionApproved: z.string().optional().nullable(),
    note: z.string().optional().nullable(),
    selectedMedia: z.string().optional().nullable(),
    selectedRecord: z.string().optional().nullable(),
    phoneNumber: z.string().optional().nullable(),

    checkAmountMM: z.string().optional().nullable(),
    loanAmount: z.string().optional().nullable(),
    loanDate: z.string().optional().nullable(),
    loanPayoffAmount: z.string().optional().nullable(),
    loanPayoffDate: z.string().optional().nullable(),

    coverageType: z.string().optional().nullable(),
    medpay: z.string().optional().nullable(),
    idAdjusterView: z.string().optional().nullable(),

    providerBalance: z.string().optional().nullable(),
    providerBill: z.string().optional().nullable(),
    providerHealthInsPmt: z.string().optional().nullable(),
    providerSelfPay: z.string().optional().nullable(),
    providerMedPay: z.string().optional().nullable(),
    providerClientToPay: z.string().optional().nullable(),
    providerReduction: z.string().optional().nullable(),
    providerAdjustment: z.string().optional().nullable(),

    // related
    adjusterPhone: z.string().nullable().optional(),
    adjusterEmail: z.string().nullable().optional(),
    adjusterAddress: z.string().nullable().optional(),
});
