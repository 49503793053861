import type {UseFormReturn} from 'react-hook-form';
import {CircularProgress, MenuItem, Paper, Typography} from "@mui/material";
import {RhfTextField} from "mui-rhf-integration";
import type {CaseFormValues} from "@/components/Forms/CaseForm.tsx";
import {useFetchTeams} from "@/queries/useFetchTeam.ts";

type Props = {
    form: UseFormReturn<CaseFormValues>;
    name: keyof CaseFormValues
    label: string
}

const CaseFormTeamsSelect = ({form, name, label} : Props) => {
    const {
        data,
        isError,
        isRefetching,
        isLoading,
        error,
    } = useFetchTeams();

    if (isError) {
        return <Paper sx={{
            p: 2,
            borderRadius: '8px',
        }}>
            <Typography variant={'h5'}>Error loading teams</Typography>
            <Typography variant={'body1'}>{error.message}</Typography>
        </Paper>
    }

    if (isLoading || isRefetching) {
        return <Paper sx={{
            p: 2,
            borderRadius: '8px',
        }}>
            <CircularProgress size={'12px'}/>
        </Paper>
    }

    return <RhfTextField
        select
        control={form.control}
        name={name}
        label={label}
        sx={{
            width: '100%',
            backgroundColor: '#fff',
        }}
    >
        {data?.map((team) => <MenuItem
            key={`${name}-${team.id}`}
            value={`${team.teamName}`}
        >
            {team.teamName}
        </MenuItem>)}
    </RhfTextField>
};

export default CaseFormTeamsSelect;
