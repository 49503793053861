import {z} from "zod";

export const caseStatusSchema = z.object({
    idUUID: z.string().optional().nullable(),
    name: z.string().optional().nullable(),
    inProgress: z.string().optional().nullable(),
    order: z.string().optional().nullable(),
});

export const addCaseStatusSchema = z.object({
    name: z.string(),
});

export const caseStatusApiSchema = z.object({
    idUUID: z.string().optional().nullable(),
    name: z.string().optional().nullable(),
    inProgress: z.string().optional().nullable(),
    order: z.number().optional().nullable(),
});
