import {UseFormReturn, useWatch} from 'react-hook-form';
import {CircularProgress, MenuItem, Paper, Typography} from "@mui/material";
import {RhfTextField} from "mui-rhf-integration";
import type {CaseFormValues} from "@/components/Forms/CaseForm.tsx";
import {useFetchCaseStatuses} from "@/queries/useFetchCaseStatuses.ts";
import {useEffect} from "react";

type Props = {
    form: UseFormReturn<CaseFormValues>;
    name: keyof CaseFormValues
    label: string
}

const CaseStatusesSelect = ({form, name, label} : Props) => {
    const idCaseStatus = useWatch({ name: 'idCaseStatus'});
    const {
        data,
        isError,
        isRefetching,
        isLoading,
    } = useFetchCaseStatuses(form.watch('idClient.idUUID'));

    useEffect(() => {
        const foundOption = data?.data
            .find((caseStatus) => caseStatus.idUUID === idCaseStatus);
        form.setValue('idCaseStatusDisplayValue', foundOption ? foundOption.name : '');
    }, [idCaseStatus, data])

    if (isError) {
        return <Paper sx={{
            p: 2,
            borderRadius: '8px',
        }}>
            <Typography variant={'h5'}>Error loading case statuses</Typography>
        </Paper>
    }

    if (isLoading || isRefetching) {
        return <Paper sx={{
            p: 2,
            borderRadius: '8px',
        }}>
            <CircularProgress size={'12px'}/>
        </Paper>
    }

    return <RhfTextField
        select
        control={form.control}
        name={name}
        label={label}
        sx={{
            width: '100%',
            backgroundColor: '#fff',
        }}
    >
        {data?.data
            .map((caseStatus) => <MenuItem
                component={'li'}
                key={`${name}-${caseStatus.name}`}
                value={caseStatus.idUUID ?? ''}
            >
                {caseStatus.name ?? ''}
            </MenuItem>)}
    </RhfTextField>
};

export default CaseStatusesSelect;
