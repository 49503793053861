import {CircularProgress, Grid2 as Grid, Paper, Typography} from "@mui/material";
import {Media, useFetchMediasForCase,} from "@/queries/useFetchMediaForCase.ts";
import {useMemo} from "react";
import MediaDocumentsListRaw from "@/components/Documents/MediaDocumentsListRaw.tsx";
import MediaUploadMultiple from "@/components/MediaUploadMultiple.tsx";

type Props = {
    caseIdUUID: string;
    limitToCategory ?: string;
};

const MediaDocumentsListForCase = ({ caseIdUUID, limitToCategory }: Props) => {
    const {
        data,
        isError,
        isRefetching,
        isLoading,
    } = useFetchMediasForCase({
        caseIdUUID,
    });

    const mediaArray = useMemo(() => {
        if (data) {
            return limitToCategory ? data.filter((mediaItem: Media) => {
                return mediaItem.tag?.toLowerCase().includes(limitToCategory.toLowerCase());
            }) : data;
        }
    }, [data, limitToCategory]);

    if (isError) {
        return (
            <Paper
                sx={{
                    p: 2,
                    borderRadius: "8px",
                }}
            >
                <Typography variant={"h5"}>Error loading documents</Typography>
            </Paper>
        );
    }

    if (isLoading || isRefetching) {
        return (
            <Paper
                sx={{
                    p: 2,
                    borderRadius: "8px",
                }}
            >
                <CircularProgress size={"12px"} />
            </Paper>
        );
    }

    return (
        <Grid container>
            <Grid size={12}>
                <Paper
                    sx={{
                        mb: 2,
                        mx: 1,
                    }}
                >
                    <Grid
                        container
                        sx={{
                            borderRadius: "8px",
                            p: 1,
                        }}
                    >
                        <Grid size={6}>
                            <Typography variant={"h5"}>Documents</Typography>
                        </Grid>
                        <Grid size={6}>
                            <MediaUploadMultiple
                                idGlobalUUID={caseIdUUID}
                                forceTag={limitToCategory}
                                category={'Cases'}
                                description={'Cases'}
                                resourceName={`Cases/${caseIdUUID}`}
                            />
                        </Grid>
                        <Grid size={12} sx={{ p: 1, mb: 1 }}>
                            {mediaArray && <MediaDocumentsListRaw
                                mediaArray={mediaArray}
                                limitToCategory={limitToCategory}
                            />}
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
};

export default MediaDocumentsListForCase;
