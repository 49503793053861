import type {UseFormReturn} from 'react-hook-form';
import {Button, Grid2 as Grid, Paper, Stack, Typography} from "@mui/material";
import {RhfTextField} from "mui-rhf-integration";
import type {CaseFormValues} from "@/components/Forms/CaseForm.tsx";
import CompanyNameAutocomplete from "@/components/Forms/CompanyNameAutocomplete.tsx";

type Props = {
    form: UseFormReturn<CaseFormValues>;
}

const HealthInsurance = ({form} : Props) => {
    return <Grid container>
        <Grid size={12}>
            <Paper
                sx={{
                    mb: 2,
                    mx: 1,
                }}
            >
                <Grid
                    container
                    sx={{
                        borderRadius: '8px',
                        p: 1,
                    }}
                >
                    <Grid size={12} sx={{p: 1, mb: 1}}>
                        <Stack
                            sx={{
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Typography variant={'h5'}>Health Insurance</Typography>
                            {form.formState.isDirty && <Button
                                variant='contained'
                                type={'submit'}
                                sx={{
                                    p: 1,
                                    borderRadius: '8px',
                                    fontWeight: 800,
                                    mb: 2,
                                    mr: 2,
                                }}
                            >
                                Save
                            </Button>}
                        </Stack>
                    </Grid>
                    <Grid size={12} sx={{p: 1}}>
                        <CompanyNameAutocomplete
                            form={form}
                        />
                    </Grid>
                    <Grid size={{xs:12, md:6}} sx={{p: 1}}>
                        <RhfTextField
                            control={form.control}
                            name={'insMemberName'}
                            label={'Member Name'}
                            sx={{
                                width: '100%',
                            }}
                        />
                    </Grid>
                    <Grid size={{xs:12, md:6}} sx={{p: 1}}>
                        <RhfTextField
                            control={form.control}
                            name={'insMemberID'}
                            label={'Member ID'}
                            sx={{
                                width: '100%',
                            }}
                        />
                    </Grid>
                    <Grid size={{xs:12, md:6}} sx={{p: 1}}>
                        <RhfTextField
                            control={form.control}
                            name={'insPlanNumber'}
                            label={'Plan Number'}
                            sx={{
                                width: '100%',
                            }}
                        />
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    </Grid>
};

export default HealthInsurance;
