import {z} from "zod";
import {zj} from "zod-joda";

export const mrtCaseSchema = z.object({
    id: z.number(),
    idUUID: z.string(),
    clientName: z.string().nullable(),
    caseName: z.string().nullable(),
    caseType: z.string().optional().nullable(),
    hasCompanions: z.boolean().optional().nullable(),
    caseStatus: z.string().optional().nullable(),
    caseStatusUM: z.string().optional().nullable(),
    assignedTeam: z.string().optional().nullable(),
    dateOfIncident: zj.localDate().nullable(),
    dateOfIncidentAge: z.string().optional().nullable(),
    lastTouch: z.coerce.date().optional().nullable(),
    assignedToAttorney: z.string().optional().nullable(),
    assignedToAttorneyPretty: z.string().optional().nullable(),
    statuteOfLimitations: zj.localDate().nullable(),
    checkReceived: z.boolean().optional().nullable(),
    releaseReceived: z.boolean().optional().nullable(),
});
