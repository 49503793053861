import {z} from "zod";

export const partyRoleSchema = z.object({
    idUUID: z.string().optional().nullable(),
    partyRoleName: z.string().optional().nullable(),
    order: z.string().optional().nullable(),
    incomeOrExpense: z.string().optional().nullable(),
});

export const addPartyRoleSchema = z.object({
    partyRoleName: z.string(),
});

export const partyRoleApiSchema = z.object({
    idUUID: z.string().optional().nullable(),
    partyRoleName: z.string().optional().nullable(),
    order: z.number().optional().nullable(),
    incomeOrExpense: z.string().optional().nullable(),
});
