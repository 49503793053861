import useAuthenticatedFetch from "@/hooks/useAuthenticatedFetch.ts";
import {apiUrl} from "@/utils/api.ts";
import {useMutation, type UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {createDataSelector, createResourceSelector, handleJsonApiError} from "jsonapi-zod-query";
import {createCaseSchema} from "@/components/Forms/schema/createCaseSchema.ts";

const resourceSelector = createResourceSelector({
    type: "case",
    attributesSchema: createCaseSchema,
});

export const caseSelector = createDataSelector(resourceSelector);
export type NewCase = ReturnType<typeof caseSelector>;

export const useAddCase = (
    personUUID: string,
): UseMutationResult<NewCase, Error, null> => {
    const fetch = useAuthenticatedFetch();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async () => {
            const response = await fetch(apiUrl('/case/create'), {
                method: "PUT",
                headers: {
                    "Content-Type": "application/vnd.api+json",
                },
                body: JSON.stringify({
                    personUUID: personUUID,
                }),
            });
            await handleJsonApiError(response);
            return await response.json();
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: ["person-cases", personUUID],
            });
        },
    });
};
