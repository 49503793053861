import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material';
import Typography from '@mui/material/Typography';

type Props = {
    id ?: string;
    open : boolean;
    onClose : () => void;
    onConfirm : (id ?: string) => Promise<void> | void;
    title : string;
};

const ConfirmDeleteModal = (
    {
        id,
        open,
        onClose,
        onConfirm,
        title,
    } : Props) : React.ReactElement => {
    const handleConfirm = async () => {
        await onConfirm(id);
    };

    const handleClose = () => {
        onClose();
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <Typography variant="body2">
                    Are you sure you want to delete this item?
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleConfirm} color="primary">
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmDeleteModal;
