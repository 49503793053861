import type React from 'react';
import {useMemo, useState} from 'react';
import {CircularProgress, Typography} from '@mui/material';
import type {UseFormReturn} from "react-hook-form";
import {RhfAutocomplete} from "mui-rhf-integration";
import type {AddProviderFormValues} from "@/components/Forms/AddEditProviderDialog.tsx";
import {useFetchCompaniesForAutocomplete} from "@/queries/useFetchCompaniesForAutocomplete.ts";

type Option = {
    id: string | null,
    name: string | null
}

type Props = {
    form: UseFormReturn<AddProviderFormValues>;
}

const ProviderCompanyAutocomplete = ({form}: Props) => {
    const [searchString, setSearchString] = useState('');
    const formValue = form.watch('idCompanyID')
    const isQueryEnabled = searchString.length >= 2 || (formValue !== null && formValue !== undefined)

    const {
        data: {data} = {},
        isError,
        isRefetching,
        isLoading,
    } = useFetchCompaniesForAutocomplete({
        searchString: searchString,
        fieldIdValue: formValue ?? null,
        isQueryEnabled: isQueryEnabled,
        subQueryKey: 'provider-company-autocomplete'
    });

    const options : Option[] = useMemo(() => {
        if (data) {
            return data.map((party) => ({
                id: party.idUUID,
                name: party.name ?? '',
            }));
        }
        return [];
    }, [data]);

    const handleInputChange = async (_event: React.SyntheticEvent, newInputValue: string) => {
        if (newInputValue.length >= 2) {
            setSearchString(newInputValue);
        }
    };

    return <>
        {(isLoading || isRefetching) && <CircularProgress/>}
        {isError && <Typography variant={"h5"}>Error loading companies</Typography>}
        <RhfAutocomplete
            control={form.control}
            name="idCompanyID"
            options={options}
            loading={(isLoading || isRefetching)}
            onInputChange={handleInputChange}

            textFieldProps={{
                label: 'Company Name',
            }}

            // display the name attribute as the dropdown value
            getOptionLabel={(option : Option | string) => {
                if (typeof option === 'string') {
                    return option;
                }

                return option.name || '';
            }}
            getOptionKey={(option : Option | string) => {
                if (typeof option === 'string') {
                    return option;
                }

                return option.id || '';
            }}
            optionToValue={(option : Option | string) => {
                if (typeof option === 'string') {
                    return option;
                }

                return option.id || '';
            }}
            valueToOption={value => {
                return options.find(option => option.id === value);
            }}
            sx={{
                "& .MuiAutocomplete-inputRoot": {
                    padding: "4px!important"
                },
                "& .MuiAutocomplete-input": {
                    paddingTop: "4px!important",
                    paddingBottom: "4px!important",
                },
            }}
        />
    </>
};

export default ProviderCompanyAutocomplete;
