import {z} from "zod";

export const saveEmployeeSchema = z.object({
    id: z.string(),
    idUUID: z.string().optional(),
    nameFirst: z.string().optional().nullable(),
    nameLast: z.string().optional().nullable(),
    username: z.string().optional().nullable(),
    password: z.string().optional().nullable(),
    privilegeSet: z.string().optional().nullable(),
    dashboardVersion: z.string().optional().nullable(),
    attorneyType: z.string().optional().nullable(),
    status: z.string().optional().nullable(),
    isParalegal: z.boolean().optional().nullable(),
    employeePhone: z.string().optional().nullable(),
    team: z.string().optional().nullable(),
    teamUUID: z.string().optional().nullable(),
    eMailAddress: z.string().optional().nullable(),
});

export const addEmployeeSchema = saveEmployeeSchema
    .omit({
        id: true,
    })
    .extend({
        nameFirst: z.string(),
        nameLast: z.string(),
        dashboardVersion: z.string(),
        eMailAddress: z.string(),
        attorneyType: z.string().optional(),
    })
    .refine(data => {
        return data.dashboardVersion !== 'Attorney' || Boolean(data.attorneyType);
    }, {
        message: 'Attorney Type is required when dashboardVersion is "Attorney"',
        path: ['attorneyType'],
    });

export const saveEmployeeSchemaForApi = saveEmployeeSchema.extend({
    isParalegal: z.string().optional().nullable(),
});
