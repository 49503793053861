import {useQuery, type UseQueryResult} from "@tanstack/react-query";
import useAuthenticatedFetch from "@/hooks/useAuthenticatedFetch.ts";
import {apiUrl} from "@/utils/api.ts";
import {createResourceSelector} from "jsonapi-zod-query";
import {caseTypeApiSchema} from "@/components/Forms/schema/caseTypeSchema.ts";

const caseTypeSelector = createResourceSelector({
    type: "caseType",
    attributesSchema: caseTypeApiSchema,
});

export type CaseTypeApi = ReturnType<typeof caseTypeSelector>;

export const useFetchCaseType = (caseTypeId: string): UseQueryResult<CaseTypeApi> => {
    const fetch = useAuthenticatedFetch();

    return useQuery({
        queryKey: ["case-type"],
        queryFn: async ({ signal }) => {
            const url = apiUrl(`/case-type/get/${caseTypeId}`);

            const response = await fetch(url, {
                signal,
                headers: {
                    accept: "application/vnd.api+json",
                },
            });

            return await response.json();
        },
        select: caseTypeSelector,
    });
};
