import {useMemo, useState} from 'react';
import {
    MaterialReactTable,
    type MRT_ColumnDef,
    type MRT_ColumnFiltersState,
    type MRT_PaginationState,
    type MRT_Row,
    type MRT_SortingState,
    useMaterialReactTable,
} from 'material-react-table';
import {Box, IconButton} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import {enqueueSnackbar} from "notistack";
import {getErrorMessage} from "@/utils/api.ts";
import {useDeletePersonPhoneNumber} from "@/mutations/useDeletePersonPhoneNumber.tsx";
import {type MrtPhoneNumber, useFetchMrtPhoneNumbersForPerson} from "@/queries/useFetchMrtPhoneNumbersForPerson.ts";
import AddEditPersonPhoneNumberDialog from "@/components/Forms/SubForms/AddEditPersonPhoneNumberDialog.tsx";
import EditIcon from "@mui/icons-material/Edit";
import {useEmployee} from "@/contexts/EmployeeProvider.tsx";

type Props = {
    personUUID: string;
};

const MrtPersonPhoneNumbersTable = ({personUUID} : Props) => {
    const {hasPermission} = useEmployee()
    const [editPhoneNumberId, setEditPhoneNumberId] = useState<string | undefined>();
    const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState<MRT_SortingState>([]);
    const [pagination, setPagination] = useState<MRT_PaginationState>({
        pageIndex: 0,
        pageSize: 10,
    });

    const deletePersonPhone = useDeletePersonPhoneNumber();
    const {data, isError, isRefetching, isLoading, error} = useFetchMrtPhoneNumbersForPerson({
        personUUID: personUUID,
        columnFilters,
        globalFilter,
        pagination,
        sorting,
    });

    const handleDeleteRow = (row: MRT_Row<MrtPhoneNumber>) => {
        if (window.confirm('Are you sure you want to delete this phone?')) {
            deletePersonPhone.mutate(
                {
                    phoneNumberUUID: row.original.idUUID,
                },
                {
                    onSuccess: () => {
                        enqueueSnackbar("Phone removed.", { variant: "success" });
                    },
                    onError: (error) => {
                        enqueueSnackbar("Error: Phone could not be removed.", { variant: "error" });
                        console.info('error', getErrorMessage(error));
                    },
                },
            )
        }
    };

    const handleEditRow = (idUUID: string) => {
        setEditPhoneNumberId(idUUID);
    }

    const columns = useMemo<MRT_ColumnDef<MrtPhoneNumber>[]>(
        () => {
            const columnDefs: MRT_ColumnDef<MrtPhoneNumber>[] = [
                {
                    accessorKey: 'edit',
                    header: '',
                    enableSorting: false,
                    Cell: ({row}) => (
                        <IconButton
                            onClick={(e) => {
                                e.stopPropagation();
                                handleEditRow(row.original.idUUID);
                            }}
                            size="small"
                        >
                            <EditIcon />
                        </IconButton>
                    ),
                    size: 50,
                },
                {
                    accessorKey: 'phoneType',
                    header: 'Type',
                    size: 50,
                },
                {
                    accessorKey: 'phoneNumber',
                    header: 'Phone Number',
                },
                {
                    accessorKey: 'extension',
                    header: 'Extension',
                    size: 50,
                },
                {
                    accessorKey: 'primary',
                    header: 'Primary?',
                    size: 50,
                },
                {
                    accessorKey: 'oK2Call',
                    header: 'Ok?',
                    size: 50,
                },
            ]

            if (hasPermission("delete:contact-info")) {
                columnDefs.push({
                    accessorKey: 'delete-phone',
                    header: 'Delete',
                    size: 50,
                    Cell: ({ row }) => (
                        <IconButton onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                            event.stopPropagation();
                            handleDeleteRow(row)
                        }}>
                            <DeleteIcon />
                        </IconButton>
                    ),
                });
            }

            return columnDefs;
        }, [handleDeleteRow, handleEditRow, hasPermission]);

    const table = useMaterialReactTable({
        columns,
        data: (isLoading || isRefetching || isError || data === undefined) ? [] : data,
        initialState: {
            showColumnFilters: false,
            density: 'compact',
        },
        enableTopToolbar: false,
        manualFiltering: true,
        manualPagination: true,
        manualSorting: true,
        muiToolbarAlertBannerProps: isError
            ? {
                color: 'error',
                children: 'Error loading data',
            }
            : undefined,
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        onPaginationChange: setPagination,
        onSortingChange: setSorting,
        rowCount: 0,
        state: {
            columnFilters,
            globalFilter,
            isLoading,
            pagination,
            showAlertBanner: isError,
            showProgressBars: isRefetching,
            showLoadingOverlay: (isLoading || isRefetching),
            sorting,
        },
    });



    if (isError) {
        return (
            <div>{error.message}</div>
        );
    }

    if (data === undefined) {
        return <div>No data</div>;
    }

    return <Box>
        <MaterialReactTable table={table} />
        <AddEditPersonPhoneNumberDialog
            personUUID={personUUID}
            showAddButton={false}
            editPhoneNumberId={editPhoneNumberId}
            clearEditPhoneNumberId={() => {
                setEditPhoneNumberId(undefined)
            }}
        />
    </Box>;
};

export default MrtPersonPhoneNumbersTable;
