import {Button,} from "@mui/material";
import {type ReactNode, useCallback} from "react";
import {enqueueSnackbar} from "notistack";
import {getErrorMessage} from "@/utils/api.ts";
import {type NewCase, useAddCase} from "@/mutations/useAddCase.tsx";

type Props = {
    personUUID: string;
};

const AddCaseDialog = ({personUUID}: Props): ReactNode => {
    const addCaseToPersonMutation = useAddCase(personUUID);

    const handleSubmit = useCallback(
        () => {
            addCaseToPersonMutation.mutate(
                null,
                {
                    onSuccess: (data: NewCase) => {
                        enqueueSnackbar("Case added.", { variant: "success" });
                        window.location.href = `/case-detail/${data.caseID}`;
                    },
                    onError: (error) => {
                        enqueueSnackbar("Error: Add Case failed.", { variant: "error" });
                        console.info('error', getErrorMessage(error));
                    },
                },
            );
        },
        [addCaseToPersonMutation],
    );

    return <>
        <Button
            variant={'contained'}
            sx={{
                ml: 2,
                minWidth: '120px',
            }}
            onClick={handleSubmit}
        >
            New Case
        </Button>
    </>;
};

export default AddCaseDialog;
