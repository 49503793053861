import type {InputBaseComponentProps} from '@mui/material';
import type {MaskedPattern} from 'imask';
import type {ForwardRefExoticComponent, PropsWithoutRef, RefAttributes} from 'react';
import {forwardRef} from 'react';
import {IMaskInput} from 'react-imask';
import {useFormContext} from "react-hook-form";

type MaskedInputOptions = {
    mask : string | NumberConstructor | RegExp;
    scale ?: number;
    thousandsSeparator ?: string;
    padFractionalZeros ?: boolean;
    normalizeZeros ?: boolean;
    mapToRadix ?: string[];
    radix ?: string;
    min ?: number;
    max ?: number;
    lazy ?: boolean;
    blocks ?: MaskedPattern['blocks'];
    eager ?: boolean;
    autofix ?: boolean;
};

export const createMaskedInput = (
    options : MaskedInputOptions
) : ForwardRefExoticComponent<PropsWithoutRef<InputBaseComponentProps> & RefAttributes<HTMLInputElement>> => {
    const Component = forwardRef<HTMLInputElement, InputBaseComponentProps>(
        (props, ref) : React.ReactNode => {
            const formContext = useFormContext();
            const {name, ...propsRest} = props;
            const {mask, ...optionsRest} = options;

            if (typeof name !== 'string') {
                throw new Error('"name" property must be defined');
            }

            return (
                <IMaskInput
                    {...propsRest}
                    {...optionsRest}

                    mask={mask as string}
                    name={name}
                    inputRef={ref}
                    onAccept={value => {
                        formContext.setValue(name, value, {shouldDirty: true});
                    }}
                />
            );
        }
    );

    Component.displayName = 'MaskedInput';

    return Component;
};
