import {Button, ButtonGroup} from "@mui/material";

type Props = {
    selectedTab: string;
    setSelectedTab: (selectedTab: string) => void;
}

const AttorneyPreLitFilters = ({selectedTab, setSelectedTab} : Props) => {
    return <ButtonGroup
        aria-label="Basic button group"
        fullWidth
        sx={{mb: 2}}
    >
        <Button
            onClick={() => setSelectedTab('')}
            variant={selectedTab === '' ? 'contained' : 'outlined'}
        >
            All Cases
        </Button>
        <Button
            onClick={() => setSelectedTab('Denial')}
            variant={selectedTab === 'Denial' ? 'contained' : 'outlined'}
        >
            Denial
        </Button>
        <Button
            onClick={() => setSelectedTab('Negotiation')}
            variant={selectedTab === 'Negotiation' ? 'contained' : 'outlined'}
        >
            Negotiation
        </Button>
        <Button
            onClick={() => setSelectedTab('Litigation Review')}
            variant={selectedTab === 'Litigation Review' ? 'contained' : 'outlined'}
        >
            Litigation Review
        </Button>
        <Button
            onClick={() => setSelectedTab('Hold Harmless')}
            variant={selectedTab === 'Hold Harmless' ? 'contained' : 'outlined'}
        >
            Hold Harmless
        </Button>
        <Button
            onClick={() => setSelectedTab('Checks Printed')}
            variant={selectedTab === 'Checks Printed' ? 'contained' : 'outlined'}
        >
            Checks Printed
        </Button>
        <Button
            onClick={() => setSelectedTab('Disbursed')}
            variant={selectedTab === 'Disbursed' ? 'contained' : 'outlined'}
        >
            Disbursed
        </Button>
    </ButtonGroup>
};

export default AttorneyPreLitFilters;
