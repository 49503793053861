import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { Autocomplete, CircularProgress, TextField, Typography } from '@mui/material';
import { useFormContext } from "react-hook-form";
import { useFetchZipCodes } from "@/queries/useFetchZipCodes.ts";

type Option = {
    id: string;
    name: string;
    displayName: string;
}

const ZipCodeAutocomplete = () => {
    const form = useFormContext();
    const formValue = form.watch('zip');

    const [inputValue, setInputValue] = useState('');
    const [searchString, setSearchString] = useState('');
    const [selectedZipCodeOption, setSelectedZipCodeOption] = useState<Option | null>(null);

    const {
        data: { data } = {},
        isError,
        isRefetching,
        isLoading,
    } = useFetchZipCodes({
        zipCode: searchString,
        isQueryEnabled: searchString.length >= 2,
    });

    const options: Option[] = useMemo(() => {
        if (data) {
            return data.map((zipCodeDocument) => ({
                id: zipCodeDocument.zIPPostal,
                name: zipCodeDocument.zIPPostal,
                displayName: `${zipCodeDocument.zIPPostal} ${zipCodeDocument.city} ${zipCodeDocument.stateProvince}`,
            }));
        }

        if (formValue) {
            return [{
                id: formValue,
                name: formValue,
                displayName: formValue,
            }];
        }

        return [];
    }, [data]);

    useEffect(() => {
        if (inputValue.length >= 2) {
            setSearchString(inputValue);
        }
    }, [inputValue]);

    useEffect(() => {
        if (formValue) {
            setSelectedZipCodeOption({
                id: formValue,
                name: formValue,
                displayName: formValue,
            });
        }
    }, [formValue]);

    useEffect(() => {
        if (selectedZipCodeOption && data) {
            const foundOption = data.find((option) => option.id === selectedZipCodeOption.id);

            if (foundOption) {
                form.setValue('city', foundOption.city);
                form.setValue('state', foundOption.stateProvince);
                form.setValue('zip', foundOption.zIPPostal);
            }
        }
    }, [selectedZipCodeOption]);

    return <>
        {(isLoading || isRefetching) && <CircularProgress />}
        {isError && <Typography variant={"h5"}>Error loading companies</Typography>}
        <Autocomplete
            value={selectedZipCodeOption ?? undefined}
            renderInput={(params) => <TextField
                {...params}
                label="Enter a Zip Code"
                size="small"
                value={inputValue}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    setInputValue(event.target.value);
                }}
                sx={{
                    "& .MuiAutocomplete-inputRoot": {
                        padding: "4px!important"
                    },
                    "& .MuiAutocomplete-input": {
                        paddingTop: "4px!important",
                        paddingBottom: "4px!important",
                    },
                    // move placehholder down
                    "& .MuiFormLabel-root": {
                        paddingTop: "8px!important",
                        paddingBottom: "8px!important",
                    }
                }}
            />}
            options={options}
            loading={(isLoading || isRefetching)}
            getOptionLabel={(option: Option) => (option.name ?? '')}
            onChange={(_event, value) => {
                if (value) {
                    setSelectedZipCodeOption(value);
                    setInputValue(value.displayName);
                } else {
                    setSelectedZipCodeOption(null);
                    setInputValue('');
                }
            }}
            renderOption={(props, option) => (
                <li {...props}>
                    {option.displayName}
                </li>
            )}
            autoHighlight
            blurOnSelect
            disableClearable
        />
    </>
};

export default ZipCodeAutocomplete;
