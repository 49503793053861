import {z} from "zod";

export const companySchema = z.object({
    idUUID: z.string(),
    name: z.string().optional().nullable(),
    address: z.string().optional().nullable(),
    address2: z.string().optional().nullable(),
    city: z.string().optional().nullable(),
    state: z.string().optional().nullable(),
    zip: z.string().optional().nullable(),
    website: z.string().optional().nullable(),
    specialty: z.string().optional().nullable(),
    prefered: z.string().optional().nullable(),
    billingCode: z.string().optional().nullable(),
});
