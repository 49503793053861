import EditIcon from '@mui/icons-material/Edit';
import {useMemo, useState} from 'react';
import {
    MaterialReactTable,
    type MRT_ColumnDef,
    type MRT_ColumnFiltersState,
    type MRT_PaginationState,
    type MRT_Row,
    type MRT_SortingState,
    useMaterialReactTable,
} from 'material-react-table';
import {type MrtParty, useFetchMrtPartiesForCase} from "@/queries/useFetchMrtPartiesForCase.ts";
import {IconButton} from "@mui/material";
import AddEditLiabilityDialog from "@/components/Forms/AddEditLiabilityDialog.tsx";
import DeleteIcon from "@mui/icons-material/Delete";
import {enqueueSnackbar} from "notistack";
import {getErrorMessage} from "@/utils/api.ts";
import {useDeleteParty} from "@/mutations/useDeleteParty.tsx";
import {useEmployee} from "@/contexts/EmployeeProvider.tsx";

type Props = {
    caseIdUUID: string;
    roleName ?: string[];
};

const MrtLiabilityTable = ({caseIdUUID, roleName} : Props) => {
    const {hasPermission} = useEmployee()
    const deleteParty = useDeleteParty();
    const [editPartyId, setEditPartyId] = useState<string | undefined>();
    const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
        [],
    );
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState<MRT_SortingState>([]);
    const [pagination, setPagination] = useState<MRT_PaginationState>({
        pageIndex: 0,
        pageSize: 10,
    });

    const {
        data,
        isError,
        isRefetching,
        isLoading,
    } = useFetchMrtPartiesForCase({
        caseIdUUID,
        roleName,
        columnFilters,
        globalFilter,
        pagination,
        sorting,
    });
    const handleEditRow = (idUUID: string) => {
        setEditPartyId(idUUID);
    }

    const handleDeleteRow = (row: MRT_Row<MrtParty>) => {
        if (window.confirm('Are you sure you want to delete this Liability Claim?')) {
            deleteParty.mutate(
                {
                    partyUUID: row.original.idUUID,
                },
                {
                    onSuccess: () => {
                        enqueueSnackbar("Liability Claim removed.", { variant: "success" });
                    },
                    onError: (error) => {
                        enqueueSnackbar("Error: Liability Claim could not be removed.", { variant: "error" });
                        console.info('error', getErrorMessage(error));
                    },
                },
            )
        }
    };

    const columns = useMemo<MRT_ColumnDef<MrtParty>[]>(
        () => {
            const columnDefs: MRT_ColumnDef<MrtParty>[] = [
                {
                    accessorKey: 'edit',
                    header: '',
                    enableSorting: false,
                    Cell: ({row}) => (
                        <IconButton
                            onClick={(e) => {
                                e.stopPropagation();
                                handleEditRow(row.original.idUUID);
                            }}
                            size="small"
                        >
                            <EditIcon />
                        </IconButton>
                    ),
                    size: 50,
                },
                {
                    accessorKey: 'claimNumber',
                    header: 'Claim number',
                },
                {
                    accessorKey: 'companyName',
                    header: 'Insurance Company',
                },
                {
                    accessorKey: 'idAdjusterView',
                    header: 'Adjuster',
                },
                {
                    accessorKey: 'policyPersonCoverageMM',
                    header: 'Liability Coverage',
                },
                {
                    accessorKey: 'policyVehiculeCoverageMM',
                    header: 'Property Damage Coverage',
                },
            ]

            if (hasPermission("delete:liability-claim")) {
                columnDefs.push({
                    accessorKey: 'delete',
                    header: 'Delete',
                    size: 50,
                    Cell: ({ row }) => (
                        <IconButton onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                            event.stopPropagation();
                            handleDeleteRow(row)
                        }}>
                            <DeleteIcon />
                        </IconButton>
                    ),
                });
            }

            return columnDefs;
        }, [hasPermission, handleEditRow, handleEditRow, handleDeleteRow]);

    const table = useMaterialReactTable({
        columns,
        data: (isLoading || isRefetching || isError || data === undefined) ? [] : data.data,
        enableColumnActions: false,
        defaultColumn: {
            size: 30,
            muiTableBodyCellProps: {
                sx: {
                    wordWrap: 'break-word',
                    whiteSpace: 'normal',
                },
            },
        },
        initialState: {
            showColumnFilters: false,
        },
        enableTopToolbar: false,
        manualFiltering: true,
        manualPagination: true,
        manualSorting: true,
        muiToolbarAlertBannerProps: isError
            ? {
                color: 'error',
                children: 'Error loading data',
            }
            : undefined,
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        onPaginationChange: setPagination,
        onSortingChange: setSorting,
        rowCount: data?.meta?.totalRowCount ?? 0,
        state: {
            columnFilters,
            globalFilter,
            isLoading,
            pagination,
            showAlertBanner: isError,
            showProgressBars: isRefetching,
            showLoadingOverlay: (isLoading || isRefetching),
            sorting,
        },
    });

    return <>
        <MaterialReactTable table={table} />
        <AddEditLiabilityDialog
            caseUUID={caseIdUUID}
            showAddButton={false}
            editPartyId={editPartyId}
            clearEditPartyId={() => {
                setEditPartyId(undefined)
            }}
        />
    </>;
};

export default MrtLiabilityTable;
