import {keepPreviousData, useQuery, type UseQueryResult} from "@tanstack/react-query";
import useAuthenticatedFetch from "@/hooks/useAuthenticatedFetch.ts";
import {apiUrl} from "@/utils/api.ts";
import {createResourceCollectionSelector} from "jsonapi-zod-query";
import {personSchemaForAutoComplete} from "@/components/Forms/schema/personSchemaForAutoComplete.ts";
import {phoneNumberSchema} from "@/components/Forms/schema/phoneNumberSchema.ts";
import {emailAddressSchema} from "@/components/Forms/schema/emailAddressSchema.ts";
import {addressSchema} from "@/components/Forms/schema/addressSchema.ts";

const personTypeSelector = createResourceCollectionSelector({
    type: "person",
    attributesSchema: personSchemaForAutoComplete,
    relationships: {
        allPhoneNumbers: {
            relationshipType: "many",
            include: {
                type: "phoneNumber",
                attributesSchema: phoneNumberSchema,
            },
            required: false,
        },
        allEmailAddresses: {
            relationshipType: "many",
            include: {
                type: "emailAddress",
                attributesSchema: emailAddressSchema,
            },
            required: false,
        },
        allAddresses: {
            relationshipType: "many",
            include: {
                type: "address",
                attributesSchema: addressSchema,
            },
            required: false,
        },
    },
});

export type Person = ReturnType<typeof personTypeSelector>;

type Props = {
    searchString: string;
    fieldIdValue : string | null;
    isQueryEnabled ?: boolean;
    subQueryKey ?: string;
};
export const useFetchPeopleForAutocomplete = (
    {
        searchString,
        fieldIdValue,
        isQueryEnabled = true,
        subQueryKey = 'person-autocomplete',
    } : Props
 ) : UseQueryResult<Person> => {
    const fetch = useAuthenticatedFetch();

    return useQuery({
        queryKey: [
            searchString,
            fieldIdValue,
            subQueryKey
        ],
        enabled: isQueryEnabled,
        queryFn: async () => {
            const fetchURL = apiUrl('/person/search');

            const response = await fetch(fetchURL.href, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/vnd.api+json",
                },
                body: JSON.stringify({
                    searchString: searchString,
                    fieldIdValue: fieldIdValue,
                }),
            });

            return await response.json();
        },
        select: personTypeSelector,
        placeholderData: keepPreviousData,
    });
};
