import {Grid2 as Grid, Paper, Typography} from "@mui/material";
import AddCaseDialog from "@/components/Forms/SubForms/AddCaseDialog.tsx";
import MrtPersonCasesTable from "@/components/Table/MrtPersonCasesTable.tsx";
import {useEmployee} from "@/contexts/EmployeeProvider.tsx";

type Props = {
    personUUID: string;
}
const PersonCases = ({personUUID} : Props) => {
    const {hasPermission} = useEmployee()

    return <Grid container>
        <Grid size={12}>
            <Paper
                sx={{
                    mb: 2,
                    mx: 1,
                }}
            >
                <Grid
                    container
                    sx={{
                        borderRadius: '8px',
                        p: 1,
                    }}
                >
                    <Grid
                        size={12}
                        sx={{
                            p: 1,
                            mb: 1,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}>
                        <Typography variant={'h5'}>Cases</Typography>
                        {hasPermission("create:case") && <AddCaseDialog personUUID={personUUID}/>}
                    </Grid>
                    <Grid size={12}>
                        <MrtPersonCasesTable personUUID={personUUID}/>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    </Grid>
};

export default PersonCases;
