import {DateTimeFormatter, LocalDate} from "@js-joda/core";

export const dateFormatterDashed = DateTimeFormatter.ofPattern('yyyy-MM-dd');

export const mapDateOrStringToLocalDate = (stringDate: string | null): LocalDate | null => {
    if (!stringDate) {
        return null;
    }

    // check format 2019-06-04T07:00:00.000Z
    if (stringDate.length === 24 && stringDate[10] === 'T') {
        return LocalDate.parse(stringDate.substring(0, 10), dateFormatterDashed);
    }

    try {
        return LocalDate.parse(stringDate, dateFormatterDashed);
    } catch (e) {
        try {
            return LocalDate.parse(stringDate);
        } catch (e) {
            return null;
        }
    }
}
