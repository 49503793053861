import {
    Alert,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid2 as Grid,
    MenuItem,
} from "@mui/material";
import {type BaseSyntheticEvent, type ReactNode, useEffect, useState} from "react";
import {FormProvider, type SubmitHandler, useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {z} from "zod";
import {enqueueSnackbar} from "notistack";
import {getErrorMessage} from "@/utils/api.ts";
import {RhfTextField} from "mui-rhf-integration";
import {addEmailSchema} from "@/components/Forms/schema/addEmailSchema.ts";
import {useAddPersonEmail} from "@/mutations/useAddPersonEmail.tsx";
import {useFetchEmailAddress} from "@/queries/useFetchEmail.ts";

export type AddEmailFormValues = z.infer<typeof addEmailSchema>;

const emailTypes = [
    'Personal',
    'Home',
    'Home 2',
    'Work',
    'Work 2',
]

type Props = {
    personUUID: string;
    showAddButton ?: boolean;
    editEmailAddressId ?: string;
    clearEditEmailAddressId ?: () => void;
};

const AddEditPersonEmailDialog = (
    {
        personUUID,
        showAddButton = true,
        editEmailAddressId,
        clearEditEmailAddressId,
    }: Props): ReactNode => {
    const addEditEmailAddress = useAddPersonEmail(personUUID);
    const [open, setOpen] = useState(false);

    const {
        data: {data} = {},
        isError,
        isRefetching,
        isLoading,
    } = useFetchEmailAddress({
        emailAddressUUID: editEmailAddressId ?? null,
        isQueryEnabled: editEmailAddressId !== undefined,
    });

    const form = useForm<AddEmailFormValues>({
        resolver: zodResolver(addEmailSchema),
        defaultValues: {},
    });

    const handleSubmit = (values: AddEmailFormValues) => {
        addEditEmailAddress.mutateAsync(
            {
                ...values
            },
        ).then(() => {
            enqueueSnackbar("Email Address added.", { variant: "success" });
            form.reset();

            if (clearEditEmailAddressId) {
                clearEditEmailAddressId();
            }

            setOpen(false)
        }).catch( (error) => {
            console.info('error', getErrorMessage(error));
            enqueueSnackbar("Error: Add Email Address failed.", { variant: "error" });
        })
    };

    const onSubmit: SubmitHandler<AddEmailFormValues> = async (
        data: AddEmailFormValues,
        event?: BaseSyntheticEvent
    ) => {
        event?.stopPropagation();
        handleSubmit(data);
    }

    useEffect(() => {
        if (editEmailAddressId && data) {
            form.reset(data)
            setOpen(true);
            return;
        }

        setOpen(false);
    }, [data, editEmailAddressId]);

    return <>
        {showAddButton && <Button
            variant={'contained'}
            sx={{
                ml: 2,
                minWidth: '120px',
            }}
            onClick={() => setOpen(true)}
        >
            {editEmailAddressId ? 'Edit' : 'New'} Email Address
        </Button>}
        <Dialog open={open} maxWidth="sm" fullWidth>
            <FormProvider {...form}>
                <form>
                    <DialogTitle>New Email</DialogTitle>
                    <DialogContent dividers>
                        {(isLoading || isRefetching) && <CircularProgress/>}
                        {isError && <Alert severity={'error'}>Error loading form data.</Alert>}
                        <Grid container spacing={2}>
                            <Grid size={{xs: 12, md: 3}}>
                                <RhfTextField
                                    select
                                    control={form.control}
                                    name={'emailType'}
                                    label={'Type'}
                                    sx={{
                                        width: '100%',
                                    }}
                                >
                                    {emailTypes.map((type) => <MenuItem
                                        key={`phone-primary-${type}`}
                                        value={type}
                                    >
                                        {type}
                                    </MenuItem>)}
                                </RhfTextField>
                            </Grid>
                            <Grid size={{xs: 12, md: 6}}>
                                <RhfTextField
                                    control={form.control}
                                    name={'emailAddress'}
                                    label={'Email Address'}
                                    sx={{
                                        width: '100%',
                                    }}
                                />
                            </Grid>
                            <Grid size={{xs: 12, md: 3}}>
                                <RhfTextField
                                    select
                                    control={form.control}
                                    name={'primary'}
                                    label={'Primary?'}
                                    sx={{
                                        width: '100%',
                                    }}
                                >
                                    {['', 'Yes', 'No'].map((type) => <MenuItem
                                        key={`phone-primary-${type}`}
                                        value={type}
                                    >
                                        {type}
                                    </MenuItem>)}
                                </RhfTextField>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpen(false)}>Close</Button>
                        <Button
                            variant={'contained'}
                            onClick={form.handleSubmit(onSubmit)}
                        >
                            {editEmailAddressId ? 'Edit' : 'Add'} Email Address
                        </Button>
                    </DialogActions>
                </form>
            </FormProvider>
        </Dialog>
    </>;
};

export default AddEditPersonEmailDialog;
