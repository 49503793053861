import {z} from "zod";

export const caseTypeSchema = z.object({
    idUUID: z.string().optional().nullable(),
    caseType: z.string().optional().nullable(),
    order: z.string().optional().nullable(),
});

export const addCaseTypeSchema = z.object({
    caseType: z.string(),
});

export const caseTypeApiSchema = z.object({
    idUUID: z.string().optional().nullable(),
    caseType: z.string().optional().nullable(),
    order: z.number().optional().nullable(),
});
