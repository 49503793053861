import {CircularProgress, Paper, Typography} from "@mui/material";
import type {ReactNode} from "react";
import {Outlet} from "react-router-dom";
import {useFetchReportLastTouch} from "@/queries/useFetchReportLastTouch.ts";

const ReportSettlementFundStatus = (): ReactNode => {
    const {
        data,
        isError,
        isRefetching,
        isLoading,
    } = useFetchReportLastTouch();

    if (isError) {
        return <Paper sx={{
            p: 2,
            borderRadius: '8px',
        }}>
            <Typography variant={'h5'}>Error loading ReportSettlementFundStatus</Typography>
        </Paper>
    }

    if (isLoading || isRefetching) {
        return <Paper sx={{
            p: 2,
            borderRadius: '8px',
        }}>
            <CircularProgress size={'12px'}/>
        </Paper>
    }

    return (
        <>
            <Typography variant="h4" mb={2}>
                Report: Settlement Fund Status
            </Typography>
            <Typography variant="body1" mb={2}>
                Data is being retrieved from the backend.
            </Typography>
            <Typography variant="body2" mb={2}>
                ToDo: Build display of the cases.
            </Typography>
            <Typography variant="body2" mb={2}>
                {JSON.stringify(data)}
            </Typography>
            <Outlet />
        </>
    );
};

export default ReportSettlementFundStatus;
