type CaseStyleProps = {
    financeLoan: string
    liabilityStatus: string
}

export const getCasePaperStyle = (
    {
        financeLoan,
        liabilityStatus,
    } : CaseStyleProps) => {
    let backgroundColor = '';

    if (financeLoan === 'Yes') {
        backgroundColor = '#c2d7b7';
    }

    if (liabilityStatus === 'Withdrawn') {
        backgroundColor = 'grey';
    }

    return {
        backgroundColor: backgroundColor,
    };
}
