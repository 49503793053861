import useAuthenticatedFetch from "@/hooks/useAuthenticatedFetch.ts";
import {apiUrl} from "@/utils/api.ts";
import {useMutation, type UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {handleJsonApiError} from "jsonapi-zod-query";
import {AddLoanFormValues} from "@/components/Forms/AddEditLoanDialog.tsx";

export const useAddLoanToCase = (
    caseUUID: string
): UseMutationResult<void, Error, AddLoanFormValues> => {
    const fetch = useAuthenticatedFetch();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (attributes) => {
            const response = await fetch(apiUrl(`/party/loan/create`), {
                method: "PUT",
                headers: {
                    "Content-Type": "application/vnd.api+json",
                },
                body: JSON.stringify({
                    caseUUID,
                    ...attributes,
                }),
            });
            await handleJsonApiError(response);
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: [`case-parties-table-data`, caseUUID],
            });
        },
    });
};
