import {useQuery, type UseQueryResult} from "@tanstack/react-query";
import useAuthenticatedFetch from "@/hooks/useAuthenticatedFetch.ts";
import {apiUrl} from "@/utils/api.ts";
import {createResourceSelector,} from "jsonapi-zod-query";
import {companySchema} from "@/components/Forms/schema/companySchema.ts";

const companySelector = createResourceSelector({
    type: "company",
    attributesSchema: companySchema,
});

export type Person = ReturnType<typeof companySelector>;

type Props = {
    placeId: string;
    isQueryEnabled ?: boolean;
}
export const useFetchCompany = ({placeId, isQueryEnabled = true}: Props): UseQueryResult<Person> => {
    const fetch = useAuthenticatedFetch();

    return useQuery({
        enabled: isQueryEnabled,
        queryKey: ["place", placeId],
        queryFn: async ({ signal }) => {
            const url = apiUrl(`/company/${placeId}`);

            const response = await fetch(url, {
                signal,
                headers: {
                    accept: "application/vnd.api+json",
                },
            });

            return await response.json();
        },
        select: companySelector,
    });
};
