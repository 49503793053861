import {z} from "zod";

export const teamSchema = z.object({
    primaryKey: z.string().nullable(),
    creationTimestamp: z.string().nullable(),
    createdBy: z.string().nullable(),
    modificationTimestamp: z.string().nullable(),
    modifiedBy: z.string().nullable(),
    teamName: z.string().nullable(),
    teamMember1: z.string().nullable(),
    teamMember2: z.string().nullable(),
    teamMember3: z.string().nullable(),
    teamEmail: z.string().nullable(),
});
