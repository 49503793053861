import {type UseFormReturn} from 'react-hook-form';
import {Grid2 as Grid} from "@mui/material";
import {RhfDatePicker} from "mui-rhf-integration/date-picker";
import {NegotiationFormValues} from "@/components/Forms/NegotiationForm.tsx";
import RhfCurrencyInput from "@/components/RhfCurrencyInput/RhfCurrencyInput.tsx";

type Props = {
    form: UseFormReturn<NegotiationFormValues>;
    field: keyof NegotiationFormValues;
    dateField: keyof NegotiationFormValues;
    title: string;
}

const NegotiationCell = (
    {
        form,
        field,
        dateField,
        title
    }
: Props) => {
    return <Grid container>
        <Grid
            size={12}
            sx={{
                textAlign: 'center',
                p: 1,
            }}
        >
            {title}
        </Grid>
        <Grid
            size={12}
            sx={{
                textAlign: 'center',
                p: 1,
            }}
        >
            <RhfCurrencyInput
                name={field}
                label={'Amount'}
                sx={{
                    width: '100%',
                    maxWidth: '300px',
                    backgroundColor: '#fff',
                }}
            />
        </Grid>
        <Grid
            size={12}
            sx={{
                textAlign: 'center',
                p: 1,
            }}
        >
            <RhfDatePicker
                control={form.control}
                name={dateField}
                label={'Date'}
                views={['year', 'month', 'day']}
                sx={{
                    width: '100%',
                    backgroundColor: '#e9ecef',
                    maxWidth: '300px',
                }}
            />
        </Grid>
    </Grid>
}

export default NegotiationCell;
