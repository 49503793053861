import useAuthenticatedFetch from "@/hooks/useAuthenticatedFetch.ts";
import {apiUrl} from "@/utils/api.ts";
import {useMutation, type UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {handleJsonApiError} from "jsonapi-zod-query";
import {AddEmailFormValues} from "@/components/Forms/SubForms/AddCompanyEmailDialog.tsx";

export const useAddCompanyEmail = (
    companyUUID: string,
): UseMutationResult<void, Error, AddEmailFormValues> => {
    const fetch = useAuthenticatedFetch();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (attributes) => {
            const response = await fetch(apiUrl(`/company/email/create`), {
                method: "PUT",
                headers: {
                    "Content-Type": "application/vnd.api+json",
                },
                body: JSON.stringify({
                    companyUUID: companyUUID,
                    ...attributes
                }),
            });
            await handleJsonApiError(response);
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: ["company-email-table-data", companyUUID],
            });
        },
    });
};
