import {CircularProgress, Grid2 as Grid, Paper, Typography} from "@mui/material";
import {MediaCollection, useFetchMediasForCase,} from "@/queries/useFetchMediaForCase.ts";
import {useMemo} from "react";
import MediaCollapsibleList from "@/components/MediaCollapsibleList.tsx";
import MediaUploadMultiple from "@/components/MediaUploadMultiple.tsx";

type MediaCollated = {
    [key : string] : MediaCollection;
}

const mediaCategories = [
    {key: 'caseDetailsMedia', displayName: "Case Details", showInGroupDisplay: true},
    {key: 'disbursementMedia', displayName: "Disbursement", showInGroupDisplay: true},
    {key: 'liabilityMedia', displayName: "Liability", showInGroupDisplay: true},
    {key: 'lienMedia', displayName: "Lien", showInGroupDisplay: true},
    {key: 'UMUIMMedia', displayName: "UM/UIM", showInGroupDisplay: true},
    {key: 'providerMedia', displayName: "Providers", showInGroupDisplay: true},
    {key: 'loanMedia', displayName: "Loan", showInGroupDisplay: true},
    {key: 'negotiationMedia', displayName: "Negotiation", showInGroupDisplay: true},
    {key: 'all', displayName: "All", showInGroupDisplay: true},
    {key: 'reqbillMedia', displayName: "Provider - Req Bill", showInGroupDisplay: false},
    {key: 'reqrecordMedia', displayName: "Provider - Req Record", showInGroupDisplay: false},
    {key: 'reduxreqMedia', displayName: "Provider - Redux Req", showInGroupDisplay: false},
    {key: 'billMedia', displayName: "Provider - Bill", showInGroupDisplay: false},
];

const processMediaArray = (mediaArray: MediaCollection) : MediaCollated => {
    const mediaCategoriesKeys = mediaCategories
        .filter((category) => category.showInGroupDisplay)
        .map((category) => category.key);
    const mediaCategoriesDisplay = mediaCategories
        .filter((category) => category.showInGroupDisplay)

    const groupedMediaArrayWithKeys = mediaCategoriesKeys.reduce<MediaCollated>(
        (acc, key) => {
            acc[key] = [];
            return acc;
        },
        {},
    );

    groupedMediaArrayWithKeys.all = mediaArray;

    return mediaArray.reduce<MediaCollated>((acc, media) => {
        const tag = media.tag;

        if (tag) {
            const matchingCategory = mediaCategoriesDisplay
                .find((category) => {
                    return tag.includes(category.displayName)
                });

            if (matchingCategory) {
                acc[matchingCategory.key].push(media);
            }
        }
        return acc;
    }, groupedMediaArrayWithKeys);
};

type Props = {
    caseIdUUID: string;
};

const MediaDocumentsCollectionForCase = ({ caseIdUUID }: Props) => {
    const {
        data: media,
        isError,
        isRefetching,
        isLoading,
    } = useFetchMediasForCase({
        caseIdUUID,
    });

    const mediaCategoriesDisplay = mediaCategories
        .filter(category => category.showInGroupDisplay)

    const mediaCollated : MediaCollated | undefined = useMemo(() => {
        if (media !== undefined) {
            return processMediaArray(media);
        }
    }, [media])

    if (isError) {
        return (

            <Paper
                sx={{
                    p: 2,
                    pb: {xs: '200px', md: '100px'},
                    borderRadius: "8px",
                }}
            >
                <Typography variant={"h5"}>Error loading documents</Typography>
            </Paper>
        );
    }

    if (isLoading || isRefetching) {
        return (
            <Paper
                sx={{
                    p: 2,
                    pb: {xs: '200px', md: '100px'},
                    borderRadius: "8px",
                }}
            >
                <CircularProgress size={"12px"} />
            </Paper>
        );
    }

    return (
        <Grid container sx={{pb: {xs: '200px', md: '100px'}}}>
            <Grid size={12}>
                <Paper
                    sx={{
                        mb: 2,
                        mx: 1,
                    }}
                >
                    <Grid
                        container
                        sx={{
                            borderRadius: "8px",
                            p: 1,
                        }}
                    >
                        <Grid size={12} sx={{ p: 1, mb: 1 }}>
                            <Typography variant={"h5"}>Documents</Typography>
                            <MediaUploadMultiple
                                idGlobalUUID={caseIdUUID}
                                forceTag={'Case Details'}
                                category={'Cases'}
                                description={'Cases'}
                                resourceName={`Cases/${caseIdUUID}`}
                            />

                            {media && mediaCollated && mediaCategoriesDisplay.map((mediaCategory) => {
                                return <MediaCollapsibleList
                                    key={mediaCategory.key}
                                    title={mediaCategory.displayName}
                                    mediaArray={mediaCollated[mediaCategory.key]}
                                />
                            })}
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
};

export default MediaDocumentsCollectionForCase;
