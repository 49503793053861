import {FormProvider, type SubmitHandler, useForm} from 'react-hook-form';
import type {z} from 'zod';
import {zodResolver} from '@hookform/resolvers/zod';
import {CircularProgress, Grid2 as Grid} from "@mui/material";
import {type BaseSyntheticEvent, useCallback, useEffect, useState} from "react";
import {useFetchEmployee} from "@/queries/useFetchEmployee.ts";
import {saveEmployeeSchema} from "@/components/Forms/schema/saveEmployeeSchema.ts";
import {useSaveEmployee} from "@/mutations/useSaveEmployee.tsx";
import {enqueueSnackbar} from "notistack";
import {getErrorMessage} from "@/utils/api.ts";
import EmployeeInformation from "@/components/Person/EmployeeInformation.tsx";

export type EmployeeFormValues = z.infer<typeof saveEmployeeSchema>;

const massageLocalDateToDayjsDates = (data: Record<string, any>) => {
    return {
        ...data.data,
        isParalegal: data.data.isParalegal === 'yes',
    }
}

type Props = {
    employeeId: string;
}

const EmployeeForm = ({employeeId} : Props) => {
    const saveEmployee = useSaveEmployee();
    const [foundEmployeeIdUUID, setFoundEmployeeIdUUID] = useState<string | null>(null);

    const {
        data,
        isError,
        isRefetching,
        isLoading,
        isPending,
        error,
    } = useFetchEmployee(employeeId);

    const form = useForm<EmployeeFormValues>({
        resolver: zodResolver(saveEmployeeSchema),
    });

    const handleSubmit = useCallback(
        (values: EmployeeFormValues) => {
            if (foundEmployeeIdUUID) {
                saveEmployee.mutate(
                    {
                        employeeUUID: foundEmployeeIdUUID,
                        ...values,
                        isParalegal: values.isParalegal ? 'yes' : 'no'
                    },
                    {
                        onSuccess: () => {
                            enqueueSnackbar("Employee saved.", { variant: "success" });
                            form.reset(form.getValues());
                        },
                        onError: (error) => {
                            enqueueSnackbar("Error: Employee failed to save.", { variant: "error" });
                            console.info('error', getErrorMessage(error));
                        },
                    },
                );
            }
        },
        [saveEmployee, foundEmployeeIdUUID, form.getValues, form.reset],
    );

    const onSubmit: SubmitHandler<EmployeeFormValues> = async (
        data: EmployeeFormValues,
        event?: BaseSyntheticEvent
    ) => {
        event?.stopPropagation();
        handleSubmit(data);
    }

    useEffect(() => {
        if (!(isError || isRefetching || isLoading ) && data) {
            form.reset(massageLocalDateToDayjsDates(data));
            setFoundEmployeeIdUUID(data.data.idUUID);
        }
    }, [data, isError, isRefetching, isLoading, form.reset]);

    if (isPending) {
        return null;
    }

    if (isError) {
        return (
            <div>{error.message}</div>
        );
    }

    if (isLoading || isRefetching) {
        return (
            <CircularProgress/>
        );
    }

    if (error) {
        return (
            <div>{error}</div>
        );
    }

    return <>
        <FormProvider {...form}>
            <form onSubmit={
                form.handleSubmit(onSubmit)
            }>
                {foundEmployeeIdUUID && <Grid container>
                    <Grid size={12}>
                        <EmployeeInformation form={form} />
                    </Grid>
                </Grid>}
            </form>
        </FormProvider>
    </>
};

export default EmployeeForm;
