import type {UseFormReturn} from 'react-hook-form';
import {CircularProgress, MenuItem, Paper, Typography} from "@mui/material";
import {RhfTextField} from "mui-rhf-integration";
import type {CaseFormValues} from "@/components/Forms/CaseForm.tsx";
import {useFetchEmployeeByType} from "@/queries/useFetchEmployeeByType.ts";

type Props = {
    form: UseFormReturn<CaseFormValues>;
    name: keyof CaseFormValues
    label: string
    type: string
}

const EmployeeByType = ({form, name, label, type} : Props) => {
    const {
        data,
        isError,
        isRefetching,
        isLoading,
        error
    } = useFetchEmployeeByType({type});

    if (isError) {
        return <Paper sx={{
            p: 2,
            borderRadius: '8px',
        }}>
            <Typography variant={'h5'}>Error loading employees</Typography>
            <Typography variant={'body1'}>{error.message}</Typography>
        </Paper>
    }

    if (isLoading || isRefetching) {
        return <Paper sx={{
            p: 2,
            borderRadius: '8px',
        }}>
            <CircularProgress size={'12px'}/>
        </Paper>
    }

    return <RhfTextField
        select
        control={form.control}
        name={name}
        label={label}
        sx={{
            width: '100%',
            backgroundColor: '#fff',
        }}
    >
        {data && data?.map((caseStatus) => <MenuItem
            key={`${name}-${caseStatus.idUUID}`}
            value={`${caseStatus.idUUID}`}
        >
            {caseStatus.fullName}
        </MenuItem>)}
    </RhfTextField>
};

export default EmployeeByType;
