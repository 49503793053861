import {Grid2 as Grid, Paper, Typography} from "@mui/material";
import AddEditPersonPhoneNumberDialog from "@/components/Forms/SubForms/AddEditPersonPhoneNumberDialog.tsx";
import AddEditPersonAddressDialog from "@/components/Forms/SubForms/AddEditPersonAddressDialog.tsx";
import AddEditPersonEmailDialog from "@/components/Forms/SubForms/AddEditPersonEmailDialog.tsx";
import MrtPersonEmailsTable from "@/components/Table/MrtPersonEmailsTable.tsx";
import MrtPersonPhoneNumbersTable from "@/components/Table/MrtPersonPhoneNumbersTable.tsx";
import MrtPersonAddressesTable from "@/components/Table/MrtPersonAddressesTable.tsx";

type Props = {
    personUUID: string;
}
const PersonContactInfo = ({personUUID}: Props) => {
    return <Grid container>
        <Grid size={12}>
            <Paper
                sx={{
                    mb: 2,
                    mx: 1,
                }}
            >
                <Grid
                    container
                    sx={{
                        borderRadius: '8px',
                        p: 1,
                    }}
                >
                    <Grid size={12} sx={{p: 1, mb: 1}}>
                        <Typography variant={'h5'}>Contact Info</Typography>
                    </Grid>
                    <Grid
                        size={12}
                        sx={{
                            p: 1,
                            mb: 1,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Typography variant={'h6'}>Phone Numbers</Typography>
                        <AddEditPersonPhoneNumberDialog personUUID={personUUID} showAddButton={true}/>
                    </Grid>
                    <Grid size={12}>
                        <MrtPersonPhoneNumbersTable personUUID={personUUID}/>
                    </Grid>
                    <Grid
                        size={12}
                        sx={{
                            p: 1,
                            mb: 1,
                            mt: 2,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Typography variant={'h6'}>Addresses</Typography>
                        <AddEditPersonAddressDialog personUUID={personUUID}/>
                    </Grid>
                    <Grid size={12}>
                        <MrtPersonAddressesTable personUUID={personUUID}/>
                    </Grid>
                    <Grid
                        size={12}
                        sx={{
                            p: 1,
                            mb: 1,
                            mt: 2,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Typography variant={'h6'}>Email</Typography>
                        <AddEditPersonEmailDialog personUUID={personUUID}/>
                    </Grid>
                    <Grid size={12}>
                        <MrtPersonEmailsTable personUUID={personUUID}/>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    </Grid>
};

export default PersonContactInfo;
