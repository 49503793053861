import {Button, Grid2 as Grid, Paper, Stack, Typography} from "@mui/material";
import type {UseFormReturn} from "react-hook-form";
import type {PersonRequestSchemaType} from "@/components/Forms/PersonForm.tsx";
import {RhfTextField} from "mui-rhf-integration";
import {isMinor} from "@/functions/IsMinor.tsx";

type Props = {
    form: UseFormReturn<PersonRequestSchemaType>;
}

const LegalGuardianInformation = ({form} : Props) => {
    const dateOfBirth = form.watch('dateofbirth')
    const legalGuardianName = form.watch('legalGuardianName')
    const legalGuardianPhone = form.watch('legalGuardianPhone')
    const legalGuardianEmail = form.watch('legalGuardianEmail')

    const incomplete = !legalGuardianName || !legalGuardianPhone || !legalGuardianEmail;

    return <Grid container sx={{display: isMinor(dateOfBirth) ? 'block' : 'none'}}>
        <Grid size={12}>
            <Paper
                sx={{
                    mb: 2,
                    mx: 1,
                    backgroundColor:  incomplete ? 'red' : '#fff',
                }}
            >
                <Grid
                    container
                    sx={{
                        borderRadius: '8px',
                        p: 1,
                    }}
                >
                    <Grid size={12} sx={{p: 1, mb: 1}}>
                        <Stack
                            sx={{
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Typography variant={'h5'}>Legal Guardian Information</Typography>
                            {form.formState.isDirty && <Button
                                variant='contained'
                                type={'submit'}
                                sx={{
                                    p: 1,
                                    borderRadius: '8px',
                                    fontWeight: 800,
                                    mb: 2,
                                    mr: 2,
                                }}
                            >
                                Save
                            </Button>}
                        </Stack>

                        <Typography variant={'body2'}>
                            Note: This does not replace the need to create a "People" entry for the legal guardian. This
                            only ensures that contact information for the legal guardian is saved.
                        </Typography>
                    </Grid>
                    <Grid size={12} sx={{p: 1, mb: 1}}>
                        <RhfTextField
                            control={form.control}
                            name={'legalGuardianName'}
                            label={'Legal Guardian Name'}
                            sx={{
                                width: '100%',
                                backgroundColor: '#fff',
                            }}
                        />
                    </Grid>
                    <Grid size={12} sx={{p: 1, mb: 1}}>
                        <RhfTextField
                            control={form.control}
                            name={'legalGuardianPhone'}
                            label={'Legal Guardian Phone #'}
                            sx={{
                                width: '100%',
                                backgroundColor: '#fff',
                            }}
                        />
                    </Grid>
                    <Grid size={12} sx={{p: 1, mb: 1}}>
                        <RhfTextField
                            control={form.control}
                            name={'legalGuardianEmail'}
                            label={'Legal Guardian Email'}
                            sx={{
                                width: '100%',
                                backgroundColor: '#fff',
                            }}
                        />
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    </Grid>
};

export default LegalGuardianInformation;
