import {Grid2 as Grid, Paper, Typography} from "@mui/material";
import MrtCompanyCasesTable from "@/components/Table/MrtCompanyCasesTable.tsx";

type Props = {
    companyUUID: string;
}
const CompanyCases = ({companyUUID} : Props) => {
    return <Grid container>
        <Grid size={12}>
            <Paper
                sx={{
                    mb: 2,
                    mx: 1,
                }}
            >
                <Grid
                    container
                    sx={{
                        borderRadius: '8px',
                        p: 1,
                    }}
                >
                    <Grid
                        size={12}
                        sx={{
                            p: 1,
                            mb: 1,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}>
                        <Typography variant={'h5'}>Cases</Typography>
                    </Grid>
                    <Grid size={12}>
                        <MrtCompanyCasesTable companyUUID={companyUUID}/>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    </Grid>
};

export default CompanyCases;
