import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import type {Document, DocumentData} from "@/types/document.ts";
import useAuthenticatedFetch from "@/hooks/useAuthenticatedFetch.ts";
import {apiUrl} from "@/utils/api.ts";
import {handleJsonApiError} from "jsonapi-zod-query";

export const useCreateApiDocumentsMutation = () : UseMutationResult<
    Document[],
    unknown,
    DocumentData[]
> => {
    const fetch = useAuthenticatedFetch();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (document: DocumentData[]) => {
            const response = await fetch(apiUrl('/document/multiple'), {
                method: "POST",
                body: JSON.stringify(document),
                headers: {
                    "Content-Type": "application/vnd.api+json",
                },
            });

            await handleJsonApiError(response);
            return (await response.json());
        },
        onSuccess: () => {
            void queryClient.invalidateQueries({
                queryKey: ["case-media"],
            });
            void queryClient.invalidateQueries({
                queryKey: ["person-media"],
            });
            void queryClient.invalidateQueries({
                queryKey: ["company-media"],
            });
        },
    });
};
