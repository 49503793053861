import {z} from "zod";

export const mediaSchema = z.object({
    id: z.number(),
    idUUID: z.string(),
    filename: z.string().nullable(),
    tag: z.string().nullable(),
    metaCreatedBy: z.string().nullable(),
    metaCreatedByPretty: z.string().nullable(),
    metaCreatedWhen : z.coerce.date().nullable(),
    metaModifiedBy: z.string().nullable(),
    metaModifiedByPretty: z.string().nullable(),
    metaModifiedWhen : z.coerce.date().nullable(),
});
