import {z} from "zod";

export const addressSchema = z.object({
    idUUID: z.string().optional().nullable(),
    addressType: z.string().optional().nullable(),
    address1: z.string().optional().nullable(),
    address2: z.string().optional().nullable(),
    city: z.string().optional().nullable(),
    state: z.string().optional().nullable(),
    zip: z.string().optional().nullable(),
});

export const addAddressSchema = addressSchema.omit({
    idUUID: true,
})
