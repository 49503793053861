import {
    Alert,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid2 as Grid,
    MenuItem,
} from "@mui/material";
import {type BaseSyntheticEvent, type ReactNode, useEffect, useState} from "react";
import {FormProvider, type SubmitHandler, useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {z} from "zod";
import {enqueueSnackbar} from "notistack";
import {getErrorMessage} from "@/utils/api.ts";
import {RhfTextField} from "mui-rhf-integration";
import {addPhoneNumberSchema} from "@/components/Forms/schema/addPhoneNumberSchema.ts";
import {useAddEditPersonPhoneNumber} from "@/mutations/useAddEditPersonPhoneNumber.tsx";
import {useFetchPhoneNumber} from "@/queries/useFetchPhoneNumber.ts";

export type AddPersonFormValues = z.infer<typeof addPhoneNumberSchema>;

const phoneTypes = [
    'Home',
    'Home2',
    'Work',
    'Work2',
    'Mobile',
    'Mobile2',
    'Fax',
];

type Props = {
    personUUID: string;
    showAddButton ?: boolean;
    editPhoneNumberId ?: string;
    clearEditPhoneNumberId ?: () => void;
};

const AddEditPersonPhoneNumberDialog = (
    {
        personUUID,
        showAddButton = true,
        editPhoneNumberId,
        clearEditPhoneNumberId,
    }
: Props): ReactNode => {
    const [open, setOpen] = useState(false);
    const addEditPhoneNumber = useAddEditPersonPhoneNumber(personUUID);

    const {
        data: {data} = {},
        isError,
        isRefetching,
        isLoading,
    } = useFetchPhoneNumber({
        phoneNumberUUID: editPhoneNumberId ?? null,
        isQueryEnabled: editPhoneNumberId !== undefined,
    });

    const form = useForm<AddPersonFormValues>({
        resolver: zodResolver(addPhoneNumberSchema),
        defaultValues: {},
    });

    const handleSubmit = (values: AddPersonFormValues) => {
        addEditPhoneNumber.mutateAsync(
            {
                ...values
            },
        ).then(() => {
            enqueueSnackbar("Phone Number added.", { variant: "success" });
            if (clearEditPhoneNumberId) {
                clearEditPhoneNumberId();
            }

            form.reset({});
            setOpen(false)
        }).catch( (error) => {
            console.info('error', getErrorMessage(error));
            enqueueSnackbar("Error: Add Phone Number failed.", { variant: "error" });
        })
    };

    const onSubmit: SubmitHandler<AddPersonFormValues> = async (
        data: AddPersonFormValues,
        event?: BaseSyntheticEvent
    ) => {
        event?.stopPropagation();
        handleSubmit(data);
    }

    useEffect(() => {
        if (editPhoneNumberId && data) {
            form.reset(data)
            setOpen(true);
            return;
        }

        setOpen(false);
    }, [data, editPhoneNumberId]);

    return <>
        {showAddButton && <Button
            variant={'contained'}
            sx={{
                ml: 2,
                minWidth: '120px',
            }}
            onClick={() => setOpen(true)}
        >
            {editPhoneNumberId ? 'Edit' : 'New'} Phone Number
        </Button>}
        <Dialog open={open} maxWidth="sm" fullWidth>
            <FormProvider {...form}>
                <form>
                    <DialogTitle>{editPhoneNumberId ? 'Edit' : 'New'} Phone Number</DialogTitle>
                    <DialogContent dividers>
                        {(isLoading || isRefetching) && <CircularProgress/>}
                        {isError && <Alert severity={'error'}>Error loading form data.</Alert>}
                        <Grid container spacing={2}>
                            <Grid size={{xs: 12, md: 2}}>
                                <RhfTextField
                                    select
                                    control={form.control}
                                    name={'phoneType'}
                                    label={'Type'}
                                    sx={{
                                        width: '100%',
                                    }}
                                >
                                    {phoneTypes.map((type) => <MenuItem
                                        key={`phone-type-${type}`}
                                        value={type}
                                    >
                                        {type}
                                    </MenuItem>)}
                                </RhfTextField>
                            </Grid>
                            <Grid size={{xs: 12, md: 4}}>
                                <RhfTextField
                                    control={form.control}
                                    name={'phoneNumber'}
                                    label={'Phone Number'}
                                    sx={{
                                        width: '100%',
                                    }}
                                />
                            </Grid>
                            <Grid size={{xs: 12, md: 2}}>
                                <RhfTextField
                                    control={form.control}
                                    name={'extension'}
                                    label={'Ext'}
                                    sx={{
                                        width: '100%',
                                    }}
                                />
                            </Grid>
                            <Grid size={{xs: 12, md: 2}}>
                                <RhfTextField
                                    select
                                    control={form.control}
                                    name={'primary'}
                                    label={'Primary'}
                                    sx={{
                                        width: '100%',
                                    }}
                                >
                                    {['', 'Yes', 'No'].map((type) => <MenuItem
                                        key={`phone-primary-${type}`}
                                        value={type}
                                    >
                                        {type}
                                    </MenuItem>)}
                                </RhfTextField>
                            </Grid>
                            <Grid size={{xs: 12, md: 2}}>
                                <RhfTextField
                                    select
                                    control={form.control}
                                    name={'oK2Call'}
                                    label={'OK to call?'}
                                    sx={{
                                        width: '100%',
                                    }}
                                >
                                    {['', 'Yes', 'No'].map((type) => <MenuItem
                                        key={`phone-primary-${type}`}
                                        value={type}
                                    >
                                        {type}
                                    </MenuItem>)}
                                </RhfTextField>
                            </Grid>
                            <Grid size={{xs: 12, md: 6}}>
                                <RhfTextField
                                    select
                                    control={form.control}
                                    name={'altContactType'}
                                    label={'Alt Contact Type'}
                                    sx={{
                                        width: '100%',
                                    }}
                                >
                                    {['N/A', 'Assistant', 'Spouse', 'Relative'].map((type) => <MenuItem
                                        key={`phone-primary-${type}`}
                                        value={type}
                                    >
                                        {type}
                                    </MenuItem>)}
                                </RhfTextField>
                            </Grid>
                            <Grid size={{xs: 12, md: 6}}>
                                <RhfTextField
                                    control={form.control}
                                    name={'altContactName'}
                                    label={'Alt Contact Name'}
                                    sx={{
                                        width: '100%',
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpen(false)}>Close</Button>
                        <Button
                            variant={'contained'}
                            onClick={form.handleSubmit(onSubmit)}
                        >
                            {editPhoneNumberId ? 'Edit' : 'Add'} Phone Number
                        </Button>
                    </DialogActions>
                </form>
            </FormProvider>
        </Dialog>
    </>;
};

export default AddEditPersonPhoneNumberDialog;
