import {useEffect, useState} from 'react';
import {useFormContext} from 'react-hook-form';
import {RhfTextField} from 'mui-rhf-integration';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    InputAdornment,
    TextField
} from '@mui/material';
import {Visibility} from '@mui/icons-material';
import {useFetchPersonUnMasked} from "@/queries/useFetchPersonUnMasked.ts";
import {useSavePersonSSN} from "@/mutations/useSavePersonSSN.tsx";
import {enqueueSnackbar} from "notistack";
import {getErrorMessage} from "@/utils/api.ts";

type Props = {
    personId: string;
    foundPersonIdUUID: string;
    fieldPath: string;
};

const MaskedSSN = ({ personId, foundPersonIdUUID, fieldPath }: Props) => {
    const form = useFormContext();
    const [openDialog, setOpenDialog] = useState(false);
    const [ssn, setSsn] = useState<string | null>(null);
    const [editMode, setEditMode] = useState(false);
    const [confirmed, setConfirmed] = useState(false);
    const savePersonSSN = useSavePersonSSN();

    const {
        data,
        refetch,
        error,
    } = useFetchPersonUnMasked({
        personUUID: foundPersonIdUUID,
        isQueryEnabled: confirmed
    });

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleViewSsn = async () => {
        setConfirmed(true);
        setEditMode(false);
    };

    const handleEditSsn = () => {
        setConfirmed(true);
        setEditMode(true);
    };

    const handleSaveSsn = () => {
        if (foundPersonIdUUID) {
            savePersonSSN.mutate(
                {
                    personId: personId,
                    personUUID: foundPersonIdUUID,
                    ssn: ssn ?? '',
                },
                {
                    onSuccess: () => {
                        enqueueSnackbar("Person saved.", { variant: "success" });
                        form.reset(form.getValues());
                        handleCloseDialog();
                    },
                    onError: (error) => {
                        enqueueSnackbar("Error: Person failed to save.", { variant: "error" });
                        console.info('error', getErrorMessage(error));
                    },
                },
            );
        }

    };

    useEffect(() => {
        if (confirmed) {
            if (data?.data.ssnumber) {
                setSsn(data.data.ssnumber);
            } else {
                setSsn('')
            }
        }
    }, [data, confirmed]);

    useEffect(() => {
        if (!openDialog) {
            setEditMode(false);
            setConfirmed(false);
            setSsn(null);
        }
    }, [openDialog]);

    useEffect(() => {
        if (confirmed) {
            void refetch();
        }
    }, [confirmed]);

    return (
        <>
            <RhfTextField
                control={form.control}
                name={fieldPath}
                label={'SSN'}
                sx={{
                    width: '100%',
                    backgroundColor: '#e9ecef',
                }}
                disabled
                slotProps={{
                    input:  {
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={handleOpenDialog} edge="end">
                                    <Visibility />
                                </IconButton>
                            </InputAdornment>
                        ),
                    },
                }}
            />

            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle>SSN Options</DialogTitle>
                <DialogContent>
                    {editMode && ssn !== null && <TextField
                        label="Edit SSN"
                        value={ssn || ''}
                        onChange={(e) => setSsn(e.target.value)}
                        fullWidth
                    />}
                    {!editMode && ssn !== null && <div>SSN: {ssn}</div>}

                    {error && <div>Error fetching SSN: {error.message}</div>}
                </DialogContent>
                <DialogActions>
                    {!editMode && ssn === null && <Button onClick={handleViewSsn} color="primary">
                        View SSN
                    </Button>}
                    {!editMode && ssn === null && <Button onClick={handleEditSsn} color="primary">
                        Edit SSN
                    </Button>}
                    <Button onClick={handleCloseDialog} color="secondary">
                        Cancel
                    </Button>
                    {editMode && ssn &&  <Button onClick={handleSaveSsn} color="primary">
                        Save
                    </Button>}
                </DialogActions>
            </Dialog>
        </>
    );
};

export default MaskedSSN;
