import {useFetchMrtPartiesForCase} from "@/queries/useFetchMrtPartiesForCase.ts";
import {useMemo} from "react";

const useNegotiationSpecials = (caseIdUUID : string) => {
    const {
        data: { data = [] } = {},
        isError,
        isRefetching,
        isLoading,
    } = useFetchMrtPartiesForCase({
        roleName: ['Provider'],
        caseIdUUID,
    });

    const returnAmount = useMemo(
        () => data.reduce((acc, curr) => acc + (curr.providerBalance ? parseFloat(curr.providerBalance) : 0), 0),
        [data]
    );

    if (isError) {
        return undefined;
    }

    if (isLoading || isRefetching) {
        return undefined;
    }

    return returnAmount
}

export default useNegotiationSpecials;
