import {CircularProgress, Container, Typography} from "@mui/material";
import {type ReactNode, useMemo} from "react";
import MrtCaseTableDashboard from "@/components/Table/MrtCaseTableDashboard.tsx";
import {useEmployee} from "@/contexts/EmployeeProvider.tsx";

const HomePage = (): ReactNode => {
    const {employee, isLoading, error} = useEmployee();

    const teamName= useMemo(() => {
        if (!(error || isLoading ) && employee) {
            return employee.team;
        }

        return '';
    }, [employee, error, isLoading]);

    return <Container maxWidth={false} sx={{p: '0 !important'}}>
        <Typography variant="h4" mb={2}>{teamName} Dashboard</Typography>
        {!(error || isLoading ) && employee && <MrtCaseTableDashboard/>}
        {error && employee && <Typography color="error">{error.message}</Typography>}
        {error && !employee && <Typography color="error">
            You have successfully logged into the Cambre application but there is an issue with your employee record.
            Please contact an administrator.
        </Typography>}
        {isLoading && <CircularProgress/>}
    </Container>
};

export default HomePage;
