import useAuthenticatedFetch from "@/hooks/useAuthenticatedFetch.ts";
import {keepPreviousData, useQuery, type UseQueryResult} from "@tanstack/react-query";
import {apiUrl} from "@/utils/api.ts";
import {createDataSelector, createResourceCollectionSelector} from "jsonapi-zod-query";
import {mrtCaseSchema} from "@/components/Forms/schema/mrtCaseSchema.ts";
import {clientSchema} from "@/components/Forms/schema/caseFormSchemaInput.ts";
import {personSchema} from "@/components/Forms/schema/personSchema.ts";

const resourceSelector = createResourceCollectionSelector({
    type: "case",
    attributesSchema: mrtCaseSchema,
    relationships: {
        idClient: {
            relationshipType: "one_nullable",
            include: {
                type: "client",
                attributesSchema: clientSchema,
            },
        },
        idPeople: {
            relationshipType: "one_nullable",
            include: {
                type: "person",
                attributesSchema: personSchema,
            },
            required: false,
        },
    }
});

export const casesSelector = createDataSelector(resourceSelector);
export type LastTouchReportCases = ReturnType<typeof casesSelector>;

export type LastTouchReportCasesDocument = ReturnType<typeof resourceSelector>;
export type MrtLastTouchReportCase = LastTouchReportCasesDocument['data'][number];

export const useFetchReportLastTouch = () : UseQueryResult<LastTouchReportCases> => {
    const fetch = useAuthenticatedFetch();

    return useQuery({
        queryKey: [
            'report-last-touch',
        ],
        queryFn: async () => {
            const response = await fetch(apiUrl('/report/last-touch'), {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            return await response.json()
        },
        select: casesSelector,
        placeholderData: keepPreviousData,
    });
};
