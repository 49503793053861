import {useMemo} from "react";
import {MaterialReactTable, type MRT_ColumnDef, MRT_Row, useMaterialReactTable,} from "material-react-table";
import {LocalDate} from "@js-joda/core";
import {dateFormatterDashed} from "@/utils/mapDateOrStringToLocalDate.ts";
import {DemandDatesReportCase} from "@/queries/useFetchReportDemandDates.ts";

type Props = {
    data: DemandDatesReportCase[]
}
const MrtCaseTableDemandDates = ({data} : Props) => {
    const handleRowClick = (row: MRT_Row<DemandDatesReportCase>) => {
        window.location.href = `/case-detail/${row.original.id}`;
    };

    const columns = useMemo<MRT_ColumnDef<DemandDatesReportCase>[]>(
        () => [
            {
                accessorKey: "id",
                header: "Case #",
                size: 50,
            },
            {
                accessorKey: "clientName",
                header: "Client Name",
            },
            {
                accessorKey: "caseType",
                header: "Type",
            },
            {
                accessorKey: "caseStatus",
                header: "Liability",
            },
            {
                accessorKey: "caseStatusUM",
                header: "UM",
            },
            {
                accessorKey: "demandLiabilitySentOn",
                header: "Liability Sent On",
                Cell: ({ renderedCellValue }) => {
                    if (renderedCellValue instanceof LocalDate) {
                        return renderedCellValue.format(dateFormatterDashed)
                    }

                    return ''
                },
            },
            {
                accessorKey: "demandUMSentOn",
                header: "UM Sent On",
                Cell: ({ renderedCellValue }) => {
                    if (renderedCellValue instanceof LocalDate) {
                        return renderedCellValue.format(dateFormatterDashed)
                    }

                    return ''
                },
            },
            {
                accessorKey: "AssignedToAttorney_Pretty",
                header: "Assigned To",
            },
            {
                accessorKey: "assignedTeam",
                header: "Team",
            },
        ],
        [],
    );

    const table = useMaterialReactTable({
        columns,
        data: data,
        initialState: {
            showColumnFilters: false,
            showGlobalFilter: true,
            density: 'compact',
        },

        // top toolbar
        enableTopToolbar: true,
        enableDensityToggle: false,
        enableFullScreenToggle: false,
        enableHiding: false,
        enableColumnFilters: false,

        manualFiltering: true,
        manualPagination: true,
        manualSorting: true,

        enablePagination: false,
        enableBottomToolbar: false,
        muiTableBodyRowProps: ({ row }: { row: MRT_Row<DemandDatesReportCase> }) => ({
            onClick: () => handleRowClick(row),
            sx: { cursor: "pointer" },
        }),
    });

    if (data === undefined) {
        return <div>No data</div>;
    }

    return <MaterialReactTable table={table} />;
};

export default MrtCaseTableDemandDates;
