import WorkIcon from "@mui/icons-material/Work";
import {CircularProgress, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import dayjs from "dayjs";
import {HistoryItem} from "@/queries/useFetchHistoryForCurrentUser.ts";
import {useFetchCaseByUUID} from "@/queries/useFetchCaseByUUID.ts";
import {useEffect, useState} from "react";

type Props = {
    historyItem: HistoryItem;
};

const CaseHistoryItem = ({historyItem}: Props) => {
    const [caseUUID, setCaseUUID] = useState('');
    const navigate = useNavigate();

    const {
        data,
        isError,
        isRefetching,
        isLoading,
    } = useFetchCaseByUUID({
        isQueryEnabled: caseUUID !== '',
        caseUUID: caseUUID,
    });

    useEffect(() => {
        if (data?.data && data?.data.id) {
            navigate(`/case-detail/${data?.data.id}`);
        }
    }, [data?.data]);

    return <ListItem key={historyItem.idUUID} disablePadding>
        <ListItemButton onClick={() => setCaseUUID(historyItem.idGlobalUUID ?? '')}>
            {historyItem.faicon && <ListItemIcon sx={{minWidth: '40px'}}>
                <WorkIcon sx={{color: 'green'}}/>
            </ListItemIcon>}
            <ListItemText
                primary={historyItem.name}
                secondary={`${dayjs(historyItem.metaModifiedWhen).format('MMM D hh:mma')}`}
            />
            {(isLoading || isRefetching) && <CircularProgress size={"12px"} />}
            {isError && <Typography variant={"h5"}>Error loading documents</Typography>}
        </ListItemButton>
    </ListItem>
}

export default CaseHistoryItem;

