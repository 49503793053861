import {useMemo} from "react";
import {MaterialReactTable, type MRT_ColumnDef, MRT_Row, useMaterialReactTable,} from "material-react-table";
import {DemandDatesReportCase} from "@/queries/useFetchReportDemandDates.ts";

type Props = {
    data: DemandDatesReportCase[]
}
const MrtCaseTableReferralsReport = ({data} : Props) => {
    const handleRowClick = (row: MRT_Row<DemandDatesReportCase>) => {
        window.location.href = `/case-detail/${row.original.id}`;
    };

    const columns = useMemo<MRT_ColumnDef<DemandDatesReportCase>[]>(
        () => [
            {
                accessorKey: "id",
                header: "Case #",
                size: 50,
            },
            {
                accessorKey: "clientName",
                header: "Client Name",
            },
            {
                accessorKey: "caseType",
                header: "Type",
            },
            {
                accessorKey: "caseStatus",
                header: "Status",
            },
            {
                accessorKey: "caseName",
                header: "Case Name",
            },
            {
                accessorKey: "assignedToAttorneyPretty",
                header: "Attorney",
            },
        ],
        [],
    );

    const table = useMaterialReactTable({
        columns,
        data: data,
        initialState: {
            showColumnFilters: false,
            showGlobalFilter: true,
            density: 'compact',
        },

        // top toolbar
        enableTopToolbar: true,
        enableDensityToggle: false,
        enableFullScreenToggle: false,
        enableHiding: false,
        enableColumnFilters: false,

        enablePagination: false,
        enableBottomToolbar: false,
        muiTableBodyRowProps: ({ row }: { row: MRT_Row<DemandDatesReportCase> }) => ({
            onClick: () => handleRowClick(row),
            sx: { cursor: "pointer" },
        }),
    });

    if (data === undefined) {
        return <div>No data</div>;
    }

    return <MaterialReactTable table={table} />;
};

export default MrtCaseTableReferralsReport;
