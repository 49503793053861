import useAuthenticatedFetch from "@/hooks/useAuthenticatedFetch.ts";
import {apiUrl} from "@/utils/api.ts";
import {useMutation, type UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {handleJsonApiError} from "jsonapi-zod-query";
import {AddAddressFormValues} from "@/components/Forms/SubForms/AddEditPersonAddressDialog.tsx";

export const useAddPersonAddress = (
    personUUID: string,
): UseMutationResult<void, Error, AddAddressFormValues> => {
    const fetch = useAuthenticatedFetch();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (attributes) => {
            const response = await fetch(apiUrl(`/person/address/create`), {
                method: "PUT",
                headers: {
                    "Content-Type": "application/vnd.api+json",
                },
                body: JSON.stringify({
                    personUUID: personUUID,
                    ...attributes
                }),
            });

            await handleJsonApiError(response);
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: ["person-addresses-table-data", personUUID],
            });
        },
    });
};
