import type {UseFormReturn} from 'react-hook-form';
import {Button, FormControlLabel, Grid2 as Grid, MenuItem, Paper, Stack, Typography} from "@mui/material";
import {RhfCheckbox, RhfTextField} from "mui-rhf-integration";
import type {EmployeeFormValues} from "@/components/Forms/EmployeeForm.tsx";
import EmployeeFormTeamsSelect from "@/components/Forms/EmployeeFormTeamsSelect.tsx";

export const attorneyTypeOptions = ['', 'Pre-Lit', 'Litigation'];
export const dashboardVersionOptions = [
    {value: 'Attorney', text: 'Attorney'},
    {value: 'CaseManager', text: 'Case Manager'},
    {value: 'DisbursementSpecialist', text: 'Disbursement Specialist'},
    {value: 'IntakeSpecialist', text: 'Intake Specialist'},
];

type Props = {
    form: UseFormReturn<EmployeeFormValues>;
}

const EmployeeInformation = ({form} : Props) => {
    const dashboardVersion = form.watch('dashboardVersion');

    return <Grid container>
        <Grid size={12}>
            <Paper
                sx={{
                    mb: 2,
                    mx: 1,
                }}
            >
                <Grid
                    container
                    sx={{
                        borderRadius: '8px',
                        p: 1,
                    }}
                >
                    <Grid size={12} sx={{p: 1, mb: 1}}>
                        <Stack
                            sx={{
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Typography variant={'h5'}>Information</Typography>
                            {form.formState.isDirty && <Button
                                variant='contained'
                                type={'submit'}
                                sx={{
                                    p: 1,
                                    borderRadius: '8px',
                                    fontWeight: 800,
                                    mb: 2,
                                    mr: 2,
                                }}
                            >
                                Save
                            </Button>}
                        </Stack>
                    </Grid>
                    <Grid size={6} sx={{p: 1}}>
                        <RhfTextField
                            control={form.control}
                            name={'nameFirst'}
                            label={'First Name'}
                            sx={{
                                width: '100%',
                            }}
                        />
                    </Grid>
                    <Grid size={6} sx={{p: 1}}>
                        <RhfTextField
                            control={form.control}
                            name={'nameLast'}
                            label={'Last Name'}
                            sx={{
                                width: '100%',
                            }}
                        />
                    </Grid>
                    <Grid size={6} sx={{p: 1}}>
                        <RhfTextField
                            control={form.control}
                            name={'eMailAddress'}
                            label={'Email Address'}
                            sx={{
                                width: '100%',
                            }}
                        />
                    </Grid>
                    <Grid size={6} sx={{p: 1}}>
                        <RhfTextField
                            select
                            control={form.control}
                            name={'dashboardVersion'}
                            label={'Dashboard Version'}
                            sx={{
                                width: '100%',
                            }}
                        >
                            {dashboardVersionOptions.map((type) => <MenuItem
                                key={`dashboard-version-${type.value}`}
                                value={type.value}
                            >
                                {type.text}
                            </MenuItem>)}
                        </RhfTextField>
                    </Grid>
                    {dashboardVersion === 'CaseManager' && <Grid size={6} sx={{p: 1}}>
                        <FormControlLabel
                            control={<RhfCheckbox
                                control={form.control}
                                name="isParalegal"
                            />}
                            label="Paralegal?"
                        />
                    </Grid>}
                    {dashboardVersion === 'Attorney' && <Grid size={6} sx={{p: 1}}>
                        <RhfTextField
                            select
                            control={form.control}
                            name={'attorneyType'}
                            label={'Attorney Type'}
                            sx={{
                                width: '100%',
                            }}
                        >
                            {attorneyTypeOptions.map((type) => <MenuItem
                                key={`attorney-type-${type}`}
                                value={type}
                            >
                                {type}
                            </MenuItem>)}
                        </RhfTextField>
                    </Grid>}
                    <Grid size={6} sx={{p: 1}}>
                        <RhfTextField
                            control={form.control}
                            name={'employeePhone'}
                            label={'Phone #'}
                            sx={{
                                width: '100%',
                            }}
                        />
                    </Grid>
                    <Grid size={6} sx={{p: 1}}>
                        <RhfTextField
                            select
                            control={form.control}
                            name={'status'}
                            label={'Status'}
                            sx={{
                                width: '100%',
                            }}
                        >
                            {['Active', 'Disabled'].map((type) => <MenuItem
                                key={`status-${type}`}
                                value={type}
                            >
                                {type}
                            </MenuItem>)}
                        </RhfTextField>
                    </Grid>
                    <Grid size={6} sx={{p: 1}}>
                        <EmployeeFormTeamsSelect form={form} name={'teamUUID'} label={'Team'}/>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    </Grid>
};

export default EmployeeInformation;
