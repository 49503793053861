import {Button} from "@mui/material";
import {useEffect, useState} from "react";
import {S3SignedUrl, useGetDocumentUrlMutation} from "@/mutations/document.ts";
import {getErrorMessage} from "@/utils/api.ts";

type Props = {
    mediaUUID : string;
    fileName : string;
};

const MediaRedirectLink = ({mediaUUID, fileName}: Props) => {
    const [redirectToMediaUUID, setRedirectToMediaUUID] = useState<string | undefined>();
    const getDocumentUrlMutation = useGetDocumentUrlMutation();

    useEffect(() => {
        if (redirectToMediaUUID) {
            getDocumentUrlMutation.mutate(
                redirectToMediaUUID,
                {
                    onSuccess: (data: S3SignedUrl) => {
                        if (data.url) {
                            window.open(data.url, '_blank');
                        }

                        setRedirectToMediaUUID(undefined);
                    },
                    onError: (error) => {
                        console.info('error', getErrorMessage(error));
                        setRedirectToMediaUUID(undefined);
                    },
                },
            )
        }

    }, [redirectToMediaUUID]);

    return <>
        <Button
            variant="text"
            sx={{
                textTransform: 'none',
                color: 'blue',
                padding: 0,
                minWidth: 'auto',
                '&:hover': {
                    textDecoration: 'underline',
                },
            }}
            onClick={() => setRedirectToMediaUUID(mediaUUID)}
        >
            {fileName}
        </Button>
    </>
};

export default MediaRedirectLink;
