import {Button, ButtonGroup} from "@mui/material";

type Props = {
    selectedTab: string;
    setSelectedTab: (selectedTab: string) => void;
}

const CaseManagerFiltersOnTeam = ({selectedTab, setSelectedTab} : Props) => {
    return <ButtonGroup
        aria-label="Basic button group"
        fullWidth
        sx={{mb: 2}}
    >
        <Button
            onClick={() => setSelectedTab('')}
            variant={selectedTab === '' ? 'contained' : 'outlined'}
        >
            All Cases
        </Button>
        <Button
            onClick={() => setSelectedTab('New Client')}
            variant={selectedTab === 'New Client' ? 'contained' : 'outlined'}
        >
            New Client
        </Button>
        <Button
            onClick={() => setSelectedTab('Treating')}
            variant={selectedTab === 'Treating' ? 'contained' : 'outlined'}
        >
            Treating
        </Button>
        <Button
            onClick={() => setSelectedTab('Done Treating')}
            variant={selectedTab === 'Done Treating' ? 'contained' : 'outlined'}
        >
            Done Treating
        </Button>
        <Button
            onClick={() => setSelectedTab('Demand Ready')}
            variant={selectedTab === 'Demand Ready' ? 'contained' : 'outlined'}
        >
            Demand Ready
        </Button>
        <Button
            onClick={() => setSelectedTab('Demand Complete')}
            variant={selectedTab === 'Demand Complete' ? 'contained' : 'outlined'}
        >
            Demand Complete
        </Button>
        <Button
            onClick={() => setSelectedTab('Withdrawal Pending')}
            variant={selectedTab === 'Withdrawal Pending' ? 'contained' : 'outlined'}
        >
            Withdrawal Pending
        </Button>
    </ButtonGroup>
};

export default CaseManagerFiltersOnTeam;
