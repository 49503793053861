import useAuthenticatedFetch from "@/hooks/useAuthenticatedFetch.ts";
import {apiUrl} from "@/utils/api.ts";
import {useMutation, type UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {createDataSelector, createResourceSelector, handleJsonApiError} from "jsonapi-zod-query";
import {personSchema} from "@/components/Forms/schema/personSchema.ts";
import {AddPersonFormValues} from "@/components/Forms/SubForms/AddPersonDialog.tsx";

const resourceSelector = createResourceSelector({
    type: "person",
    attributesSchema: personSchema,
});

export const personSelector = createDataSelector(resourceSelector);
export type NewPerson = ReturnType<typeof personSelector>;

export const useAddPerson = (): UseMutationResult<NewPerson, Error, AddPersonFormValues> => {
    const fetch = useAuthenticatedFetch();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (attributes) => {
            const response = await fetch(apiUrl(`/person/create`), {
                method: "PUT",
                headers: {
                    "Content-Type": "application/vnd.api+json",
                },
                body: JSON.stringify(attributes),
            });
            await handleJsonApiError(response);
            return await response.json();
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: ["people"],
            });
            await queryClient.invalidateQueries({
                queryKey: ["people-table-data"],
            });
        },
    });
};
