import {useFetchMrtPartiesForCase} from "@/queries/useFetchMrtPartiesForCase.ts";
import {useMemo} from "react";

const useNegotiationLoansTotal = (caseIdUUID : string) => {
    const {
        data: { data = [] } = {},
        isError,
        isRefetching,
        isLoading,
    } = useFetchMrtPartiesForCase({
        roleName: ['Loan'],
        caseIdUUID,
    });

    const returnAmount = useMemo(
        () => data.reduce((acc, curr) => {
            const checkAmountMM = curr.checkAmountMM ? Number.parseFloat( curr.checkAmountMM ) : 0;
            const loanPayoffAmount = curr.loanPayoffAmount ? Number.parseFloat( curr.loanPayoffAmount ) : 0;
            return acc + (checkAmountMM - loanPayoffAmount);
        }, 0),
        [data]
    );

    if (isError) {
        return undefined;
    }

    if (isLoading || isRefetching) {
        return undefined;
    }

    return returnAmount;
}

export default useNegotiationLoansTotal;
