import {Alert, Step, StepLabel, Stepper} from "@mui/material";
import {useMemo} from "react";

type Props = {
    stepNewClient: boolean;
    stepTreating: boolean;
    stepDoneTreating: boolean;
    stepDemandReady: boolean;
    stepDemandComplete: boolean;
    stepNegotiation: boolean;
    stepHoldHarmless: boolean;
    stepChecksPrinted: boolean;
    stepDisbursed: boolean;
    stepSettled: boolean;
}
const ProgressBar = (
    {
        stepNewClient,
        stepTreating,
        stepDoneTreating,
        stepDemandReady,
        stepDemandComplete,
        stepNegotiation,
        stepHoldHarmless,
        stepChecksPrinted,
        stepDisbursed,
        stepSettled,
    } : Props) => {

    const activeStep = useMemo(() => {
        let activeStep = 1;

        if (!stepHoldHarmless) {
            if (stepTreating) {
                activeStep += 1;
            }

            if (stepDoneTreating) {
                activeStep += 1;
            }

            if (stepDemandReady) {
                activeStep += 1;
            }

            if (stepDemandComplete) {
                activeStep += 1;
            }
            if (stepNegotiation) {
                activeStep += 1;
            }

            return activeStep;
        }

        if (stepChecksPrinted) {
            activeStep += 1;
        }

        if (stepDisbursed) {
            activeStep += 1;
        }

        if (stepSettled) {
            activeStep += 1;
        }

        return activeStep;
    }, [])

    return (
        <div>
            {/*/!* Success message for new clients *!/*/}
            {!stepNewClient && (
                <div className="steps">
                    <Alert id="progressAlert" variant="outlined" className="mx-auto">
                        New information regarding your case will be added soon. In the meantime, please contact your
                        case manager for more information about your case.
                    </Alert>
                </div>
            )}

            {/*/!* First Progress Bar *!/*/}
            {stepNewClient && !stepHoldHarmless && (
                <Stepper activeStep={activeStep} alternativeLabel>
                    <Step key={'New Client'}>
                        <StepLabel>New Client</StepLabel>
                    </Step>
                    <Step key={'Treating'}>
                        <StepLabel>Treating</StepLabel>
                    </Step>
                    <Step key={'Done Treating'}>
                        <StepLabel>Done Treating</StepLabel>
                    </Step>
                    <Step key={'Demand Ready'}>
                        <StepLabel>Demand Ready</StepLabel>
                    </Step>
                    <Step key={'Demand Complete'}>
                        <StepLabel>Demand Complete</StepLabel>
                    </Step>
                    <Step key={'Negotiation'}>
                        <StepLabel>Negotiation</StepLabel>
                    </Step>
                </Stepper>
            )}

            {/*/!* Second Progress Bar *!/*/}
            {stepHoldHarmless && (
                <Stepper activeStep={activeStep} alternativeLabel>
                    <Step key={'Hold Harmless'}>
                        <StepLabel>Hold Harmless</StepLabel>
                    </Step>
                    <Step key={'Accounting Procedures'}>
                        <StepLabel>Accounting Procedures</StepLabel>
                    </Step>
                    <Step key={'Disbursed'}>
                        <StepLabel>Disbursed</StepLabel>
                    </Step>
                    <Step key={'Settled'}>
                        <StepLabel>Settled</StepLabel>
                    </Step>
                </Stepper>
            )}
        </div>
    );
};

export default ProgressBar;
