import {z} from "zod";

export const saveDeprecatedUmUimSchema = z.object({
    id: z.string(),
    idUUID: z.string(),
    insClaimNumber: z.string().optional().nullable(),
    uMUIMCompanyName: z.string().optional().nullable(),
    inspolicy: z.string().optional().nullable(),
    coverageType: z.string().optional().nullable(),
    insamountPerPerson: z.string().optional().nullable(),
    insFormLimits: z.string().optional().nullable(),
    insmedicalPmtAmount: z.string().optional().nullable(),
    insClaimNumber2: z.string().optional().nullable(),
    uMUIMCompanyName2: z.string().optional().nullable(),
    inspolicy2: z.string().optional().nullable(),
    coverageType2: z.string().optional().nullable(),
    insamountPerPerson2: z.string().optional().nullable(),
    insFormLimits2: z.string().optional().nullable(),
    insmedicalPmtAmount2: z.string().optional().nullable(),
    insClaimNumber3: z.string().optional().nullable(),
    uMUIMCompanyName3: z.string().optional().nullable(),
    inspolicy3: z.string().optional().nullable(),
    coverageType3: z.string().optional().nullable(),
    insamountPerPerson3: z.string().optional().nullable(),
    insFormLimits3: z.string().optional().nullable(),
    insmedicalPmtAmount3: z.string().optional().nullable(),
});
