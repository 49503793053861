import {ChronoUnit, LocalDate} from "@js-joda/core";

export const isMinor = (dateOfBirth: LocalDate | null) => {
    let isUnderTen = false;

    if (dateOfBirth) {
        const today = LocalDate.now();
        const tenYearsAgo = today.minus(18, ChronoUnit.YEARS);
        isUnderTen = dateOfBirth.isAfter(tenYearsAgo);
    }

    return isUnderTen;
}
