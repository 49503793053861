import {useMemo, useState} from 'react';
import {
    MaterialReactTable,
    type MRT_ColumnDef,
    type MRT_ColumnFiltersState,
    type MRT_PaginationState,
    type MRT_Row,
    type MRT_SortingState,
    useMaterialReactTable,
} from 'material-react-table';
import {MrtCompanion, useFetchMrtCompanionForCase} from "@/queries/useFetchMrtCompanionForCase.ts";
import CompanionCaseSearchAutocomplete from "@/components/Forms/CompanionCaseSearchAutocomplete.tsx";
import {Box, IconButton} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import {useDeleteCompanionCase} from "@/mutations/useDeleteCompanionCase.tsx";
import {enqueueSnackbar} from "notistack";
import {getErrorMessage} from "@/utils/api.ts";
import {useEmployee} from "@/contexts/EmployeeProvider.tsx";

type Props = {
    caseUUID: string;
};

const MrtCompanionsTable = ({caseUUID} : Props) => {
    const {hasPermission} = useEmployee()
    const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
        [],
    );
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState<MRT_SortingState>([]);
    const [pagination, setPagination] = useState<MRT_PaginationState>({
        pageIndex: 0,
        pageSize: 10,
    });

    const deleteCompanionCase = useDeleteCompanionCase();
    const {data, isError, isRefetching, isLoading, error} = useFetchMrtCompanionForCase({
        caseId: caseUUID,
        columnFilters,
        globalFilter,
        pagination,
        sorting,
    });

    const handleDeleteRow = (row: MRT_Row<MrtCompanion>) => {
        if (window.confirm('Are you sure you want to delete this companion case?')) {
            deleteCompanionCase.mutate(
                {
                    companionCaseId: row.original.idUUID,
                },
                {
                    onSuccess: () => {
                        enqueueSnackbar("Companion case removed.", { variant: "success" });
                    },
                    onError: (error) => {
                        enqueueSnackbar("Error: Companion case could not be removed.", { variant: "error" });
                        console.info('error', getErrorMessage(error));
                    },
                },
            )
        }
    };

    const columns = useMemo<MRT_ColumnDef<MrtCompanion>[]>(
        () => {
            const columnDefs: MRT_ColumnDef<MrtCompanion>[] = [
                {
                    accessorKey: 'id',
                    header: 'Case Number',
                    Cell: ({ row }) => {
                        if (row.original.idCase1.idUUID === caseUUID) {
                            return row.original.idCase2.id;
                        }

                        if (row.original.idCase2.idUUID === caseUUID) {
                            return row.original.idCase1.id;
                        }
                    },
                },
                {
                    accessorKey: 'clientName',
                    header: 'Client Name',
                    Cell: ({ row }) => {
                        if (row.original.idCase1.idUUID === caseUUID) {
                            return row.original.idCase2.clientName;
                        }

                        if (row.original.idCase2.idUUID === caseUUID) {
                            return row.original.idCase1.clientName;
                        }
                    },
                },
                {
                    accessorKey: 'caseName',
                    header: 'Case Name',
                    Cell: ({ row }) => {
                        if (row.original.idCase1.idUUID === caseUUID) {
                            return row.original.idCase2.caseName;
                        }

                        if (row.original.idCase2.idUUID === caseUUID) {
                            return row.original.idCase1.caseName;
                        }
                    },
                },
            ]

            if (hasPermission("delete:companion")) {
                columnDefs.push({
                    accessorKey: 'delete',
                    header: 'Delete',
                    size: 50,
                    Cell: ({ row }) => (
                        <IconButton onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                            event.stopPropagation();
                            handleDeleteRow(row)
                        }}>

                        <DeleteIcon />
                        </IconButton>
                    ),
                });
            }

            return columnDefs;
        }, []);

    const handleRowClick = (row: MRT_Row<MrtCompanion>) => {
        if (row.original.idCase1.idUUID === caseUUID) {
            window.location.href = `/case-detail/${row.original.idCase2.id}`;
        }

        if (row.original.idCase2.idUUID === caseUUID) {
            window.location.href = `/case-detail/${row.original.idCase1.id}`;
        }
    };

    const table = useMaterialReactTable({
        columns,
        data: (isLoading || isRefetching || isError || data === undefined) ? [] : data,
        initialState: {
            showColumnFilters: false,
        },
        enableTopToolbar: false,
        manualFiltering: true,
        manualPagination: true,
        manualSorting: true,
        muiToolbarAlertBannerProps: isError
            ? {
                color: 'error',
                children: 'Error loading data',
            }
            : undefined,
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        onPaginationChange: setPagination,
        onSortingChange: setSorting,
        rowCount: 0,
        state: {
            columnFilters,
            globalFilter,
            isLoading,
            pagination,
            showAlertBanner: isError,
            showProgressBars: isRefetching,
            showLoadingOverlay: (isLoading || isRefetching),
            sorting,
        },
        muiTableBodyRowProps: ({ row }: { row: MRT_Row<MrtCompanion> }) => ({
            onClick: () => handleRowClick(row),
            sx: { cursor: "pointer" },
        }),
    });

    if (isError) {
        return (
            <div>{error.message}</div>
        );
    }

    if (data === undefined) {
        return <div>No data</div>;
    }

    return <Box>
        <CompanionCaseSearchAutocomplete caseId={caseUUID}/>
        <MaterialReactTable table={table} />
    </Box>;
};

export default MrtCompanionsTable;
