import useAuthenticatedFetch from "@/hooks/useAuthenticatedFetch.ts";
import {keepPreviousData, useQuery, type UseQueryResult} from "@tanstack/react-query";
import {apiUrl} from "@/utils/api.ts";
import {createDataSelector, createResourceCollectionSelector} from "jsonapi-zod-query";
import {Dayjs} from "dayjs";
import {caseFormSchemaInput} from "@/components/Forms/schema/caseFormSchemaInput.ts";
import {dayjsToLocalDate} from "@/utils/datetime.ts";

const resourceSelector = createResourceCollectionSelector({
    type: "case",
    attributesSchema: caseFormSchemaInput,
});

export const casesSelector = createDataSelector(resourceSelector);
export type DemandDatesReportCases = ReturnType<typeof casesSelector>;

export type DemandDatesReportCasesDocument = ReturnType<typeof resourceSelector>;
export type DemandDatesReportCase = DemandDatesReportCasesDocument['data'][number];

type Props = {
    isQueryEnabled ?: boolean;
    startDate: Dayjs | null;
    endDate: Dayjs | null;
};

export const useFetchReportDemandDates = (
    {
        isQueryEnabled,
        startDate,
        endDate,
    }: Props) : UseQueryResult<DemandDatesReportCases> => {
    const fetch = useAuthenticatedFetch();

    return useQuery({
        queryKey: [
            'report-last-touch',
        ],
        enabled: isQueryEnabled,
        queryFn: async () => {
            const response = await fetch(apiUrl('/report/demand-dates'), {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    startDate: startDate ? dayjsToLocalDate(startDate) : null,
                    endDate: endDate ? dayjsToLocalDate(endDate) : null,
                })
            });

            return await response.json()
        },
        select: casesSelector,
        placeholderData: keepPreviousData,
    });
};
