import {FormProvider, type SubmitHandler, useForm} from 'react-hook-form';
import {
    Alert,
    Button,
    CircularProgress,
    Grid2 as Grid,
    MenuItem,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import {enqueueSnackbar} from "notistack";
import {zodResolver} from "@hookform/resolvers/zod";
import {type BaseSyntheticEvent, useCallback, useEffect} from "react";
import {getErrorMessage} from "@/utils/api.ts";
import {useFetchCase} from "@/queries/useFetchCase.ts";
import {RhfTextField} from "mui-rhf-integration";
import {z} from "zod";
import {useSaveCaseNegotiationMutation} from "@/mutations/useSaveCaseNegotiationMutation.tsx";
import {saveDeprecatedUmUimSchema} from "@/components/Forms/schema/saveDeprecatedUmUimSchema.ts";

const massageLocalDateToDayjsDates = (
    data: Record<string, any>,
) => {
    return {
        ...data.data,
    }
}

type Props = {
    caseUUID: string;
    foundCaseId: string;
}

export type UmUimDeprecatedFormValues = z.infer<typeof saveDeprecatedUmUimSchema>;

const UmUimDeprecatedForm = ({caseUUID, foundCaseId} : Props) => {
    const saveCaseMutation = useSaveCaseNegotiationMutation(caseUUID);

    const {
        data,
        isError,
        isRefetching,
        isLoading,
        isPending,
        error,
    } = useFetchCase(foundCaseId);

    const form = useForm<UmUimDeprecatedFormValues>({
        resolver: zodResolver(saveDeprecatedUmUimSchema),
    });

    const insClaimNumber = form.watch('insClaimNumber')
    const insClaimNumber2 = form.watch('insClaimNumber2')
    const insClaimNumber3 = form.watch('insClaimNumber3')

    const handleSubmit = useCallback(
        (values: UmUimDeprecatedFormValues) => {
            if (!caseUUID) {
                return;
            }
            saveCaseMutation.mutate(
                {
                    ...values
                },
                {
                    onSuccess: () => {
                        enqueueSnackbar("Changes were successfully saved.", { variant: "success" });
                        form.reset(form.getValues());
                    },
                    onError: (error) => {
                        enqueueSnackbar("Error: save failed.", { variant: "error" });
                        console.info('error', getErrorMessage(error));
                    },
                },
            );
        },
        [saveCaseMutation, caseUUID],
    );

    const onSubmit: SubmitHandler<UmUimDeprecatedFormValues> = async (
        data: UmUimDeprecatedFormValues,
        event?: BaseSyntheticEvent
    ) => {
        handleSubmit(data);
        event?.stopPropagation();
    }

    useEffect(() => {
        if (!isError && !isRefetching && !isLoading && data) {
            form.reset(massageLocalDateToDayjsDates(data));
        }
    }, [data, isError, isRefetching, isLoading]);

    if (isPending) {
        return null;
    }

    if (isError) {
        return (
            <div>{error.message}</div>
        );
    }

    if (isLoading) {
        return (
            <CircularProgress/>
        );
    }

    if (!(foundCaseId && caseUUID)) {
        return (
            <div>Case not found</div>
        );
    }

    return <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
            <Grid container>
                <Grid size={12}>
                    {form.formState.isDirty && <Button
                        variant='contained'
                        type={'submit'}
                        sx={{
                            p: 1,
                            borderRadius: '8px',
                            fontWeight: 800,
                            m: 2,
                        }}
                    >
                        Save
                    </Button>}
                </Grid>
                <Grid size={12}>
                    <Paper
                        sx={{
                            border: "1px solid rgba(255, 0, 0, 0.796)",
                            margin: '8px',
                            padding: '10px',
                            borderRadius: '5px',
                            backgroundColor: 'rgba(255, 0, 0, 0.051)',
                        }}
                    >
                        <Grid container spacing={2}>
                            <Grid
                                size={12}
                                //v-if="form.Ins_ClaimNumber"
                            >
                                <Alert severity="error">
                                    <strong style={{color: 'red'}}>Note: </strong>This case is using a deprecated version of
                                    the UM/UIM Information module. <strong>All</strong> new UM/UIM entries must be completed in the module
                                    above.
                                </Alert>
                            </Grid>
                            <Grid size={12} sx={{pt: 2, px: 2}}>
                                <TableContainer component={Paper}>
                                    <Table
                                        className="Um/UimTable"
                                        sx={{
                                            '& .MuiTableCell-root': {
                                                borderColor: 'rgba(255, 255, 255, 0.12)',
                                            }
                                        }}
                                    >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{whiteSpace: 'nowrap', textAlign: 'center'}}>Claim #</TableCell>
                                                <TableCell sx={{whiteSpace: 'nowrap', textAlign: 'center'}}>Ins Company</TableCell>
                                                <TableCell sx={{whiteSpace: 'nowrap', textAlign: 'center'}}>Policy</TableCell>
                                                <TableCell sx={{whiteSpace: 'nowrap', textAlign: 'center'}}>Coverage Type</TableCell>
                                                <TableCell sx={{whiteSpace: 'nowrap', textAlign: 'center'}}>Coverage</TableCell>
                                                <TableCell sx={{whiteSpace: 'nowrap', textAlign: 'center'}}>Policy Limits</TableCell>
                                                <TableCell sx={{whiteSpace: 'nowrap', textAlign: 'center'}}>MedPay</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell sx={{verticalAlign: 'top', textAlign: 'center', pt: 4}}>
                                                    <RhfTextField
                                                        control={form.control}
                                                        name={'insClaimNumber'}
                                                        label={'Claim #'}
                                                        sx={{
                                                            width: '100%',
                                                            mb: 1,
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell sx={{verticalAlign: 'top', textAlign: 'center', pt: 4}}>
                                                    <RhfTextField
                                                        control={form.control}
                                                        name={'uMUIMCompanyName'}
                                                        label={'Ins Company'}
                                                        sx={{
                                                            width: '100%',
                                                            mb: 1,
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell sx={{verticalAlign: 'top', textAlign: 'center', pt: 4}}>
                                                    <RhfTextField
                                                        control={form.control}
                                                        name={'inspolicy'}
                                                        label={'Policy'}
                                                        sx={{
                                                            width: '100%',
                                                            mb: 1,
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell sx={{verticalAlign: 'top', textAlign: 'center', pt: 4}}>
                                                    <RhfTextField
                                                        select
                                                        control={form.control}
                                                        name={'coverageType'}
                                                        label={'Coverage Type'}
                                                        sx={{
                                                            width: '100%',
                                                        }}
                                                    >
                                                        {['', 'Add-on', 'Reduced By'].map(limit => <MenuItem key={`coverage-limit-${limit}`} value={limit}>{limit}</MenuItem>)}
                                                    </RhfTextField>
                                                </TableCell>
                                                <TableCell sx={{verticalAlign: 'top', textAlign: 'center', pt: 4}}>
                                                    <RhfTextField
                                                        control={form.control}
                                                        name={'insamountPerPerson'}
                                                        label={'Coverage'}
                                                        sx={{
                                                            width: '100%',
                                                            mb: 1,
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell sx={{verticalAlign: 'top', textAlign: 'center', pt: 4}}>
                                                    <RhfTextField
                                                        control={form.control}
                                                        name={'insFormLimits'}
                                                        label={'Policy Limits'}
                                                        sx={{
                                                            width: '100%',
                                                            mb: 1,
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell sx={{verticalAlign: 'top', textAlign: 'center', pt: 4}}>
                                                    <RhfTextField
                                                        control={form.control}
                                                        name={'insmedicalPmtAmount'}
                                                        label={'MedPay'}
                                                        sx={{
                                                            width: '100%',
                                                            mb: 1,
                                                        }}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                            {(insClaimNumber !== '' || insClaimNumber2) && <TableRow>
                                                <TableCell sx={{verticalAlign: 'top', textAlign: 'center', pt: 4}}>
                                                    <RhfTextField
                                                        control={form.control}
                                                        name={'insClaimNumber2'}
                                                        label={'Claim #'}
                                                        sx={{
                                                            width: '100%',
                                                            mb: 1,
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell sx={{verticalAlign: 'top', textAlign: 'center', pt: 4}}>
                                                    <RhfTextField
                                                        control={form.control}
                                                        name={'uMUIMCompanyName2'}
                                                        label={'Ins Company'}
                                                        sx={{
                                                            width: '100%',
                                                            mb: 1,
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell sx={{verticalAlign: 'top', textAlign: 'center', pt: 4}}>
                                                    <RhfTextField
                                                        control={form.control}
                                                        name={'inspolicy2'}
                                                        label={'Policy'}
                                                        sx={{
                                                            width: '100%',
                                                            mb: 1,
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell sx={{verticalAlign: 'top', textAlign: 'center', pt: 4}}>
                                                    <RhfTextField
                                                        select
                                                        control={form.control}
                                                        name={'coverageType2'}
                                                        label={'Coverage Type'}
                                                        sx={{
                                                            width: '100%',
                                                        }}
                                                    >
                                                        {['', 'Add-on', 'Reduced By'].map(limit => <MenuItem key={`coverage-limit-${limit}`} value={limit}>{limit}</MenuItem>)}
                                                    </RhfTextField>
                                                </TableCell>
                                                <TableCell sx={{verticalAlign: 'top', textAlign: 'center', pt: 4}}>
                                                    <RhfTextField
                                                        control={form.control}
                                                        name={'insamountPerPerson2'}
                                                        label={'Coverage'}
                                                        sx={{
                                                            width: '100%',
                                                            mb: 1,
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell sx={{verticalAlign: 'top', textAlign: 'center', pt: 4}}>
                                                    <RhfTextField
                                                        control={form.control}
                                                        name={'insFormLimits2'}
                                                        label={'Policy Limits'}
                                                        sx={{
                                                            width: '100%',
                                                            mb: 1,
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell sx={{verticalAlign: 'top', textAlign: 'center', pt: 4}}>
                                                    <RhfTextField
                                                        control={form.control}
                                                        name={'insmedicalPmtAmount2'}
                                                        label={'MedPay'}
                                                        sx={{
                                                            width: '100%',
                                                            mb: 1,
                                                        }}
                                                    />
                                                </TableCell>
                                            </TableRow>}
                                            {(insClaimNumber2 !== '' || insClaimNumber3) && <TableRow>
                                                <TableCell sx={{verticalAlign: 'top', textAlign: 'center', pt: 4}}>
                                                    <RhfTextField
                                                        control={form.control}
                                                        name={'insClaimNumber3'}
                                                        label={'Claim #'}
                                                        sx={{
                                                            width: '100%',
                                                            mb: 1,
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell sx={{verticalAlign: 'top', textAlign: 'center', pt: 4}}>
                                                    <RhfTextField
                                                        control={form.control}
                                                        name={'uMUIMCompanyName3'}
                                                        label={'Ins Company'}
                                                        sx={{
                                                            width: '100%',
                                                            mb: 1,
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell sx={{verticalAlign: 'top', textAlign: 'center', pt: 4}}>
                                                    <RhfTextField
                                                        control={form.control}
                                                        name={'inspolicy3'}
                                                        label={'Policy'}
                                                        sx={{
                                                            width: '100%',
                                                            mb: 1,
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell sx={{verticalAlign: 'top', textAlign: 'center', pt: 4}}>
                                                    <RhfTextField
                                                        select
                                                        control={form.control}
                                                        name={'coverageType3'}
                                                        label={'Coverage Type'}
                                                        sx={{
                                                            width: '100%',
                                                        }}
                                                    >
                                                        {['', 'Add-on', 'Reduced By'].map(limit => <MenuItem key={`coverage-limit-${limit}`} value={limit}>{limit}</MenuItem>)}
                                                    </RhfTextField>
                                                </TableCell>
                                                <TableCell sx={{verticalAlign: 'top', textAlign: 'center', pt: 4}}>
                                                    <RhfTextField
                                                        control={form.control}
                                                        name={'insamountPerPerson3'}
                                                        label={'Coverage'}
                                                        sx={{
                                                            width: '100%',
                                                            mb: 1,
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell sx={{verticalAlign: 'top', textAlign: 'center', pt: 4}}>
                                                    <RhfTextField
                                                        control={form.control}
                                                        name={'insFormLimits3'}
                                                        label={'Policy Limits'}
                                                        sx={{
                                                            width: '100%',
                                                            mb: 1,
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell sx={{verticalAlign: 'top', textAlign: 'center', pt: 4}}>
                                                    <RhfTextField
                                                        control={form.control}
                                                        name={'insmedicalPmtAmount3'}
                                                        label={'MedPay'}
                                                        sx={{
                                                            width: '100%',
                                                            mb: 1,
                                                        }}
                                                    />
                                                </TableCell>
                                            </TableRow>}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </form>
    </FormProvider>
};

export default UmUimDeprecatedForm;
