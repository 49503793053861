import {UseFormReturn} from 'react-hook-form';
import {Button, Grid2 as Grid, MenuItem, Paper, Stack, Typography} from "@mui/material";
import {RhfTextField} from "mui-rhf-integration";
import {CaseFormValues} from "@/components/Forms/CaseForm.tsx";
import {optionsYesNo} from "@/constants/optionsYesNo.ts";

type Props = {
    form: UseFormReturn<CaseFormValues>;
}

const painScaleOptions = [
    '1/10',
    '2/10',
    '3/10',
    '4/10',
    '5/10',
    '6/10',
    '7/10',
    '8/10',
    '9/10',
    '10/10'
];

const statuteOptions = [
    '§ 40-6-48 (Failure to Maintain Lane)',
    '§ 40-6-49 (Following Too Closely)',
    '§ 40-6-70 (Failure to Yeild)',
    '§ 40-6-71 (Failure to Yield While Vehicle Turning Left)',
    '§ 40-6-73 (Failure to Yield While Entering or Crossing Roadway)',
    '§ 40-6-121 (Improper U-Turn)',
    '§ 40-6-123 (Improper Lane Change)',
    '§ 40-6-240 (Improper Backing)',
    '§ 40-6-241 (Driver to Exercise Due Care)',
    '§ 40-6-270 (Hit and Run: Duty of Driver to Stop and Return to the Scene)',
    '§ 40-6-273 (Duty to Report Accident)',
    '§ 40-6-390 (Reckless Driving)',
    '§ 40-6-391 (Driving Under the Influence)',
    '§ 40-6-395 (Fleeing or Attempting to Elude Police Officer)'
]
const AccidentInfo = ({form} : Props) => {
    return <Grid container>
        <Grid size={12}>
            <Paper
                sx={{
                    mb: 2,
                    mx: 1,
                }}
            >
                <Grid
                    container
                    sx={{
                        borderRadius: '8px',
                        p: 1,
                    }}
                >
                    <Grid size={12} sx={{p: 1, mb: 1}}>
                        <Stack
                            sx={{
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Typography variant={'h5'}>Accident Information</Typography>
                            {form.formState.isDirty && <Button
                                variant='contained'
                                type={'submit'}
                                sx={{
                                    p: 1,
                                    borderRadius: '8px',
                                    fontWeight: 800,
                                    mb: 2,
                                    mr: 2,
                                }}
                            >
                                Save
                            </Button>}
                        </Stack>
                    </Grid>
                    <Grid size={{xs: 12, md: 6}} sx={{p: 1}}>
                        <RhfTextField
                            select
                            control={form.control}
                            name={'accidentReportDone'}
                            label={'Accident Report Done'}
                            sx={{
                                width: '100%',
                                backgroundColor: '#fff',
                            }}
                        >
                            {optionsYesNo && optionsYesNo.map(option => <MenuItem
                                key={`accident-report-done-${option.id}`}
                                value={`${option.id}`}
                                sx={{minHeight: '32px !important'}}
                            >
                                {option.label}
                            </MenuItem>)}
                        </RhfTextField>
                    </Grid>
                    <Grid size={{xs: 12, md: 6}} sx={{p: 1}}>
                        <RhfTextField
                            control={form.control}
                            name={'incidentReportDoneWho'}
                            label={'By Who'}
                            sx={{
                                width: '100%',
                            }}
                        />
                    </Grid>
                    <Grid size={{xs: 12, md: 4}} sx={{p: 1}}>
                        <RhfTextField
                            control={form.control}
                            name={'accidentCaseNumber'}
                            label={'Case Number'}
                            sx={{
                                width: '100%',
                            }}
                        />
                    </Grid>
                    <Grid size={{xs: 12, md: 4}} sx={{p: 1}}>
                        <RhfTextField
                            control={form.control}
                            name={'pDSettled'}
                            label={'PD Settled?'}
                            sx={{
                                width: '100%',
                                backgroundColor: '#fff',
                            }}
                        />
                    </Grid>
                    <Grid size={{xs: 12, md: 4}} sx={{p: 1}}>
                        <RhfTextField
                            control={form.control}
                            name={'pDSettledAmount'}
                            label={'PD Amount'}
                            sx={{
                                width: '100%',
                                backgroundColor: '#fff',
                            }}
                        />
                    </Grid>
                    <Grid size={{xs: 12, md: 6}} sx={{p: 1}}>
                        <RhfTextField
                            control={form.control}
                            name={'mainInjury1'}
                            label={'Main Injury #1'}
                            sx={{
                                width: '100%',
                                color: 'darkred'
                            }}
                            slotProps={{
                                inputLabel: {
                                    sx: {
                                        color: 'darkred'
                                    }
                                }
                            }}
                        />
                    </Grid>
                    <Grid size={{xs: 12, md: 6}} sx={{p: 1}}>
                        <RhfTextField
                            control={form.control}
                            name={'mainInjury2'}
                            label={'Main Injury #2'}
                            sx={{
                                width: '100%',
                            }}
                            slotProps={{
                                inputLabel: {
                                    sx: {
                                        color: 'darkred'
                                    }
                                }
                            }}
                        />
                    </Grid>
                    <Grid size={{xs: 12, md: 6}} sx={{p: 1}}>
                        <RhfTextField
                            control={form.control}
                            name={'clientPainScale'}
                            label={'Client Pain Scale'}
                            sx={{
                                width: '100%',
                            }}
                            slotProps={{
                                inputLabel: {
                                    sx: {
                                        color: 'darkred'
                                    }
                                }
                            }}
                        >
                            {painScaleOptions && painScaleOptions.map(option => <MenuItem
                                key={`case-type-${option}`}
                                value={`${option}`}
                            >
                                {option}
                            </MenuItem>)}
                        </RhfTextField>
                    </Grid>
                    <Grid size={{xs: 12, md: 6}} sx={{p: 1}}>
                        <RhfTextField
                            select
                            control={form.control}
                            name={'primaryStatute'}
                            label={'Primary Statute'}
                            sx={{
                                width: '100%',
                                backgroundColor: '#fff',
                            }}
                            slotProps={{
                                inputLabel: {
                                    sx: {
                                        color: 'darkred'
                                    }
                                }
                            }}

                        >
                            {statuteOptions && statuteOptions.map(option => <MenuItem
                                key={`case-type-${option}`}
                                value={`${option}`}
                            >
                                {option}
                            </MenuItem>)}
                        </RhfTextField>
                    </Grid>
                    <Grid size={12} sx={{p: 1}}>
                        <RhfTextField
                            control={form.control}
                            name={'injury'}
                            label={'Injury Notes'}
                            placeholder={'Please select injuries from menu above before adding injury notes.'}
                            sx={{
                                width: '100%',
                                backgroundColor: '#fff',
                            }}
                            multiline
                            rows={4}
                        />
                    </Grid>
                    <Grid size={12} sx={{p: 1}}>
                        <RhfTextField
                            control={form.control}
                            name={'accidentSummary'}
                            label={'Accident Summary'}
                            sx={{
                                width: '100%',
                                backgroundColor: '#fff',
                            }}
                            multiline
                            rows={4}
                        />
                    </Grid>
                    <Grid size={12} sx={{p: 1}}>
                        <RhfTextField
                            control={form.control}
                            name={'incidentLocation'}
                            label={'Accident Location'}
                            sx={{
                                width: '100%',
                                backgroundColor: '#fff',
                            }}
                            multiline
                            rows={4}
                        />
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    </Grid>
};

export default AccidentInfo;
