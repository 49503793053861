import DeleteIcon from '@mui/icons-material/Delete';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    LinearProgress,
    MenuItem,
    Stack,
    SxProps,
    TextField
} from '@mui/material';
import {enqueueSnackbar} from 'notistack';
import type * as React from 'react';
import {ChangeEvent, useCallback, useEffect, useState} from 'react';
import type {FileWithPath} from 'react-dropzone';
import {useDropzone} from 'react-dropzone';
import ConfirmDeleteModal from '@/components/ConfirmDeleteModal.tsx';
import {useDeleteDocumentMutation} from '@/mutations/document.ts';
import type {DocumentData} from '@/types/document.ts';
import {useGenerateMultiplePreSignedPutUrl} from "@/mutations/useGenerateMultiplePreSignedPutUrl.ts";
import {useCreateApiDocumentsMutation} from "@/mutations/useCreateApiDocumentsMutation.ts";
import {getErrorMessage} from "@/utils/api.ts";

const tags = [
    'Accounting',
    'Case Details',
    'Client Contact',
    'Closing',
    'Companies',
    'Disbursement',
    'File Activity / Review',
    'Intake',
    'Liability',
    'Loan',
    'MedPay',
    'Negotiation',
    'Person Details',
    'Providers',
    'Subrogation',
    'Termination Process',
    'UM/UIM',
];

type Props = {
    idGlobalUUID: string;
    category : string;
    description : string;
    resourceName : string;
    forceTag ?: string;
    sx ?: SxProps;
};

const MultipleMediaUpload = (
    {
        idGlobalUUID,
        category,
        description,
        resourceName,
        forceTag,
        sx,
    } : Props) : React.ReactElement => {
    const generatePreSignedPutUrl = useGenerateMultiplePreSignedPutUrl();
    const createDocumentsMutation = useCreateApiDocumentsMutation();
    const deleteDocumentMutation = useDeleteDocumentMutation();
    const [filesUpload, setFilesUpload] = useState<File[]>([]);
    const [documentsData, setDocumentsData] = useState<DocumentData[]>([]);
    const [waitingForUpload, setWaitingForUpload] = useState(false);
    const [waitingForDelete, setWaitingForDelete] = useState(false);
    const [openConfirm, setOpenConfirm] = useState(false);
    const [displayResourceUrls, setDisplayResourceUrls] = useState<string[]>([]);
    const [open, setOpen] = useState(false);
    const [tag, setTag] = useState(forceTag);
    const [tagError, setTagError] = useState('');
    const [fileError, setFileError] = useState('');
    const [uploading, setUploading] = useState(false);

    const handleConfirm = () => {
        if (tag && displayResourceUrls.length > 0 && documentsData.length > 0) {
            documentsData.forEach(doc => doc.tag = tag);
            generatePreSignedPutUrl.mutate(documentsData);
            return;
        }

        setTagError(tag === '' ? 'Tag is required.' : '');
        setFileError(!(displayResourceUrls.length > 0 && documentsData.length > 0) ? 'File is required.' : '');
    };

    const handleCloseModal = () => {
        setTag(forceTag);
        setTagError('');
        setFileError('');
        setDocumentsData([]);
        setDisplayResourceUrls([]);
        setOpen(false);
    };

    const onDrop = useCallback((acceptedFiles : FileWithPath[]) => {
        if (acceptedFiles.length) {
            const filesToUpload = acceptedFiles;

            const documentsData = filesToUpload.map(file => ({
                idGlobalUUID: idGlobalUUID,
                category: category,
                filename: file.name,
                description: description,
                size: file.size,
                mime: file.type,
                resourceName: resourceName + `/${file.name}`,
                tag: null,
            }));

            setFilesUpload(filesToUpload);
            setDocumentsData(documentsData);

            filesToUpload.map(file => {
                const reader = new FileReader();

                reader.onload = event => {
                    if (event.target?.result) {
                        setDisplayResourceUrls(prev => [...prev, String(event.target?.result)]);
                    }
                };

                reader.readAsDataURL(file);
                return reader;
            });
        }
    }, []);

    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        onDrop,
        multiple: true,
        accept: {
            'image/*': [],
            'application/pdf': [],
            'application/msword': [],
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [],
            'text/plain': [],
        },
        minSize: 0,
        maxSize: (130 * (1024 * 1024)), // 130MB
    });

    const handleDeleteDocument = (index: number) => {
        const updatedFiles = [...filesUpload];
        updatedFiles.splice(index, 1);
        setFilesUpload(updatedFiles);

        const updatedDocuments = [...documentsData];
        updatedDocuments.splice(index, 1);
        setDocumentsData(updatedDocuments);

        const updatedUrls = [...displayResourceUrls];
        updatedUrls.splice(index, 1);
        setDisplayResourceUrls(updatedUrls);
    };

    useEffect(() => {
        if (generatePreSignedPutUrl.isSuccess && documentsData.length > 0) {
            setUploading(true);

            const uploadPromises = documentsData.map((_doc, index) => {
                return new Promise((resolve, reject) => {
                    const xhr = new XMLHttpRequest();
                    xhr.open('PUT', generatePreSignedPutUrl.data[index].url);
                    xhr.onload = () => xhr.status === 200 ? resolve(xhr.response) : reject(new Error(`Upload failed for file ${index}`));
                    xhr.onerror = () => reject(new Error(`Upload failed for file ${index}`));
                    xhr.send(filesUpload[index]);
                });
            });

            Promise.all(uploadPromises)
                .then(() => {
                    createDocumentsMutation.mutate(
                        documentsData,
                        {
                            onSuccess: () => {
                                enqueueSnackbar("Documents added.", { variant: "success" });
                            },
                            onError: (error) => {
                                enqueueSnackbar("Error: Adding documents.", { variant: "error" });
                                console.info('error', getErrorMessage(error));
                            },
                        },
                    );
                })
                .catch(() => enqueueSnackbar('Document Upload Failed', { variant: 'error' }))
                .finally(() => {
                    setUploading(false);
                });

        }
    }, [generatePreSignedPutUrl.isSuccess, documentsData]);

    useEffect(() => {
        if (createDocumentsMutation.status === 'pending') {
            setWaitingForUpload(true);
        }

        if (waitingForUpload && createDocumentsMutation.isSuccess) {
            enqueueSnackbar('Documents Uploaded Successfully', {variant: 'success'});
            setWaitingForUpload(false);
            handleCloseModal()
        }

        if (waitingForUpload && createDocumentsMutation.isError) {
            enqueueSnackbar('Documents Upload Failed (API)', {variant: 'error'});
            setWaitingForUpload(false);
        }
    }, [createDocumentsMutation]);

    useEffect(() => {
        if (deleteDocumentMutation.status === 'pending') {
            setWaitingForDelete(true);
        }

        if (waitingForDelete && deleteDocumentMutation.isSuccess) {
            enqueueSnackbar('Document Deleted Successfully', {variant: 'success'});
            setWaitingForDelete(false);
        }

        if (waitingForDelete && deleteDocumentMutation.isError) {
            enqueueSnackbar('Document Delete Failed', {variant: 'error'});
            setWaitingForDelete(false);
        }
    }, [deleteDocumentMutation]);

    return <Stack
        sx={{
            p: 1,
            flexDirection: 'row',
            justifyContent: 'start',
            position: 'relative',
        }}
    >
        <Button
            variant={'contained'}
            sx={{
                ml: 2,
                minWidth: '120px',
            }}
            onClick={() => {
                setOpen(true);
            }}
        >
            New Media
        </Button>
        <Dialog open={open} fullWidth={true} maxWidth='sm' onClose={handleCloseModal}>
            <DialogTitle>Add Media</DialogTitle>
            <DialogContent sx={{minHeight: '300px'}}>
                <Stack
                    sx={{
                        p: 1,
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Box>
                        <Stack
                            sx={{
                                p: 1,
                                flexDirection: 'column',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                            }}
                        >
                            <TextField
                                select
                                value={tag}
                                name={'tag'}
                                label={'Tag'}
                                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                    setTag(event.target.value);
                                }}
                                error={tagError !== ''}
                                helperText={tagError !== '' ? tagError : ''}
                                sx={{
                                    minWidth: '355px',
                                    width: '100%',
                                    mb: 2,
                                }}
                            >
                                {tags.map((tag) => (
                                    <MenuItem key={tag} value={tag}>
                                        {tag}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Stack>
                        <Stack
                            {...getRootProps()}
                            sx={{
                                p: 1,
                                flexDirection: 'column',
                                border: '1px dashed orange',
                                justifyContent: 'center',
                                ...sx,
                            }}
                        >
                            <input {...getInputProps()}/>
                            {displayResourceUrls.map((url, index) => (
                                <Box key={index} sx={{position: 'relative', mb: 1}}>
                                    <Box
                                        component="img"
                                        alt="Media"
                                        src={url}
                                        sx={{
                                            height: '100%',
                                            width: '100%',
                                        }}
                                    />
                                    <IconButton
                                        aria-label="delete-media"
                                        onClick={e => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            handleDeleteDocument(index);
                                        }}
                                        sx={{
                                            position: 'absolute',
                                            color: '#aaa',
                                            top: -8,
                                            right: 0,
                                        }}
                                    >
                                        <DeleteIcon/>
                                    </IconButton>
                                </Box>
                            ))}
                            {displayResourceUrls.length > 0 && <Divider/>}
                            {!displayResourceUrls.length && isDragActive
                                ? <Box sx={{textAlign: 'center', p: 1}}>Drop the files here ...</Box>
                                : <Box sx={{textAlign: 'center', p: 1}}>
                                    Drag 'n' drop files here, or click to select files.
                                </Box>
                            }
                        </Stack>
                        {fileError && <Stack
                            sx={{
                                flexDirection: 'row',
                                justifyContent: 'flex-start',
                                width: '100%',
                            }}
                        >
                            <Box
                                sx={{
                                    mt: 2,
                                    fontSize: '12px',
                                    color: '#d32f2f',
                                    pl: 3,
                                }}
                            >
                                {fileError}
                            </Box>
                        </Stack>}
                        <Box sx={{mt: 2}}>File size is limited to 130mb</Box>
                        {uploading && <Stack
                            sx={{
                                flexDirection: 'column',
                                justifyContent: 'flex-start',
                                width: '100%',
                            }}
                        >
                            <Box sx={{mt: 2}}>Uploading</Box>
                            <LinearProgress/>
                        </Stack>}
                    </Box>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseModal}>Cancel</Button>
                <Button onClick={handleConfirm}>Add Media</Button>
            </DialogActions>
        </Dialog>
        <ConfirmDeleteModal
            open={openConfirm}
            onClose={() => {
                setOpenConfirm(false);
            }}
            onConfirm={() => {
                handleDeleteDocument(0); // Assuming you want to delete the first file
                setOpenConfirm(false);
            }}
            title={'Confirm Delete'}
        />
    </Stack>;
};

export default MultipleMediaUpload;
