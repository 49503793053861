import useAuthenticatedFetch from "@/hooks/useAuthenticatedFetch.ts";
import {apiUrl} from "@/utils/api.ts";
import {useMutation, type UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {handleJsonApiError} from "jsonapi-zod-query";
import {AddCompanyFormValues} from "@/components/Forms/SubForms/AddEditCompanyPhoneNumberDialog.tsx";

export const useAddCompanyPhoneNumber = (
    companyUUID: string,
): UseMutationResult<void, Error, AddCompanyFormValues> => {
    const fetch = useAuthenticatedFetch();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (attributes) => {
            const response = await fetch(apiUrl(`/company/phone-number/create`), {
                method: "PUT",
                headers: {
                    "Content-Type": "application/vnd.api+json",
                },
                body: JSON.stringify({
                    companyUUID: companyUUID,
                    ...attributes
                }),
            });
            await handleJsonApiError(response);
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: ["company-phone-number-table-data", companyUUID],
            });
        },
    });
};
