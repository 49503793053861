import {useQuery, type UseQueryResult} from "@tanstack/react-query";
import useAuthenticatedFetch from "@/hooks/useAuthenticatedFetch.ts";
import {apiUrl} from "@/utils/api.ts";
import {createResourceCollectionSelector} from "jsonapi-zod-query";
import {caseTypeApiSchema} from "@/components/Forms/schema/caseTypeSchema.ts";

const caseTypeSelector = createResourceCollectionSelector({
    type: "caseType",
    attributesSchema: caseTypeApiSchema,
});

export type CaseForm = ReturnType<typeof caseTypeSelector>;

export const useFetchCaseTypes = (clientId: string): UseQueryResult<CaseForm> => {
    const fetch = useAuthenticatedFetch();

    return useQuery({
        queryKey: ["case-types"],
        queryFn: async ({ signal }) => {
            const url = apiUrl(`/case-type/for-client/${clientId}`);

            const response = await fetch(url, {
                signal,
                headers: {
                    accept: "application/vnd.api+json",
                },
            });

            return await response.json();
        },
        select: caseTypeSelector,
    });
};
