import HistoryIcon from '@mui/icons-material/History';
import DrawerContent from "@/components/Layout/DrawerContent.tsx";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import {AppBar, Box, Container, Drawer, IconButton, Toolbar, Typography} from "@mui/material";
import {bindTrigger, usePopupState} from "material-ui-popup-state/hooks";
import type {ReactNode} from "react";
import {useState} from "react";
import {Outlet} from "react-router-dom";
import UserMenu from "@/components/Layout/UserMenu.tsx";
import ActivityDrawer from "@/components/Layout/ActivityDrawer.tsx";
import {useEmployee} from "@/contexts/EmployeeProvider.tsx";

const drawerWidth = 240;
const activityDrawerWidth = 300;

const Layout = (): ReactNode => {
    const {employee} = useEmployee()
    const userMenuState = usePopupState({ variant: "popover", popupId: "user-menu" });
    const [mobileOpen, setMobileOpen] = useState(false);
    const [activityOpen, setActivityOpen] = useState(false);
    const [isClosing, setIsClosing] = useState(false);
    const handleDrawerClose = () => {
        setIsClosing(true);
        setMobileOpen(false);
    };

    const handleDrawerTransitionEnd = () => {
        setIsClosing(false);
    };

    const handleDrawerToggle = () => {
        if (!isClosing) {
            setMobileOpen(!mobileOpen);
        }
    };

    const drawerContent = <DrawerContent />;
    const activityContent = <ActivityDrawer />;

    return (
        <Box display="flex">
            <AppBar
                position="fixed"
                sx={{
                    width: {
                        xs: `calc(100% - ${activityOpen ? activityDrawerWidth : 0}px)`,
                        md: `calc(100% - ${drawerWidth}px - ${activityOpen ? activityDrawerWidth : 0}px)`,
                    },
                    ml: { md: `${drawerWidth}px` },
                    mr: `${activityOpen ? activityDrawerWidth : 0}px`,
                    height: '64px',
                    backgroundColor: '#313131'
                }}
            >
                <Toolbar>
                    <Typography
                        variant='h6'
                        sx={{
                            display: {xs: 'none', md: 'block'},
                            color: '#fff',
                        }}
                    >
                        Cambre & Associates
                    </Typography>
                    <IconButton
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { md: "none" }, color: '#fff' }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" component="div" sx={{ display: { md: "none" }, color: '#fff' }}>
                        Cambre
                    </Typography>
                    <IconButton
                        edge="end"
                        {...bindTrigger(userMenuState)}
                        sx={{
                            ml: "auto",
                            borderRadius: '20px',
                            '&:hover': {
                                borderRadius: '20px',
                            },
                            color: '#fff',
                        }}
                    >
                        <AccountCircleIcon />
                        <Typography sx={{mx: 1}}>{employee?.fullName}</Typography>
                    </IconButton>
                    <IconButton
                        edge="end"
                        sx={{
                            ml: "0",
                            color: '#fff',
                        }}
                        onClick={() => setActivityOpen(!activityOpen)}
                    >
                        <HistoryIcon />
                    </IconButton>
                    <UserMenu state={userMenuState} />
                </Toolbar>
            </AppBar>
            <Box component="nav" sx={{width: { md: drawerWidth }, flexShrink: { md: 0 } }}>
                <Drawer
                    variant="temporary"
                    open={mobileOpen}
                    onTransitionEnd={handleDrawerTransitionEnd}
                    onClose={handleDrawerClose}
                    ModalProps={{
                        keepMounted: true,
                    }}
                    sx={{
                        display: { xs: "block", md: "none" },
                        "& .MuiDrawer-paper": {
                            boxSizing: "border-box",
                            width: drawerWidth,
                            backgroundColor: '#313131',
                            color: '#fff',
                        },
                    }}
                >
                    {drawerContent}
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: "none", md: "block" },
                        "& .MuiDrawer-paper": {
                            boxSizing: "border-box",
                            width: drawerWidth,
                            backgroundColor: '#313131',
                            color: '#fff',
                        },
                    }}
                    open
                >
                    {drawerContent}
                </Drawer>
            </Box>

            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    py: 3,
                    width: { xs: "100%", md: `calc(100% - ${drawerWidth + (activityOpen ? activityDrawerWidth : 0)}px)` },
                    height: "calc(100vh - 64px)"
                }}
            >
                <Toolbar />
                <Container
                    maxWidth="xl"
                    sx={{
                        flexGrow: 1,
                        height: '100%',
                    }}
                >
                    <Outlet />
                </Container>
            </Box>

            {activityOpen && <Box component="nav" sx={{width: { md: activityDrawerWidth }, flexShrink: { md: 0 } }}>
                <Drawer
                    variant={"permanent"}
                    open={activityOpen}
                    anchor={'right'}
                    onTransitionEnd={handleDrawerTransitionEnd}
                    onClose={handleDrawerClose}
                    ModalProps={{
                        keepMounted: true,
                    }}
                    sx={{
                        "& .MuiDrawer-paper": {boxSizing: "border-box", width: activityDrawerWidth},
                    }}
                >
                    {activityContent}
                </Drawer>
            </Box>}
        </Box>
    );
};

export default Layout;
