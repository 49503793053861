import {z} from "zod";
import {dayjsLocalDateSchema} from "@/utils/zod.ts";

export const addEditLoanSchema = z.object({
    idUUID: z.string().optional().nullable(),
    idCompanyID: z.string().optional().nullable(),
    checkAmountMM: z.string().optional().nullable(),
    loanDate: dayjsLocalDateSchema.optional().nullable(),
    loanPayoffAmount: z.string().optional().nullable(),
    loanPayoffDate: dayjsLocalDateSchema.optional().nullable(),
    note: z.string().optional().nullable(),
});
