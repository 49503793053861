import {Typography} from "@mui/material";
import type {ReactNode} from "react";
import {Outlet} from "react-router-dom";

const TasksPage = (): ReactNode => {
    return (
        <>
            <Typography variant="h4" mb={2}>
                Tasks
            </Typography>

            <Typography variant="h4" mb={2}>
                ToDo: Build Tasks
            </Typography>

            {/*<MrtPeopleTable/>*/}
            <Outlet />
        </>
    );
};

export default TasksPage;
