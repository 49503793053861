import {Auth0Provider} from "@auth0/auth0-react";
import AuthGuard from "@/components/AuthGuard/index.ts";
import FullPageSpinner from "@/components/FullPageSpinner/index.ts";
import MultiProvider from "@/components/MultiProvider/index.ts";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import {CssBaseline} from "@mui/material";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {SnackbarProvider} from "notistack";
import {StrictMode} from "react";
import {ConfirmProvider} from "react-confirm-hook";
import {createRoot} from "react-dom/client";
import {RouterProvider} from "react-router-dom";
import {router} from "./router.js";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {LocalizationProvider} from "@mui/x-date-pickers";
import ThemeProvider from "@/components/ThemeProvider";
import {ReactQueryDevtools} from "@tanstack/react-query-devtools";

const container = document.getElementById("root");

if (!container) {
    throw new Error("Root element missing");
}

const queryClient = new QueryClient(
    {
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false,
            },
        },
    }
);
const root = createRoot(container);

root.render(
    <StrictMode>
        <MultiProvider
            providerCreators={[
                (children) => (
                    <ThemeProvider>
                        <CssBaseline />
                        {children}
                    </ThemeProvider>
                ),
                (children) => (
                    <QueryClientProvider client={queryClient}>
                        {children}
                        <ReactQueryDevtools initialIsOpen={false} />
                    </QueryClientProvider>
                ),
                (children) => <SnackbarProvider>{children}</SnackbarProvider>,
                (children) => <ConfirmProvider>{children}</ConfirmProvider>,
                (children) => (<LocalizationProvider dateAdapter={AdapterDayjs}>{children}</LocalizationProvider>),
                (children) => {
                    // if route is /client-case-status display without Auth0Provider or AuthGuard
                    if (window.location.pathname === "/client-case-status") {
                        return children;
                    }

                    return <Auth0Provider
                        domain={import.meta.env.VITE_APP_AUTH0_DOMAIN}
                        clientId={import.meta.env.VITE_APP_AUTH0_CLIENT_ID}
                        authorizationParams={{
                            redirect_uri: window.location.origin,
                            audience: import.meta.env.VITE_APP_AUTH0_AUDIENCE,
                            scope: "",
                        }}
                        onRedirectCallback={(appState) => {
                            void router.navigate(appState?.returnTo ?? "/");
                        }}
                        useRefreshTokens
                        cacheLocation="localstorage"
                    >
                        <AuthGuard>{children}</AuthGuard>
                    </Auth0Provider>
                },
                // (children) => <AuthGuard>{children}</AuthGuard>,
            ]}
        >
            <RouterProvider router={router} fallbackElement={<FullPageSpinner />} />
        </MultiProvider>
    </StrictMode>,
);
