import {Alert, CircularProgress, List, ListItem,} from "@mui/material";
import {type ReactNode} from "react";
import {HistoryItem, useFetchHistoryForCurrentUser} from "@/queries/useFetchHistoryForCurrentUser.ts";
import CaseHistoryItem from "@/components/History/CaseHistoryItem.tsx";
import CompanyHistoryItem from "@/components/History/CompayHistoryItem.tsx";
import PersonHistoryItem from "@/components/History/PersonHistoryItem.tsx";

const ActivityDrawer = (): ReactNode => {
    const {
        data,
        isError,
        isRefetching,
        isLoading,
    } = useFetchHistoryForCurrentUser();

    return (
        <>
            {(isRefetching ||isLoading) && <CircularProgress/>}
            {isError && <Alert severity='error'>Error fetching history</Alert>}
            {!(isRefetching ||isLoading) && <List>
                {data?.data && data.data.map((historyItem: HistoryItem) => {
                    if (historyItem.module === 'Cases') {
                        return <CaseHistoryItem key={historyItem.idUUID} historyItem={historyItem} />;
                    }

                    if (historyItem.module === 'People') {
                        return <PersonHistoryItem key={historyItem.idUUID} historyItem={historyItem} />;
                    }

                    if (historyItem.module === 'Companies') {
                        return <CompanyHistoryItem key={historyItem.idUUID} historyItem={historyItem} />;
                    }

                    return (
                        <ListItem key={historyItem.idUUID} disablePadding>
                            UNKNOWN
                        </ListItem>
                    );
                })}
            </List>}
        </>
    );
};

export default ActivityDrawer;
