import {useQuery, type UseQueryResult} from "@tanstack/react-query";
import useAuthenticatedFetch from "@/hooks/useAuthenticatedFetch.ts";
import {apiUrl} from "@/utils/api.ts";
import {createResourceCollectionSelector} from "jsonapi-zod-query";
import {caseStatusApiSchema} from "@/components/Forms/schema/caseStatusSchema.ts";

const caseStatusSelector = createResourceCollectionSelector({
    type: "caseStatus",
    attributesSchema: caseStatusApiSchema,
});

export type CaseStatus = ReturnType<typeof caseStatusSelector>;

export const useFetchCaseStatuses = (clientId: string): UseQueryResult<CaseStatus> => {
    const fetch = useAuthenticatedFetch();

    return useQuery({
        queryKey: ["case-status"],
        queryFn: async ({ signal }) => {
            const url = apiUrl(`/case-status/for-client/${clientId}`);

            const response = await fetch(url, {
                signal,
                headers: {
                    accept: "application/vnd.api+json",
                },
            });

            return await response.json();
        },
        select: caseStatusSelector,
    });
};
