import {Button, ButtonGroup} from "@mui/material";

type Props = {
    selectedTab: string;
    setSelectedTab: (selectedTab: string) => void;
}

const IntakeSpecialistFilters = ({selectedTab, setSelectedTab} : Props) => {
    return <ButtonGroup
        aria-label="Basic button group"
        fullWidth
        sx={{mb: 2}}
    >
        <Button
            onClick={() => setSelectedTab('')}
            variant={selectedTab === '' ? 'contained' : 'outlined'}
        >
            All Cases
        </Button>
        <Button
            onClick={() => setSelectedTab('New Client')}
            variant={selectedTab === 'New Client' ? 'contained' : 'outlined'}
        >
            New Client
        </Button>
        <Button
            onClick={() => setSelectedTab('Withdrawal Pending')}
            variant={selectedTab === 'Withdrawal Pending' ? 'contained' : 'outlined'}
        >
            Withdrawal Pending
        </Button>
        <Button
            onClick={() => setSelectedTab('Contract Sent')}
            variant={selectedTab === 'Contract Sent' ? 'contained' : 'outlined'}
        >
            Contract Sent
        </Button>
    </ButtonGroup>
};

export default IntakeSpecialistFilters;
