import {keepPreviousData, useQuery, type UseQueryResult} from "@tanstack/react-query";
import useAuthenticatedFetch from "@/hooks/useAuthenticatedFetch.ts";
import {apiUrl} from "@/utils/api.ts";
import {createResourceSelector} from "jsonapi-zod-query";
import {addressSchema} from "@/components/Forms/schema/addressSchema.ts";

const addressTypeSelector = createResourceSelector({
    type: "address",
    attributesSchema: addressSchema,
});

export type Address = ReturnType<typeof addressTypeSelector>;

type Props = {
    addressUUID : string | null;
    isQueryEnabled ?: boolean;
};
export const useFetchAddress = (
    {
        isQueryEnabled = true,
        addressUUID,
    } : Props
 ) : UseQueryResult<Address> => {
    const fetch = useAuthenticatedFetch();

    return useQuery({
        queryKey: [
            'address',
            addressUUID,
        ],
        enabled: isQueryEnabled,
        queryFn: async () => {
            const fetchURL = apiUrl(`/address/${addressUUID}`);

            const response = await fetch(fetchURL.href, {
                method: 'GET',
                headers: {
                    "Content-Type": "application/vnd.api+json",
                },
            });

            return await response.json();
        },
        select: addressTypeSelector,
        placeholderData: keepPreviousData,
    });
};
