import type {UseFormReturn} from 'react-hook-form';
import {MenuItem} from "@mui/material";
import {RhfTextField} from "mui-rhf-integration";
import type {PersonRequestSchemaType} from "@/components/Forms/PersonForm.tsx";

export const nameSuffixes = [
    { value: ''},
    { value: 'II'},
    { value: 'III'},
    { value: 'IV'},
    { value: 'V'},
    { value: 'Jr.'},
    { value: 'Ret.'},
    { value: 'Sr.' }
]


type Props = {
    form: UseFormReturn<PersonRequestSchemaType>;
}

const PersonNameSuffixSelect = ({form} : Props) => {
    return <RhfTextField
        select
        control={form.control}
        name={'nameSuffix'}
        label={'Suffix'}
        sx={{
            width: '100%',
            backgroundColor: '#fff',
        }}
    >
        {nameSuffixes?.map((role) => <MenuItem
            key={`role-${role.value}`}
            value={`${role.value}`}
        >
            {role.value}
        </MenuItem>)}
    </RhfTextField>
};

export default PersonNameSuffixSelect;
