import {Stack, Typography} from "@mui/material";
import type {ReactNode} from "react";
import MrtDemandDatesReportVirtualized from "@/components/Table/MrtDemandDatesReportVirtualized.tsx";
import {useEmployee} from "@/contexts/EmployeeProvider.tsx";

const ReportDemandDates = (): ReactNode => {
    const {hasPermission} = useEmployee()

    if (!hasPermission("view:report-demand-dates")) {
        return <div>You do not have access to this page.</div>;
    }

    return (
        <Stack
            sx={{
                flexGrow: 1,
                height: "100%",
                width: "100%",
                display: "flex",
            }}
        >
            <Typography variant="h4" mb={2}>Report: Demand-Dates</Typography>
            <MrtDemandDatesReportVirtualized/>
        </Stack>
    );
};

export default ReportDemandDates;
