import {Typography} from "@mui/material";
import type {ReactNode} from "react";
import {Outlet} from "react-router-dom";
import MrtCaseTable from "@/components/Table/MrtCaseTable.tsx";

const CasesPage = (): ReactNode => {
    return (
        <>
            <Typography variant="h4" mb={2}>
                Cases
            </Typography>

            <MrtCaseTable/>
            <Outlet />
        </>
    );
};

export default CasesPage;
