import {useMemo} from "react";

const useGetFormValueAsNumber = (watchValue: string | null | undefined) => {
    return useMemo(() => {
        let value = 0;

        if (typeof watchValue === 'string') {
            const sanitizedValue = watchValue.trim().replace(/,/g, '');

            value = Number.parseFloat(sanitizedValue);

            if (isNaN(value) || value === Infinity) {
                value = 0;
            }
        }

        return value;
    }, [watchValue]);
};

export default useGetFormValueAsNumber;
