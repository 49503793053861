import React, {type BaseSyntheticEvent, useState} from 'react';
import {Alert, Box, Button, Stack} from "@mui/material";
import banner from "@/assets/banner.png";
import {z} from "zod";
import {type SubmitHandler, useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {RhfTextField} from "mui-rhf-integration";
import {useFetchClientLogin} from "@/queries/useFetchClientLogin.ts";
import Dashboard from "@/ClientApplication/Dashboard.tsx";

const schema = z.object({
    caseNumber: z.string().trim().min(1),
    userPIN: z.string().trim().min(1),
});

type FieldValues = z.input<typeof schema>;
type TransformedValues = z.output<typeof schema>;

const Login: React.FC = () => {
    const [caseNumber, setCaseNumber] = useState<string>('')
    const [userPIN, setUserPIN] = useState<string>('')
    const enabled = !!caseNumber && !!userPIN;

    const {
        data,
        refetch,
        error,
    } = useFetchClientLogin({
        caseNumber,
        userPIN,
        enabled
    });

    const form = useForm<FieldValues, unknown, TransformedValues>({
        resolver: zodResolver(schema),
        defaultValues: {
            caseNumber: '',
            userPIN: ''
        }
    });

    const onSubmit: SubmitHandler<TransformedValues> = async (
        data: TransformedValues,
        event?: BaseSyntheticEvent
    ) => {
        event?.stopPropagation();
        setCaseNumber(data.caseNumber);
        setUserPIN(data.userPIN);

        if (error) {
            refetch();
        }
    }

    return (
        <Stack
            sx={{
                mt: 3,
                p: 4,
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            {data?.data && <Box
                sx={{
                    mt: 3,
                    p: 4,
                    borderRadius: '8px',
                    maxWidth: '600px',
                    width: '600px',
                    backgroundColor: '#fff',
                }}
            >
                <Dashboard caseData={data?.data}/>
            </Box>}
            {!data?.data && <Box
                sx={{
                    mt: 3,
                    p: 4,
                    borderRadius: '8px',
                    maxWidth: '400px',
                    backgroundColor: '#fff',
                }}
            >
                <Box className="row">
                    <a href="https://www.glenncambre.com" target="_blank" rel="noopener noreferrer">
                        <Box
                            component={'img'}
                            id="logoLink2"
                            src={banner}
                            alt="logo"
                            sx={{
                                maxWidth: '100%'
                            }}
                        />
                    </a>
                </Box>

                <form onSubmit={form.handleSubmit(onSubmit)}>
                    <Box className="col-md-12 mx-auto" id="mainBox">
                        <Stack
                            sx={{
                                m: 3,
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <Box className="field">
                                <RhfTextField
                                    control={form.control}
                                    name={'caseNumber'}
                                    label={'Case Number'}
                                    sx={{
                                        mb: 2,
                                    }}
                                    required
                                />
                            </Box>

                            <Box className="field">
                                <RhfTextField
                                    control={form.control}
                                    name={'userPIN'}
                                    label={'PIN'}
                                    sx={{
                                        mb: 2,
                                    }}
                                    required
                                />
                            </Box>


                            {<Stack
                                sx={{
                                    m: 2,
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                {error && <Alert
                                    severity={'error'}
                                    sx={{
                                        mb: 2,
                                    }}
                                >
                                    {error?.errors && error.errors.length ? 'Incorrect login information. Please try again.' : 'Too many retrys'}
                                </Alert>}

                                <Button
                                    variant='contained'
                                    type={'submit'}
                                    sx={{
                                        p: 1,
                                        borderRadius: '8px',
                                        fontWeight: 800,
                                        mb: 2,
                                        mr: 2,
                                    }}
                                >
                                    Sign in
                                </Button>
                            </Stack>}

                            <Stack
                                sx={{
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                }}
                            >
                                <Box sx={{mr: 1}}>Not a client?</Box>
                                <a href="https://glenncambre.com/contact/">Contact us.</a>
                            </Stack>
                        </Stack>
                    </Box>
                </form>
            </Box>}
            <Stack
                id="disclosure"
                className="mx-auto d-flex justify-content-center"
                sx={{
                    mt: 3,
                    maxWidth: '500px',
                    color: '#fff',
                    fontSize: '12px',
                    alignItems: 'center',
                    textAlign: 'center',
                    fontStyle: 'italic',
                }}
            >
                Notice: The information contained on this website is Attorney/Client Privileged and confidential
                information intended only for the use of the inBoxidual or entity named above. If the reader of
                this message is not the intended recipient, you are hereby notified that any dissemination,
                distribution, or copying of this communication is strictly prohibited.
            </Stack>
        </Stack>
    );
};

export default Login;
