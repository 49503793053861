import {z} from "zod";
import {dayjsLocalDateSchema} from "@/utils/zod.ts";

export const addProviderSchema = z.object({
    idUUID: z.string().nullable().optional(),
    idCompanyID: z.string().optional().nullable(),
    address: z.string().optional().nullable(),
    providerStartDate: dayjsLocalDateSchema.optional().nullable(),
    providerEndDate: dayjsLocalDateSchema.optional().nullable(),
    providerSelfPay: z.string().optional().nullable(),
    providerMedPay: z.string().optional().nullable(),
    providerReductionApproved: z.string().optional().nullable(),
    providerReduction: z.string().optional().nullable(),
    providerClientToPay: z.string().optional().nullable(),
    note: z.string().optional().nullable(),
    selectedMedia: z.string().optional().nullable(),
    selectedRecord: z.string().optional().nullable(),
    phoneNumber: z.string().optional().nullable(),
    providerBill: z.string().optional().nullable(),
    providerBalance: z.string().optional().nullable(),
    providerHealthInsPmt: z.string().optional().nullable(),
    providerAdjustment: z.string().optional().nullable(),
});
