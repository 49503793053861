import {z} from "zod";
import {dayjsLocalDateSchema} from "@/utils/zod.ts";
import {savePersonSchema} from "@/components/Forms/schema/personSchema.ts";
import {clientSchema} from "@/components/Forms/schema/caseFormSchemaInput.ts";

export const caseFormSchema = z.object({
    id: z.string(),
    idUUID: z.string(),
    clientName: z.string().nullable(),
    caseName: z.string().nullable(),
    caseType: z.string().optional().nullable(),

    anteLitemState: z.string().nullable(),
    dOICounty: z.string().nullable(),
    dateOfIncident: dayjsLocalDateSchema.nullable(),
    statuteOfLimitations: dayjsLocalDateSchema.nullable(),

    anteLitemYesNo: z.string().nullable(),
    anteLitemGovernmentEntity: z.string().nullable(),
    anteLitem: dayjsLocalDateSchema.nullable(),
    anteLitemEntity: z.string().nullable(),

    idCaseStatus: z.string().nullable(),
    liabilityAccepted: z.string().nullable(),
    demandLiabilitySentOn: dayjsLocalDateSchema.nullable(),
    litigationStatus: z.string().nullable(),

    idCaseStatusUM: z.string().nullable(),
    uMUIMAccepted: z.string().nullable(),
    demandUMSentOn: dayjsLocalDateSchema.nullable(),
    litigationSubStatus: z.string().nullable(),

    assignedTeam: z.string().nullable(),
    assignedToAttorney: z.string().nullable(),
    assignedToDisbursementSpecialist: z.string().nullable(),
    assignedToIntakeSpecialist: z.string().nullable(),

    assignedToParalegal: z.string().nullable(),

    financeLoan: z.string().nullable(),
    attorneyFee: z.string().nullable(),
    crmStatus: z.string().nullable(),

    // for conditional display
    idCaseStatusDisplayValue: z.string().optional().nullable(),

    // determined
    referralRadio: z.string().nullable(),

    // health insurance
    insidCompany: z.string().nullable().optional(),
    insMemberName: z.string().nullable().optional(),
    insMemberID: z.string().nullable().optional(),
    insPlanNumber: z.string().nullable().optional(),

    // accident info
    accidentReportDone: z.string().nullable().optional(),
    incidentReportDoneWho: z.string().nullable().optional(),
    accidentCaseNumber: z.string().nullable().optional(),
    pDSettled: z.string().nullable().optional(),
    pDSettledAmount: z.string().nullable().optional(),
    mainInjury1: z.string().nullable().optional(),
    mainInjury2: z.string().nullable().optional(),
    injury: z.string().optional().nullable(),
    clientPainScale: z.string().nullable().optional(),
    primaryStatute: z.string().nullable().optional(),
    accidentSummary: z.string().nullable().optional(),
    incidentLocation: z.string().nullable().optional(),

    clientPIN: z.string().nullable().optional(),

    idReferredBy: z.string().nullable().optional(),
    idReferredByCompany: z.string().nullable().optional(),

    idPeople: savePersonSchema.nullable(),
    idClient: clientSchema.nullable(),
})

export const casesFilterSchema = z.object({
    idCaseStatus: z.string().nullable(),
    caseType: z.string().nullable(),
    caseStatus: z.string().nullable(),
    daysToSol: z.string().nullable(),
})
