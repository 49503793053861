import {useFetchNotesForCase} from "@/queries/useFetchNotesForCase.ts";
import type {Note} from "@/types/Note.ts";
import {
    Card,
    CardContent,
    CardHeader,
    Grid2 as Grid,
    IconButton,
    List,
    ListItem,
    Paper,
    TextField,
    Typography
} from "@mui/material";
import React, {ChangeEvent, useMemo} from 'react';
import AddNoteDialog from "@/components/Forms/AddNoteDialog.tsx";
import DeleteIcon from "@mui/icons-material/Delete";
import {enqueueSnackbar} from "notistack";
import {getErrorMessage} from "@/utils/api.ts";
import {useDeleteNote} from "@/mutations/useDeleteNote.tsx";
import {useEmployee} from "@/contexts/EmployeeProvider.tsx";

const dateFormatterDashed = Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
    timeZone: 'America/New_York'
});

const NoteItem = ({note}: { note: Note }) => {
    const {hasPermission} = useEmployee()
    const deleteNote = useDeleteNote();
    const noteData = note.note;

    const title = useMemo(() => {
        let title = `${note.noteBy} - ${note.metaCreatedWhen ? dateFormatterDashed.format(note.metaCreatedWhen) : ''}`;

        if (note.tag) {
            title += ` - ${note.tag}`;
        }

        return title;
    }, [note]);

    const handleDeleteNote = (noteUUID: string) => {
        if (window.confirm('Are you sure you want to delete this Note?')) {
            deleteNote.mutate(
                {
                    noteUUID: noteUUID,
                },
                {
                    onSuccess: () => {
                        enqueueSnackbar("Note removed.", {variant: "success"});
                    },
                    onError: (error) => {
                        enqueueSnackbar("Error: Note could not be removed.", {variant: "error"});
                        console.info('error', getErrorMessage(error));
                    },
                },
            )
        }
    };

    return <Card sx={{height: '100%'}}>
        <CardHeader
            title={title}
            titleTypographyProps={{variant: 'body1'}}
            action={hasPermission("delete:note") && <IconButton
                onClick={(e) => {
                    e.stopPropagation();

                    if (note.idUUID) {
                        handleDeleteNote(note.idUUID);
                    }
                }}
                size="small"
            >
                <DeleteIcon/>
            </IconButton>}
            sx={{
                backgroundColor: '#e0e1e1',
            }}
        />
        <CardContent
            sx={{
                backgroundColor: '#f2f2f2',
                height: '100%',
            }}
        >
            {noteData && <Typography variant={'body2'}>
                {noteData.split('\n').map((line, i) => (
                    <React.Fragment key={`${note.idUUID}-${i}`}>
                        {line}
                        {i !== noteData.split('\n').length - 1 && <br/>}
                    </React.Fragment>
                ))}
            </Typography>}
        </CardContent>
    </Card>
};

type Props = {
    caseIdUUID: string;
    limitToCategory?: string;
}

const NoteList = ({caseIdUUID, limitToCategory}: Props) => {
    const [searchValue, setSearchValue] = React.useState('');
    const {
        data,
        error,
        isError,
        isRefetching,
        isLoading,
    } = useFetchNotesForCase({
        caseIdUUID,
    });

    let displayNotes: Note[] = [];

    if (data) {
        displayNotes = data;

        if (limitToCategory) {
            displayNotes = displayNotes.filter(note => {
                return note.tag?.toLowerCase().includes(limitToCategory.toLowerCase());
            });
        }

        if (searchValue) {
            displayNotes = displayNotes.filter(note => {
                return note.note?.toLowerCase().includes(searchValue.toLowerCase()) ||
                    note.noteBy?.toLowerCase().includes(searchValue.toLowerCase()) ||
                    note.tag?.toLowerCase().includes(searchValue.toLowerCase()) ||
                    note.metaModifiedWhen?.toString().toLowerCase().includes(searchValue.toLowerCase());
            });
        }
    }

    if (isLoading || isRefetching) {
        return <div>Loading...</div>;
    }

    if (isError) {
        return (
            <div>{error.message}</div>
        );
    }

    return <Grid container>
        <Grid size={12}>
            <Paper
                sx={{
                    mb: 2,
                    mx: 1,
                }}
            >
                <Grid
                    container
                    sx={{
                        borderRadius: '8px',
                        p: 1,
                    }}
                >
                    <Grid
                        size={12}
                        sx={{
                            p: 1,
                            mb: 1,
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <Typography variant={'h5'}>Notes</Typography>
                        <AddNoteDialog caseUUID={caseIdUUID}/>
                    </Grid>
                    <Grid size={12} sx={{p: 1, mb: 1}}>
                        <TextField
                            value={searchValue}
                            onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                setSearchValue(event.target.value);
                            }}
                            placeholder={'Search Notes'}
                            sx={{
                                width: '100%',
                            }}
                            size={'small'}
                        />
                    </Grid>
                    <Grid size={12} sx={{p: 1}}>
                        <List>
                            {displayNotes.map((note: Note) => <ListItem key={note.idUUID}>
                                    <NoteItem note={note}/>
                                </ListItem>
                            )}
                        </List>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    </Grid>
};

export default NoteList;
