import useAuthenticatedFetch from "@/hooks/useAuthenticatedFetch.ts";
import {apiUrl} from "@/utils/api.ts";
import {useMutation, type UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {createDataSelector, createResourceSelector, handleJsonApiError} from "jsonapi-zod-query";
import {companySchema} from "@/components/Forms/schema/companySchema.ts";

const resourceSelector = createResourceSelector({
    type: "company",
    attributesSchema: companySchema,
});

export const companySelector = createDataSelector(resourceSelector);
export type NewCompany = ReturnType<typeof companySelector>;

export const useAddCompany = (): UseMutationResult<NewCompany, Error, null> => {
    const fetch = useAuthenticatedFetch();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async () => {
            const response = await fetch(apiUrl(`/company/create`), {
                method: "PUT",
                headers: {
                    "Content-Type": "application/vnd.api+json",
                },
            });
            await handleJsonApiError(response);
            return await response.json();
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: ["companies"],
            });
        },
    });
};
