import {useState} from 'react';
import {Box, Collapse, Typography} from '@mui/material';
import MediaDocumentsListRaw from "@/components/Documents/MediaDocumentsListRaw.tsx";
import {Media} from "@/queries/useFetchMediaForCase.ts";

type Props = {
    title: string;
    mediaArray: Media[];
}

const MediaCollapsibleList = ({title, mediaArray} : Props) => {
    const [open, setOpen] = useState(false);

    const handleToggle = () => {
        setOpen(!open);
    };

    return (
        <Box sx={{ border: '1px solid #ccc', borderRadius: 2, margin: 2 }}>
            <Box
                onClick={handleToggle}
                aria-label="toggle list"
                sx={{
                    padding: 1,
                    borderBottom: open ? '1px solid #ccc' : 'none',
                }}
            >
                <Typography variant="h6" component="span">
                    {`${title} - (${mediaArray.length})`}
                </Typography>
            </Box>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <MediaDocumentsListRaw mediaArray={mediaArray}/>
            </Collapse>
        </Box>
    );
};

export default MediaCollapsibleList;
