import type {SelectOption} from "@/types/SelectOption.ts";

export const optionsYesNo : SelectOption[] = [
    {
        id: 'Yes',
        label: 'Yes',
    },
    {
        id: 'No',
        label: 'No',
    },
]

export const optionsYesNoWithBlank : SelectOption[] = [
    {
        id: '',
        label: '',
    },
    ...optionsYesNo,
]
