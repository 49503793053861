import {Button, ButtonGroup} from "@mui/material";

type Props = {
    selectedTab: string;
    setSelectedTab: (selectedTab: string) => void;
}

const DisbursementSpecialistFilters = ({selectedTab, setSelectedTab} : Props) => {
    return <ButtonGroup
        aria-label="Basic button group"
        fullWidth
        sx={{mb: 2}}
    >
        <Button
            onClick={() => setSelectedTab('')}
            variant={selectedTab === '' ? 'contained' : 'outlined'}
        >
            All Cases
        </Button>
        <Button
            onClick={() => setSelectedTab('Negotiation')}
            variant={selectedTab === 'Negotiation' ? 'contained' : 'outlined'}
        >
            Negotiation
        </Button>
        <Button
            onClick={() => setSelectedTab('Policy Limits')}
            variant={selectedTab === 'Policy Limits' ? 'contained' : 'outlined'}
        >
            Policy Limits
        </Button>
        <Button
            onClick={() => setSelectedTab('Hold Harmless')}
            variant={selectedTab === 'Hold Harmless' ? 'contained' : 'outlined'}
        >
            Hold Harmless
        </Button>
        <Button
            onClick={() => setSelectedTab('Checks Printed')}
            variant={selectedTab === 'Checks Printed' ? 'contained' : 'outlined'}
        >
            Checks Printed
        </Button>
        <Button
            onClick={() => setSelectedTab('Disbursed')}
            variant={selectedTab === 'Disbursed' ? 'contained' : 'outlined'}
        >
            Disbursed
        </Button>
        <Button
            onClick={() => setSelectedTab('Escrow')}
            variant={selectedTab === 'Escrow' ? 'contained' : 'outlined'}
        >
            Escrow
        </Button>
    </ButtonGroup>
};

export default DisbursementSpecialistFilters;
