import {z} from 'zod';
import {CircularProgress, Grid2 as Grid} from "@mui/material";
import {useEffect, useState} from "react";
import CaseTabs from "@/components/Forms/CaseTabs.tsx";
import {useFetchCase} from "@/queries/useFetchCase.ts";
import CaseFooter from "@/components/Forms/CaseFooter.tsx";
import {caseFormSchema} from "@/components/Forms/schema/caseFormSchema.ts";

export type CaseFormValues = z.infer<typeof caseFormSchema>;

type Props = {
    caseId: string;
}

const CaseForm = ({caseId} : Props) => {
    const [foundCaseId, setFoundCaseId] = useState<string | null>(null);
    const [foundCaseIdUUID, setFoundCaseIdUUID] = useState<string | null>(null);
    const [isFinishedLoading, setIsFinishedLoading] = useState(false);

    const {
        data,
        isError,
        isLoading,
        isPending,
        error
    } = useFetchCase(caseId);

    useEffect(() => {
        if (!isError && !isLoading && data) {
            setFoundCaseId(data.data.id);
            setFoundCaseIdUUID(data.data.idUUID);
            setIsFinishedLoading(true);
        }
    }, [data, isError, isLoading]);

    useEffect(() => {
        if (data?.data) {
            document.title = data.data.clientName  ?? 'Cambre Case'
        }
    }, [data?.data]);

    if (isError) {
        return (
            <div>{error.message}</div>
        );
    }

    if (isPending || isLoading || !isFinishedLoading) {
        return (
            <CircularProgress/>
        );
    }

    if (!(foundCaseId && foundCaseIdUUID) && isFinishedLoading) {
        return (
            <div>Case not found</div>
        );
    }

    return <>
        {foundCaseIdUUID && isFinishedLoading && <Grid
            container
            sx={{
                borderRadius: '8px',
                p: 1,
            }}
        >
            {foundCaseId && data.data && <CaseTabs
                foundCaseId={foundCaseId}
                caseIdUUID={foundCaseIdUUID}
                caseData={data.data}
            />}

            <Grid size={12} sx={{pb: 3, mb: 3}}>
                {foundCaseId && data.data && <CaseFooter caseId={foundCaseId} caseData={data.data}/>}
            </Grid>
        </Grid>}
    </>
};

export default CaseForm;
