import Layout from "@/components/Layout/index.ts";
import RootErrorBoundary from "@/components/RootErrorBoundary/index.ts";
import HomePage from "@/pages/HomePage/index.ts";
import {createBrowserRouter, type RouteObject} from "react-router-dom";
import CaseDetail from "@/pages/CaseDetail/CaseDetail.tsx";
import PeoplePage from "@/pages/PeoplePage/PeoplePage.tsx";
import PlacesPage from "@/pages/PlacesPage/PlacesPage.tsx";
import LeadsPage from "@/pages/LeadsPage/LeadsPage.tsx";
import CasesPage from "@/pages/CasesPage/CasesPage.tsx";
import TasksPage from "@/pages/TasksPage/TasksPage.tsx";
import ReportDemandDates from "@/pages/ReportDemandDates/ReportDemandDates.tsx";
import ReportSettlementFundStatus from "@/pages/ReportSettlementFundStatus/ReportSettlementFundStatus.tsx";
import ReportSol from "@/pages/ReportSol/ReportSol.tsx";
import PersonDetail from "@/pages/PersonDetail/PersonDetail.tsx";
import EmployeeProvider from "@/contexts/EmployeeProvider.tsx";
import CompanyDetail from "@/pages/CompanyDetail/CompanyDetail.tsx";
import ReportReferrals from "@/pages/ReportReferrals/ReportReferrals.tsx";
import ReportLastTouch from "@/pages/ReportLastTouch/ReportLastTouch.tsx";
import AdminUserManagement from "@/pages/AdminUserManagement/AdminUserManagement.tsx";
import EmployeeDetail from "@/pages/EmployeeDetail/EmployeeDetail.tsx";
import CaseStatusDetail from "@/pages/CaseStatusDetail/CaseStatusDetail.tsx";
import PartyRoleDetail from "@/pages/PartyRoleDetail/PartyRoleDetail.tsx";
import CaseTypeDetail from "@/pages/CaseTypeDetail/CaseTypeDetail.tsx";
import PublicClientLogin from "@/pages/PublicClientLogin/PublicClientLogin.tsx";

const pathRoutes: RouteObject[] = [
    {
        path: "/",
        element: <HomePage />,
    },
    {
        path: "/people",
        element: <PeoplePage />,
    },
    {
        path: "/places",
        element: <PlacesPage />,
    },
    {
        path: "/leads",
        element: <LeadsPage />,
    },
    {
        path: "/cases",
        element: <CasesPage />,
    },
    {
        path: "/tasks",
        element: <TasksPage />,
    },
    {
        path: "/reports/demand-dates",
        element: <ReportDemandDates />,
    },
    {
        path: "/reports/last-touch",
        element: <ReportLastTouch />,
    },
    {
        path: "/reports/settlement-fund-status",
        element: <ReportSettlementFundStatus />,
    },
    {
        path: "/reports/sol",
        element: <ReportSol />,
    },
    {
        path: "/reports/referrals",
        element: <ReportReferrals />,
    },
    {
        path: "/case-detail/:caseId",
        element: <CaseDetail />,
    },
    {
        path: "/party-role-detail/:partyRoleUUID",
        element: <PartyRoleDetail />,
    },
    {
        path: "/case-type-detail/:caseTypeUUID",
        element: <CaseTypeDetail />,
    },
    {
        path: "/person-detail/:personId",
        element: <PersonDetail />,
    },
    {
        path: "/employee-detail/:employeeId",
        element: <EmployeeDetail />,
    },
    {
        path: "/case-status-detail/:caseStatusUUID",
        element: <CaseStatusDetail />,
    },
    {
        path: "/place-detail/:placeId",
        element: <CompanyDetail />,
    },
    {
        path: "/admin/user-management",
        element: <AdminUserManagement />,
    },
    // {
    //     path: "/admin/case-status-setup",
    //     element: <AdminCaseStatus />,
    // },
    // {
    //     path: "/admin/party-role-setup",
    //     element: <PartyRoleSetup />,
    // },
    // {
    //     path: "/admin/case-type",
    //     element: <AdminCaseType />,
    // },
    // {
    //     path: "/reports/manage-teams",
    //     element: <AdminManagementTeams />,
    // },
];

const protectedRoutes: RouteObject = {
    path: "/",
    element: <EmployeeProvider><Layout /></EmployeeProvider>,
    errorElement: <RootErrorBoundary />,
    children: pathRoutes,
};

const pathRoutes2: RouteObject[] = [
    {
        path: "/client-case-status",
        element: <PublicClientLogin />,
    },
];

const protectedRoutes2: RouteObject = {
    path: "/",
    errorElement: <RootErrorBoundary />,
    children: pathRoutes2,
};


export const router = createBrowserRouter([protectedRoutes, protectedRoutes2]);

if (import.meta.hot) {
    import.meta.hot.dispose(() => router.dispose());
}
