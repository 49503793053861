import {keepPreviousData, useQuery, type UseQueryResult} from "@tanstack/react-query";
import useAuthenticatedFetch from "@/hooks/useAuthenticatedFetch.ts";
import {apiUrl} from "@/utils/api.ts";
import {createResourceSelector} from "jsonapi-zod-query";
import {phoneNumberSchema} from "@/components/Forms/schema/phoneNumberSchema.ts";

const phoneNumberTypeSelector = createResourceSelector({
    type: "phoneNumber",
    attributesSchema: phoneNumberSchema,
});

export type PhoneNumber = ReturnType<typeof phoneNumberTypeSelector>;

type Props = {
    phoneNumberUUID : string | null;
    isQueryEnabled ?: boolean;
};

export const useFetchPhoneNumber = (
    {
        isQueryEnabled = true,
        phoneNumberUUID,
    } : Props
 ) : UseQueryResult<PhoneNumber> => {
    const fetch = useAuthenticatedFetch();

    return useQuery({
        queryKey: [
            'phoneNumber',
            phoneNumberUUID,
        ],
        enabled: isQueryEnabled,
        queryFn: async () => {
            const fetchURL = apiUrl(`/phone-number/${phoneNumberUUID}`);

            const response = await fetch(fetchURL.href, {
                method: 'GET',
                headers: {
                    "Content-Type": "application/vnd.api+json",
                },
            });

            return await response.json();
        },
        select: phoneNumberTypeSelector,
        placeholderData: keepPreviousData,
    });
};
