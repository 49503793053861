import {keepPreviousData, useQuery, type UseQueryResult} from "@tanstack/react-query";
import type {MRT_ColumnFiltersState, MRT_PaginationState, MRT_SortingState,} from 'material-react-table';
import useAuthenticatedFetch from "@/hooks/useAuthenticatedFetch.ts";
import {apiUrl} from "@/utils/api.ts";
import {createResourceCollectionSelector} from "jsonapi-zod-query";
import {dtMetaSchema} from "@/components/Forms/schema/dtMetaSchema.ts";
import {partySchema} from "@/components/Forms/schema/partySchema.ts";

const resourceSelector = createResourceCollectionSelector({
    type: "party",
    attributesSchema: partySchema,
    documentMetaSchema: dtMetaSchema,
});

export type PartyDocument = ReturnType<typeof resourceSelector>;
export type MrtParty = PartyDocument['data'][number];

type Props = {
    caseIdUUID : string;
    roleName ?: string[];
    columnFilters ?: MRT_ColumnFiltersState;
    globalFilter ?: string;
    pagination ?: MRT_PaginationState;
    sorting ?: MRT_SortingState;
};

export const useFetchMrtPartiesForCase = (
    {
        caseIdUUID,
        roleName,
        columnFilters = [],
        globalFilter = '',
        pagination = {
            pageIndex: 0,
            pageSize: 1000,
        },
        sorting = [],
    } : Props
) : UseQueryResult<PartyDocument> => {
    const fetch = useAuthenticatedFetch();

    return useQuery({
        queryKey: [
            'case-parties-table-data',
            caseIdUUID,
            columnFilters,
            globalFilter,
            pagination.pageIndex,
            pagination.pageSize,
            sorting,
            roleName,
        ],
        queryFn: async () => {
            const fetchURL = apiUrl('/party/for-case');

            const response = await fetch(fetchURL.href, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    caseIdUUID: caseIdUUID,
                    roleName: roleName,
                    start: pagination.pageIndex * pagination.pageSize,
                    size: pagination.pageSize,
                    filters: columnFilters ?? [],
                    globalFilter: globalFilter ?? '',
                    sorting: sorting ?? [],
                }),
            });
            return await response.json();
        },
        select: resourceSelector,
        placeholderData: keepPreviousData,
    });
};
