import {useCallback} from 'react';
import {apiUrl} from "@/utils/api.ts";

type Props = {
    caseUUID : string;
    email : string;
}

const useEmailPinToClient = () : ({caseUUID, email} : Props) => Promise<Response> => {
    return useCallback(async (data : Props) : Promise<Response> => {
        const url = apiUrl('/case/email-client-pin');

        return await fetch(url, {
            method: 'POST',
            headers: {
                "Content-Type": "application/vnd.api+json",
            },
            body: JSON.stringify({
                ...data,
            }),
        });
    }, []);
};

export default useEmailPinToClient;
