import {z} from "zod";

export const zipCodeSchema = z.object({
    zIPPostal: z.string(),
    city: z.string().optional().nullable(),
    county: z.string().optional().nullable(),
    stateProvince: z.string().optional().nullable(),
    areaCode: z.string().optional().nullable(),
    fIPS: z.string().optional().nullable(),
    timeZone: z.string().optional().nullable(),
    daylightSavings: z.string().optional().nullable(),
    type: z.string().optional().nullable(),
    preferred: z.string().optional().nullable(),
    population: z.string().optional().nullable(),
    sOL: z.number().optional().nullable(),
    metaCreatedByPretty: z.string().nullable(),
    metaCreatedWhen : z.coerce.date().nullable(),
    metaModifiedByPretty: z.string().nullable(),
    metaModifiedWhen : z.coerce.date().nullable(),
});
