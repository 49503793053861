import {keepPreviousData, useQuery, type UseQueryResult} from "@tanstack/react-query";
import useAuthenticatedFetch from "@/hooks/useAuthenticatedFetch.ts";
import {apiUrl} from "@/utils/api.ts";
import {createResourceCollectionSelector} from "jsonapi-zod-query";
import {z} from "zod";

export const caseFormSchemaInputAutocomplete = z.object({
    idUUID: z.string(),
    clientName: z.string().optional().nullable(),
    caseName: z.string().optional().nullable(),
    caseType: z.string().optional().nullable(),
});

const companionCaseTypeSelector = createResourceCollectionSelector({
    type: "case",
    attributesSchema: caseFormSchemaInputAutocomplete,
});

export type CompanionCase = ReturnType<typeof companionCaseTypeSelector>;

type Props = {
    searchString: string;
};
export const useFetchCompanionCasesForAutocomplete = (
    {
        searchString,
    } : Props
 ) : UseQueryResult<CompanionCase> => {
    const fetch = useAuthenticatedFetch();

    return useQuery({
        queryKey: [
            `companion-case-autocomplete-${searchString}`,
        ],
        queryFn: async () => {
            const fetchURL = apiUrl('/case/search/autocomplete');

            const response = await fetch(fetchURL.href, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/vnd.api+json",
                },
                body: JSON.stringify({
                    searchString: searchString,
                }),
            });

            return await response.json();
        },
        select: companionCaseTypeSelector,
        placeholderData: keepPreviousData,
    });
};
